import { useState, useContext } from 'react';
import React from 'react';
import { InputText, InputPass, Button } from '../../a_model/Input';
import { LoginPage } from '../../a_model/Container';
import { Context } from '../e_contexts/AuthContext';
import { get } from '../../calendar/f_aux/fetch';
import { useParams } from 'react-router';

function Login() {
    const [login, setLogin] = useState({ usuario: '', senha: '' })
    const { user } = useParams()
    const { handleLogin } = useContext(Context)

    async function handleChange(e) {
        setLogin({ ...login, [e.target.name]: e.target.value })
    }

    function logar() {

        get(`/logar/${login.usuario}/${login.senha}`, then)
        function then(resp) {
            if (resp) {
                if (resp.senha) {
                    handleLogin(resp)
                } else {
                    window.alert('Senha incorreta')
                }
            } else {
                window.alert('Login incorreto')
            }
        }
    }

    function handleKeyDown(event) {
        //console.log('foi')
        if (event.key === 'Enter') {
            logar();
        }
    }

    return (

        <LoginPage
            logar={logar}
            login={login}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
        />
    )

}
export default Login