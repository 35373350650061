import React, { useRef, useEffect, useState, useContext } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from "react-dnd";
import Context from '../f_aux/context';
import { produce } from 'immer'

export function Sucess() {

  const [card, setCard] = useState([])
  useEffect(() => {
    // //console.log(props.refresh)
    fetch(`${process.env.REACT_APP_CALENDAR}/teste`, {
      method: "GET",
      heders: {
        'Content-type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((resp2) => {
        setCard(resp2)
        //console.log(resp2)
      })
      .catch(err => console.log(err))



  }, []);

  function move(fromList, toList, from, to){
    setCard(produce(card, draft=>{
    //  //console.log(fromList)
      const dragged = draft[fromList].agenda[from]

      draft[fromList].agenda.splice(from, 1)
      draft[toList].agenda.splice(to, 0, dragged)

    }))
  }


  
  return (
    <Context.Provider value={{ card, move }}>
    <DndProvider backend={HTML5Backend} >
      <div style={{ display: 'inline-flex' }}>
        {card.map((item, listIndex) =>
          <div key={listIndex}>
            <header>{item.nome}</header>
            {item.agenda.map((sub, index) =>
              <Card
                listIndex={listIndex}
                index={index}
                card={sub}
              />
            )}
          </div>
        )}
      </div>
    </DndProvider>
    </Context.Provider>
  )
}

function Card({ card, listIndex, index }) {

  const ref = useRef()
  const { move } = useContext(Context)

  const [{ isDragging }, dragRef] = useDrag({
    item: { listIndex, index, nome_cliente: card.nome_cliente },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {
      const draggedListIndex = item.listIndex
      const targetListIndex = listIndex
      const draggedIndex = item.index
      const targetIndex = index

      if (draggedIndex === targetIndex && draggedListIndex === targetListIndex) {
        return
      }

      const targetSize = ref.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2
      const draggedOffset = monitor.getClientOffset()
      const draggedTop = draggedOffset.y - targetSize.top

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return
      }

      move(draggedListIndex, targetListIndex, draggedIndex, targetIndex)
       
      item.index = targetIndex
      item.listIndex = targetListIndex
    }

  })

  dragRef(dropRef(ref))

  return (

    <div ref={ref} isDragging={isDragging} key={index} >
      <div style={{ cursor: ' grabbing', backgroundColor: '#fff', padding: '10px', margin: '5px', border: '1px solid black' }}>
        <label>{card.nome_cliente}</label>
      </div>
    </div>


  )
}