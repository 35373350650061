import { useEffect, useState } from "react";
import moment from "moment";
import "./Month.scss";
import 'moment/locale/pt-br'
import calendarBuild from "../calendar/f_aux/CalendarBuild";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { getTheme } from "./Theme";
export default function MonthCalendar(props) {
  const [currentMoment, setCurrentMoment] = useState(moment().locale("pt-br"));

  const [dateSelected, setDateSelected] = useState([]);


  moment.updateLocale("pt", {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  });


  return (

    <div className="monthPage">

      <>

        <div className="headerPage" style={{ background: getTheme(localStorage.getItem('tema'), 0) }}>

          <IconButton
            size="small"
            onClick={() => setCurrentMoment(currentMoment.clone().subtract(1, 'month'))}
          >
            <KeyboardArrowLeftIcon style={{ color: "#143296", fontSize: 40 }} />
          </IconButton>
          {currentMoment.format('MMMM YYYY')}
          <IconButton
            size="small"
            onClick={() => setCurrentMoment(currentMoment.clone().add(1, 'month'))}
          >
            <KeyboardArrowRightIcon style={{ color: "#143296", fontSize: 40 }} />
          </IconButton>

        </div>

        <div className="contentMonth">

          <MonthCard
            tema={props.tema}
            mudar={props.mudar}
            setCurrentFormat={props.setCurrentFormat}
            setCurrentDay={props.setCurrentDay}
            currentDay={props.currentDay}
            currentMoment={currentMoment}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
          />

        </div>
      </>

    </div>

  );
}

function MonthCard(props) {

  const [calendar, setCalendar] = useState([]);
  const weekDays = ["D", "S", "T", "Q", "Q", "S", "S"];
  const [currentSel, setCurrentSel] = useState(props.currentDay)

  function mudar(day) {
    props.mudar(day)
    setCurrentSel(day)
  }

  useEffect(() => {
    setCalendar(calendarBuild(props.currentMoment));


  }, [props.currentMoment]);

  return (
    <div className="monthCard">
      <div className="weekDays">
        {weekDays.map((value, index) => (
          <div className="weekDay" key={index}>
            {value}
          </div>
        ))}
      </div>
      {calendar.map((week, index) => (
        <div className='week' key={index}


        >
          {week.map((day, index) => (

            <div key={index} className='Days Days2'
              style={{ backgroundColor: day.format('DD/MM') == currentSel.format('DD/MM') ? '#f1c7f1' : '' }}
              onClick={() => mudar(day)}
            >
              <div
                style={{ color: day.format('M') == props.currentMoment.format('M') ? getTheme(props.tema, 0) : '#999' }}

              >
                {day.format("DD").toString()}
              </div>

            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
