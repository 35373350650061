import React, { useState, useContext } from "react";
import Context from "../Context";
import './Pdf.css'
import { Card } from "../../a_model/Container";
import { getArrayTheme } from '../../a_model/Theme'
import { update } from "../f_aux/fetch";

export function Custom() {
    const { tema, user, setTema } = useContext(Context)
    const [custom, setCustom] = useState(localStorage.getItem('tema'))
    const options = getArrayTheme()

    function alterar(index){
        
      
            setCustom(index)
            
        update(`/alterarTema`, { tema: index, menu: 0}, then)
        
        function then(){
            setTema(index)
            localStorage.setItem('tema', index)
        }
    }
    return (
        <Card >
            <div>
              
                
                <div>
                    <h3>Tema</h3>
                    <div>
                        {options &&
                            options.map((option, index) =>
                                <div key={index} className="itemTema">
                                    <Check onClick={() => alterar(index)} index={index} custom={custom} />
                                    {option.tema.map((item) =>
                                        <div className="tema" style={{ background: item }}>

                                        </div>
                                    )}
                                </div>
                            )
                        }
                    </div>
                </div>
                <footer>

                </footer>
            </div>
        </Card>

    )

}

function Check({ custom, onClick, index }) {

    return (
        <div key={index} onClick={onClick} className="checkCustom">
            <div style={{ opacity: custom == index ? 1 : 0 }}>

            </div>
        </div>
    )
}