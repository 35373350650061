import React, { useContext, useState, useEffect } from "react";
import Context from '../Context';
import { MdChatBubbleOutline, MdOutlinePhone, MdSend } from "react-icons/md";
import copy from "copy-to-clipboard";
import { get, insert } from "../f_aux/fetch";
import './CliForm.scss'
import VendasForm from "./VendasForm";
import { BiEdit } from 'react-icons/bi'
import { InputSearch, LabelText } from "../../a_model/Input";
import { EditarCadastro } from "../c_layouts/EditarCliente";

export default function CliForm({ form }) {

    const { user, dialog } = useContext(Context)
    const [project, setProject] = useState({ telefone: form.telefone, id_leads: form.id, tabela: 'acompanhamento', user, nota: '' })
    const [step, setStep] = useState(0)
    const [info, setInfo] = useState({ nome: false, leads: 0, comentarios: 0, agendamentos: 0, orcamentos: 0 })
    const [cliente, setCliente] = useState({ id: false, nome: false, telefone: '0000' })
    const [isCad, setIsCad] = useState(false)
    useEffect(() => {
        cliente.id &&
            get(`/getFormEvent/${cliente.telefone}`, setInfo)
    }, [cliente, isCad])


    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    const steps = [
        { content: <Informações isCad={isCad} setIsCad={setIsCad} setInfo={setInfo} setCliente={setCliente} cliente={cliente} dialog={dialog} info={info} />, nome: 'Informações', color: '#c7e3fa' },
        { content: <ComentariosLeads telefone={cliente.telefone} />, nome: `Leads (${info.leads})`, color: '#E1D9F8' },
        { content: <Comentarios id={cliente.id} />, nome: `Comentários (${info.comentarios})`, color: '#FADCF5' },
        { content: <Event id_cliente={cliente.id} />, nome: `Agendamentos (${info.agendamentos})`, color: '#FAD8D2' },
        { content: <VendasForm cliente={cliente} />, nome: `Orçamentos (${info.orcamentos})`, color: '#F7F3D6' },
    ]

    return (
        form &&
        <div id='cliFormContainer'>

            <div id='stepsForm' style={{ background: steps[step].color }}>
                <header>
                    {steps.map((item, index) =>
                        <button
                            key={index}
                            style={{ background: index == step ? item.color : 'transparent' }}
                            onClick={() => setStep(index)}
                        >
                            {item.nome}
                        </button>

                    )}

                </header>

                {steps[step].content}

            </div>

        </div>


    )
}

function Informações({ isCad, setIsCad, info, dialog, setCliente, cliente, setInfo }) {

    const titles = [
        { nome: 'Msg enviada', status: 'Mens', },
        { nome: 'Outro momento', status: 3, },
        { nome: 'Indeciso', status: 4, },
        { nome: 'Sem interesse', status: 2, },
        { nome: 'Sem Retorno', status: 'nao', },
        { nome: 'Lixeira', status: 'lixeira', },
        { nome: 'Agendado', status: 1, },
        { nome: 'Outros', status: 8, },
    ]


    function copiar(value) {
        copy(value)
        dialog('Copiado', 0)
    }

    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }

    function setName(item) {
        setCliente({ ['id']: item.id, ['nome']: item.nome_l, ['telefone']: item.telefone_l })
    }

    function limpar() {
        setCliente({ id: false, nome: false })
        setInfo({ id: false, nome: false, leads: 0, comentarios: 0, agendamentos: 0, orcamentos: 0 })
    }

    return (

        info.id ?
            <div id='infoLeads'>
                {isCad &&
                    <EditarCadastro
                        setIsCad={setIsCad}
                        cadastro={{ patients_idpatient: cliente.id, title: cliente.nome, telefone: cliente.telefone }}
                    />
                }


                <div style={{ background: 'transparent' }}>
                    <LabelText

                        svg='1'
                        header='Cliente:'
                        onClick={() => limpar()}
                    >
                        {info.nome}
                    </LabelText>
                    <LabelText

                        svg='0'
                        header='Código:'
                        onClick={() => copy(cliente.id)}

                    >
                        {info.id}
                    </LabelText>

                </div>
                <LabelInfo header='Telefone:'>
                    {info.telefone ? formatTel(info.telefone) : '-'}
                    <button onClick={() => copiar('3' + info.telefone)} style={{ background: '#038129' }}>
                        <MdOutlinePhone /> Ligação
                    </button >
                    <button onClick={() => copiar(info.telefone.substr(2, 2) + info.telefone.substr(5, info.telefone.length - 2))} style={{ background: '#1e5dd4' }}>
                        <MdOutlinePhone /> Chat
                    </button >

                    <button onClick={() => setIsCad(true)} style={{ background: '#1e5dd4' }}>
                        <BiEdit color='#fff' /> Editar
                    </button >
                </LabelInfo>
                <LabelInfo header='Status:'>
                    <span> {titles.find((element) => element.status == info.status)
                        ? titles.find((element) => element.status == info.status).nome
                        : info.status}</span>
                </LabelInfo>
                {info.interesse_l && <LabelInfo header='Interesse:'>
                    <span onClick={() => copiar(info.id)}>{info.interesse_l}</span>
                </LabelInfo>}
                {info.campanha && <LabelInfo header='Campanha:'>
                    <span onClick={() => copiar(info.rede_l)}>{info.campanha}</span>
                </LabelInfo>}
                <LabelInfo header='Data de cadastro:'>
                    <span >{info.data_cadastro && info.data_cadastro.substr(0, 10).split('-').reverse().join('/')}</span>
                </LabelInfo>

            </div>
            :
            <ClientesBar

                setCliente={setName}
            />

    )
}
function Comentarios({ id }) {

    const [comentarios, setComentarios] = useState([])

    useEffect(() => {

        get(`/buscarComentarios/${id}/acompanhamento`, setComentarios)

    }, [])

    return (
        <ul id='leadsFormList'>

            {comentarios.length > 0 ?
                comentarios.map((comentario, index) =>

                    <li key={index}>
                        <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)}:</label>
                        <p>{comentario.nota}</p>
                    </li>
                ) :

                <li style={{ height: '25px' }}>Nenhum Comentário</li>
            }
        </ul>

    )
}

function ComentariosLeads({ telefone }) {

    const [comentarios, setComentarios] = useState([])

    useEffect(() => {

        get(`/buscarComentariosLeads/${telefone}`, setComentarios)

    }, [])

    return (
        <ul id='leadsFormList'>

            {comentarios.length > 0 ?
                comentarios.map((comentario, index) =>

                    <li key={index}>
                        <label>{comentario.usuario} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)} ({comentario.leads}):</label>
                        <p>{comentario.texto}</p>
                    </li>
                ) :

                <li style={{ height: '25px' }}>Nenhum Comentário</li>
            }
        </ul>

    )
}

function Event({ id_cliente }) {

    const [event, setEvent] = useState([])

    useEffect(() => {
        id_cliente &&
            get(`/getLeadEvent/${id_cliente}`, setEvent)

    }, [])

    return (
        <ul id='leadsFormList'>

            {event.length > 0 && id_cliente ?
                event.map((item, index) =>

                    <li className='liEvent' key={index}>

                        <span>Data: {item.data.substr(2, 8).split('-').reverse().join('/')} - {item.hora.substr(0, 5)}</span>
                        <span>Avaliadora: {item.avaliadora}</span>
                        <span>Usuário: {item.usuario}</span>
                        <div style={{ background: item.color }} > {item.categ}</div>
                    </li>
                ) :

                <li style={{ height: '25px' }}>Nenhuma Avaliação</li>
            }
        </ul>

    )
}

function LabelInfo(props) {
    return (
        <div className='infoContainer'>
            <label >{props.header}</label>

            <span>{props.children}</span>
            {props.onClick &&
                <div onClick={props.onClick} > <BiEdit /> </div>}
        </div>


    )

}

function ClientesBar({ setCliente }) {
    const [search, setSearch] = useState('');
    const [clientes, setClientes] = useState([])

    useEffect(() => {
        if (search.length > 2) {
            get(`/getClientes/${search}/${localStorage.getItem('empresa')}`, setClientes)
        }

    }, [search])

    return (
        <InputSearch
            width='350px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setCliente={setCliente}
                clientes={clientes}
            />
        </InputSearch>
    );
}

function ListItem({ clientes, setCliente, search }) {
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {clientes.map((result, index) => (

                    <li key={index} onClick={() => setCliente(result)}>
                        <span>{result.nome_l || '-'}</span>{result.telefone_l && <span>{formatTel(result.telefone_l)}</span>}
                    </li>

                ))}
            </ul>

        </>
    );
}