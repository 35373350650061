import { useEffect, useState } from "react";
import 'moment/locale/pt-br'
import "./AtendForm.scss";
import { Button, } from "../../a_model/Input";
import { get, update, updateParams } from "../f_aux/fetch";

import { Escala } from "../c_layouts/Contents";

export function AtendForm(props) {

  const [project, setProject] = useState([])

  useEffect(() => {
    if (props.atend) {
      get(`/atendente/${props.atend}`, setProject)
    }
  }, [props.atend, props.project])



  function desativarAtendente() {
    let resp = window.confirm(`Deseja desativar esta atendente da agenda?`)
    if (resp) {
      updateParams(`/desativarAtendente/${props.atend}`, then)
      function then() {
        window.alert("Desativada!")
        props.setAtend('')
        get(`/atendentes/${props.unidade}`, props.setAtendentes)
      }
    }
  }

  function alterarAtendente() {
    update(`/alterarAtendente/${props.atend}`, project, then)
    function then() {
      window.alert("Agenda alterada!")
    }
  }

  return (
    props.atend ?
      <div className="atend">
        <div className="headerAtend">

          <Button
            click={() => alterarAtendente()}
            value='Salvar'
            color='#174fb6'
          />
          <Button
            click={() => desativarAtendente()}
            value='Desativar'
            color='#333'
          />

        </div>
        <Escala project={project} setProject={setProject} />

      </div>
      : <span>Selecione um(a) atendente...</span>
  );
}
