import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import DayForm from '../b_forms/DayForm'
import moment from "moment";
import { Dialog } from "../c_layouts/Dialog";
import MonthCalendar from "../c_layouts/MonthCalendar";
import Context from '../Context'
import { produce } from 'immer'
import { get } from "../f_aux/fetch";

function Calendar() {
  const { datap } = useParams()
  const { userp } = useParams()
  const user = userp || localStorage.getItem('login')
  const [agenda, setAgenda] = useState([])
  const [currentDay, setCurrentDay] = useState(datap ? moment(datap.split('-')).clone().locale("pt-br").subtract(1, 'month') : moment().subtract(moment().format('d'), 'days').locale("pt-br"));
  const [currentFormat, setCurrentFormat] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [refresh, setRefresh] = useState('')
  const [tema, setTema] = useState(0)
  useEffect(() => {
    setTema(localStorage.getItem('tema'))
    get(`/buscarTema/${user}`, then)
    function then(resp2) {
      localStorage.setItem('tema', resp2.tema)
      setTema(resp2)
    }

  }, [])
  useEffect(() => {
    get(`/getAgendaIecb/${currentDay.format('YYYY-MM-DD')}`, setAgenda)

  }, [currentDay, refresh, currentFormat])



  function subtractA(index) {
    setAgenda(produce(agenda, draft => {
      draft.splice(index, 1)
    }))
  }

  function mudar(value) {
    setCurrentDay(value)
  }
  const [text, setText] = useState(false)

  function dialog(text, type) {
    setText({ text: text, type: type })
  }

  return (
    <Context.Provider value={{
      currentFormat,
      setCurrentFormat,
      user,
      count: agenda.length,
      dialog,
      currentDay,
      setCurrentDay,
      refresh,
      setRefresh,
      isEdit,
      setIsEdit,
      subtractA,
      agenda,
      tema: tema,
      tema2: tema,
      setTema
    }}>
          <div className="agenda" >

            <div className='dayMenu1'>
              <MonthCalendar tema={tema} currentDay={currentDay} mudar={mudar} />
            </div>

            <div className='dayPage1'>
              <DayForm />
            </div>
          </div>     
      <Dialog value={text} />

    </Context.Provider>
  )
}

export default Calendar
