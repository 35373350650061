import React, { useState, useContext } from "react";
import Context from '../../Context';
import './Pdf.css'
import { Card } from "../../../a_model/Container";
import { getArrayTheme } from "../../../a_model/Theme";
import { update } from "../../../calendar/f_aux/fetch";

export function Custom() {
    const { user } = useContext(Context)
    const [custom, setCustom] = useState({user, tema: localStorage.getItem('tema')})
    const options = getArrayTheme()

    //console.log(localStorage.getItem('tema'), localStorage.getItem('login'))
    function alterar(item, index){
        
        let project = custom
        project.user = user
        if(item === 0){
            project.menu = index
            setCustom({...custom, ['menu']:index, user})
        }else{
            project.tema = index
            setCustom({...custom, ['tema']:index, user})
        }     
        update(`/alterarTema`, project, then)
       
        function then(){
            localStorage.setItem('tema', JSON.stringify(project.tema))
            window.location.replace(`/leadsComercial/${user}/1`)
        }
    }
    return (
        <Card >
            <div>
           
                <div>
                    <h3>Tema</h3>
                    <div>
                        {options &&
                            options.map((option, index) =>
                                <div key={index} className="itemTema">
                                    <Check onClick={() => alterar(1, index)} index={index} custom={custom.tema} />
                                    {option.tema.map((item) =>
                                        <div className="tema" style={{ background: item }}>

                                        </div>
                                    )}
                                </div>
                            )
                        }
                    </div>
                </div>
                <footer>

                </footer>
            </div>
        </Card>

    )

}

function Check({ custom, onClick, index }) {

    return (
        <div key={index} onClick={onClick} className="checkCustom">
            <div style={{ opacity: custom == index ? 1 : 0 }}>

            </div>
        </div>
    )
}