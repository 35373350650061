import React, { useState, useEffect, useContext, useMemo } from "react";
import { Form, Card, ContainerC, StepsForm } from "../../a_model/Container";
import { ComentariosForm, SearchList, Table, Table2, Th } from "../../a_model/Contents";
import { Modal } from '../../a_model/Modal'
import { get, insert, update } from "../../calendar/f_aux/fetch";
import { Button, InputSearch, InputText, TextArea2, LabelText3 } from "../../a_model/Input";
import Context from "../../calendar/Context";
import copy from "copy-to-clipboard";
import { IoIosSearch } from "react-icons/io";
import { getTheme } from "../../a_model/Theme";
import { MdOutlineSend } from "react-icons/md";
import gif from '../../img/carregando1.gif';

import { MdSend } from "react-icons/md";
import moment from "moment";

export default function Restantes() {
    const { user, data } = useContext(Context)
    const [isEdit, setIsEdit] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [statusStep, setStatusStep] = useState(0)
    const [currentCard, setCurrentCard] = useState({ card: [], valor: 0, clientes: 0 })
    const [loading, setLoading] = useState(gif);

    useEffect(() => {
        mudarStatus(0)
   
    }, [data]);

    function handleOpenModal(project) {
        setIsEdit(project)
        setIsOpen(true)

    }

    function mudarStatus(x) {
        setStatusStep(x)
        setLoading(gif)
        get(`/buscarRestantes/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${x}/${data.ver}`, thenE)
        function thenE(resp) {

            setCurrentCard({ ...currentCard, card: resp.cards, valor: resp.total, clientes: resp.clientes })
            setLoading(false)
        }

    }


    function Restantes() {

        const [search, setSearch] = useState('')
        const results = useMemo(() => {
            const lowerBusca = search.toLowerCase()
            return Array.isArray(currentCard.card) && currentCard.card.filter(user => user.nome_cliente ? (user.nome_cliente.toLowerCase().includes(lowerBusca) || user.nome_procedimento.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca)) : '');
        }, [search, currentCard])
        const titles = [
            { width: '320px', title: 'Cliente' },
            { width: '320px', title: 'Procedimento' },
            { width: '50px', title: 'Data' },
            { width: '60px', title: 'Crédito' },
            { width: '60px', title: 'Status' },
        ]


        const stepTitles = [
            "Todos",
            "Iniciados",
            "À iniciar",
            'Última Sessão',
            'Encerrados'
        ]
        return (

            <Form>
                <SearchList
                    stepTitles={stepTitles}
                    search={search}
                    setSearch={setSearch}
                    setStep={mudarStatus}
                    step={statusStep}
                >

                    <Table
                        height='70vh'
                        footer={search.length > 0 ? '' : `Clientes: ${currentCard.clientes} Procedimentos: ${Array.isArray(currentCard.card) && currentCard.card.length}, Valor Total: ${parseFloat(currentCard.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} `}
                    >
                        <Th array={titles} />
                        {Array.isArray(results) && results.map((dataCard, index) =>
                            <tr key={index} className='hover' onClick={() => handleOpenModal(dataCard)}>
                                <td>{dataCard.nome_cliente}</td>

                                <td style={{ maxWidth: '200px' }}>{dataCard.nome_procedimento}</td>
                                <td>{dataCard.ultimo ? dataCard.ultimo.substr(0, 10).split('-').reverse().join('/') : dataCard.data_venda.substr(0, 10).split('-').reverse().join('/')}</td>
                                <td >{parseFloat(dataCard.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ color: dataCard.ver == 0 ? '#208a49' : '#aaa' }}>{dataCard.ver == 0 ? 'Resolvido' : 'Aberto'}</td>
                            </tr>
                        )}

                    </Table>
                </SearchList>
            </Form>
        )
    }



    return (
        loading ?
            <div className="carregandoRestantes">
                <img src={loading} alt="Carregando..." />
            </div> :
            <>
                <Restantes />
                <Modal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                >
                    <ResModal
                        setIsOpen={setIsOpen}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                    />
                </Modal>
            </>

    )
}

function ResModal({ setIsOpen, setIsEdit, isEdit }) {

    const [dataCard, setDataCard] = useState([])
    const [step, setStep] = useState(0)
    useEffect(() => {
        get(`/getVendas/${isEdit.id_cliente}`, setDataCard)
    }, []);
    function resolver(isEdit) {

        update(`/resolverRestantes`, isEdit, setIsOpen)
    }

    function handleChange(e) {
        setIsEdit({ ...isEdit, [e.target.name]: e.target.value })
    }
    function formatTel(telefone) {
        if (telefone) {
            let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))

            return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
        } else {
            return 0
        }
    }

    const steps = [
        {
            content:
                <Vendas
                    dataCard={dataCard}
                    id_venda_sub={isEdit.id_venda_sub}
                />,
            nome: 'Lançamentos',
            color: '#ddd'
        },
        {
            content:
                <Comentarios
                    id_cliente={isEdit.id_cliente}
                />,
            nome: 'Comentários',
            color: '#ddd'
        },
    ]

    return (
        <div id='resModal' >

            <div id='headerModal'>
                <button onClick={() => setIsOpen(false)} id='buttonResolver'>
                    X
                </button>
                <div style={{ display: 'flex', margin: '15px 40px 0 15px' }}>
                    <LabelText3
                        header="Cliente:"
                        svg='1'
                        onClick={() => copy(isEdit.nome_cliente)}

                    >
                        {isEdit.nome_cliente}
                    </LabelText3>
                    <div style={{ marginLeft: '15px' }}>
                        <LabelText3
                            header="Telefone:"
                            svg='1'
                            onClick={() => copy(isEdit.telefone)}

                        >
                            {formatTel(isEdit.telefone)}
                        </LabelText3>
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                        <LabelText3
                            header="Total:"
                            svg='1'
                            onClick={() => copy(dataCard.total)}

                        >
                            {parseFloat(dataCard.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </LabelText3>
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                        <LabelText3
                            header="Crédito:"
                            svg='1'
                            onClick={() => copy(dataCard.credito)}

                        >
                            {parseFloat(dataCard.credito).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </LabelText3>
                    </div>
                    <div style={{ margin: '13px' }}>
                        <Button
                            value='Resolver'
                            color='#3db150'
                            click={() => resolver({ ...isEdit, ['ver']: 0 })}
                        />
                    </div>
                </div>

            </div>
            <StepsForm
                height='calc(90vh - 130px)'
                steps={steps}
                step={step}
                setStep={setStep}
            />
        </div>
    )
}

function Vendas({ dataCard, id_venda_sub }) {
    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: '1', nome: 'Matriz' },
        { id: '3', nome: 'Goiânia' },
        { id: '4', nome: 'Orion' },
        { id: '5', nome: 'Campinas' },
        { id: '10', nome: 'Altamira' },
        { id: '11', nome: 'Paulinia' },

    ]

    const [stepForm, setStepForm] = useState(0)
    const [assinaturas, setAssinaturas] = useState([])
    const titles = [
        { width: '', title: 'Procedimento' },
        { width: '', title: 'Sessoes' },
        { width: '', title: 'Restantes' },
        { width: '', title: 'Valor' },
        { width: '', title: 'Crédito' }
    ]
    const titles2 = [
        { width: '', title: '*' },
        { width: '', title: 'Data' },
        { width: '', title: 'Status' },
        { width: '', title: 'Atendente' },
        { width: '', title: 'Unidade' },
        { width: '', title: 'Evolução' },
    ]
    const status = [
        { nome: 'Pendente', cor: '#696969' },
        { nome: 'Ativo', cor: '#0f8f2f' },
        { nome: 'Encerrado', cor: '#b60000' },
        { nome: 'Indicação', cor: '#1A3BDB' }
    ]

    function getAss(id) {
        function then(resp2) {
            setAssinaturas(resp2)
            setStepForm(1)
        }
        get(`/getAss/${id}`, then)
    }

    const forms = [
        <ContainerC >

            {!Array.isArray(dataCard) ?


                dataCard.vendas1.map((venda, index) =>


                    <Card index={index} header={
                        <>
                            <div>
                                <label>Data:<span> {venda.data.substr(0, 10).split('-').reverse().join('/')}</span></label>
                                <label>Status:<span style={{ backgroundColor: status[venda.status].cor, color: '#fff', fontWeight: '600', padding: '0 4px', borderRadius: '8px' }}> {status[venda.status].nome}</span></label>
                            </div>
                            <div>
                                <label>Avaliadora:<span> {venda.avaliadora === venda.caixa ? '?' : venda.avaliadora}</span></label>
                                <label>Caixa:<span> {venda.caixa}</span></label>
                            </div>
                            <div>
                                <label>Unidade:<span> {unidades.find((element) => element.id == venda.unidade).nome}</span></label>
                                <label>Código:<span> {venda.id}</span></label>
                            </div>
                        </>
                    }>
                        <Table2 >
                            <Th array={titles} />
                            {venda.vendas_sub.map((vendaSub, index) =>

                                <tr onClick={() => getAss(vendaSub.id)}
                                    key={index}>

                                    <td style={id_venda_sub === vendaSub.id ? { color: '#1BA740', fontWeight: 500, maxWidth: '300px' } : {maxWidth: '300px'}}>
                                        {vendaSub.nome_procedimento}
                                    </td>
                                    <td>
                                        {vendaSub.qnt_sessao}

                                    </td>
                                    <td>
                                        {vendaSub.qnt_sessao - vendaSub.realizadas}
                                    </td>
                                    <td>
                                        {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                    </td>
                                    <td>
                                        {parseFloat(vendaSub.credito).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                    </td>
                                </tr>
                            )}
                        </Table2>

                    </Card>
                )

                : ''

            }
        </ContainerC>
        ,
        <ContainerC >


            <Table2 >
                <Th array={titles2} />
                {assinaturas.map((assinatura, index) =>
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{assinatura.data && assinatura.data.substr(0, 10).split('-').reverse().join('/')}</td>
                        <td style={{ backgroundColor: assinatura.status.color, color: '#f1f1f1' }}>{assinatura.status.status}</td>
                        <td>{assinatura.atendente}</td>
                        <td>{unidades.find((element) => element.id == assinatura.unidade).nome}</td>
                        <td>{assinatura.evolucao}</td>
                    </tr>
                )}
            </Table2>
            <Button
                value='Voltar'
                color='#b13d3d'
                click={() => setStepForm(0)}
            />
        </ContainerC>
    ]

    return (

        forms[stepForm]


    )
}

function Comentarios({ id_cliente }) {
    const { user } = useContext(Context)
    const [comentarios, setComentarios] = useState([])
    const [project, setProject] = useState({ id_leads: id_cliente, tabela: 'tratamento', user, nota: '', telefone: 0 })
    useEffect(() => {
        getComentarios()
    }, [])

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function getComentarios() {
        get(`/buscarComentarios/${id_cliente}/tratamento`, setComentarios)
    }

    function comentar() {

        if (project.nota.length > 0) {
            insert(`/insertTentativa`, project, getComentarios)
            setProject({ ...project, ['nota']: '' })
        } else {
            window.alert('Comentário vazio')
        }
    }

    return (
        <ComentariosForm height='calc( 80vh - 120px )'>
            <ul>

                {comentarios.length > 0 ?
                    comentarios.map((comentario, index) =>

                        <li key={index}>
                            <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)}:</label>
                            <p>{comentario.nota}</p>
                        </li>
                    ) :

                    <li style={{ height: '25px' }}>Nenhum Comentário</li>
                }
            </ul>
            <footer>
                <textarea
                    placeholder="Adicionar comentário..."
                    name='nota'
                    value={project.nota}
                    onChange={(e) => handleChange(e)}
                />
                <button
                    onClick={() => comentar()}
                >
                    Enviar <MdSend />
                </button>
            </footer>
        </ComentariosForm>
    )
}


