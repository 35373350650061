import React, { useEffect, useState } from "react"
import { get, insert, update } from "../f_aux/fetch"
import { produce } from "immer"
import { Button, InputFloat, InputText, LabelText, Select } from "../../a_model/Input"

export function EditarCadastro({ cadastro, setIsCad }) {
    const [project, setProject] = useState({ nome: cadastro.title, telefone: cadastro.telefone, id: cadastro.patients_idpatient })
    const [interesses, setInteresses] = useState({ id_cliente: cadastro.patients_idpatient, itens: [] })
    const [interessesGet, setInteressesGet] = useState([])
    
    const [listInteresses, setListInteresses] = useState([])
    useEffect(() => {
        get(`/getInteresses`, setListInteresses)
        get(`/getInteressesCliente/${cadastro.patients_idpatient}`, setInteressesGet)
    }, [])
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function adicionar(e) {
        setInteresses(produce(interesses, draft => {
            draft.itens.push(e.target.value)
        }))

    }

    function salvar() {

        update(`/alterarNomeAcompanhamento`, project, then)
        function then() {
            if (interesses.itens.length > 0) {
                insert(`/insertInteresses`, interesses, then2)
            } else {
                setIsCad(false)
            }
        }
        function then2() {
            setIsCad(false)
        }
    }



    return (
        <div id='editarCadastro'>
            <h2>Alterar Cadastro</h2>
            <div className='input'>
            <InputText
                title='Cliente'
                name='nome'
                value={project.nome}
                handleOnChange={handleChange}
            />
            </div>
            <div className='input'>
            <InputFloat
                title='Telefone'
                name='telefone'
                value={project.telefone}
                handleOnChange={handleChange}
            />
             </div>
             <div id='forma'>
            <Select
                options={listInteresses}
                padrao='Selecione um interesse'
                handleOnChange={adicionar}
            />
           {interesses.itens.length>0 &&
            <span>Selecionados:</span>}
            {interesses.itens.map((item)=>
            <LabelText>
                {listInteresses.find((element)=> element.id == item).nome}
            </LabelText>
            )}
            {interessesGet.length>0 &&
            <span style={{fontWeight:500}}>Salvos:</span>}
            {interessesGet.map((item)=>
            <LabelText>
                {item.nome}
            </LabelText>
            )}
            </div>
            <div className='inline1'>
                <Button
                    color='#447461'
                    value='Salvar'
                    click={() => salvar()}
                />
                <Button
                    color='#333'
                    value='Cancelar'
                    click={() => setIsCad(false)}
                />
            </div>
        </div>
    )

}