import React, { useState, useEffect } from "react";
import { InputDate, Button, Select, InputText } from "../../a_model/Input";

import { Card, Form } from "../../a_model/Container";
import { get, insert } from "../f_aux/fetch";

export function NewAten({ unidade, user, handleCloseModal }) {
    const [project, setProject] = useState({ unidade: unidade, login: 100 })
    const [_p, set_p] = useState([])
    const unidades = [
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 13, nome: 'Brasília' },
    ]

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //  //console.log(project)
    }
    function loginChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //console.log(project)
    }
    function unidadeChange(e) {
        setProject({ ...project, [e.target.name]: unidades[e.target.value].id })
        // //console.log()
    }

    useEffect(() => {

        get(`/buscar_p/${unidade}`, set_p)

    }, [])

    function cadastrar() {
        function then(resp2) {
            resp2.error ?
                window.alert(`Este nome já está cadastrado como "${resp2.result.nome}" na sua unidade. `)
                :
                window.alert(`Cadastrado!`)
        }
        if(project.login === 100){
           window.alert('Selecione o login')
        }else{
            insert('/insertAtend', project, then)
        }
    }

    return (

        <Card height='70%' width="300px" header={
            <h2>Nova atendente</h2>
        }>
            <div >
                <div>
                    <InputText
                        flex='column'
                        name='nome'
                        value={project.nome}
                        title='Nome'
                        handleOnChange={handleChange}
                    />

                    <Select
                        padrao='Login'
                        text='Login'
                        name='login'
                        options={_p}
                        handleOnChange={loginChange}
                    />
                    <div style={{ marginTop: '10px' }}>
                        <Select
                            disabled='true'
                            text='Unidade'
                            name='unidade'
                            value={project.unidade}
                            options={unidades}
                            handleOnChange={unidadeChange} />
                    </div>
                </div>

            </div>
            <footer>
                <Button
                    color='#174fb6'
                    click={cadastrar}
                    value='Cadastrar'
                />


            </footer>
        </Card>



    )

}