import { useState, useContext, useEffect } from 'react'
import { InputText, Button, InputTime, LabelText, LabelText3, InputDate, InputDateC, Checkbox } from '../../a_model/Input'
import './InputDay.css'
import RBar from './RBar'
import { insertA, deleteA, fecharA, editarA, fecharPeriodo } from '../f_aux/functions'
import moment from 'moment'
import { ProcList, SalaList } from './ProcList'
import { BsArrowReturnLeft, BsTrash } from 'react-icons/bs'
import { AiOutlineFileAdd, AiOutlineForm, AiOutlineCarryOut, AiOutlineLock, AiOutlineSwap } from 'react-icons/ai'
import copy from "copy-to-clipboard";
import { Form } from '../../a_model/Container'
import { get, insert } from '../f_aux/fetch'
import Context from '../Context'

function InputDay({ setInput, dataCard }) {
    const { group, dialog, unidade, user, setIsEdit } = useContext(Context)
    const [project, setProject] = useState(dataCard[0])
    const [currentName, setCurrentName] = useState(dataCard[0].nome_cliente ? dataCard[0].nome_cliente != 'Aberto' ? 0 : 1 : 1)
    const [currentProc, setCurrentProc] = useState(project.nome_procedimento ? 0 : 1)
    const [currentSala, setCurrentSala] = useState(project.sala ? 0 : 1)
    const [options, setOptions] = useState([])
    const [salaValue, setSalaValue] = useState(false)
    const [sala, setSala] = useState([])
    const [isBlock, setIsBlock] = useState(false)
    useEffect(() => {

        if (project.id == 0) {
            get(`/verificarHorario/${project.data}/${project.atendente}/${project.hora}/${project.hora_fim}`, corrigirHorario)
        } else {

            setProject({ ...project, ['duracao']: parseInt(project.duracao / 2) })
        }
    }, [])

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        setCurrentName(0)
    }
    function handleChange2(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }
    function duracaoChange(e) {
        if (moment(`2020.05.05 ${project.hora}`).add(e.target.value, 'minutes').format('HH:mm') > moment(`2020.01.01 ${'22:00'}`).format('HH:mm')) {
            let duracao =
                (22 - parseInt(project.hora.substr(0, 2))) * 60 - parseInt(project.hora.substr(3, 2))
            window.alert('Duração máxima permitida: ' + duracao + ' min.')
        } else if (moment(`2020.05.05 ${project.hora}`).add(e.target.value, 'minutes').format('HH:mm') < project.hora) {
            window.alert(`hora de início: ${project.hora} e hora de término: ${moment(`2020.05.05 ${project.hora}`).add(e.target.value, 'minutes').format('HH:mm')} ???`)
        } else {
            setProject({ ...project, ['duracao']: e.target.value, ['hora_fim']: moment(`2020.05.05 ${project.hora}`).add(e.target.value, 'minutes').format('HH:mm') })

        }
    }
    function inputClose(text, type) {
        setInput(0, dataCard[0])
        text && dialog(text, type)
    }
    function horaChange(e) {

        setProject({ ...project, ['hora']: e.target.value, ['hora_fim']: moment(`2020.05.05 ${e.target.value}`).add(project.duracao, 'minutes').format('HH:mm') })

    }
    function horaFimChange(e) {

        let duracao = (parseInt(e.target.value.substr(0, 2)) - parseInt(project.hora.substr(0, 2))) * 60 + parseInt(e.target.value.substr(3, 2)) - parseInt(project.hora.substr(3, 2))
        setProject({ ...project, ['hora_fim']: e.target.value, ['duracao']: duracao })
    }
    function insertAgenda(project) {
        project.user = user
        project.unidade = unidade

        if (project.nome_cliente && (project.sala || project.sala == 0) && project.nome_procedimento) {
            function then() {
                inputClose('Cadastrado', 0)
            }
            insert(`/maestrin`, project, then)
        } else {
            window.alert(`Erro! Cliente: ${project.nome_cliente || 'vazio'}, procedimento: ${project.procedimento || 'vazio'}`)

        }
    }

    function corrigirHorario(resp) {

        if (resp) {
            if (resp[0].hora >= project.hora + ':00') {
                setProject({ ...project, ['hora_fim']: resp[0].hora, ['duracao']: parseInt(project.duracao / 2) })

            } else if (resp[0].hora_fim >= project.hora + ':00') {
                setProject({ ...project, ['hora']: resp[0].hora_fim, ['duracao']: parseInt(project.duracao / 2) })

            } else {

                setProject({ ...project, ['duracao']: parseInt(project.duracao / 2) })

            }



        } else {
            setProject({ ...project, ['duracao']: parseInt(project.duracao / 2) })
        }
    }

    function limpar() {
        //console.log(dataCard)
        setProject({
            ...project, ['id']: 0,
            ['id_cliente']: '',
            ['nome_cliente']: '',
            ['procedimento']: '',
            ['sala']: '',
            
        })
        setCurrentName(1)
        setCurrentProc(1)
        setCurrentSala(1)
        setOptions([])

    }
    function limpar2() {
        //console.log(dataCard)
        setProject({
            ...project, ['id']: 0,
            ['id_cliente']: '',
            ['nome_cliente']: '',
            ['procedimento']: '',
            ['id']: '',
            ['sala']: '',
        })
        setCurrentName(1)
        setCurrentProc(1)
        setCurrentSala(1)
        setOptions([])

    }
    function setName(e) {
        setProject({ ...project, ['id_cliente']: e.id, ['nome_cliente']: e.nome, ['telefone']: e.telefone  })
        setCurrentName(0)
    }
    function setName2(e) {
        setProject({ ...project, ['id_cliente']: e.id_cliente, ['nome_cliente']: e.nome_cliente, ['telefone']: e.telefone })
        setCurrentName(0)
    }

    function setProcedimento(e) {

        get(`/disp/${project.data.substr(0, 10)}/${project.hora}/${project.hora_fim}/${e.sala}/${unidade}/${project.atendente}/${e.id_vendas}`, then)
        function then(resp2) {
            let resp3 = false
            if (resp2.pendencia) {
                window.alert('Este procedimento está bloqueado por inadimplência.')
            } else {
                if (resp2.block) {
                    window.alert("Procedimento bloqueado para essa atendente")
                    inputClose()
                } else {
                    if (!resp2.disp) {
                        resp3 = window.confirm(`Sala reservada para ${resp2.atendente} , confirma o agendamento nesse horário? [${resp2.id}]`)
                    }
                    if (resp2.disp || resp3) {
                        setSalaValue(e.sala + ' - ' + e.nome_sala)
                        setProject({ ...project, ['nome_procedimento']: e.nome, ['procedimento']: e.id_pacote, ['id_venda_sub']: e.id_vendas_sub, ['sala']: e.sala, ['duracao']: resp2.result, ['hora_fim']: moment(`2020.05.05 ${project.hora}`).add(resp2.result, 'minutes').format('HH:mm') })
                        e.sala ? setCurrentSala(0) : get(`/buscarSalas/${unidade}`, setSala)
                        setCurrentProc(0)
                    }
                }
            }
            }
        }

        function editarSala(e) {
            get(`/disp/${project.data.substr(0, 10)}/${project.hora}/${project.hora_fim}/${e}/${unidade}/${project.atendente}/50`, then)
            function then(resp2) {
                let resp3 = false
                if (resp2.block) {
                    window.alert("Procedimento bloqueado para essa atendente")
                    inputClose()
                } else {
                    if (!resp2.disp) {
                        resp3 = window.confirm(`Sala reservada para ${resp2.atendente} , confirma o agendamento nesse horário?`)
                    }
                    if (resp2.disp || resp3) {
                        setSalaValue(sala.find((element) => element.id == e).id + ' - ' + (sala.find((element) => element.id == e)).nome)
                        setProject({ ...project, ['sala']: (sala.find((element) => element.id == e)).id, ['duracao']: resp2.result, ['hora_fim']: moment(`2020.05.05 ${project.hora}`).add(resp2.result, 'minutes').format('HH:mm') })
                        setCurrentSala(0)

                    }
                }
            }
        }

        function limparProc() {
            get(`/procs/${project.id_cliente}`, setOptions)
            setCurrentProc(1)
        }


        function setThisSala() {
            setCurrentSala(1)
            get(`/buscarSalas/${unidade}`, setSala)
        }
        function formatTel(telefone) {
            let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
            return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
        }
        function setAnestesico(e){
            e.target.value === 1 ? 
            setProject({...project, ['id_venda_sub']: null,['procedimento']: null, ['nome_procedimento']: 'Anestésico', ['anestesico']: 1})
            :
            setProject({...project, ['id_venda_sub']: '',['procedimento']: '', ['nome_procedimento']: '', ['anestesico']: 0})
           
        }
        const nameStates = [
            <>
                <LabelText3
                    header="Cliente:"

                    onClick={() => copy(project.nome_cliente)}
                    onClick2={() => limpar(true)}
                >
                    {project.nome_cliente}
                </LabelText3>
                <LabelText3
                    header="Telefone:"

                    onClick={() => copy(project.telefone)}
                    onClick2={() => limpar(true)}
                >
                    {formatTel(project.telefone)}
                </LabelText3>
            </>,
            <RBar
                handleChange={handleChange}
                setName={setName}
                setName2={setName2}
                setCurrentName={setCurrentName}
                setOptions={setOptions}
                users={dataCard[0]} />
        ]

        const procStates = [
            <LabelText3
                header="Procedimento:"

                onClick={() => copy(project.nome_procedimento)}
                onClick2={() => limparProc()}

            >
                {project.nome_procedimento}
            </LabelText3>,
            <>
            {project.id_cliente &&
            <Checkbox
                event={setAnestesico}
                label='Anestésico'
            />}
            {!project.anestesico &&
            <ProcList
                setProc={setProcedimento}
                procs={options}
            />}
            </>


        ]

        const salaStates = [
            <LabelText3
                header="Sala:"
                onClick2={() => setThisSala()}
            >
                {project.sala ? salaValue || project.sala : ''}
            </LabelText3>,
            <SalaList
                salas={sala}
                editarSala={editarSala}
            />


        ]
        function transferir() {

            setInput(0, dataCard[0])
            setIsEdit(project)
        }

        const statusList = [
            { color: '#444444', status: 'Agendado' },
            { color: '#7B4EDE', status: 'Confirmado' },
            { color: '#0B87D4', status: 'Confirmado' },
            { color: '#07871E', status: 'Assinado' },
            { color: '#D67A01', status: 'Atrasado' },
            { color: '#D4CD0D', status: 'Reagendar' },
            { color: '#EB2009', status: 'Cancelado' },
            { color: '#111', status: 'Anulado' },

        ]


        return (
            <Form>
                <div className='inputForm'>
                    <div className='inline2'>
                        <LabelText
                            header='Data:'
                        >
                            {project.data && project.data.substr(0, 10).split('-').reverse().join('/')}
                        </LabelText>
                        <LabelText
                            header="Atendente:"
                        >
                            {project.nome_atendente}
                        </LabelText>
                        {project.id > 0 &&
                            <LabelText
                                header="Status:"
                            >
                                <div style={{ color: statusList[project.status].color }}> {statusList[project.status].status}</div>
                            </LabelText>

                        }
                    </div>

                    {isBlock ?
                        <div id='inputBlock'>
                            <h1>Selecione o período</h1>
                            <div className='inline2'>
                                <InputDateC
                                    flex='column'
                                    title='Início'
                                    name='data'
                                    value={project.data}
                                    handleOnChange={handleChange2}
                                />
                                <InputDateC
                                    flex='column'
                                    title='Término'
                                    name='data_fim'
                                    value={project.data_fim || project.data}
                                    handleOnChange={handleChange2}
                                />
                            </div>

                        </div>
                        :
                        nameStates[currentName]
                    }{procStates[currentProc]}
                    {project.id_venda_sub  || project.anestesico? salaStates[currentSala] : ''}
                    <div className='inline1'>
                        <InputTime

                            flex='column'
                            title='Início'
                            name='hora'
                            value={project.hora}
                            width='95px'
                            handleOnChange={horaChange}
                        />
                        <InputTime
                            flex='column'
                            disabled={false}
                            title='Término'
                            name='hora_fim'
                            value={project.hora_fim}
                            width='95px'
                            handleOnChange={horaFimChange}
                        />
                        <InputText
                            flex='column'
                            title='Duração'
                            value={project.duracao}
                            width='70px'
                            handleOnChange={duracaoChange}
                        />
                    </div>
                    <InputText
                        value={project.nota}
                        handleOnChange={(e) => handleChange2(e)}
                        name='nota'
                        width='500px'
                        flex='column'
                        title='Anotação'
                    />


                    {project.id ?
                        <div className='inline2'>
                            <LabelText>
                                {project.user} {project.data_agendamento && project.data_agendamento.substr(5, 5).split('-').reverse().join('/')} {project.data_agendamento.substr(11, 5).split(':').join('h')}
                            </LabelText>

                            <LabelText
                                header={'a'}
                            >
                                <div onClick={() => copy(project.id)}>
                                    {project.id}
                                </div>
                            </LabelText>
                            <LabelText
                                header={'v'}
                            >
                                <div onClick={() => copy(project.id_venda_sub)}>
                                    {project.id_venda_sub}
                                </div>
                            </LabelText>
                            <LabelText
                                header={'c'}
                            >
                                <div onClick={() => copy(project.id_cliente)}>
                                    {project.id_cliente}
                                </div>
                            </LabelText>
                        </div>
                        : ''
                    }
                </div>
             {   group != 4 &&
                <footer>
                    {
                        isBlock ? (
                            <>
                                <Button
                                    color="#9F4DAB"
                                    value='Inserir'
                                    click={() => fecharPeriodo(project,unidade, user, inputClose)}
                                >
                                    <AiOutlineLock />
                                </Button>
                                <Button
                                    color="#474747"
                                    value='Cancelar'
                                    click={() => setIsBlock(false)}
                                >
                                    <BsArrowReturnLeft />
                                </Button>
                            </>
                        ) : (
                            project.id > 0 ? (
                                <>
                                    <Button
                                        color="#2d4492"
                                        value='Novo'
                                        click={() => limpar2()}
                                    >
                                        <AiOutlineFileAdd />
                                    </Button>
                                    <Button
                                        color="#447461"
                                        value='Salvar'
                                        click={() => editarA(project, inputClose)}
                                    >
                                        <AiOutlineForm />
                                    </Button>
                                    <Button
                                        color="#8f2828"
                                        value='Desativar'
                                        click={() => deleteA(project, inputClose, user)}
                                    >
                                        <BsTrash />
                                    </Button>
                                    <Button
                                        color="#6c388f"
                                        value='Transferir'
                                        click={() => transferir()}
                                    >
                                        <AiOutlineSwap />
                                    </Button>
                                </>
                            ) : (
                                project.id_cliente ? (
                                    <Button
                                        color="#447461"
                                        value='Agendar'
                                        click={() => insertAgenda(project)}
                                    >
                                        <AiOutlineCarryOut />
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            color="#6c388f"
                                            value='Bloquear'
                                            click={() => fecharA(project, unidade, user, inputClose)}
                                        >
                                            <AiOutlineLock />
                                        </Button>
                                        <Button
                                            color="#9F4DAB"
                                            value='Bloquear Período'
                                            click={() => setIsBlock(true)}
                                        >
                                            <AiOutlineLock />
                                        </Button>
                                    </>
                                )
                            )
                        )}
                    {!isBlock &&
                        <Button
                            color="#474747"
                            value='Voltar'
                            click={() => setInput(0, dataCard[0])}
                        >
                            <BsArrowReturnLeft />
                        </Button>}



                    {/* Adicione outras condições e botões de maneira semelhante... */}

                </footer>
                }
            </Form>
        )
    } export default InputDay
