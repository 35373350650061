import React, { useContext, useState } from "react"
import Context from "../Context"
import moment from "moment"
import { InputDate, InputTime, InputText, Button } from "../../a_model/Input"

export function BlockSala({ sala, setIsOpen }) {
    const {user, unidade} = useContext(Context)
    const [project, setProject] = useState({
        sala: sala.id,
        nome_procedimento: sala.nome,
        user: user,
        unidade: unidade,
        data: moment().format('YYYY-MM-DD'),
        data_fim: moment().format('YYYY-MM-DD'),
        hora: '08:00',
        hora_fim: '22:00',
        nota: ''
    })
    function bloquear() {
        fetch(`${process.env.REACT_APP_CALENDAR}/insertBlockSala`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(project),
        })
            .then((resp) => resp.json()).then((data) => {

                window.alert(`Bloqueado`)
                setIsOpen(false)

            })
            .catch(err => console.log(err))
    }
    function handleChange(e) {


        if (project.data === project.data_fim && e.target.name === 'hora' && project.hora_fim < e.target.value) {
            window.alert('O horário de início deve ser anterior ao final ')
        } else if (project.data === project.data_fim && e.target.name === 'hora_fim' && e.target.value < project.hora) {
            window.alert('O horário final deve ser posterior ao de início ')
        } else {
            if (e.target.name === 'data' && project.data_fim < e.target.value) {
                setProject({ ...project, ['data']: e.target.value, ['data_fim']: e.target.value })
            } else if (e.target.name === 'data_fim' && e.target.value < project.data) {
                setProject({ ...project, ['data']: e.target.value, ['data_fim']: e.target.value })
            } else {
                setProject({ ...project, [e.target.name]: e.target.value })
            }
        }
    }
    return (
        <div style={{ backgroundColor: '#ddd', padding: '5px 5px 2px 5px' }}>
            <h2>Bloquear {sala.nome}</h2>
            <div className="inline1">
                <InputDate
                    name='data'
                    title="De: "
                    value={project.data}
                    handleOnChange={handleChange}
                />
                <InputTime
                    name='hora'
                    value={project.hora}
                    handleOnChange={handleChange}
                />

            </div>
            <div className="inline1">
                <InputDate

                    name='data_fim'
                    title='Até'
                    value={project.data_fim}
                    handleOnChange={handleChange}
                />
                <InputTime

                    name='hora_fim'

                    value={project.hora_fim}
                    handleOnChange={handleChange}
                />
            </div>
            <InputText
                flex='column'
                name='nota'
                width='400px'
                title='Justificativa: '
                value={project.nota}
                handleOnChange={handleChange}
            />
            <div className="inlineButton">

                <Button
                    value='Bloquear'
                    color='#961414'
                    click={() => bloquear()}
                />
                <Button
                    value='Voltar'
                    color='#222'
                    click={() => setIsOpen(false)}
                />
            </div>


        </div>
    )
}