import { Container, Label } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext, useMemo, useState, useEffect } from 'react'
import Context from '../../Context';
import styled from 'styled-components'
import CardEmpty from './CardEmpty';
import { get } from '../../../calendar/f_aux/fetch';
import './Cards.scss'

export default function Carteira() {

  const { search, data, empresa, user, leads } = useContext(Context)
  const [cards, setCards] = useState({ nome: 'Carteira', cards: [] });
  const [params, setParams] = useState({ page: 0, att: 0})
  useEffect(() => {
      get(`/getLead/${data.data}/${data.fim}/${user}/${empresa}/100/${params.page}`, setCards)
  
  }, [data, params])
  const results = useMemo(() => {
      const lowerBusca = search.toLowerCase()
      return cards.cards.filter(user => user.nome_l ? (user.nome_l.toLowerCase().includes(lowerBusca) || user.telefone_l.includes(lowerBusca) ) : '');
  }, [search, cards])
  function handleChange(e) {
    setParams({ ...params, [e.target.name]: e.target.value })
    
}
function att(){
  setParams({...params, ['att']: Math.random()})

}

  return (
    <div className ='cardsContainer' style={{width: 96/( leads.filter(item => item.display === true).length +1 )+'vw' }}>
      <header><h2>{cards.nome}</h2></header>
      <ul>
        { Array.isArray(cards.cards) && results.length > 0? 
         results.map((card, index)=>
        <Card att={att} index={index} card={card}/>

    ):
    <CardEmpty att={att} status={cards.status} index={0} indexCards={100}/>}
      </ul>
    </div>
  )
}

function Card({ card, index, att}) {
  const ref = useRef()
  const { move, handleOpenModal } = useContext(Context)
  const indexCards = 100
  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id: card.id, status: card.status, att },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',

    drop(item, monitor) {

      move(item, indexCards, att)



      //   var value2 = cadastro.status == 0 ? 1 : 0
      //let resp = window.confirm(`Deseja ocultar a ${props.atendente} atendente da agenda?`)
      // if (resp) {

      ////console.log(`${process.env.REACT_APP_CALENDAR}/agendar/${cadastro.id_venda_sub}/${cadastro.data.substr(0, 10)}/${confirm}/${cadastro.atendente}`)


    }



  })

  dragRef(dropRef(ref))

  return (
    <div className ='cardContainer' onClick={() => handleOpenModal(card)} ref={ref} isDragging={isDragging} index={index}>
      <header>

        <h3>{card.data_cadastro && card.data_cadastro.substr(0, 10).split('-').reverse().join('/')}</h3>
        <Label color='#7159c1' />
      </header>
      <p>{card.nome_l}</p>
      <p>{card.tentativas} tentativas ({card.data_carteira && card.data_carteira.substr(-13, 5)})</p>

    </div>
  )
}

