import { useState, useContext, useEffect } from 'react'
import { InputText, Button, InputNumber, Select } from '../../a_model/Input'
import './InputDay.scss'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { AiOutlineForm } from 'react-icons/ai'
import { get, insert } from '../f_aux/fetch'
import Context from '../Context'
import { LeftList2 } from "../../a_model/Contents";
import { toDate } from '../../a_model/functions'


export default function CadastrarAcompanhamento({ id_leads, setId_leads, aula, setRefresh, user2 }) {
    const { dialog, user } = useContext(Context)
    const [project, setProject] = useState({})
    const [cad, setCad] = useState(false)
    useEffect(() => {
        get(`/getLeadIecbCadastrar/${id_leads}`, setProject)

    }, [id_leads])

    function cadastrar(cadastro) {
        cadastro.usuario = user || user2
        insert(`/insertAcompanhamentoIecb`, cadastro, then)
        function then(resp) {
            if (aula.id_aula) {
                let conf = window.confirm('Cadastro realizado! Confirma o agendamento?')
                if (conf) {
                    setCad(
                        {
                            id_aluno: resp.insert.insertId,
                            id_aula: aula.id_aula,
                            data: aula.data,
                            valor: aula.valor,
                            usuario: user,
                            tipo: 1
                        }
                    )
                }
            } else {
                then2(resp)
            }
        }
        function then2(resp) {
            setRefresh(resp, true)
            if (aula.id_aula) {
                setId_leads(false)
            }
        }
    }

    function agendar() {
        function then(resp) {
            setRefresh(resp, true)
            setId_leads(false)
        }
        insert(`/insertAluno`, cad, then)
    }


    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    return (
        <div id='inputAgendadoIecb'>
            <h1>Cadastro de Cliente</h1>
            <div>

                <body>
                    <InputText
                        flex='column'
                        title='Aluna'
                        width='400px'
                        name='nome_l'
                        value={project.nome_l}
                        handleOnChange={(e) => handleChange(e)}
                    />
                    <InputNumber
                        flex='column'
                        title='Telefone'
                        width='150px'
                        name='telefone_l'
                        value={project.telefone_l}
                        handleOnChange={(e) => handleChange(e)}
                    />
                    <InputText
                        flex='column'
                        width='300px'
                        title='E-mail'
                        name='email_l'
                        value={project.email_l}
                        handleOnChange={(e) => handleChange(e)}
                    />
                    <InputText
                        flex='column'
                        width='300px'
                        title='Interesse'
                        name='interesse_l'
                        value={project.interesse_l}
                        handleOnChange={(e) => handleChange(e)}
                    />
                    <InputText
                        flex='column'
                        width='400px'
                        title='Anotação'
                        name='nota'
                        value={project.nota}
                        handleOnChange={(e) => handleChange(e)}
                    />

                </body>

            </div >
            <footer >
                <Button
                    color="#447461"
                    value='Cadastrar'
                    click={() => cadastrar(project)}
                >
                    <AiOutlineForm />
                </Button>
                <Button
                    color="#474747"
                    value='Voltar'
                    click={() => setId_leads(false)}
                >
                    <BsArrowReturnLeft />
                </Button>
            </footer>
            {cad &&
                <InsertAluno
                    cad={cad}
                    setCad={setCad}
                    agendar={agendar}
                />}
        </div >
    )
}

function InsertAluno({ cad, setCad, agendar }) {

    function handleChange(e) {
        setCad({ ...cad, [e.target.name]: e.target.value })
    }
    const tipos = [
        { id: 1, nome: 'Aluna' },
        { id: 2, nome: 'Modelo' },
    ]
    return (
        <div id='cad' >
            <span>Selecione a categoria</span>
            <Select
                options={tipos}
                handleOnChange={handleChange}
                name='tipo'
                value={cad.tipo}
            />
            <div style={{ display: 'flex' }}>
                <Button
                    value='Agendar'
                    color='#555'
                    click={() => agendar()}
                />
                <Button
                    color='#992222'
                    value='Cancelar'
                    click={() => setCad(false)}
                />
            </div>

        </div>
    )

}


