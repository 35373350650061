import { useContext, useEffect, useState } from 'react';
import React from 'react';
import Context from './Context';
import { Form } from '../a_model/Container';
import { get, insert, update } from '../a_model/fetch';
import { Button, InputText, LabelText3, Select, TextArea2 } from '../a_model/Input';
import { produce } from 'immer'
import { GiCheckMark } from 'react-icons/gi'
import css from './checklist.module.scss'
import { sortState } from '../a_model/functions';


export default function Options() {

    const { user, empresa, dialog, categorias } = useContext(Context)
    const [options, setOptions] = useState([])
    const [att, setAtt] = useState(false)
    useEffect(() => {
        get(`/get/checklist_options/0/0`, then)

    }, [att])

    function then(resp) {
        sortState(resp, setOptions, 'categoria', 'asc')
    }

    function subChange(e, index) {
        setOptions(produce(options, draft => {
            draft[index][e.target.name] = e.target.value
        }))

    }
    function updateAtivo(index, column) {

        update(`/editar/checklist_options/id/${options[index].id}`,
            { [column]: options[index][column] ? 0 : 1 },
            then
        )

        function then() {

            setAtt(Math.random())
        }
    }
    function cadastrar(cadastro) {
        insert(`/inserir/checklist_options`, cadastro, setAtt)

    }

    return (
        <Form height='100%'>
            <div id={css.grid}>
                <h1>Editar Checklist</h1>
                {options.map((item, index) =>
                    <>
                        {item.categoria ?
                            item.categoria !== options[index - 1].categoria ?
                                <h2>{categorias.find((element) => element.id == item.categoria).nome}</h2>
                                : '' : ''
                        }
                        <ItemIsActive
                            item={item}
                            index={index}
                            updateAtivo={updateAtivo}
                            setAtt={setAtt}
                        />
                    </>
                )}
            </div>
            <InsertItem cadastrar={cadastrar} indice={options.length} />
        </Form>
    )

}

function ItemIsActive({ item, index, updateAtivo, setAtt }) {

    const [project, setProject] = useState({ descr: item.descr })
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }
    function then2() {
        setStepDescr(0)
        setAtt(Math.random())
    }


    const stepDescrs = [

        <LabelText3
            onClick2={() => setStepDescr(1)}
            width='400px'
        >
            {item.descr || "Sem descrição"}
        </LabelText3>
        ,
        <TextArea2
            name='descr'
            value={project.descr}
            handleOnChange={handleChange}
        >
            <Button
                value='Salvar'
                click={() => update(`/editar/checklist_options/id/${item.id}`, project, then2)}
            />
            <Button
                value='Cancelar'
                color='#555'
                click={() => setStepDescr(0)}
            />
        </TextArea2>

    ]
    const [stepDescr, setStepDescr] = useState(0)
    return (
        <div className={css.itemIsActive} key={index}>
            <div className={css.nameDescr}>
                <span>
                    {item.nome}
                </span>
                {stepDescrs[stepDescr]}
            </div>
            <div className={css.checkbox}>
                <div>
                    <label>Ativo</label>
                    <div onClick={() => updateAtivo(index, 'ativo')}>
                        {item.ativo ?
                            <GiCheckMark /> : ''
                        }
                    </div>

                </div>
                <div>
                    <label>Status</label>
                    <div onClick={() => updateAtivo(index, 'status')}>
                        {item.status ?
                            <GiCheckMark /> : ''
                        }
                    </div>

                </div>
            </div>
        </div>
    )

}

function InsertItem({ indice, cadastrar }) {
    const { categorias } = useContext(Context)

    const [project, setProject] = useState({ nome: '', categoria: 0, indice })

    useEffect(() => {
        setProject({ nome: '', categoria: 0, indice })
    }, [indice])

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }


    return (
        <div className={css.insert}>
            <InputText
                title='Novo item'
                width='250px'
                name='nome'
                value={project.nome}
                handleOnChange={handleChange}
            />
            <Select
                width='150px'
                name='categoria'
                options={categorias}
                value={project.categoria}
                handleOnChange={handleChange}
            />
            <Button
                value='Inserir'
                color='#333'
                click={() => cadastrar(project)}
            />

        </div>
    )

}
