import React, { useEffect, useContext, useState } from "react";
import { getColor } from '../../calendar/c_layouts/salaColor'
import { Context } from "../e_contexts/AuthContext";
import { get } from "../../calendar/f_aux/fetch";

export default function Agenda() {
    const { params, toForm, login, step, statusList } = useContext(Context)
    const [cards, setCards] = useState(false)
    useEffect(() => {
        step == 0 &&
            get(`/getAgenda/${params.data}/${login}/${params.status}`, setCards)
         
    }, [params, step])
    const emptyText = [
        <p>Vazio</p>,
        <p >{'Nenhuma evolução pendente, você é 10!'} &#128526;</p>
    ]

    return (
        Array.isArray(cards) &&
        <div id="gridMobile">
            {cards.length > 0 ?
                cards.map((card, index) =>
                    <div key={index} className="agendaMobileCard" onClick={() => toForm(card, 1)}>
                        <header >
                            <div className="">
                                <span> {params.status == 1 ? `${card.data.substr(0, 10).split("-").reverse().join('/')} - ${card.hora}` : card.hora + ' - ' + card.hora_fim}</span>
                            </div>

                            <div className="agendaStatus">
                                <div style={{ background: `${statusList[card.status].color}` }}></div>
                                <span>{statusList[card.status].status}</span>
                            </div>

                        </header>
                        {card.status < 9 && <p>
                            {card.nome_cliente}
                        </p>}
                        {card.nome_procedimento && <span style={{ color: getColor(card.sala) + "99", WebkitTextStrokeColor: getColor(card.sala) }}>
                            {card.nome_procedimento}
                        </span>}

                        {card.nota && <label>
                            {card.user}: {card.nota}
                        </label>}
                    </div>
                )
                : <div style={{ padding: '15px' }} id="emptyCard">{emptyText[params.status]}</div>
            }
        </div>
    )
}