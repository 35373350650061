import React, { useContext, useEffect, useState } from "react";
import './Mobile.scss'
import { get, update } from "../../calendar/f_aux/fetch";
import { Context } from "../e_contexts/AuthContext";
import { BiLogOut } from 'react-icons/bi'
import { Button2, InputDate2, InputText2, Select2 } from "../../a_model/Input";
import moment from "moment";
import Agenda from "../b_forms/Orcamentos";
import LeadForm from "../b_forms/LeadForm";
import { FaRegFilePdf } from "react-icons/fa";
import Indicacoes from "../b_forms/Indicacoes";
import Tabelas from "../b_forms/Tabelas";
import { AiOutlineSwap } from 'react-icons/ai'
import { IoIosRefresh } from "react-icons/io";
import Atendimentos from "../b_forms/Atendimentos";

export default function Leads() {
    const { handleLogout, login } = useContext(Context)
    const [atendente, setAtendente] = useState('')
    const [params, setParams] = useState({
        data: moment().subtract(5, 'months').format('YYYY-MM-DD'),
        data_fim: moment().format('YYYY-MM-DD'),
        categ: 100,
        avaliadora: 100,
        page: 0,
        text: '',
        att: false
    })
    const [step, setStep] = useState(0)
    const [cardForm, setCardForm] = useState({})
    const statusList = [
        { status: 0, nome: 'Pendente', color: '#818181' },
        { status: 1, nome: '', color: '#3948d3' },
        { status: 2, nome: 'Indicado', color: '#3948d3' },

    ]

    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value })

    }

    useEffect(() => {
        get(`/loginMobile/${login}`, setAtendente)

    }, [])



    const bodys = [
        <Agenda />,
        <LeadForm />,
        <Indicacoes />,
        <Tabelas />,
        <Atendimentos />
    ]
    const header0 = (
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100vw', alignItems: 'center' }}>

            <Button2
                value={<FaRegFilePdf
                    color='#fff'
                />}
                onClick={() => setStep(3)}
            />


            <InputText2
                name='text'
                value={params.text}
                handleOnChange={handleChange}
                placeholder={step === 0 ? 'Renegociação' : 'Atendimentos'}
            />

            <Button2
                onClick={() => setStep(step === 0 ? 4 : 0)}
                value={<AiOutlineSwap
                    color='#fff'
                />}
            />

        </div>
    )
    const headers = [
        header0,

        <>
            <Button2
                value="Voltar"
                onClick={() => setStep(0)}
            />
        </>,
        <Button2
            value="Voltar"
            onClick={() => setStep(1)}
        />,
        <>
            <Button2
                value="Voltar"
                onClick={() => setStep(0)}
            />
        </>,
        header0
    ]

    function changeStatus() {
        setParams({ ...params, ['status']: params.status == 1 ? 0 : 1 })
    }
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value, ['status']: 0 })
    }


    function then2() {
        console.log('2')

    }

    function toForm(card, toStep) {
        //  get(`/get/vendas_servico/id/${card.id_vendas}`, step===0? then: then2)
        //    function then(resp) {
        //        if (moment(resp[0].data_carteira) < moment().subtract(1, 'day') || login == resp[0].id_usuario) {
        //            update(`/editar/vendas_servico/id/${card.id_vendas}`, { id_usuario: login, data_carteira: moment().format('YYYY-MM-DD HH:mm:ss') }, then2)
        //            console.log('1')
        //         } else {

        //            setParams({...params, ['att']: Math.random()})
        //            window.alert(`Este Lead já foi acessado por ${resp[0].id_usuario}`)
        //           
        //       }
        setStep(toStep)
        toStep === 1 && setCardForm(card)
        //}
    }

    return (
        <Context.Provider value={{ params, setParams, step, setStep, login, toForm, cardForm, setCardForm, statusList, atendente }}>
            <div id="containerMobile" translate="no">
                <header>
                    <div >
                        <div className="white">Seja bem-vinda!<label>{atendente.nome}</label></div>
                        <button onClick={() => handleLogout()} id='headerButtons'><BiLogOut /> Sair</button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {headers[step]}
                    </div>
                </header>
                <body>
                    {bodys[step]}
                </body>


            </div>
        </Context.Provider>
    )
}


