import { useState, useContext } from 'react';
import React from 'react';
import { InputText, InputPass, Button } from '../../a_model/Input';
import { LoginPage } from '../../a_model/Container';
import { Context } from '../e_contexts/AuthContext';
import { get } from '../../calendar/f_aux/fetch';
import { useParams } from 'react-router';

function Login() {
    const [login, setLogin] = useState({ usuario: '', senha: '' })
    const { user } = useParams()
    const { handleLogin } = useContext(Context)
    ////console.log('Login'+verif)


    async function handleChange(e) {
        setLogin({ ...login, [e.target.name]: e.target.value })


    }

    function logar() {

        get(`/loginMobile/${login.usuario}`, then)

        function then(resp) {
            if (resp) {
                if (login.senha === process.env.REACT_APP_PASS2) {
                    if (resp.inativo) {
                        window.alert("Usuário inativo")
                    } else {
                    
                        handleLogin(resp)
                    }
                } else {
                    window.alert('Senha incorreta')
                }
            } else {
                window.alert('Login incorreto')
            }
        }
    }
    return (

        <LoginPage
            logar={logar}
            login={login}
            handleChange={handleChange}
        />
    )

}
export default Login