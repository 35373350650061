import React,{useMemo, useContext} from "react";
import  { Container } from '../../styles/CardsStyle'
import Card from "./Card";
import CardEmpty from "./CardEmpty";
import Context from "../../Context";
import '../components.css'

export default function Cards({cards, indexCards}){

    const { search } = useContext(Context)
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return cards.cards.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca) || String(user.cidade).includes(lowerBusca) ) : '');
    }, [search, cards])
    return(
        <Container >
           <header><h2>{cards.nome} ({cards.cards.length})</h2></header>
           <ul>
            { Array.isArray(cards.cards) && results.length > 0? 
           results.map((card, index)=>
           <Card  status={cards.status} card={card} index={index} indexCards={indexCards} key={index}/>
            )
           :
            <CardEmpty status={cards.status} index={0} indexCards={indexCards}/>
           }
            </ul>
        </Container>
    )
}