import { Container, Label } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext, useEffect } from 'react'
import Context from '../../Context';
import './Card.scss'
import { getTheme } from '../../../a_model/Theme';
import { toDate } from '../../../a_model/functions';

export function Card({ att, card, index, indexCards }) {

  const ref = useRef()
  const { move, handleOpenModal, tema, data } = useContext(Context)

  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id: card.id, status: card.status, telefone: card.telefone_l, att },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
      move(item, indexCards, att)
      // att()
    }

  })

  dragRef(dropRef(ref))

  const colors = [
    { id: 0, color: '#9929EA' },
    { id: 1, color: '#7962EA' },

    { id: 2, color: '#9982EB' },
    { id: 3, color: '#93A4EB' },
    { id: 4, color: '#B2BEEA' },
    { id: 5, color: '#B2BEEAaa' },
    { id: 6, color: '#B2BEEA44' },
    { id: 7, color: '#ddd' },
  ]
  return (
    <div className={`cardContainer ${isDragging ? 'isDragging' : ''}`} onClick={() => handleOpenModal(0, card, att)} ref={ref} isDragging={isDragging}>
      <header style={{background:getTheme(tema, 3)+'99'}}>

        <h3>{card.data_cadastro && card.data_cadastro.substr(0, 10).split('-').reverse().join('/')}</h3>
        { indexCards == 11 && <h3>{card.status}</h3>}
        <Label color={card.tentativas < 7 ? colors.find((element) => element.id == card.tentativas).color : colors[7].color} />
      </header>
      <body>
      <p>{card.nome}</p>

      { card.nota?<span style={{color: '#2b59bb'}}>{card.nota}</span>
        :<span style={{fontWeight:500}}>{card.ultimo_texto || card.ultimo_status || card.interesse || '-'}</span>}
       {data.user == 100 &&<p style={{fontFamily:'Fira Sans'}}>{card.usuario}</p>}
      </body>
    </div>
  )
}

export function CardAgendado({ att, card, index, status_naoveio, disabled }) {

  const ref = useRef()
  const { move2, handleOpenModal, tema, data } = useContext(Context)

  const [{ isDragging }, dragRef] = useDrag({
    item: { status_naoveio, index, id: card.id, telefone: card.telefone, att, idevent: card.idevent },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
    !disabled && move2(item, status_naoveio, att)
    // //console.log(item, status_naoveio)
    }

  })

  dragRef(dropRef(ref))

  const colors = [
    { id: 0, color: '#EA072A' },
    { id: 1, color: '#EA072A' },
    { id: 20, color: 'transparent' },
    { id: 2, color: '#aaa' },
    { id: 3, color: '#aaa' },
    { id: 4, color: '#aaa' },
    { id: 5, color: '#13C32F' },
    { id: 6, color: '#111' },
    { id: 7, color: '#ddd' },
  ]
  
  return (
    <div className={`cardContainer ${isDragging ? 'isDragging' : ''}`} onClick={() => handleOpenModal(0, card, att)} ref={ref} isDragging={isDragging}>
      <header style={{background:getTheme(tema, 3)+'99'}}>

        <h3>{toDate(card.start_date)}</h3>
        <Label color={colors.find((element) => element.id == card.status_naoveio).color} />
      </header>
      <body>
      <p>{card.nome_cliente}</p>
    
      { card.descr2?<span style={{color: '#2b59bb'}}>{card.descr2}</span>
        :<p>-</p>}
          {data.user == 100 &&<p style={{fontFamily:'Fira Sans'}}>{card.usuario}</p>}
      </body>
    </div>
  )
}