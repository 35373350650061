import React, { useContext, useEffect, useState } from "react";
import './Mobile.scss'
import { get } from "../../calendar/f_aux/fetch";
import { Context } from "../e_contexts/AuthContext";
import { BiLogOut } from 'react-icons/bi'
import { Button2, InputDate2, Select2 } from "../../a_model/Input";
import moment from "moment";
import Agenda from "../b_forms/Agenda";
import Form from "../b_forms/Form";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import Relatorio from "../b_forms/Relatorio";
import Indicacoes from "../b_forms/Indicacoes";



export default function Mobile() {
    const { handleLogout, login } = useContext(Context)
    const [atendente, setAtendente] = useState('')
    const [params, setParams] = useState({ data: moment().format('YYYY-MM-DD'), status: 0 })
    const [step, setStep] = useState(0)
    const [cardForm, setCardForm] = useState({})
    const statusList = [
        { status: 'Agendado', color: '#818181' },
        { status: 'Confirmado', color: '#3948d3' },
        { status: 'Confirmado', color: '#3948d3' },
        { status: 'Evoluir', color: '#d339d3' },
        { status: 'Atrasado', color: '#d37e39' },
        { status: 'Reagendar', color: '#c2bf34' },
        { status: 'Cancelado', color: '#21a32c' },
        { status: 'Anulado', color: '#a32121' },
        { status: 'Finalizado', color: '#07871E' },
        { color: '#ccc', status: 'Bloqueio' },
    ]

    useEffect(() => {
        
        get(`/loginMobile/${login}`, setAtendente)
    }, [])

    const status = [
        { id: 0, nome: " Agenda " },
        { id: 1, nome: 'Pendente' }
    ]

    const bodys = [
        <Agenda />,
        <Form />,
        <Relatorio />,
        <Indicacoes />
    ]
    const headers = [
        <>
            <Button2
                value="Relatório"
                onClick={() => setStep(2)}
            />,
            <InputDate2
                name='data'
                classe='inputHeaderMobile white'
                value={params.data}
                handleOnChange={handleChange}
            />
            <Button2
                value={<div className="white">{status[params.status].nome} <CgArrowsExchangeAltV /></div>}
                onClick={() => changeStatus()}
            />
        </>,
        <>
        <div style={{marginRight:'10px'}}>
          <Button2
            value="Indicação"
            onClick={() => setStep(3)}
        />
        </div>
            <Button2
                value="Voltar"
                onClick={() => setStep(0)}
            />
        </>
        ,
        <>
            <InputDate2
                name='data'
                classe='inputHeaderMobile white'
                value={params.data}
                handleOnChange={handleChange}
            />
            <Button2
                value="Voltar"
                onClick={() => setStep(0)}
            />
        </>,
        <>
        <div style={{marginRight:'10px'}}>
          <Button2
            value="Voltar"
            onClick={() => setStep(1)}
        />
        </div>
            <Button2
                value="Agenda"
                onClick={() => setStep(0)}
            />
        </>

    ]
    function changeStatus() {
        setParams({ ...params, ['status']: params.status == 1 ? 0 : 1 })
    }
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value, ['status']: 0 })
    }
    function toForm(card, step) {
        setStep(step)
        step === 1 && setCardForm(card)
    }

    return (
        <Context.Provider value={{ params, setParams, step, setStep, login, toForm, cardForm, setCardForm, statusList, atendente }}>
            <div id="containerMobile" translate="no">
                <header>
                    <div >
                        <div className="white">Seja bem-vinda!<label>{atendente.nome}</label></div>
                        <button onClick={() => handleLogout()} id='headerButtons'><BiLogOut /> Sair</button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {headers[step]}
                    </div>
                </header>
                <body>
                    {bodys[step]}
                </body>

            </div>
        </Context.Provider>
    )
}


