import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './mobile/e_contexts/AuthContext'
import Login from './mobile/a_pages/Login'
import Mobile from './mobile/a_pages/Mobile'
import LeadsMobile from './mobile/a_pages/Leads'
import Menu from './Menu'
function App2() {
  return (


      <AuthProvider>
        <Routes>
          <Route exact path='/login' element={<Login />}></Route>
          <Route exact path='/leads' element={<LeadsMobile />}></Route>
          <Route exact path='/home' element={<Mobile />}></Route>
          <Route exact path='/menu' element={<Menu />}></Route>
          <Route exact path='/' element={<Menu />}></Route>
        </Routes>
      </AuthProvider>




  );
}

export default App2;