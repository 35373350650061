import React, { useState, useEffect } from "react";
import { AtendForm } from '../b_forms/AtendForm'
import { Header3 } from "../../a_model/Header";
import { Select } from "../../a_model/Input";
import SemanalForm from "../b_forms/SemanalForm";
import Context from "../Context";
import { BiCalendar } from "react-icons/bi";
import { Page } from "../../a_model/Container";
import { get } from "../f_aux/fetch";
import { getTheme } from '../../a_model/Theme'
import { useContext } from "react";

function EditA() {

    const empresa = localStorage.getItem('empresa')
    const user = localStorage.getItem('login')
    const [doctors, setDoctors] = useState([])
    const [project, setProject] = useState([])
    const { setCurrentForm } = useContext(Context)

    useEffect(() => {
        get(`/getDoctors/${empresa}`, setDoctors)

    }, [])
    const [atend, setAtend] = useState('')

    function setAtendente(e) {
      //  get(`/getModelDoctor/${e.target.value}`, console.log)
        setAtend(e.target.value)

    }
    function setStep(e) {

        setCurrentFicha(e.target.value)

    }
    const [currentFicha, setCurrentFicha] = useState(0)
    const navList = [
        { id: '0', nome: 'Modelo' },
        { id: '1', nome: 'Semanal' },
    ]
    const steps = [
        <AtendForm
            empresa={empresa}
            atend={atend}
            user={user}
            project={project}
            setDoctors={setDoctors}
            setAtend={setAtend}
        />
        ,
        <SemanalForm
            empresa={empresa}
            atend={atend}
            user={user}
            project={project}
        />


    ]

    return (
        <Context.Provider value={{ empresa, user }}>
            <Page>
                <Header3
                    buttons={[
                        <button onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>
                    ]}
                >
                    <Select
                        classe='input2'
                        padrao='Avaliadoras'
                        handleOnChange={setAtendente}
                        options={doctors}
                    />
                    <div style={{ paddingTop: '5px' }}>
                        <Select
                            classe='input2'
                            value={currentFicha}
                            handleOnChange={setStep}
                            options={navList}
                        />
                    </div>
                </Header3>
                <div style={{display: 'flex', justifyContent:'center', padding:'15px'}}>
                    {steps[currentFicha]}
                </div>
            </Page>
        </Context.Provider>

    )
}

export default EditA