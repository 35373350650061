import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Button, InputSearch, InputText } from '../../a_model/Input';
import { get } from '../f_aux/fetch';
import Context from "../Context";
import { IoIosSearch } from "react-icons/io";

export default function RBar({ setName }) {
    const [search, setSearch] = useState('');
    const { user, empresa } = useContext(Context)
    const [clientes, setClientes] = useState([])


    useEffect(() => {
        if (search.length > 2) {
            get(`/getClientes/${search}/${empresa}`, setClientes)
        }

    }, [search])

    return (
        <InputSearch
            width='450px'
            placeholder="Pesquisar cliente..."
            search={search}
            setSearch={setSearch}
            >
            <ListItem
                setName={setName}
                users={clientes}
                search={search}
            />
        </InputSearch>
    );
}

function ListItem({ search, users, setName }) {

    function clickName(user) {
        setName(user)
        if (user.obs) {
            window.alert(user.obs)
        }
    }

    return (
        search.length > 2 &&
        <ul>
            {users.map((user, index) => (
                <li
                    key={index} onClick={() => clickName(user)}
                >
                    <div >
                        {user.nome_l || '-'}

                    </div>

                    <div >

                        {" (" + user.telefone_l.substr(2, 2) + ") " + user.telefone_l.substr(4, (user.telefone_l.length - 8)) + "-" + user.telefone_l.substr(-4, user.telefone_l.length) || '-'}

                    </div>
                </li>
            ))}

        </ul>

    );
}
