import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    padding: 30px 0;

   
    display: flex;
    align-itens: center;
    

`