import React, { useState, useEffect, useMemo } from "react";
import Context from "../Context";
import { BiCalendar } from 'react-icons/bi'
import { getTheme } from '../../a_model/Theme'
import { useContext } from "react";
import CliForm from "../b_forms/CliForm";

export default function Clientes() {

    const unidade = localStorage.getItem('unidade')
    const user = localStorage.getItem('login')
    const [cliente, setCliente] = useState({telefone: '0000'})
    const { setCurrentForm } = useContext(Context)

    return (
        <Context.Provider value={{ unidade, user, setCliente, cliente }}>
            
            <button style={{position:'absolute', top: '7px', right:'7px', zIndex:'5', fontSize:'20px'}}
            onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>
                <div style={{ height:'100%',background: getTheme(localStorage.getItem('tema'), 1) }}>
                <CliForm form={cliente}/>,
                </div>

       
        </Context.Provider>
    )
}

