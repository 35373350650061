import React, { useContext, useState, useEffect } from "react";
import { Container, List, ListItem } from '../../styles/ModalStyle'
import Context from '../../Context';
import { LabelInfo } from "../../Input";
import { TextArea } from "../../styles/ContentStyle";
import { MdChatBubbleOutline, MdOutlinePhone, MdSend } from "react-icons/md";
import copy from "copy-to-clipboard";
import { get, insert } from "../../../calendar/f_aux/fetch";
import './LeadsForm.scss'
import VendasForm from "./VendasForm";

export default function LeadsForm({ form, handleCloseModal }) {

    const { user, dialog, data, setData } = useContext(Context)
    const [project, setProject] = useState({ telefone: form.telefone_l, id_leads: form.id_l, tabela: 'leads_iecb', user, nota: '' })
    const [step, setStep] = useState(0)

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function comentar() {
        function then() {
            handleCloseModal(form.status)
            dialog('Comentário inserido', 0)
            //  setData({ ...data, ['att']: Math.random() })
        }
        if (project.nota.length > 0) {

            insert(`/insertTentativaIecb`, project, then)
        } else {
            window.alert('Comentário vazio')
        }
    }


    const steps = [
        { content: <Comentarios info={form} dialog={dialog} />, nome: `Comentários`, color: '#FADCF5' },

    ]
    return (
        form &&
        <div id='leadsFormContainer'>

            <div onClick={() => handleCloseModal(form.status)} style={{ cursor: 'pointer', fontSize: '30px', position: 'absolute', top: '0', right: '10px', color: '#c02d2d' }}>
                x
            </div>
            <div id='stepsForm' style={{ background: steps[step].color }}>
                <header>
                    {steps.map((item, index) =>
                        <button
                            style={{ background: index == step ? item.color : 'transparent' }}
                            onClick={() => setStep(index)}
                        >
                            {item.nome}
                        </button>

                    )}

                </header>

                {steps[step].content}

            </div>
            <footer>
                <textarea
                    placeholder="Adicionar comentário..."
                    name='nota'
                    value={project.nota}
                    onChange={(e) => handleChange(e)}
                />
                <button
                    onClick={() => comentar()}
                >
                    <MdSend />
                </button>
            </footer>
        </div>


    )
}


function Comentarios({ info, dialog }) {
    //console.log(info)
    const titles = [
        { nome: 'Msg enviada', selecao: 'Mens', },
        { nome: 'Outro momento', selecao: 3, },
        { nome: 'Indeciso', selecao: 4, },
        { nome: 'Sem interesse', selecao: 2, },
        { nome: 'Novos', selecao: 'nao', },
        { nome: 'Lixeira', selecao: 'lixeira', },
        { nome: 'Agendado', selecao: 1, },
        { nome: 'Outros', selecao: 8, },
    ]
    function copiar(value) {
        copy(value)
        dialog('Copiado', 0)
    }

    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }

    const [interesses, setInteresses] = useState([])
    const [comentarios, setComentarios] = useState([])

    useEffect(() => {
        get(`/getInteressesLeadIecb/${info.telefone_l}`, setInteresses)

        get(`/buscarComentarios/${info.id_l}/leads_iecb`, setComentarios)

    }, [])

    return (
        <div id='infoLeadsContainer'>
            <header id='infoLeads'>

                <LabelInfo header='Cliente:'>
                    <span onClick={() => copiar(info.id_l)}>{info.nome_l}</span>
                </LabelInfo>
                <LabelInfo header='Telefone:'>
                    {info.telefone_l ? formatTel(info.telefone_l) : '-'}
                    <button onClick={() => copiar('3' + info.telefone_l)} style={{ background: '#038129' }}>
                        <MdOutlinePhone /> Ligação
                    </button >
                    <button onClick={() => copiar(info.telefone_l.substr(5, info.telefone_l.length - 2))} style={{ background: '#1e5dd4' }}>
                        <MdOutlinePhone /> Chat
                    </button >

                </LabelInfo>
                
                <div className="row">
                    <LabelInfo header='Data de cadastro:'>
                        <span >{info.data_cadastro_l.substr(0, 10).split('-').reverse().join('/')}</span>
                    </LabelInfo>
                    <LabelInfo header='Status:'>
                        <span> {titles.find((element) => element.selecao == info.selecao)
                            ? titles.find((element) => element.selecao == info.selecao).nome
                            : info.selecao}</span>
                    </LabelInfo>
                </div>

                {info.interesse_l && <LabelInfo header='Interesse (s):'>
                    <span onClick={() => copiar(info.id_l)}>{interesses.map((interesse, index) => (index > 0 ? ', ' : '') + interesse.interesse_l)}</span>
                </LabelInfo>}


                <LabelInfo header='Formação:'>
                    <span >{info.formacao}</span>
                </LabelInfo>
                <LabelInfo header='Modalidade:'>
                    <span >{info.modalidade}</span>
                </LabelInfo>
            </header>
            <ul id='leadsFormListIecb'>

                {comentarios.length > 0 ?
                    comentarios.map((comentario, index) =>

                        <li key={index}>
                            <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)}:</label>
                            <p>{comentario.nota}</p>
                        </li>
                    ) :

                    <li style={{ height: '25px' }}>Nenhum Comentário</li>
                }
            </ul>
        </div>

    )
}
