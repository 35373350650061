import React, { useEffect, useContext, useState, useRef } from "react";
import { Context } from "../e_contexts/AuthContext";
import { get, insert, update } from "../../calendar/f_aux/fetch";
import "./Form.scss"
import { MdSend } from "react-icons/md";
import { toCash, toDate, toDateTime, toTel } from "../../a_model/functions";
import Indicar from "./Indicar";
import copy from 'copy-to-clipboard'
import { Modal } from "../../a_model/Modal";
export default function LeadForm() {
    const { cardForm, setCardForm, setStep, atendente, login } = useContext(Context)
    const [comentarios, setComentarios] = useState([])
    const [isKey, setIsKey] = useState(35)
    const [att, setAtt] = useState(false)

    const [modalIsOpen, setIsOpen] = useState(false)
    const [stepForm, setStepForm] = useState(0)
    const [project, setProject] = useState
        ({
            id_leads: cardForm.id_vendas,
            texto: '',
            leads: 'leads_indicacao',
            id_cliente: cardForm.id_cliente,
            usuario: login,
            telefone: cardForm.telefone

        })
    const [itens, setItens] = useState([])


    useEffect(() => {
        setStepForm(0)


        get(`/getComentariosIndicacao/${cardForm.id_vendas}`, setComentarios)
    }, [cardForm, att])


    const buttonEvoRef = useRef(null);

    useEffect(() => {
        // Focar no botão quando o componente é montado
        if (buttonEvoRef.current) {
            buttonEvoRef.current.focus();
        }

    }, []);
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

        e.target.value.length < 28 && isKey != 35 ?
            setIsKey(35)
            : e.target.value.length < 54 && e.target.value.length > 27 && isKey != 60 ?
                setIsKey(60)
                : e.target.value.length < 81 && e.target.value.length > 53 && isKey != 90 ?
                    setIsKey(90)
                    : e.target.value.length < 100 && e.target.value.length > 80 && isKey != 120 ?
                        setIsKey(120) : console.log('')
    }

    function comentar(up) {
        if (up.texto.length) {

            insert(`/inserir/comentario_leads`, up, then)
        } else {
            window.alert("Evolução vazia")

        }
        function then() {

            update(`/editar/vendas_servico/id/${cardForm.id_vendas}`, { categ: 1, id_usuario: login }, then2)
        }
        function then2() {
            setAtt(Math.random())

        }
    }

    function indicar(itens) {
        setItens(itens)

        setStepForm(2)
    }

    function EvoCard({ comentario, index }) {
        const [project2, setProject2] = useState(comentario)
        const [isKey2, setIsKey2] = useState(35)
        const [state, setState] = useState(0)

        function handleChange2(e) {
            setProject2({ ...project2, [e.target.name]: e.target.value })

            e.target.value.length < 28 && isKey2 != 35 ?
                setIsKey2(35)
                : e.target.value.length < 54 && e.target.value.length > 27 && isKey2 != 60 ?
                    setIsKey2(60)
                    : e.target.value.length < 81 && e.target.value.length > 53 && isKey2 != 90 ?
                        setIsKey2(90)
                        : e.target.value.length < 100 && e.target.value.length > 80 && isKey2 != 120 ?
                            setIsKey2(120) : console.log('')
        }

        const line = [
            <span key={index} onClick={() => ''}>{comentario.texto}</span>,
            <div className="inputForm2">
                <textarea
                    style={{ height: isKey2 + 'px' }}
                    cols='27'
                    className="textAreaEvo"
                    name='evolucao'
                    placeholder="Inserir evoluçao..."
                    value={project2.texto}
                    onChange={(e) => handleChange2(e)}
                />

                <button
                    className="buttonEvo"
                    onClick={() => comentar(project2)}
                >
                    <MdSend />
                </button>
            </div>
        ]

        return (
            comentario.texto &&
            <div >

                <p>{toDateTime(comentario.data)} - {comentario.usuario}:
                    {comentario.usuario === atendente.nome ?
                        <button onClick={() => setState(state === 0 ? 1 : 0)}>
                            {state === 0 ? 'editar' : 'cancelar'}
                        </button>
                        : ''}
                </p>
                {line[state]}
            </div>
        )
    }

    const forms = [
        <>

            <Indicacoes setStep={setStep} indicar={indicar} />

            <div className="evoCards">

                <header>Comentarios:
                    <button style={{ marginLeft: '10px' }} onClick={() => setStepForm(1)}>
                        Adicionar
                    </button>
                </header>

                {comentarios.map((comentario, index) =>
                    <EvoCard index={index} comentario={comentario} />
                )}
            </div>

        </>,

        <>
            <div className="inputForm3">
                <textarea
                    style={{ height: isKey + 'px' }}
                    cols='27'
                    className="textAreaEvo textAreaFooter"
                    name='texto'

                    placeholder="Inserir Comentário..."
                    value={project.texto}
                    onChange={(e) => handleChange(e)}
                />

                <button
                    className="buttonEvo"
                    onClick={() => comentar(project)}
                >
                    <MdSend />
                </button>
            </div>
        </>,
        <Indicar itens={itens} setStepForm={setStepForm} />

    ]

    const [copyStep, setCopyStep] = useState(0)

    const copySteps = [
        <button onClick={() => copiar()}>
            Copiar
        </button>,
        <button disabled='true'>
            Copiado
        </button>
    ]
    function copiar() {
        copy(cardForm.telefone.substr(2, 2) +
            (parseInt(cardForm.telefone.substr(2, 1)) > 2 ?
                cardForm.telefone.substr(5, cardForm.telefone.length - 1)
                : cardForm.telefone.substr(4, cardForm.telefone.length - 1))
        )
        setCopyStep(1)

    }



    return (

        <div id="leadForm">
            <header>
                <div>
                    <p onClick={()=> copy(cardForm.id_cliente)}>
                        Cliente: <span>{cardForm.nome_cliente}</span>
                    </p>
                    <p>
                        Telefone:<span> {toTel(cardForm.telefone)}</span>
                        <span style={{ padding: '0 5px' }}>
                            {copySteps[copyStep]}
                        </span>
                    </p>
                </div>

            </header>
            <body>

                {forms[stepForm]}
                <div style={{height:'40px'}}></div>
            </body>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >
                <Info />
            </Modal>
        </div>


    )
}

function Info() {
    const { cardForm } = useContext(Context)
    const [interesses, setInteresses] = useState([])

    useEffect(() => {
        get(`/getInteressesCliente/${cardForm.id_cliente}`, setInteresses)
    }, [])

    return (
        <div>

        </div>
    )
}

function Indicacoes({ setStep, indicar }) {
    const { cardForm } = useContext(Context)
    const [vendas, setVendas] = useState([])
    function then(resp) {
        setVendas(resp.vendas1)
    }
    useEffect(() => {
        get(`/getVendas/${cardForm.id_cliente}`, then)

    }, [])

    return (
        <>

            <div>
                {vendas.map((card, index) =>

                    <div className="indicacaoCard" key={index}>
                        <header>
                            <span> {toDate(card.data)} - {card.avaliadora} </span>
                            <button onClick={() => indicar(card.vendas_sub)}>
                                Indicar
                            </button>
                        </header>
                        {card.vendas_sub.map((item, index) =>
                            <div key={index}>
                                <span style={{ fontWeight: 600, fontSize: '17px', color: item.venda == 'Sim' ? '#088b13' : '' }}>
                                    {item.nome_procedimento}
                                </span>
                                <span>
                                    {toCash(item.total)}
                                </span>
                            </div>
                        )}
                    </div>


                )}

            </div>
        </>

    )

}
