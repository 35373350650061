import React, { useContext } from "react";
import { useState, useEffect } from "react";
import './DiarioForm.css'
import { get, insert } from "../f_aux/fetch";
import { Form } from '../../a_model/Container'
import { Table, Th } from '../../a_model/Contents'
import Context from "../Context";

export default function Assinar() {
  const { params, user } = useContext(Context)
  const [card, setCard] = useState([])
  const [att, setAtt] = useState(0)
  
  useEffect(() => {

    get(`/buscarAssinar/${params.data}/${params.unidade}`,setCard)
    
  }, [att, params.data, params.unidade]);

  const titles = [
    { width: '', title: 'Nome' },
    { width: '', title: 'Procedimento' },
    { width: '', title: 'Atendente' },
    { width: '', title: 'Assinar' },

  ]

  return (
    user == 'fernanda' ||user == 'fellipe'||user == 'daiane' || user == 'renata' ?
    
    <Form>  
     { card.length > 0 ?
      <Table >
      <Th array={titles} />
        {card.map((item, index) =>
         <tr key={index}>
           
            <td style={{maxWidth:'150px'}}> {item.nome_cliente}</td>
            <td style={{maxWidth:'150px'}}> {item.nome_procedimento}</td>
            <td> {item.login}</td>
            <td onClick={()=>  insert(`/insertAP`,item,setAtt)}>
            Assinar
          </td>
          </tr>

        )}
      </Table>
     : 'Nenhuma assinatura pendente na data selecionada...' }
    </Form>
    :'Usuário não autorizado'
  )
}