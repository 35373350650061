import { useState, useEffect, useContext, useMemo } from 'react'
import { InputSearch, LabelText, LabelText3 } from "../../a_model/Input";

import { InputText, Button, Checkbox } from '../../a_model/Input'
import './SalaForm.css'
import { getColor } from '../c_layouts/salaColor'
import { Modal } from '../../a_model/Modal'
import { BiEdit } from 'react-icons/bi'
import { TbCalendarTime } from 'react-icons/tb'
import { CgArrowsExchangeAlt } from 'react-icons/cg'
import { SeletorDeCor, Table, Th } from '../../a_model/Contents'
import { Card, Form } from '../../a_model/Container'
import Context from "../Context";
import { get, update } from '../f_aux/fetch'
import { BlockSala } from '../c_layouts/BlockSala'
import { EditSala } from '../c_layouts/EditSala'
import copy from "copy-to-clipboard";

export function SalaForm() {
    const { unidade, user } = useContext(Context)
    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' },
        { id: 4, nome: 'Orion' },
        { id: 5, nome: 'Campinas' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 13, nome: 'Brasília' },
    ]
    const [salas, setSalas] = useState([])
    const [sala, setSala] = useState({})
    const [refresh, setRefresh] = useState({})
    const [modalIsOpen, setIsOpen] = useState(false)
    const [modal, setModal] = useState(0)
    function handleOpenModal(value, modal) {
        setSala(value)
        setIsOpen(true)
        setModal(modal)
    }
    useEffect(() => {
        ////console.log(unidade)
        get(`/buscarSalas/${unidade}`, setSalas)

    }, [refresh])



    function getColor1(sala) {
        return (salas.find((element) => element.id == sala).color)
    }

    const titles = [
        { width: '', title: 'id' },
        { width: '', title: 'Nome' },
        { width: '', title: 'Qnt' },
        { width: '', title: 'Tempo' },
        { width: '', title: 'Unid' },
        { width: '', title: <CgArrowsExchangeAlt /> },
        { width: '', title: 'Bloquear' },
    ]
    const modals = [
        <BlockSala
            sala={sala}
            setIsOpen={setIsOpen}
        />,
        <EditSala chave={sala} />
    ]
    return (

        <Form>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >
                {modals[modal]}
            </Modal>

            <Table>
                <Th array={titles} />
                {salas.map(sala =>

                    <tr key={sala.id}>
                        <td >
                            {sala.id}
                        </td>
                        <td style={{ width: `300px` }}>
                            <div style={{ backgroundColor: getColor1(sala.id), color: '#fff', padding: '10px', borderRadius: '10px' }}>{sala.nome || '-'}</div>

                        </td>

                        <td >

                            {sala.qnt || '-'}

                        </td>
                        <td >

                            {sala.duracao || '-'}

                        </td>
                        <td >

                            {unidades.find((element)=> element.id == sala.unidade).nome}

                        </td>
                        <td className='dele2' onClick={() => handleOpenModal(sala, 1)} >

                            <BiEdit />

                        </td >
                        <td className='offSala' onClick={() => handleOpenModal(sala, 0)} >

                            <TbCalendarTime />

                        </td >
                    </tr>
                )}


            </Table >


        </Form>
    )
}

export function NewSala({ setRefresh }) {

    const { unidade, user } = useContext(Context)
    const [project, setProject] = useState({ qnt: 1, unidade: 0, color: '#ccc', duracao: 40, indice: false })


    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })



    }
    function unidadeChange(e) {
        setProject({ ...project, ['unidade']: e.target.value === 0 ? 0 : unidade })



    }


    function insertSala(cadastro) {

        (fetch(`${process.env.REACT_APP_CALENDAR}/insertSala`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(cadastro),
        })
            .then((resp) => resp.json()).then((data) => {
                window.alert('Cadastrado!')

            })
            .catch(err => console.log(err)))
    }

    function setProject2(sala) {
        setProject({ ...project, ['indice']: sala.indice, ['color']: sala.color, ['nome_sala']: sala.nome })

    }
    function limpar() {
        setProject({ ...project, ['indice']: false, ['color']: '#ccc', ['nome_sala']: false })

    }

    return (

        <Card width='550px' height='500px'>
            {project.indice ?

                <LabelText3
                    header="Sala Vinculada:"
                    onClick={() => copy(project.nome_sala)}
                    onClick2={() => limpar()}

                >
                    <span style={{ color: project.color }}>{project.indice} - {project.nome_sala}</span>
                </LabelText3>
                :
                <ProcsBar
                    setProject={setProject2}
                />
            }

            <div>

                <InputText
                    width='400px'
                    flex='column'
                    title='Nome'
                    placeholder='Insira o nome da sala...'
                    value={project.nome || ''}
                    name='nome'
                    handleOnChange={handleChange}

                />
                <div style={{ display: 'flex' }}>
                    <div>
                        <InputText
                            width='40px'
                            flex='column'
                            title='Limite por horário'
                            name="qnt"
                            value={project.qnt || 1}
                            handleOnChange={handleChange}
                            required
                        />
                        <InputText
                            width='40px'
                            flex='column'
                            title='Duração (minutos)'
                            name="duracao"
                            value={project.duracao || 40}
                            handleOnChange={handleChange}
                            required
                        />

                        <Checkbox
                            label={`Restrito à unidade ${unidade}`}
                            name='unidade'
                            value={project.unidade}
                            event={unidadeChange}

                        />
                      
                    </div>
                    <SeletorDeCor project={project} name='color' setProject={setProject} />
                </div>
              
            </div>
            <footer>
                <Button
                    color='#222'
                    click={() => insertSala(project, unidade, user)}
                    value='Cadastrar'
                />
            </footer>
        </Card>

    )
}

function ProcsBar({ setProject }) {
    const { unidade } = useContext(Context)
    const [salas, setSalas] = useState([])
    useEffect(() => {
        get(`/buscarSalas/${unidade}`, setSalas)

    }, [])
    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return salas.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || String(user.indice).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            width='400px'
            search={search}
            placeholder='Vincular sala...'
            setSearch={setSearch}>

            <ListItem
                search={search}
                setProject={setProject}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setProject, search }) {
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setProject(result)}>
                        <span style={{ color: result.color, fontWeight: 500 }}>{result.id} - {result.nome}</span>
                    </li>

                ))}
            </ul>

        </>
    );
}