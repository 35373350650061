import './calendar.css'
import { useEffect, useState, useContext } from 'react';
import { Context } from '../e_contexts/AuthContext';
import { getTheme } from '../../a_model/Theme'
import { HomeContainer } from '../../a_model/Container';
import { produce } from 'immer'
import Calendar from './Calendar';
import { CiCalendarDate,CiWavePulse1, CiCalculator1, CiCircleList, CiSettings } from "react-icons/ci";
import Caixa from '../../caixa/a_pages/Caixa';
import Leads from '../../leads/components/Leads';
import cb from '../../img/corpobueno.png';
import Relatorios from '../../relatorio/a_pages/Relatorios';
export default function Home() {
  const listModule = [
    [
      'Agenda',
      <Calendar />
    ],
    [
      'Caixa',
      <Caixa />
    ],
    [
      'Leads',
      <Leads />
    ],
    ['Relatórios',
      <Relatorios />]
  ]
  const [currentModule, setCurrentModule] = useState(0);
  const [tema, setTema] = useState(6)
  const [activeName, setActiveName] = useState([])
  const [activeModule, setActiveModule] = useState([])
  const { handleLogout, login } = useContext(Context)
  useEffect(() => {
    if (localStorage.getItem('tema') != tema) {
      setTema(localStorage.getItem('tema'))
    }
  
  }, [])

  function abrir(index) {
    setActiveName(produce(activeName, draft => {
      draft.push(listModule[index][0])
    }))
    setActiveModule(produce(activeModule, draft => {
      draft.push(listModule[index][1])
      setCurrentModule(draft.length - 1)
    }))

  }
  function fechar(index) {
    if (currentModule === index) {
      setCurrentModule(0)

    } else {
      //console.log(currentModule, index)
    }
    setActiveName(produce(activeName, draft => {

      draft.splice(index, 1)
    }))
    setActiveModule(produce(activeModule, draft => {
      draft.splice(index, 1)


    }))

  }

  function CardHome() {
    const unidades = [
      { id: '0', nome: 'Indefinido' },
      { id: 1, nome: 'Matriz' },
      { id: 3, nome: 'Goiânia Shopping' },
      { id: 4, nome: 'Orion' },
      { id: 5, nome: 'Campinas' },
      { id: 10, nome: 'Altamira' },
      { id: 11, nome: 'Paulinia' },
      { id: 13, nome: 'Brasília' },
  ]
    return (
      <div id='cardHome'>
        <span> Usuário: </span>
        <label style={{ color: getTheme(tema, 0) }}>{localStorage.getItem('nome') || ''} ({localStorage.getItem('cargo')})</label>
        <span> Unidade: </span>
        <label style={{ color: getTheme(tema, 0) }}>{unidades.find((element) => element.id == localStorage.getItem('unidade')).nome}</label>
      </div>
    )
  }

  function HoverDropdown() {
    const grupo = localStorage.getItem('grupo')
    return (

      <ul className="dropdown-menu" >
        <li onClick={() => abrir(0)}>
          <CiCalendarDate color={getTheme(tema, 0)} />
          <span>
            Agenda
          </span>
        </li>
        <li onClick={() => abrir(1)}>
          <CiCalculator1 color={getTheme(tema, 0)} />
          <span>
            Caixa
          </span>
        </li>


        {(grupo == 1 || grupo == 11 || grupo == 12|| grupo == 3) &&

          <li onClick={() => abrir(2)}>
            <CiCircleList color={getTheme(tema, 0)} />
            <span> Leads Franqueados</span>
          </li>

        }
        <li onClick={() => abrir(3)}>
          <CiWavePulse1 color={getTheme(tema, 0)} />
          <span>
            Relatórios
          </span>
        </li>
        <li onClick={() => ''}>
          
          <CiSettings color={getTheme(tema, 0)} />
          <span> Personalizar</span>
        </li>
      </ul>

    );
  }

  return (
    <Context.Provider value={{
      handleLogout
    }}>
      <div className="agenda" >

        <div className={`dayMenu`} style={{ background: '#fff' }} >
          <img src={cb} />
          <CardHome />
          <HoverDropdown />
        </div>

        <div className={`dayPage`} style={{ background: '#fff' }}>
          <HomeContainer
            currentModule={currentModule}
            setCurrentModule={setCurrentModule}
            activeName={activeName}
            fechar={fechar}
            sair={handleLogout}
          >
            {activeModule.length > 0 ?
              activeModule.map((module, index) =>
                <div
                  key={index}
                  style={{ display: index === currentModule ? 'block' : 'none' }}>
                  {module}
                </div>
              )

              : 
              <h1 id='welcome'>Seja Bem-vindo!</h1>
            }
          </HomeContainer>
        </div>

      </div>
    </Context.Provider>
  )
}
