import { useContext, useEffect, useMemo, useState } from "react"
import { SearchList, Table, Th } from "../../a_model/Contents"
import { toCash, toDate } from "../../a_model/functions"
import { get } from "../../calendar/f_aux/fetch"
import Context from "../../a_model/context"
import { ContainerE, Card } from "../../a_model/Container"
import { Modal } from "../../a_model/Modal"

export default function Fechamento() {
    const { id_vendas } = useContext(Context)
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
     useEffect(() => {
    //    get(`/getIndicacoes/${params.data}/${params.data_fim}/${params.atendente}/${params.fechamento}/${params.unidade}`, setList)
    }, [])


    return (
        <ContainerE>
      <div></div>
        </ContainerE>
    )
}
