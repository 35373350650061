import React, { useContext, useEffect, useMemo, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { get, update } from "../../calendar/f_aux/fetch";
import { InputCash, InputNumber, Button, Select, LabelText3, InputText } from '../../a_model/Input'
import { SearchList, Table, Th } from "../../a_model/Contents";
import { toCash, toDate } from "../../a_model/functions";

import { produce } from 'immer'
import Context from "../../calendar/Context";
import { Form } from "../../a_model/Container";
import { InsertProduto } from "./InsertProduto";

export function InputPg() {

    const [aluno, setAluno] = useState(false)


    return (
        <Form>
            {aluno ?
                <InsertPg aluno={aluno} setAluno={setAluno} />
                : <PendingList aluno={aluno} setAluno={setAluno} />}
        </Form>

    )
}

function InsertPg(props) {

    const { user, setStep } = useContext(Context)
    const [aluno, setAluno] = useState(props.aluno)
    const [salvos, setSalvos] = useState([])
    const [project, setProject] = useState(
        {
            id_cliente: aluno.id_cliente,
            id_aula: aluno.id_aula,
            docente: aluno.docente,
            id_aluno: aluno.id_aluno,
            caixa: user,
            pagamentos: [],
            valor_pendente: aluno.valor + aluno.valor_matricula - aluno.pago,
            total: 0

        })
    const [credito, setCredito] = useState([])
    const [att, setAtt] = useState(false)
    useEffect(() => {
        if (aluno) {
            console.log(aluno)
            get(`/get/pagamento_iecb/id_aluno/${aluno.id_aluno}/ativo=1`, setSalvos)
            get(`/getCreditoIecb/${aluno.id_cliente}`, setCredito)
        }
    }, [att])


    const formas = [
        { id: 1, nome: 'Débito', parcelas: false },
        { id: 2, nome: 'Dinheiro', parcelas: false },
        { id: 4, nome: 'Crédito', parcelas: true },
        { id: 11, nome: 'Pago pelo link', parcelas: true },
        { id: 17, nome: 'Pix', parcelas: false },
        { id: 3, nome: 'Cheque', parcelas: false },

    ]

    const titles = [
        { title: 'Forma' },
        { title: 'Valor' },
        { title: 'Parcelas' },
        { title: <HiOutlineTrash /> }
    ]
    const titles1 = [
        { title: 'Forma' },
        { title: 'Valor' },
        { title: 'Parcelas' },

    ]
    const [pagamento, setPagamento] = useState({
        valor: project.valor_pendente,
        id_pagamento: 1,
        qnt: 1
    })

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }
    function aChange(e) {

        setAluno({ ...aluno, [e.target.name]: e.target.value })
    }
    function pgChange(e) {
        setPagamento({ ...pagamento, [e.target.name]: e.target.value })
    }

    function adicionar() {
        if (project.valor_pendente < (parseFloat(project.total) + parseFloat(pagamento.valor))) {
            window.alert(`Valor restante: R$${project.valor_pendente - project.total}`)
        } else {
            setPagamento({ qnt: 1, id_pagamento: 1, valor: parseFloat(project.valor_pendente) - parseFloat(project.total) - parseFloat(pagamento.valor) })
            setProject(produce(project, draft => {
                draft.pagamentos.push(pagamento)
                draft.total += parseFloat(pagamento.valor)

            }))
        }
    }
    function remover(index) {
        setProject(produce(project, draft => {
            draft.total = draft.total - draft.pagamentos[index].valor
            draft.pagamentos.splice(index, 1)

        }))
    }
    function resgatar(item) {
        let resp = window.confirm('Deseja transferir o pagamento para esta aula?')
        if (resp) {
            update(`/editar/pagamento_iecb/id/${item.id}`, { id_aluno: aluno.id_aluno, id_aula: aluno.id_aula }, thenCredito)
        }
        function thenCredito() {
            const valorInput = parseFloat(project.valor) - parseFloat(project.total) - parseFloat(item.valor)
            if (valorInput > 0) {

                setAtt(Math.random())
                setPagamento({ qnt: 1, id_pagamento: 1, valor: valorInput })
                setProject(produce(project, draft => {
                    draft.total += parseFloat(item.valor)
                    // draft.valor = draft.valor - item.valor
                }))
            } else {
                update(`/editar/alunos_iecb/id/${aluno.id_aluno}`, { status: 1 }, then2)
            }
        }

        function then2() {
            window.alert(`Pagamento Confirmado.`)
            setStep(0)
        }


    }
    function thenValor() {
        window.alert('Valor alterado')
        let valor_pendente = parseFloat(aluno.valor) + parseFloat(aluno.valor_matricula) - parseFloat(aluno.pago)
        setProject({ ...project, ['valor_pendente']: valor_pendente })
        setPagamento({ ...pagamento, ['valor']: 0 })
    }
    function insertPagamento() {
        // console.log(project)
        function then() {

            props.setAluno(false)
        }
        update(`/updateStatusAluno`, project, then)
    }

    return (
        aluno &&
        <div id='pgCard'>
            <header>
                <LabelText3
                    header='Aluna'
                >
                    {aluno.nome_aluno}
                </LabelText3>
                <LabelText3
                    header='Curso'
                >
                    {aluno.nome_curso}
                </LabelText3>
                <LabelText3
                    header='Professora'
                >
                    {aluno.nome_docente}
                </LabelText3>
                {aluno.tipo == 1 &&
                    <div style={{ display: 'flex', alignItems: 'flex-end', width: '200px' }}>
                        <InputCash
                            width='120px'
                            flex='Column'
                            name='valor_matricula'
                            value={aluno.valor_matricula}
                            title='Taxa de matrícula'
                            handleOnChange={aChange}
                        />
                        <Button
                            color='#005ac9'
                            click={() => update(`/editar/alunos_iecb/id/${aluno.id_aluno}`, { valor_matricula: aluno.valor_matricula }, thenValor)}
                        >
                            Salvar
                        </Button>
                    </div>
                }
                <div style={{ display: 'flex', alignItems: 'flex-end', width: '200px' }}>
                    <InputCash
                        width='120px'
                        flex='Column'
                        name='valor'
                        value={aluno.valor}
                        title='Valor'
                        handleOnChange={aChange}
                    />
                    <Button
                        color='#005ac9'
                        click={() => update(`/editar/alunos_iecb/id/${aluno.id_aluno}`, { valor: aluno.valor }, thenValor)}
                    >
                        Salvar
                    </Button>
                </div>
                {credito.length ?
                    credito.map((item, index) =>
                        <div key={index} style={{ display: 'flex', alignItems: 'flex-end', width: '200px' }}>
                            <LabelText3
                                header='Crédito'
                            >
                                {toCash(item.valor)}
                            </LabelText3>
                            <Button
                                color='#005ac9'
                                click={() => resgatar(item)}
                            >
                                Resgatar
                            </Button>

                        </div>
                    ) : ''}
            </header>
            <body>
                <LabelText3
                    header='Restante'
                >
                    {toCash(project.valor_pendente)}
                </LabelText3>
                {salvos.length > 0 &&
                    <div style={{ margin: '10px' }}>
                        Lançamentos inseridos:

                        <Table height='152px'
                        >
                            <Th array={titles1} />
                            {salvos.map((pg, index) =>
                                <tr key={index}>
                                    <td>
                                        {formas.find((element) => element.id == pg.id_pagamento).nome}
                                    </td>
                                    <td>
                                        {parseFloat(pg.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td>
                                        {parseInt(pg.qnt) > 1 ? pg.qnt + 'x' : 'À Vista'}
                                    </td>

                                </tr>
                            )}
                        </Table>



                    </div>
                }
                {project.pagamentos.length > 0 &&
                    <div style={{ margin: '10px' }}>
                        Inserir:

                        <Table height='152px'
                        >
                            <Th array={titles} />
                            {project.pagamentos.map((pg, index) =>
                                <tr key={index}>
                                    <td>
                                        {formas.find((element) => element.id == pg.id_pagamento).nome}
                                    </td>
                                    <td>
                                        {parseFloat(pg.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td>
                                        {parseInt(pg.qnt) > 1 ? pg.qnt + 'x' : 'À Vista'}
                                    </td>
                                    <td onClick={() => remover(index)}>
                                        <HiOutlineTrash />
                                    </td>
                                </tr>
                            )}
                        </Table>



                    </div>
                }
                <div >
                    {project.valor_pendente > project.total &&
                        <>
                            <div id='inputPg'>
                                <Select
                                    options={formas}
                                    name='id_pagamento'
                                    value={pagamento.id_pagamento}
                                    handleOnChange={pgChange}
                                />
                                {formas.find((element) => element.id == pagamento.id_pagamento).parcelas &&
                                    <InputNumber
                                        width='60px'
                                        min='1'
                                        max='12'
                                        name='qnt'
                                        value={pagamento.qnt}
                                        handleOnChange={pgChange}
                                    />}
                                <InputCash
                                    width='125px'
                                    name='valor'
                                    value={pagamento.valor}
                                    handleOnChange={pgChange}
                                />

                                <Button
                                    color='#447461'
                                    value='Inserir'
                                    disabled={pagamento.valor > 0 ? false : true}
                                    click={() => adicionar()}
                                />

                            </div>

                        </>
                    }
                </div>

            </body>
            <footer>

                <div className='inline1'>
                    {project.total > 0 &&
                        <Button
                            color='#2d4492'
                            value='Salvar'
                            click={() => insertPagamento()}
                        />}
                    <Button
                        color="#474747"
                        value='Cancelar'
                        click={() => props.setAluno(false)}
                    />
                </div>
            </footer>
        </div>
    )
}

function PendingList({ aluno, setAluno }) {
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
    const [step, setStep] = useState(0)

    useEffect(() => {
        !aluno &&
            get('/getPendingIecb', setList)
    }, [aluno])
    const stepTitles = ['Pagamentos em aberto', 'Produto']
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return list.filter(item => item.nome_aluno ? (item.nome_aluno.toLowerCase().includes(lowerBusca) || item.nome_docente.toLowerCase().includes(lowerBusca)) : '');
    }, [search, list])
    const steps = [
        <Pendentes results={results} length={list.length} setAluno={setAluno} />,
        <InsertProduto />
    ]
    return (
        <SearchList
            stepTitles={stepTitles}
            search={search}
            setSearch={setSearch}
            setStep={setStep}
            step={step}
        >
            {steps[step]}

        </SearchList>

    )
}

function Pendentes({ length, results, setAluno }) {
    const titles = [
        { title: 'Data', width: '' },
        { title: 'Tipo', width: '' },
        { title: 'Cliente', width: '180px' },
        { title: 'Professora', width: '180px' },
        { title: 'Curso', width: '180px' },
        { title: 'Pendente', width: '' },
        { title: 'Código', width: '' },

    ]
    const tipos = [
        '',
        <div className='tipo' style={{ color: '#d3912e' }}>A</div>,
        <div className='tipo' style={{ color: '#701d97' }}>M</div>
    ]
    return (
        <Table
            height='calc(100vh - 180px)'
            footer={`Total: ${length}`}
        >
            <Th array={titles} />
            {Array.isArray(results) && results.length > 0 ?
                results.map((item, index) =>
                    <tr key={index} className='hover' onClick={() => setAluno(item)}>
                        <td >{toDate(item.data)}</td>
                        <td>{tipos[item.tipo]}</td>
                        <td style={{ maxWidth: '180px' }}>{item.nome_aluno}</td>
                        <td style={{ maxWidth: '180px' }}>{item.nome_curso}</td>
                        <td style={{ maxWidth: '180px' }}>{item.nome_docente}</td>
                        <td >{toCash(item.valor + item.valor_matricula - item.pago)}</td>
                        <td >{item.id_aluno}</td>

                    </tr>
                )
                : 'Nenhum Resultado'
            }


        </Table>
    )
}