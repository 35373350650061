import React, { useContext, useState, useEffect } from "react";
import { Container, List, ListItem } from '../../styles/ModalStyle'
import Context from '../../Context';
import { LabelInfo } from "../../Input";
import { TextArea } from "../../styles/ContentStyle";
import { MdChatBubbleOutline, MdOutlinePhone, MdSend } from "react-icons/md";
import copy from "copy-to-clipboard";
import { Button, InputText } from "../../../a_model/Input";
import { get, update } from "../../../calendar/f_aux/fetch";


export default function LeadsForm({ form, handleCloseModal, noRegister, setNoRegister }) {

    const { user, dialog } = useContext(Context)
    const [project, setProject] = useState({ telefone: form.telefone, id_leads: form.id, tabela: 'leads_franquiados', user, nota: '' })
    const [comentarios, setComentarios] = useState([])
    const [cidadeStep, setCidadeStep] = useState(0

    )

    useEffect(() => {
        get(`/buscarComentarios/${form.id}/leads_franquiados`, setComentarios)

    }, [])

    const title = [
        'Geral',
        'Erro',
        'Apresentação COF',
        'Aguardando decisão',
        'Quente',
        'Morno',
        'Lixeira'
    ]
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }
    function comentar() {
        if (project.nota.length > 0) {
            fetch(`${process.env.REACT_APP_CALENDAR}/insertTentativa`, {
                method: "POST",
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(project),
            })
                .then(() => {
                    handleCloseModal()

                })
                .catch(err => console.log(err))

            if (noRegister) {

                fetch(`${process.env.REACT_APP_CALENDAR}/updateStatus2`, {
                    method: "PUT",
                    headers: {
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify(noRegister),
                })
                    .then((resp) => resp.json()).then((resp2) => {
                        setNoRegister(false)

                    })
                    .catch(err => console.log(err))
            }
        } else {
            window.alert('Comentário vazio')
        }
    }
    function copiar(value) {
        copy(value)
        dialog('Copiado', 0)
    }

    const cidadeForm = [
        <LabelInfo header='Cidade:'>
            {form.cidade} - {form.estado}
            {form.origem !== 'Formulario' &&
                <button
                    style={{ background: '#1e5dd4' }}
                    onClick={()=> setCidadeStep(1)}
                >
                    Editar
                </button>
            }
        </LabelInfo>,
        <EditCidade setCidadeStep={setCidadeStep} handleCloseModal={handleCloseModal} cadastro={{ cidade: form.cidade, estado: form.estado, id: form.id }} />
    ]
    return (
        <Container>

            <div>
                <LabelInfo header='Cliente:'>
                    {form.nome}
                </LabelInfo>
                <LabelInfo header='Telefone:'>
                    {form.telefone}
                    <button onClick={() => copiar(form.telefone)} style={{ background: '#1e5dd4' }}>
                        <MdOutlinePhone /> Copiar Número
                    </button >



                </LabelInfo>

                <LabelInfo header='E-mail:'>
                    {form.email}
                </LabelInfo>
                {cidadeForm[cidadeStep]}

            </div>
            <div>
                <List>
                    <h2>Comentários</h2>
                    <ul>
                        {comentarios.map((comentario, index) =>
                            <ListItem key={index}>
                                <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')}:</label>
                                <p>{comentario.nota}</p>
                            </ListItem>
                        )}
                    </ul>
                </List>
                <TextArea>
                    <textarea
                        placeholder="Adicionar comentário..."
                        name='nota'
                        value={project.nota}
                        onChange={(e) => handleChange(e)}
                    />
                    <button
                        onClick={() => comentar()}
                    >
                        Enviar <MdSend />
                    </button>
                </TextArea>
            </div>

        </Container>
    )
}

function EditCidade({ cadastro, handleCloseModal, setCidadeStep }) {
    const [project, setProject] = useState({
        cidade: cadastro.cidade,
        estado: cadastro.estado
    })
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }
    function salvar() {
        function then() {
            handleCloseModal()
        }
        update(`/editar/leads_franquiados/id/${cadastro.id}`, project, then)
    }
    return (
        <div style={{ border: '1px solid #ccc', padding: '2px' }}>
            <InputText
                width='200px'
                title='Cidade'
                name='cidade'
                value={project.cidade}
                handleOnChange={handleChange}
            />
            <InputText
                width='60px'
                title='Estado'
                name='estado'
                value={project.estado}
                handleOnChange={handleChange}
            />
            <div style={{ display: 'flex', }}>
               
                 <Button
                    color='#256ac4'
                    value='Salvar'
                    click={()=> salvar()}
                />
                 <Button
                value='Voltar'
                    color='#888'
                    click={()=> setCidadeStep(0)}
               />
            </div>
        </div>
    )
}