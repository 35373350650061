import { getTheme } from './Theme'
import css from './Container.module.scss'
import { CiUnlock, CiUser, CiLogout } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { useState } from 'react';
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import React, { forwardRef } from 'react';

export function Page(props) {

    return (
        <div className={css.page}>

            {props.children}

        </div>
    )
}

export function HomeContainer(props) {
    const color = getTheme(localStorage.getItem('tema'), 1)
    return (
        <div id={css.homeContainer}
            style={{ background: color }}
        >
            <header style={{ background: getTheme(localStorage.getItem('tema'), 3) }}
            >
                {props.activeName.map((name, index) =>
                    <button
                        style={{ background: props.currentModule === index ? color : '' }}
                        className={props.currentModule === index ? css.on : css.off}
                        key={index}
                    >

                        <span onClick={() => props.setCurrentModule(index)}
                        >{name}</span>
                        <div onClick={() => props.fechar(index)}><IoIosClose /></div>
                    </button>
                )}
                <div id={css.logout} onClick={() => props.sair(0)}>
                    <CiLogout />
                    Sair
                </div>
            </header>
            <body >
                {props.children}
            </body>
        </div>
    )
}

export function Card(props) {

    return (
        <div style={{ background: getTheme(localStorage.getItem('tema'), 3), height: `${props.height}`, width: `${props.width}` }} className={css.card}>
            {props.header &&
                <header >
                    {props.header}
                </header>}

            {props.children}

        </div>
    )
}
export function Card2(props) {

    return (
        <div style={{ height: `${props.height}`, width: `${props.width}` }} className={css.card2}>
            {props.header &&
                <header >
                    {props.header}
                </header>}
       
                {props.children}
   
        </div>
    )
}

export function Form(props) {
    return (
        <div 
            style={{ overflow: props.overflow || 'auto', height: props.height, maxHeight: props.height }} 
            className={css.form}>
            {props.children}
        </div>
    );
}

export const Form2 = forwardRef((props, ref) => {
    return (
        <form 
            ref={ref}
            style={{ overflow: props.overflow || 'auto', height: props.height, maxHeight: props.height }} 
            className={css.form}>
            {props.children}
        </form>
    );
});

export function FormContainer(props) {
    return (
        <div style={{ overflow: props.overflow || 'auto', maxHeight: props.height }} className={css.formContainer}>
            {props.children}
        </div>
    )
}
export function Caixa(props) {
    return (
        <div className={css.caixa}>
            {props.children}
        </div>
    )
}

export function FormLine(props) {
    return (
        <div style={{ overflow: `${props.overflow}` || 'hidden' }} className={css.formLine}>
            {props.children}
        </div>
    )
}
export function FormLine2(props) {
    return (
        <div className={css.formLine2}>
            {props.children}
        </div>
    )
}

export function ContainerA(props) {
    return (
        <div className={css.containerA}>
            {props.children}
        </div>
    )
}

export function ContainerB(props) {
    return (
        <div className={css.containerB}>
            {props.children}
        </div>
    )
}

export function ContainerC(props) {
    return (
        <div className={css.containerC}>
            {props.children}
        </div>
    )
}
export function ContainerD(props) {
    return (
        <div className={css.containerD}>
            {props.children}
        </div>
    )
}
export function ContainerE(props) {
    return (
        <div className={css.containerE}>
            {props.children}
        </div>
    )
}

export function LoginPage({ login, logar, cadastrar, handleChange }) {

    const types = ['password', 'text'];
    const [type, setType] = useState(0);
    const svgs = [<HiOutlineEye />, <HiOutlineEyeSlash />];

    // Função para lidar com o evento de tecla pressionada
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Evita o comportamento padrão de submit do Enter
            logar(); // Chama a função logar
        }
    };

    return (
        <div id={css.login}>
            <div>
                <h1>Seja Bem-vindo!</h1>

                <div className={css.loginInput}>
                    <CiUser />
                    <input
                        placeholder='Usuário'
                        type='text'
                        onChange={(e) => handleChange(e)}
                        value={login.usuario || ''}
                        name='usuario'
                    />
                </div>

                <div className={css.loginInput}>
                    <CiUnlock />
                    <input
                        placeholder='Senha'
                        type={types[type]}
                        onChange={(e) => handleChange(e)}
                        value={login.senha || ''}
                        name='senha'
                        onKeyDown={handleKeyDown} // Adiciona o handler de tecla aqui
                    />
                    <div onClick={() => setType(type === 0 ? 1 : 0)}>
                        {svgs[type]}
                    </div>
                </div>

                <button
                    id={css.buttonLogin}
                    onClick={() => logar()}
                >
                    LOGIN
                </button>
                <button
                    id={css.buttonCad}
                    onClick={() => cadastrar()}
                >
                    Cadastrar
                </button>
            </div>
        </div>
    );
}

export function StepsForm({ steps, step, setStep, height, width }) {
    /*
       const steps = [
        { content: <Informações/>, nome: 'Informações', color: '#c7e3fa' },
    ]
    */
    return (
        <div id={css.stepsForm} style={{ background: steps[step].color, height: height, width: width }}>
            <header>
                {steps.map((item, index) =>
                    <button
                        style={index == step ? { background: item.color } : {}}
                        className={index == step ? css.on : css.off}
                        onClick={() => setStep(index)}
                    >
                        {item.nome}
                    </button>

                )}

            </header>

            {steps[step].content}

        </div>

    )
}

