import React, { useState, useEffect, useContext } from "react";
import { InputDate, Button, LabelText } from "../../a_model/Input";
import moment from "moment";
import { createPDF } from "../f_aux/createPDF";
import Context from "../Context";
import './Clientes.css'
import { Assinaturas, NewSub } from "../c_layouts/ClientesLayout";
import { Modal } from '../../a_model/Modal'
import CadastroCliForm from "./CadastroCliForm";
import { Table, Th } from '../../a_model/Contents'
import { Card, Form } from '../../a_model/Container'
import { HiOutlineTrash } from "react-icons/hi";
import { deleteA } from "../f_aux/functions";
import { get } from "../f_aux/fetch";

export default function Historico({ confirm, setConfirm }) {

    const [historico, setHistorico] = useState([])
    const { unidade, user, cliente, setCliente, step } = useContext(Context)
    const today = moment()  

        const [project, setProject] = useState({
            data: moment().add(1, 'day').format('YYYY-MM-DD'),
            inicio: moment('2023 07 15').format('YYYY-MM-DD'),
            fim: moment().add(1, 'year').format('YYYY-MM-DD')
        })

        function handleChange(e) {
            setProject({ ...project, [e.target.name]: e.target.value })
            //   //console.log(project.hora_fim)
        }
        function deletar(item) {
            function then() {
                setProject({ ...project, ['att']: Math.random() })
                window.alert('Registro Desativado')
            }
            deleteA(item, then, user)
        }
        function gerarPdf2(download) {
            function then(resp2) {
                setHistorico(resp2)
                createPDF(resp2, false, download)
            }
            get(`/buscarPDFcli/${cliente.id}/${project.inicio}/${project.fim}`, then)
        }
        const [agendamentos, setAgendamentos] = useState([]); // Novo estado para armazenar os agendamentos

        useEffect(() => {
            fetch(`${process.env.REACT_APP_CALENDAR}/buscarPDFcli/${cliente.id}/${project.inicio}/${project.fim}`, {
                method: "GET",
                heders: {
                    'Content-type': 'application/json',
                },
            })
            .then((resp) => resp.json())
            .then((resp2) => {
                if (resp2.length === 0) {
                    setAgendamentos([0]); // Define os agendamentos como vazio ou 0 se não houver dados
                } else {
                    setAgendamentos(resp2); // Define os agendamentos obtidos da API
                }
            })
            .catch(err => console.log(err))
        }, [cliente.id, project.inicio, project.fim]); // Dependências do useEffect
    
        // Atualiza o estado historico somente depois que os dados forem obtidos
        useEffect(() => {
            setHistorico(agendamentos);
        }, [agendamentos]);

        const titles = [

            { width: '90px', title: 'Data' },
            { width: '250px', title: 'Procedimento' },

            { width: '50px', title: 'Hora' },
            { width: '100px', title: 'Status' },
            { width: '60px', title: 'id' },
            { width: '', title: <HiOutlineTrash /> },
        ]


    return(
        historico.length > 0 ?
            <Form overflow='hidden'>

                <header>
                    <div>
                        <h2>Histórico de agendamentos</h2>

                        <div className="dataCli">
                            <InputDate

                                name='inicio'
                                title='De'
                                value={project.inicio}
                                handleOnChange={handleChange}
                            />
                            <InputDate

                                name='fim'
                                title='Até'
                                value={project.fim}
                                handleOnChange={handleChange}
                            />
                        </div>

                    </div>
                </header>
                <body>
                    <Table height={'95%'}>
                        <Th array={titles} />


                        {historico.map((agendamento, index) =>
                            <tr
                                key={index}>

                                <td>
                                    {agendamento.data ? agendamento.data.substr(0, 10).split('-').reverse().join('/') : ''}
                                </td>
                                <td>
                                    {agendamento.nome_procedimento}

                                </td>
                                <td>
                                    {agendamento.hora}
                                </td>
                                <td >
                                    {agendamento.status == 0 ? 'Agendado' :
                                        parseInt(agendamento.assinado) > 0 ? parseInt(agendamento.assinado) > 1 ? 'Assinado' : 'Ass. Pendente' : 'Confirmado'}
                                </td>

                                <td>
                                    {agendamento.id}
                                </td>

                                {today.format("YYYY-MM-DD") <= agendamento.data ?

                                    <td onClick={() => deletar(agendamento)}><HiOutlineTrash /></td> : <td>-</td>}

                            </tr>
                        )}
                    </Table>
                </body>
                <footer>
                    <Button
                        color='#9e1b1b'
                        click={() => gerarPdf2(true)}
                        value='Gerar PDF'
                    />
                    <Button
                        color='#174fb6'
                        click={() => gerarPdf2(false)}
                        value='Imprimir'
                    />
                </footer>

            </Form>
            : <div>Nenhum agendamento encontrado..</div>
    )

}

/*

*/