import { useState } from 'react';
import Context from '../Context'
import { Dialog } from "../c_layouts/Dialog";
import { getTheme } from '../../a_model/Theme';
import { Page, StepsForm } from '../../a_model/Container';


export default function Cadastro(){
    const [att, setAtt] = useState('')
    const [text, setText] = useState(false)
    const [step, setStep] = useState(0)
    const steps = [
        {content:<div>oi</div>,nome:'Cliente', color:getTheme(localStorage.getItem('tema'), 1)},
        
        {content:<div>tchau</div>,nome:'dois', color:getTheme(localStorage.getItem('tema'), 3)}, 
    ]
    return(
        <Context.Provider value={{  }}>
            <Page>
                <Dialog value={text} />
                <div style={{padding:'20px', height:'100%', display:'flex', justifyContent:'center'}}>
                <StepsForm
                    step={step}
                    setStep={setStep}
                    steps={steps}
                    height='100%'
                    width='100%'
                />
                </div>
            </Page>
        </Context.Provider>
    )
}