import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import Context from "../Context";
import { BiCalendar } from "react-icons/bi";
import { Page } from "../../a_model/Container";
import { get } from "../f_aux/fetch";
import DiarioForm from "../b_forms/DiarioForm";
import moment from "moment";
import Assinar from "../b_forms/Assinar";
import Anulados from "../b_forms/Anulados";
import { getTheme } from "../../a_model/Theme";

export default function Diario() {
    const { setCurrentForm, user, unidade } = useContext(Context)
   
    const [atendentes, setAtendentes] = useState([])
    const [params, setParams] = useState({ sala: 100, atendente: 100, status: 100, unidade, data: moment().format('YYYY-MM-DD'), data_fim: moment().format('YYYY-MM-DD') })
    const [card, setCard] = useState({ itens: [] })
    const [att, setAtt] = useState('')
    const [salas, setSalas] = useState([])
    useEffect(() => {
        get(`/atendentes/${params.unidade}`, setAtendentes)
    }, [params.unidade]);
    useEffect(() => {
        get(`/buscarDiario/${params.data}/${params.data_fim}/${params.atendente}/${params.status}/${params.unidade}`, setCard)
    }, [params, att]);
    useEffect(() => {
        get(`/buscarSalas/${params.unidade}`, setSalas)
    }, []);

    const status = [
        { id: '0', nome: 'Agendado', cor: '#818181' },
        { id: 1, nome: 'Confirmado', cor: '#3948d3' },
        { id: 2, nome: 'Confirmado', cor: '#3948d3' },
        { id: 3, nome: 'Assinado', cor: '#d339d3' },
        { id: 4, nome: 'Atrasado', cor: '#d37e39' },
        { id: 5, nome: 'Reagendar', cor: '#c2bf34' },
        { id: 6, nome: 'Cancelado', cor: '#21a32c' },
        { id: 7, nome: 'Anulado', cor: '#a32121' },
        { id: 8, nome: 'Finalizado', cor: '#07871E' },
    ]
    function handleChange(e) {
        if (e.target.name === 'data' && params.data_fim < e.target.value) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else if (e.target.name === 'data_fim' && e.target.value < params.data) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else {
            setParams({ ...params, [e.target.name]: e.target.value })
        }
    }

    const [currentFicha, setCurrentFicha] = useState(0)
    const navList = ['Diário','Anulados', user == 'fellipe' &&'Assinar']
    const steps = [
        <DiarioForm />,
        <Anulados/>,
        <Assinar />,
    ]
    const unidades = [
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'SPA' },
        { id: 4, nome: 'Orion' },
        { id: 5, nome: 'Campinas' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 12, nome: 'Brasília' },
    ]


    return (
        <Context.Provider value={{ status, card, setAtt, params, user }}>
            <Page>
                <Header   
                    setStep={setCurrentFicha}
                    navList={navList}
                    buttons={[
                        <button onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>
                    ]}
                ><>
                        <InputDate
                            classe='input2'
                            name='data'
                            value={params.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='data_fim'
                            value={params.data_fim}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            padrao='Atendentes'
                            options={atendentes}
                            name='atendente'
                            value={params.atendente}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            padrao='Status'
                            options={status}
                            name='status'
                            value={params.status}
                            handleOnChange={handleChange}
                        />
                          <Select
                          width='150px'
                            classe='input2'
                            padrao='Procedimento'
                            options={salas}
                            name='sala'
                            value={params.sala}
                            handleOnChange={handleChange}
                        />
                        {unidade == 1 &&
                            <Select
                                classe='input2'
                                options={unidades}
                                name='unidade'
                                value={params.unidade}
                                handleOnChange={handleChange}
                            />}
                    </>
               </Header>
                <body> {steps[currentFicha]}
                </body>
            </Page>
        </Context.Provider>

    )
}
