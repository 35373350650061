import React, { useEffect } from "react";
import './index.scss'

export default function Menu(){
 
    useEffect(() => {
      
    }, [])
    return(
        <div id='menuGeral'>
            <button
                onClick={()=> window.location.replace('/mobile/home')}
            >
                Agenda Evolução
            </button>
            <button
                onClick={()=> window.location.replace('/mobile/leads')}
            >
                Indicação
            </button>
        </div>
    )
}