import React, { useEffect } from "react";
import './index.scss'

export default function Redirect(){
 
    useEffect(() => {
       window.location.replace(`/mobile`)
    }, [])
    return(
        <div>
          
        </div>
    )
}