import React, { useState } from 'react'
import './Contents.scss'
import RBar from './VBar'
import { Button, LabelText, InputNumber } from '../../a_model/Input'
import { Label1, Table, Th } from '../../a_model/Contents'
import { Card } from '../../a_model/Container'
import { toDate } from '../../a_model/functions'

export function Venda0(props) {


  return (

    <div className='card'>
      <header>
        Vendas sem registro de orçamento:

      </header>

      <Table thead={<tr >

        {
          props.titles.map((title) =>
            <th >{title.title}
            </th>

          )}</tr>}>
        {props.children}
      </Table>


    </div>





  )

}

export function Assinaturas(props) {

  return (

    <Card
      height='85vh'
      header={
        <>
          <LabelText
            header='Procedimento:'

          >{props.sub.nome_procedimento}</LabelText>
          <LabelText
            header='Sessões:'

          >{props.sub.qnt_sessao}</LabelText>

          <LabelText
            header='id:'

          >{props.sub.id}</LabelText>
        </>
      }
    >

      <ul id='evoCard'>
        {props.assinaturas.map((assinatura, index) =>
          <li key={index}>
            <header >
              <div>{toDate(assinatura.data)}</div>
              <div style={{ color: assinatura.status.color }}>{assinatura.status.status}</div>
            </header>
            {assinatura.status1 === 3 && <div>
              <label>{assinatura.atendente}:</label>
              <p>{assinatura.evolucao}</p>
            </div>}
          </li>
        )}
      </ul>
    </Card >


  )

}

export function NewSub(props) {

  const [project, setProject] = useState({ id_cliente: props.id_cliente, unidade: props.unidade, user: props.user })

  function alterarFechamento() {
    project.id_produto ?
      fetch(`${process.env.REACT_APP_CALENDAR}/insertSub`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(project),
      })
        .then(() => {
          window.alert('Cadastrado!')
          props.setCurrent(0)

        })
        .catch(err => console.log(err))
      : window.alert('Selecione o procedimento...')
  }
  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })
    //console.log(project)

  }
  function valorChange(e) {
    setProject({ ...project, ['total']: e.target.value })
    //console.log(project)

  }
  return (

    <div className='cliCard'>
      {project.nome ?

        <Label1
          label='Pacote: '
          span={project.nome}
          limpar={() => setProject({ ...project, ['nome']: '', ['id_produto']: '' })}
        /> :
        <RBar
          project={project}
          setProject={setProject}
        />}
      <InputNumber
        title='Código do AZ: '
        width='140px'
        name='az'
        value={project.az}
        handleOnChange={handleChange}
      />
      <InputNumber
        title='Valor: R$'
        name='total'
        value={project.total}
        handleOnChange={handleChange}
      />
      <Button
        value='Cadastrar'
        color='#111'
        click={() => alterarFechamento()}
      />
    </div>
  )
}

export function Historico(props) {


  return (

    <div className='vendaForm'>


      <div className='table1'>
        <table >
          <thead>
            <tr className='th1'>

              {
                props.titles.map((title) =>
                  <th style={{ width: title.width }}>{title.title}</th>

                )}

            </tr>
          </thead>
          <tbody className=''>
            {props.children}
          </tbody>
        </table>
      </div>


    </div>





  )

}


