import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import { Page } from "../../a_model/Container";
import { Header } from "../../a_model/Header";
import Context from "../Context";

import { BiCalendar } from "react-icons/bi";
import { SalaForm, NewSala } from "../b_forms/SalaForm";
import { getTheme } from "../../a_model/Theme";
import { useContext } from "react";

export default function Salas() {

    
    const unidade = localStorage.getItem('unidade')
    const user = localStorage.getItem('login')
   const [currentFicha, setCurrentFicha] = useState(0)
    const navList = ['Salas', 'Cadastrar']
    const  { setCurrentForm} = useContext(Context)
    const steps = [
        <SalaForm unidade={unidade} user={user} />,
        <NewSala />
    ]

    return (
        <Context.Provider value={{ unidade, user }}>
            <Page>

                <Header 
                background={getTheme(localStorage.getItem('tema'), 0)}
                    setStep={setCurrentFicha}
                    navList={navList}
                    buttons={[
                        <button onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>
                    ]}
                >
                   

                </Header>
                <body style={{background: getTheme(localStorage.getItem('tema'), 1)}}>

                    {steps[currentFicha]}
                </body>

            </Page>
        </Context.Provider>

    )
}