import styled, { css } from 'styled-components'


export const NavButton = styled.button`
border:none;
font-size: 1.05em;
padding: 4px 15px;
opacity: 0.6;
margin-bottom: -6px;

${props => props.step == props.current && css`
    opacity: 1; 
    border-bottom: 2px solid #ffffff55;
    font-weight: 500;
    
`
    }

    ${props => props.step !== props.current && css`
    border-radius: 15px;
    :hover{
    cursor: pointer;
    opacity: 0.9;
  
    background: #ffffff44;
    border:none;
    transition: all ease 0.4s;
}
`
    }
`

export const NavButton2 = styled.button`
border:none;
font-size: 1.05em;
padding: 4px 15px;  
margin-bottom: -5px;
background: transparent;  
opacity: 0.7;   
color: #fff;





${props => props.step == props.current && css`
    opacity: 1; 
    border-bottom: 2px solid #fff;
`
    }

    ${props => props.step !== props.current && css`
    :hover{
    cursor: pointer;
    border-radius: 15px;
    background: #ffffff44;
    border:none;
 
}
`
    }
`


