import React, { useEffect, useState } from "react";
import { get } from "../../calendar/f_aux/fetch";

export default function Tabelas() {
 
    const [cards, setCards] = useState([]);
    const [selectedPdf, setSelectedPdf] = useState(null); // Estado para armazenar o PDF selecionado

    useEffect(() => {
        get(`/get/menu_pdf/type/1`, setCards);
    }, []);

    function open(link) {
        
        setSelectedPdf(convertToPreviewLink(link)); // Define o link do PDF selecionado
    }
    function convertToPreviewLink(link) {
        return link.replace('/view?usp=sharing', '/preview');
    }

    return (

        !selectedPdf ?
            <div id="gridMobile">
                {cards.map((card, index) =>
                    <div key={index} className="agendaMobileCard" onClick={() => open(card.link)}>
                        <p style={{ padding: '10px' }}>
                            {card.nome}
                        </p>
                    </div>
                )}
            </div>
            :
            <div style={{ marginTop: '20px', display:'flex', flexDirection:'column', alignItems:'center' }}>
            <button style={{fontSize:'17px', padding: '5px', marginBottom:'10px'}} onClick={()=> setSelectedPdf(null)}>
            Voltar
            </button>
                <iframe
                    src={selectedPdf}
                    style={{ width: '95vw', height: 'calc(100vh - 200px)' }}
                    title="PDF Viewer"
                />
            </div>



    );
}
