import React, { useEffect, useState } from 'react'
import { get } from '../calendar/f_aux/fetch'
import { Header } from '../a_model/Header'
import { Page } from '../a_model/Container'
import Context from '../a_model/context'
import { useParams } from 'react-router'
import { InputDate, Select } from '../a_model/Input'
import moment from 'moment'
import IndicacaoGrid from './b_forms/IndicacaoGrid'
import Fechamento from './b_forms/Fechamento'

export default function Indicacao() {
    const { unidade, user } = useParams()
    const [step, setStep] = useState(0)
    const [atendentes, setAtendentes] = useState([])
    const [params, setParams] = useState(
        {
            atendente: 100,
            fechamento: 100,
            data: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            data_fim: moment().format('YYYY-MM-DD'),
            unidade
        })
    const [id_vendas, setIdVendas] = useState(false)

    useEffect(() => {
        //  get(`/clients/${unidade}`, '')
        get(`/getAtendentes/${unidade}`, setAtendentes)
    }, [])

    const navList = ['Indicações']
    const buttons = []
    const forms = [
       <>
        <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={buttons}
                >
                    <InputDate
                        classe='input2'
                        name='data'
                        value={params.data}
                        handleOnChange={handleChange}
                    />
                    <InputDate
                        classe='input2'
                        name='data_fim'
                        value={params.data_fim}
                        handleOnChange={handleChange}
                    />
                    <Select
                        name='atendente'
                        padrao='Atendentes'
                        value={params.atendente}
                        options={atendentes}
                        handleOnChange={handleChange}
                    />
                </Header>

                <body>
                <IndicacaoGrid />
                </body>
       </>,
       <Fechamento/>

    ]
    function handleChange(e) {
        if (e.target.name === 'data' && params.data_fim < e.target.value) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else if (e.target.name === 'data_fim' && e.target.value < params.data) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else {
            setParams({ ...params, [e.target.name]: e.target.value })
        }
    }
    return (
        <Context.Provider value={{ setStep, params, id_vendas, setIdVendas }}>
            <Page>
               {forms[step]}

            </Page>
        </Context.Provider>
    )
}