import React, { useEffect, useContext, useState, useRef } from "react";
import { Context } from "../e_contexts/AuthContext";
import { get, insert, update } from "../../calendar/f_aux/fetch";
import "./Form.scss"
import { getColor } from '../../calendar/c_layouts/salaColor'
import { InputCash, InputSearch, InputText, LabelText4 } from "../../a_model/Input";
import { Modal } from "../../a_model/Modal";
import { useMemo } from "react";
import { produce } from "immer";
import { toCash, toDate } from "../../a_model/functions";

export default function Indicar({ itens, setStepForm, setAtt }) {

    const { cardForm, login } = useContext(Context)
    const [currentItem, setCurrentItem] = useState(0)
    const [currentModal, setCurrentModal] = useState(0)
    const [project, setProject] = useState
        ({
            id_cliente: cardForm.id_cliente,
            login: localStorage.getItem('login'),
            empresa: cardForm.empresa,
            unidade: cardForm.unidade,
            itens: [],
            obs_pagamento: ''
        })
    const [modalIsOpen, setIsOpen] = useState(false)
    const modals = [
        <InsertItem adicionar={adicionar} />,
        <EditItem currentItem={currentItem} editar={editar} />
    ]
    const [isDisbled, setIsDisbled] = useState(false)
    useEffect(() => {
      
        setProject(produce(project, draft => {
            draft.itens = []
            for (let i in itens) {
                if (!itens[i].status) {
                    draft.itens.push(itens[i])
                }
            }
        }))

    }, [])

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //   //console.log(project.hora_fim)
    }

    function adicionar(proc) {
        setProject(produce(project, draft => {
            draft.itens.push(proc)
        }))
        setIsOpen(false)
    }

    function editar(proc, index) {
        setProject(produce(project, draft => {
            draft.itens[index] = proc
        }))
        setIsOpen(false)
    }

    function remover(index) {
        setProject(produce(project, draft => {
            draft.itens.splice(index, 1)

        }))
    }

    function inserir() {
        setIsDisbled(true)
        function then() {
            window.alert('Indicação Inserida.')
            setStepForm(0)
        }
        insert(`/insertIndicacao`, project, then)
        function then(){
           
            update(`/editar/vendas_servico/id/${cardForm.id_vendas}`, {categ: 2, id_usuario: login}, then2)
        }
        function then2(){
            setAtt(Math.random())
            
        }
    }

    function toEdit(item, index) {
        setCurrentItem({item, index})
        setIsOpen(true)
        setCurrentModal(1)
    }
    function add() {
        setCurrentModal(0)
        setIsOpen(true)
    }

    return (
        <div id="indicaForm">
            <body>

                <div className="indicacaoCard">
                    <header>Registrar indicação</header>
                    {project.itens.map((item, index) =>
                        <div key={index}>
                            <span style={{ fontWeight: 600, fontSize: '17px' }}>
                                {item.nome_procedimento}
                            </span>
                            <span>
                                Região: {item.regiao}
                            </span>
                            <span>
                                {toCash(item.total)}
                            </span>
                            <div style={{width:'100%',display:'flex', justifyContent:'space-around'}}>
                                <button
                                    className="buttonMobile"
                                    onClick={() => toEdit(item, index)}
                                >Editar</button>
                                <button
                                    className="buttonMobile"
                                    onClick={() => remover(index)}
                                >Remover</button>
                            </div>
                        </div>

                    )}
                </div>
                <button
                    className="buttonMobile"
                    onClick={() => add()}
                >Adicionar</button>
            </body>
            <footer>
                <div className="inputTextMobile" style={{ marginTop: '25px' }}>
                    <span>Anotação</span>
                    <input
                        name='obs_pagamento'
                        value={project.obs_pagamento}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <button
                    style={{ marginTop: '25px' }}
                    className="buttonMobile"
                    disabled={project.itens.length > 0 && !isDisbled ? false : true}
               
                    onClick={() => inserir()}
                >
                    Salvar
                </button>
            </footer>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >
                {modals[currentModal]}
            </Modal>

        </div>
    )
}


function InsertItem({ adicionar }) {
    const [procs, setProcs] = useState([])
    const [proc, setProc] = useState({ idproduto: false })
    function handleChange(e) {
        setProc({ ...proc, [e.target.name]: e.target.value })
        //console.log(proc)
    }
    function setProc2(item) {
        setProc({ total: item.preco_venda, valor: item.preco_venda, nome_procedimento: item.nome, idproduto: item.id, qnt: item.qnt_sessao, sala: item.chave, SP: item.SP })
    }

    useEffect(() => {
        setProc({})
        get(`/buscarProcsProds`, setProcs)
    }, [])





    return (
        <div id='procModal'>
            <h3>Selecione o procedimento</h3>
            {proc.idproduto ?
                <>
                    <LabelText4
                        header='Procedimento'
                    >
                        {proc.nome_procedimento}
                    </LabelText4>
                    <InputCash
                        title='Valor'
                        flex='column'
                        name='total'
                        width='90px'
                        value={proc.total || ''}
                        handleOnChange={handleChange}
                    />
                    <InputText
                        title='Região'
                        flex='column'
                        name='regiao'
                        width='250px'
                        value={proc.regiao}
                        handleOnChange={handleChange}

                    />

                    <button
                        className="buttonMobile"
                     
                        onClick={() => adicionar(proc)}
                    >
                        Inserir
                    </button>
                </>
                :
                <ProcsBar
                    procs={procs}
                    setProc2={setProc2}
                />
            }
        </div>
    )
}

function EditItem({ currentItem, editar }) {
    const [proc, setProc] = useState(currentItem.item)
    function handleChange(e) {
        setProc({ ...proc, [e.target.name]: e.target.value })
       
    }

    return (
        <div id='procModal'>
            <h3>Editar procedimento</h3>
            <>
                <LabelText4
                    header='Procedimento'
                >
                    {proc.nome_procedimento}
                </LabelText4>
                <InputCash
                    title='Valor'
                    flex='column'
                    name='total'
                    width='90px'
                    value={proc.total || ''}
                    handleOnChange={handleChange}
                />
                <InputText
                    title='Região'
                    flex='column'
                    name='regiao'
                    width='250px'
                    value={proc.regiao}
                    handleOnChange={handleChange}

                />

                <button
                    className="buttonMobile"
                    onClick={() => editar(proc, currentItem.index)}
                >
                    Salvar
                </button>
            </>


        </div>
    )
}

function ProcsBar({ procs, setProc2 }) {
    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return procs.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            width='350px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setProc2={setProc2}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setProc2, search }) {
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setProc2(result)}>
                        <span>{result.nome || '-'}</span>{result.telefone && <span>{formatTel(result.telefone)}</span>}
                    </li>

                ))}
            </ul>

        </>
    );

}

