import { useState } from 'react'
import './Contents.scss'
import { GiCheckMark } from 'react-icons/gi'
import { FcCancel } from 'react-icons/fc'
import { produce } from 'immer'
import { getTheme } from '../../a_model/Theme'
import { MdOutlineFastfood } from "react-icons/md";

export function Label1({ label, span, limpar }) {

  return (
    <div className='label1'>
      <label> {label} </label>
      <span>{span}</span>
      {limpar ?
        <button
          className=''
          type='button'
          onClick={() => limpar()}
        >
          Limpar
        </button>
        : ''
      }
    </div>
  )
}

export function Escala({ project, setProject }) {
  let horarios = [

    '08:00',
    '08:40',
    '09:20',
    '10:00',
    '10:40',
    '11:20',
    '12:00',
    '12:40',
    '13:20',
    '14:00',
    '14:40',
    '15:20',
    '16:00',
    '16:40',
    '17:20',
    '18:00',
    '18:40',
    '19:20',
    '20:00',
    '20:40',
    '21:20',
  ]



  function setFullDisp(semana, open) {

    setProject(produce(project, draft => {
      for (let i in draft[semana].check) {
        draft[semana].check[i] = open
      }

    }))

  }
  function setHour(hora, open) {

    setProject(produce(project, draft => {
      for (let j in draft) {
        draft[j].check[hora] = open
      }

    }))

  }

  function setFull() {
    setProject(produce(project, draft => {

      for (let semana = 0; semana < 7; semana++) {

        for (let i in draft[semana].check) {
          draft[semana].check[i] = '0'
        }
      }

    }))
  }

  function setDisp(semana, hora) {
    setProject(produce(project, draft => {
      draft[semana].check[hora] = project[semana].check[hora] == '0' ? '1' : project[semana].check[hora] == '1' ? '2' : '0'
    }))
  }

  return (
    project.length > 0 &&
    <div className="bodyAtend" >
      <div>
        <div className='fecharAtend'>
          <button
            onClick={() => setFull()}
          >
            Fechar
          </button>
        </div>
        <div className="atendHora" >

          {project.length > 0 &&
            horarios.map((hora, index) => (
              <div
                className="atendHoras"
                key={index}
              >
                <span>{hora}</span>
                <HourOpen hora={index} setHour={setHour} />
              </div>
            ))
          }
        </div>
      </div>
      <div className="atendOpt">
        {project.map((day, index) => (
          <div
            className="semAtend"
            key={index}

          >



            <div className="fullDisp">
              {day.nome}
              <FullOpen
                semana={index}
                setFullDisp={setFullDisp}
              />


            </div>



            <DayCard
              setDisp={setDisp}
              card={day}
              index={index}
            />

          </div>
        ))}

      </div>






    </div>
  )
}

function DayCard(props) {

  const statusList = [
    < FcCancel />,
    <GiCheckMark style={{ fill: 'rgb(43, 112, 30)' }} />,
    <MdOutlineFastfood />
  ]

  return (
    <div className="contentAtend">
      {
        props.card.check.map((open, index) => (
          <div className='cardItem'>
            <div
              className="cardAtend"
              key={index}
              onClick={() => props.setDisp(props.index, index)}
            >
              {statusList[open]}
            </div>
          </div>
        ))
      }
    </div>

  );
}


function HourOpen(props) {

  const [current, setCurrent] = useState('1')
  function setHour() {
    props.setHour(props.hora, current == '0' ? '1' : current == '1' ? '2' : '0')
    setCurrent(current == '0' ? '1' : current == '1' ? '2' : '0')
  }
  const statusList = [
    < FcCancel />,
    <GiCheckMark style={{ fill: 'rgb(43, 112, 30)' }} />,
    <MdOutlineFastfood />
  ]
  return (
    <div
      className="cardAtend"
      key={props.index}
      onClick={() => setHour()}
    >
      {statusList[current]}
    </div>
  )
}

function FullOpen(props) {
  const [current, setCurrent] = useState('1')
  function setFullDisp() {
    props.setFullDisp(props.semana, current)
    setCurrent(current == '1' ? '0' : '1')
  }

  return (
    <div
      className="cardAtend"
      key={props.index}
      onClick={() => setFullDisp()}
    >
      {current == '0' ? <GiCheckMark style={{ fill: 'rgb(43, 112, 30)' }} /> : <FcCancel />}
    </div>


  )
}
