import React, { useState, useEffect, useContext } from "react";
import './DiarioForm.css'
import { Form } from '../../a_model/Container'
import { Table, Th } from '../../a_model/Contents'
import Context from "../Context";
import copy from "copy-to-clipboard";
import { deletar, get } from "../f_aux/fetch";
import { HiOutlineTrash } from "react-icons/hi";

export default function Anulados() {
  const { params } = useContext(Context)
  const [card, setCard] = useState([])

useEffect(() => {
  get(`/buscarAnulados/${params.data}/${params.data_fim}/${params.atendente}/${params.unidade}`, setCard)
}, [params]);

function deleteAnulado(id){
  const resp = window.confirm('Confirma a exclusão desse registro?')
  if(resp){
    function then(){
      get(`/buscarAnulados/${params.data}/${params.data_fim}/${params.atendente}/${params.unidade}`, setCard)
    }
    deletar(`/deleteAnulado/${id}`, then)
    
  }
  
}
const titles = [
  { width: '', title: 'Data' },
  { width: '', title: 'Nome' },
  { width: '', title: 'Procedimento' },
  { width: '', title: 'Atendente' },
  { width: '', title: <HiOutlineTrash/> },

]

  return (
    card.length > 0 &&

    <Form>
       
      <Table>
         <Th array={titles} />
        {card.map((item, index) =>
          <tr key={index} onClick={() => copy(item.id)}>
            <td> {item.data.substr(2, 8).split('-').reverse().join('/')}</td>
            <td style={{ maxWidth: '150px' }} onClick={() => copy(item.id_cliente)}> {item.nome_cliente}</td>
            <td style={{ maxWidth: '150px' }} onClick={() => copy(item.id_venda_sub)}> {item.nome_procedimento}</td>
            <td> {item.login}</td>
            <td onClick={()=> deleteAnulado(item.id)}>
            <HiOutlineTrash/>
            </td>


          </tr>

        )}
      </Table>
  
    </Form>
  )
}