import React, { useState, useEffect, useContext } from "react";
import { Button, Checkbox, InputCash, InputText } from "../../a_model/Input";
import Context from "../Context";
import { Form, FormContainer, Page } from "../../a_model/Container";
import { get, update } from "../f_aux/fetch";
import { Dialog } from "../c_layouts/Dialog";
import { Table, Th } from "../../a_model/Contents";
import { toCash } from "../../a_model/functions";
import { Modal } from "../../a_model/Modal";

export default function CursosIecb() {
    const [text, setText] = useState(false)
    const [dataCard, setDataCard] = useState(false)
    const [att, setAtt] = useState('')
    const [modalIsOpen, setIsOpen] = useState(false)
    function dialog(text, type) {
        setText({ text: text, type: type })
    }
    function openModal(curso) {
        setIsOpen(true)
        setDataCard(curso)
    }
    return (
        <Context.Provider value={{ att, setAtt, dialog, dataCard, openModal, setIsOpen }}>
            <Page>

                <Dialog value={text} />
             
                    <CursosForm />
             
            </Page>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >
                <EditCurso />
            </Modal>
        </Context.Provider>

    )
}

function CursosForm() {
    const { openModal, att } = useContext(Context)
    const [cursos, setCursos] = useState([])


    useEffect(() => {
        get(`/getCursos/1`, setCursos)
    }, [att]);
    const titles = [
        { width: '', title: 'ID' },
        { width: '100px', title: 'Nome' },
        { width: '', title: 'Valor' },
        { width: '', title: 'VIP' },
        { width: '', title: '% Modelos' },
    ]

    return (
        <Form>
             <header>
            <h2>Lista de Cursos</h2>
            </header>
            
            <Table height='100%'>
                <Th array={titles} />
                {
                    cursos.map((curso, index) =>
                        <tr  className='hover' key={index} onClick={() => openModal(curso)}>
                            <td>
                                {curso.id}
                            </td>
                            <td style={{ maxWidth: '400px' }}>
                                {curso.nome}
                            </td>
                            <td>
                                {toCash(curso.valor)}
                            </td>
                            <td>
                                {toCash(curso.vip)}
                            </td>
                            <td>
                                {curso.rateio_modelo? 'Sim' : 'Não'}
                            </td>
                        </tr>
                    )}
            </Table>
        </Form>
    )

}

function EditCurso() {
    const { dataCard, setAtt, setIsOpen } = useContext(Context)
    const [project, setProject] = useState(dataCard)

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
       
    }
    function salvar() {
        update(`/editar/cursos_iecb/id/${project.id}`, project, then)
        function then() {
            setAtt(Math.random())
            setIsOpen(false)
        }
    }
    return (
        <FormContainer height='400px'>
            <header>
            <h2>Editar Curso</h2>
            </header>
            <body>
                <InputText
                    width='400px'
                    flex='column'
                    title='Nome'
                    name='nome'
                    value={project.nome}
                    handleOnChange={handleChange}
                />
                <InputCash
                    flex='column'
                    title='Valor Regular'
                    name='valor'
                    value={project.valor}
                    handleOnChange={handleChange}
                />
                <InputCash
                    flex='column'
                    title='Valor VIP'
                    name='vip'
                    value={project.vip}
                    handleOnChange={handleChange}
                />
                 <Checkbox
                    value={project.rateio_modelo}
                    label='Comissão de modelos'
                    name='rateio_modelo'
                    event={handleChange}

                />
                 <Checkbox
                    value={project.ativo}
                    label='Ativo'
                    name='ativo'
                    event={handleChange}

                />
            </body>
            <footer>
                <Button
                    value='Salvar'
                    click={() => salvar()}
                    color='#555'
                />
            </footer>
        </FormContainer>
    )

}