import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-modal'
import { Card, ContainerC, Form } from '../../a_model/Container'
import { toCash, toDate } from '../../a_model/functions'
import Context from "../Context";
import copy from "copy-to-clipboard";
import { Button, InputText, LabelText } from "../../a_model/Input";
import { update } from "../f_aux/fetch";
import { HiOutlineTrash } from "react-icons/hi";
import { Table, Th } from "../../a_model/Contents";
import { CiAlarmOn, CiBadgeDollar } from "react-icons/ci";


export default function DiarioIecbForm() {
  const { card } = useContext(Context)
  const titles = [
    { width: '', title: `ID` },
    { width: '', title: `*` },
    { width: '', title: `Nome` },
    { width: '', title: `Valor` },
    { width: '', title: `*` },
  ]

  const tipos = [
    '',
    <div className='tipo' style={{ fontWeight: 500, color: '#d3912e' }}>A</div>,
    <div className='tipo' style={{ fontWeight: 500, color: '#701d97' }}>M</div>
  ]
  const status = [
    <div className='status'><CiAlarmOn /></div>,
    <div className='status'><CiBadgeDollar color='#1c992d' /></div>,
  ]
  return (
    card.length ?
      <ContainerC>
        {card.map((item, index) =>

          <Card
            width='700px'
            index={index}
            header={
              <>
                <div>
                  <label>Data:<span> {toDate(item.data)}</span></label>
                  <label>Total:<span> {toCash(item.soma)}</span></label>
                </div>
                <div>
                  <label>Professora:<span> {item.nome_docente}</span></label>
                  <label>Curso:<span> {item.nome_curso}</span></label>
                </div>
                <div>
                  <label>Alunos:<span> {item.count}</span></label>
                  <label>Código:<span> {item.id}</span></label>
             
                </div>
              </>
            }
          >
            <Table>
              <Th array={titles} />
              {item.alunos.map((aluno, index) =>
                <tr key={index}>
                  <td>
                    {aluno.id}
                  </td>
                  <td>
                    {tipos[aluno.tipo]}
                  </td>
                  <td>
                    {aluno.nome_aluno}
                  </td>
                  <td>
                    {toCash(aluno.valor)}
                  </td>
                  <td>
                    {status[aluno.status]}
                  </td>

                </tr>

              )}

            </Table>

          </Card>

        )}
      </ContainerC>
      :
      <span>Nada encontrado no filtro selecionado.</span>
  )
}

