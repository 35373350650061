import { getTheme } from './Theme'
import './Contents.scss'
import { ChromePicker } from 'react-color';
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import { InputSearch } from './Input';

export function Table(props) {

    return (
        <>
            <div
                className='table'
                style={{ maxHeight: props.height }}>
                <table >
                    <thead>
                        {props.thead}
                    </thead>
                    <tbody>
                        {props.children}
                    </tbody>

                </table>
                {props.footer &&
                
                    <label className='footerTable'>{props.footer}</label>
                }
            </div>
           
        </>
    )
}

export function Table2(props) {

    return (
        <>
            <div className='table2'>
                <table >
                    <thead>
                        {props.thead}
                    </thead>
                    <tbody>
                        {props.children}
                    </tbody>

                </table>

            </div>
            {props.footer &&
                <div className='footerTable'>
                    <label >{props.footer}</label>
                </div>}
        </>
    )
}

export function Th2({ array, params, setParams }) {


    function mudarOrdem(coluna) {
        if (coluna) {
            if (coluna == params.coluna) {
                setParams({ ...params, ['sentido']: params.sentido == 'asc' ? 'desc' : 'asc' })
            } else {
                setParams({ ...params, ['coluna']: coluna, ['sentido']: 'asc' })
            }
        }
    }

    return (
        <tr >
            {
                array.map((th, index) =>
                    <th key={index} className='trOrder th' onClick={() => mudarOrdem(th.coluna)}>
                        {th.title}  {th.coluna == params.coluna ?
                            params.sentido == 'asc' ?
                                <RiArrowDropUpFill />
                                : <RiArrowDropDownFill />
                            : ''}
                    </th>
                )
            }
        </tr>
    )
}

export function Th({ array }) {

    return (
        <tr >
            {
                array.map((th, index) =>
                    <th key={index} className='th' style={{ width: th.width }}>
                        {th.title}
                    </th>
                )
            }
        </tr>
    )
}


export function Label1({ label, span, limpar }) {

    return (
        <div className='label1'>
            <label> {label} </label>
            <span>{span}</span>
            {limpar ?
                <button
                    className=''
                    type='button'
                    onClick={() => limpar()}
                >
                    Limpar
                </button>
                : ''
            }
        </div>
    )
}

export function LeftList(props) {

    return (
        <div
            className='lList'
            style={{ background: getTheme(localStorage.getItem('tema'), 2) }}
        >
            {props.children}
        </div>
    )
}

export function RightList(props) {

    return (
        <div
            className='rList'
            style={{ background: getTheme(localStorage.getItem('tema'), 2) }}
        >
            {props.children}
        </div>
    )
}

export function LeftList2(props) {

    return (
        <div
            className='lList2'

        >
            {props.children}
        </div>
    )
}

export function RightList2(props) {

    return (
        <div
            className='rList2'

        >
            {props.children}
        </div>
    )
}

export function SubNav({ step, setStep, buttonsSub }) {


    return (
        <div style={{ background: getTheme(localStorage.getItem('tema'), 3) }} id='subNav'>
            {buttonsSub.map((item, index) =>
                <div className={step == index ? 'navOn' : 'navOff'}
                    key={index}
                    onClick={() => setStep(index)}
                >
                    {item}
                </div>
            )}
        </div>
    )
}

export const SeletorDeCor = ({ project, setProject, name }) => {

    const handleCorChange = (cor) => {
        setProject({ ...project, [name]: cor.hex });
    };

    return (
        <div>
            <ChromePicker color={project.color} onChange={handleCorChange} />

        </div>
    );
};

export function RankingBar(props) {


    return (
        <ul id='rankingBar' style={{ maxHeight: props.height, width: props.width }}>
            {props.children}
        </ul>
    )
}

export function ComentariosForm(props) {
    return (
        <div id='ComentariosForm' style={{ height: props.height, width: props.width }}>
            {props.children}
        </div>
    )
}

export function NavMobile(props) {
    return (
        <div id='navMobile'>
            <div id='buttons'>
                {props.buttons.map((button, index) =>
                    <button key={index} className={props.nav == index ? 'navMOn' : 'navMOff'} onClick={() => props.setNav(index)}>
                        {button}
                    </button>
                )}
            </div>

            {props.children}

        </div>
    )
}

export function Square({ color, size }) {
    return (
        <div style={{ top: '50%', position: 'absolute', border: '1px solid #ccc', borderRadius: '2px', height: size, width: size, background: color }} />
    )
}

export function SearchList(props) {
    return (
        <div id='searchListContainer'>

            <div >

                <header>

                    <InputSearch search={props.search} setSearch={props.setSearch} />

                    <div id='searchListSteps' >
                        {props.stepTitles.map((step, index) =>
                            <div style={index == props.step ? { background: getTheme(localStorage.getItem('tema'), 3), fontWeight: '500' } : { background: '' }} onClick={() => props.setStep(index)}>
                                {step}
                            </div>
                        )}
                    </div>

                </header>

                <body style={{ background: getTheme(localStorage.getItem('tema'), 3) }}>

                    {props.children}

                </body>
            </div>

        </div>
    )
}

export function EvoList({list}){

    return(
        <div>{list.length}</div>
    )
}