import React, { useState, useEffect, useContext } from "react";
import './Day.scss'
import { IoIosLock } from "react-icons/io";
import moment from "moment";
import Context from "../Context";
import { deletar, get, update, updateParams } from "../f_aux/fetch";
import { GiCheckMark } from 'react-icons/gi'
import { FiUserCheck } from "react-icons/fi";
import { GrSubtractCircle } from "react-icons/gr";
import { TbChecklist, TbCalendarEvent, TbAlertTriangle, TbCalendarOff } from "react-icons/tb";
import { FiClock } from "react-icons/fi";

function Day(props) {
    const { dialog, isEdit, setIsEdit, setRefresh, salas } = useContext(Context)
    const width = 100 / props.card.length + "%"
    const hrs = { indice: props.index, hora: props.horario, hora_fim: props.horario_fim }
    const style = {
        marginLeft: parseInt(props.card.z) > 2 ? 23.5 * (parseInt(props.card.z) - 2) + '%' : 0,
        top: props.card.indice + 'px',
        height: props.card.duracao > 59 ? props.card.duracao + 'px' : '60px',
        width: parseInt(props.card.z) > 2 ? `calc( 100% - 24*${parseInt(props.card.z) - 2}% )` : `100%`
    }


    useEffect(() => {

    }, [])

    function getColor1(sala) {
        return (salas.find((element) => element.id == sala) ? salas.find((element) => element.id == sala).color : '#111')
    }

    function alterarAgenda(cadastro) {

        get(`/disp/${cadastro.data.substr(0, 10)}/${cadastro.hora}/${cadastro.hora_fim}/${isEdit.sala}/${isEdit.unidade}/${cadastro.atendente}/0`, then0)
        function then0(resp2) {
            let resp3 = false
            if (resp2.block) {
                window.alert("Procedimento bloqueado para essa atendente")
                setIsEdit(false)
            } else {
                if (!resp2.disp) {
                    resp3 = window.confirm(`Sala reservada para ${resp2.atendente} , confirma o agendamento nesse horário?`)
                }
                if (resp2.disp || resp3) {

                    if (isEdit.atendente !== 13) {
                        let cad = {
                            data: cadastro.data,
                            atendente: cadastro.atendente,
                        }
                        cad.status = isEdit.status == '3' ? 3 : 0
                        cad.assinado = isEdit.assinado
                        cad.hora = props.card.hora
                        cad.indice = props.index
                        cad.hora_fim = moment(`2020.05.05 ${cad.hora}`).add(isEdit.duracao, 'minutes').format('HH:mm')
                        update(`/alterarAgenda/${isEdit.id}`, cad, then)
                        function then() {
                            setRefresh(cadastro)
                            setIsEdit(false)
                            props.setInput(0, cad)
                        }

                    } else {
                        window.alert('Esse props.card é um anestésico...')
                    }


                }
            }
        }

    }

    function confirmarAgenda(id_cliente, data, status, id_venda_sub) {
        updateParams(`/confirmarAgenda/${id_cliente}/${data}/${status == 0 ? 1 : 0}/${id_venda_sub}`, inputClose)

    }

    function anularTransferencia(user) {
        window.alert('Horário bloqueado por ' + user)
        setIsEdit(false)
    }

    function inputClose() {
        setRefresh(props.card)
        dialog(props.card.status == 0 ? 'Confirmado' : 'Status alterado', 0)


    }

    function desbloquear() {

        let resp = window.confirm(`${props.card.user}: "${props.card.nota}". Deseja desbloquear esse horário?`)
        if (resp) {

            deletar(`/delete5/${props.card.id}`, inputClose)
        }


    }

    function Aberto() {
        return (
            <div
            key={props.index+'-'+props.indexCard}
                onClick={() =>
                    isEdit ?
                        alterarAgenda(props.card)
                        : props.setInput(2, props.card, hrs)
                } className={`hourAgenda hourAgendaDisp`} style={style}
            >
                <p style={{ color: '#4c95e2' }} >
                    {

                        props.card.hora.substr(0, 5)

                    }
                </p>

            </div>

        )
    }


    function Disp0() {
        const [disp, setDisp] = useState(false)

        function abrir() {
            let resp = window.confirm('Este horario está fechado, deseja abrir assim mesmo?')
            if (resp) { setDisp(true) }
        }
        return (

            disp ?
                <div key={props.index+'-'+props.indexCard} style={style} className="hourAgenda hourAgendaDisp"
                    onClick={() =>
                        isEdit ?
                            alterarAgenda(props.card)
                            : props.setInput(2, props.card, hrs)
                    }
                >
                    <p style={{ color: props.color }} >
                        {

                            props.hora.substr(0, 5)

                        }
                        
                    </p>

                </div>


                :

                <div
                    className="hourAgenda hourAgendaIndisp" style={style}
                    onClick={() => isEdit ?
                        alterarAgenda(props.card)
                        : abrir()

                    }

                >
                </div>



        )
    }
    function Intervalo() {

        return (
            <div key={props.index+'-'+props.indexCard} className="hourAgenda hourBloqueio" style={style}>


                <div style={{ minWidth: width, backgroundColor: props.card.length > 1 && '#fff' }}         >
                    Almoço
                </div>
            </div>

        )
    }

    function Bloqueio() {
        return (
            <div
            key={props.index+'-'+props.indexCard}
                style={style}
                className={`hourAgenda hourBloqueio`}
                onClick={() =>
                    isEdit ?
                        anularTransferencia(props.card.user) :
                        desbloquear()
                }
            >
                <div style={{ fontSize: props.card.nota.length > 25 ? '0.75em' : '' }}>
                    {
                        props.card.nota === 'fechado' ?
                            <div> <IoIosLock /> </div>
                            :
                            props.card.nota.length > 100 ?
                                props.card.nota.substr(0, 100)
                                : props.card.nota
                    }

                </div>

            </div >
        )
    }

    function Agendado() {
        function formatTel(telefone) {
            let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
            return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
        }

        const statusList = [
            { status: 'Agendado', color: '#444' },
            { status: 'Confirmado', color: '#7B4EDE' },
            { status: 'Confirmado', color: '#0B87D4' },
            { status: 'Assinado', color: '#07871E' },
            { status: 'Atrasado', color: '#D67A01' },
            { status: 'Reagendar', color: '#D4CD0D' },
            { status: 'Cancelado', color: '#EB2009' },
            { status: 'Anulado', color: '#D67A01' },
        ]
        return (

            <div
                key={props.index+'-'+props.indexCard}
                style={style}
                className={`hourAgenda hourAgendado`}

            >
                <header className={`balaoDay ${props.card.indice > 100 ?
                    props.indexList < props.count - 2 ?
                        'topRight' : 'topLeft'
                    : props.indexList < props.count - 2 ?
                        'bottomRight' : 'bottomLeft'
                    }`}>
                    <p>{props.card.nome_cliente}</p>
                    <p>{props.card.nome_procedimento}</p>
                    <p>{formatTel(props.card.telefone)}</p>
                    <p>{props.card.hora.substr(0, 5)} ~ {props.card.hora_fim.substr(0, 5)}</p>
                    <p style={{ color: statusList[props.card.status].color }}>{statusList[props.card.status].status}</p>
                    {props.card.nota ? <p style={{ fontWeight: '600' }}>* {props.card.nota}</p> : ''}
                </header>
                <div style={{ background: getColor1(props.card.sala), borderRadius: '7px' }} className='hourHover'
                    onClick={() =>
                        isEdit ?
                            alterarAgenda(props.card)
                            : props.setInput(2, props.card, hrs)}>
                    {isEdit ? <span>Selecione o horário de destino</span> : <>
                        <span style={{ color: '' }}
                        >
                            {props.card.nome_cliente ?
                                props.card.nome_cliente : ''
                            }
                        </span>
                        {parseInt(props.card.duracao) > 79 ?
                            <span style={{ color: '' }}>{

                                props.card.nome_procedimento

                            }
                            </span>
                            : ''}

                    </>}



                    {
                        props.card.nota ?
                            <span style={{}} >
                                {props.card.nota}
                            </span>
                            :
                            <span style={{}} >
                                {parseInt(props.card.duracao) > 79 ? `${props.card.hora} ~ ${props.card.hora_fim}` : props.card.nome_procedimento}
                            </span>
                    }
                </div>
                <Status />
            </div>
        )
    }

    function Status() {

        const statusList = [
            <TbCalendarEvent color='#444' />,
            <GiCheckMark fill='#7B4EDE' />,
            <FiUserCheck color='#0B87D4' />,
            <TbChecklist color='#07871E' />,
            <FiClock color='#D67A01' />,
            <TbAlertTriangle color='#D4CD0D' />,
            <TbCalendarOff color='#EB2009' />,
            <GrSubtractCircle fill='#D67A01' />,

        ]
        const [onMouse, setOnMouse] = useState(statusList[props.card.status])

        return (
            parseInt(props.card.status) > 2 ?
                <button className="status" >
                    {statusList[props.card.status]}
                </button>
                :
                <button
                    onMouseOut={() => setOnMouse(props.card.status == 0 ? statusList[1] : statusList[0])}
                    onMouseLeave={() => setOnMouse(statusList[props.card.status])} className="status status2"

                    onClick={() => confirmarAgenda(props.card.id_cliente, props.card.data.substr(0, 10), props.card.status, props.card.id_venda_sub)}
                >
                    {onMouse}
                </button>
        )
    }

    return (
        <div className="cards" >

            {props.card && props.card.nome_cliente ?
                props.card.id_venda_sub == 1 ?
                    <Bloqueio />
                    : props.card.procedimento == 2 ?
                        <Aberto />
                        : <Agendado />
                : props.card && props.card.disp == 1 && (props.card.data == moment().format('YYYY-MM-DD') && props.card.hora > moment().format('HH:mm') || props.card.data > moment().format('YYYY-MM-DD')) ?
                    <Aberto />
                    : props.card.disp == 2 ? <Intervalo />
                        : <Disp0 />
            }

        </div>
    )
} export default Day


//style={{ backgroundImage: `linear-gradient(${statusList[props.card.status].color},${statusList[props.card.status].color + "77"})` }}