import { useEffect, useState, useContext } from "react";
import 'moment/locale/pt-br'
import "./DayForm.css";
import Day from "../c_layouts/Day";
import Anestesic from "../c_layouts/Anestesic";
import SalaDay from "../c_layouts/SalaDay";
import Context from "../Context";
import { get } from "../f_aux/fetch";
import InputDay from "../c_layouts/InputDay";
import { Modal } from "../../a_model/Modal";
import { getTheme } from "../../a_model/Theme";
import { RiRefreshLine } from 'react-icons/ri'
import InputAnes from "../c_layouts/InputAnes";
import { horariosProc } from "../../a_model/templates";
export default function DayForm() {
  const {  mudarDayForm, count, currentDay, refresh, setRefresh, user, unidade } = useContext(Context)
  const [dataCard, setDataCard] = useState([{ atendente: '' }])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [sala, setSala] = useState([])
  const [agenda, setAgenda] = useState([])


  useEffect(() => {
    get(`/buscarBloqueio/${currentDay.format('YYYY-MM-DD')}/${unidade}`, setSala)
  }, [currentDay, refresh]);

  useEffect(() => {
    get(`/getAgenda/${currentDay.format('YYYY-MM-DD')}/${unidade}`, setAgenda)

  }, [currentDay, refresh])



  function setInput(page, dataCard, hrs) {
    setDataCard([dataCard, hrs])
    page == 0 ? setIsOpen(false)
      : setIsOpen(true)
    setRefresh(Math.random())
  }


  return (
    agenda &&

    <>

      <Modal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      >{dataCard[0].atendente == '13' ?
        <InputAnes
          setInput={setInput}
          dataCard={dataCard}
        /> :
        <InputDay
          setInput={setInput}
          dataCard={dataCard}
        />}
      </Modal>

      <div className="content">

        <div id="inlineAtend">

          <div id='iconHours' onClick={() => window.location.replace(`/calendar/${unidade}/${user}`)}>
            <RiRefreshLine color={getTheme(localStorage.getItem('tema'), 0)} />
          </div>

          {agenda && agenda.map((atendente, index) => (
            <div onClick={() => mudarDayForm(atendente.atendente.id)} className='atendente' key={index}
              style={{ width: `calc( ( 100vw - 300px ) / ${agenda.length + 1} )` }}
            >
              <span style={{
                color: getTheme(localStorage.getItem('tema'), 0),
                borderLeft: '1px solid ' + getTheme(localStorage.getItem('tema'), 0)
              }}>{atendente.atendente.nome && atendente.atendente.nome}</span>
            </div>
          ))}


          {sala.map((atendente, index) => (
            <div className='atendente' key={index} style={{ width: '10px' }} >
              <span style={{
                color: getTheme(localStorage.getItem('tema'), 0),
                borderLeft: '1px solid ' + getTheme(localStorage.getItem('tema'), 0)
              }}>'</span>
            </div>
          ))}


          <div className='atendente' style=
            {{ width: `calc( ( (100vw - 310px) / ${agenda.length + 1} ) - 10*${sala.length}px)` }} >
            <span style={{
              color: getTheme(localStorage.getItem('tema'), 0),
              borderLeft: '1px solid ' + getTheme(localStorage.getItem('tema'), 0)
            }}>Anestésico</span>
          </div>

        </div>

        <div className="inlineHours">

          <div className="horas">
            {
              horariosProc.map((hora, index) => (
                <div className="hora" key={index}>
                  <span style={{ color: getTheme(localStorage.getItem('tema'), 0) }}>
                    {hora.hora}</span>
                </div>
              ))
            }
          </div>

          {agenda && agenda.map((atendente, index) => (
            <div key={index} className="atendColumn" style={{ width: `calc( (100vw - 300px) / ${agenda.length + 1} )` }}>
              <DayCard
                count={agenda.length}
                setInput={setInput}
                horarios={horariosProc}
                cards={atendente.agenda}
                indexList={index}
              />
            </div>
          ))

          }

          {sala.map((item, index) =>
            <div key={index} className="" >
              <Sala
                sala={sala}
                item={item}
                setSala={setSala}

                setInput={setInput}
                horarios={horariosProc}

              />
            </div>
          )}

          <Anestesico
            salaLength={sala.length}
            count={agenda.length}
            setInput={setInput}
            horarios={horariosProc}

          />

        </div>
      </div>
    </>

  );
}

function DayCard(props) {

  return (



    props.cards && props.cards.map((hora, index) => (
      <Day
        count={props.count}
        setInput={props.setInput}
        horario={props.horarios[index]}
        index={index}
        hora={hora.hora}
        card={hora}
        indexList={props.indexList}
      />
    ))


  );
}

function Sala(props) {
  const { refresh } = useContext(Context)

  return (
    props.item.map((hora, index) =>
      <div key={index}>
        <SalaDay
          setBalao={props.setBalao}
          horario={props.horarios[index]}
          horario_fim={props.horarios[index + 1]}
          refresh={refresh}
          setRefresh={props.setRefresh}
          setIsEdit={props.setIsEdit}
          setInput={props.setInput}
          hora={props.item.hora}
          card={hora}
        />
      </div>
    ))
}


function Anestesico(props) {
  const { unidade, user, currentDay, refresh } = useContext(Context)
  const [card, setCard] = useState({ agenda: [] });

  useEffect(() => {
    get(`/buscarAnestesico/${currentDay.format('YYYY-MM-DD')}/${unidade}`, setCard)

  }, [currentDay, refresh]);

  return (
    <div key={props.index} className='atendColumn'>
      {card.agenda.map((hora, index) => (
        <div className="cards anestesicos" style={{ width: `calc( (100w - 310px) / ${props.count + 1} )` }}>
          <Anestesic
            count={props.count}
            salaLength={props.salaLength}
            salaHeader={props.salaHeader}
            refresh={props.refresh}
            setRefresh={props.setRefresh}
            user={user}
            setInput={props.setInput}
            index={index}
            hora={hora[0].hora}
            card={hora}
          />
        </div>
      ))}
    </div>
  );
}
/**           */