import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import DayForm from '../b_forms/DayForm'
import moment from "moment";
import { Modal } from '../../a_model/Modal'
import './calendar.css'
import { Dialog } from "../c_layouts/Dialog";
import { Pdf } from "../c_layouts/Pdf";
import MonthCalendar from "../../a_model/MonthCalendar";
import Context from '../Context'
import { produce } from 'immer'
import { get } from "../f_aux/fetch";
import { Reagendar } from "../c_layouts/Reagendar";
import DayAForm from "../b_forms/DayAForm";
import MonthAtend from "../c_layouts/MonthAtend";
import { getTheme } from '../../a_model/Theme'
import { Custom } from "../c_layouts/Custom";
import Clientes from "./Clientes";
import Diario from "./Diario";
import EditA from "./EditA";
import Salas from "./Salas";
import { CiMedicalMask, CiEdit, CiFileOn, CiSettings, CiBoxList, CiUser } from "react-icons/ci";
import InputWithLabel from "../../a_model/Input";

function Calendar() {
  const { datap } = useParams()
  const { unidadep } = useParams()
  const { userp } = useParams()
  const user = userp.toLowerCase() || localStorage.getItem('login').toLowerCase()
  const unidade = unidadep || localStorage.getItem('unidade')
  const [clients, setClients] = useState([])
  const [agenda, setAgenda] = useState([])
  const [currentDay, setCurrentDay] = useState(datap ? moment(datap.split('-')).clone().locale("pt-br").subtract(1, 'month') : moment().locale("pt-br"));
  const [currentFormat, setCurrentFormat] = useState(0)
  const [currentModal, setCurrentModal] = useState(0)
  const [currentForm, setCurrentForm] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [refresh, setRefresh] = useState('')
  const [id_atendente, setId_atendente] = useState({})
  const [tema, setTema] = useState(0)
  const [reagendar, setReagendar] = useState([])
  const [salas, setSalas] = useState([])
  const [group, setGroup] = useState(0)
  useEffect(() => {

    if (clients.length === 0) {
      get(`/clients/${unidade}`, setClients)
    }

    get(`/buscarReagendar/${unidade}`, setReagendar)

  }, [currentDay, refresh])

  useEffect(() => {
    //setTema(localStorage.getItem('tema'))
    get(`/buscarTema/${user}`, then)
    function then(resp2) {
      localStorage.setItem('tema', resp2.tema)
      setTema(resp2.tema)
    }
    if (localStorage.getItem('user') != user || localStorage.getItem('unidade') != unidade) {
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('unidade', unidade)
    }

    if (salas.length == 0) {
      get(`/buscarSalas/${unidade}`, setSalas)

    }
    if (!localStorage.getItem('unidade')) {
      localStorage.setItem('unidade', unidadep)
      localStorage.setItem('login', userp)
    }

    get(`/get/sec_users_groups/login/${user}`, thenG)

  }, [])

  function thenG(resp) {
    if (resp.length) {
      setGroup(resp[0].group_id)
      if (resp[0].group_id == 4 && unidade < 4) {
        get(`/get/atendentes/login/${user}/unidade=${unidade}`, thenG2)

        function thenG2(resp) {
          if (resp?.length) {
            mudarDayForm(resp[0].id)

          }
        }
      }
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false)

  function handleCloseModal() {
    setIsOpen(false)
  }

  function handleOpenModal(value) {
    setCurrentModal(value)
    setIsOpen(true)
  }

  function mudarDayForm(value) {
    if (value) {
      setCurrentFormat(1)
      setId_atendente(value)
    } else {
      setCurrentFormat(0)
      setCurrentDay(moment())
    }
  }

  function HoverDropdown() {

    return (

      <ul className="dropdown-menu" >
        <li style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div onClick={() => setCurrentForm(1)}>
            <CiBoxList color={getTheme(tema, 0)} />
            <span> Diário</span>
          </div>
          {reagendar.length > 0 && <div onClick={() => handleOpenModal(2)} id='diarioIcon' style={{ background: getTheme(tema, 2) }} >{reagendar.length}</div>}
        </li>

        <li onClick={() => setCurrentForm(2)}>
          <CiUser color={getTheme(tema, 0)} />
          <span>
            Clientes
          </span>
        </li>

        <li onClick={() => setCurrentForm(3)}>
          <CiMedicalMask color={getTheme(tema, 0)} />
          <span>Atendentes</span>
        </li>
        <li onClick={() => setCurrentForm(4)}>
          <CiSettings color={getTheme(tema, 0)} />
          <span>Salas</span>
        </li>
        <li onClick={() => handleOpenModal(0)}>
          <CiFileOn color={getTheme(tema, 0)} />
          <span> Imprimir</span>
        </li>
        <li onClick={() => handleOpenModal(1)}>
          <CiEdit color={getTheme(tema, 0)} />
          <span> Personalizar</span>
        </li>
      </ul>

    );
  }

  const modals = [
    <Pdf
      atendentes={agenda}
      handleCloseModal={handleCloseModal}
    />,
    <Custom />,
    <Reagendar list={reagendar} />
  ]

  function mudar(value) {
    setCurrentDay(value)
  }



  const pers = [
    <DayForm />,
    <DayAForm />,
  ]
  const forms = [
    '',
    <Diario />,
    <Clientes />,
    <EditA />,
    <Salas />
  ]
  const calendars = [
    <MonthCalendar tema={tema} currentDay={currentDay} mudar={mudar} />,
    <MonthAtend tema={tema} currentDay={currentDay} mudar={mudar} />,

  ]

  const [text, setText] = useState(false)

  function dialog(text, type) {
    setText({ text: text, type: type })
  }

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Limpa o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <Context.Provider value={{
      unidade,
      user,
      dialog,
      currentDay,
      setCurrentDay,
      refresh,
      setRefresh,
      isEdit,
      setIsEdit,
      id_atendente,
      clients,
      agenda,
      mudarDayForm,
      tema,
      setTema,
      salas,
      setCurrentForm,
      currentFormat,
      group
    }}>
      {currentForm < 1 ?

        windowSize.width > 800 ?
          <div className="agenda" >

            <div className='dayMenu1'>
              {calendars[currentFormat]}
              <HoverDropdown />

            </div>

            <div className='dayPage1' style={{ background: getTheme(tema, 1) }}>
              {pers[currentFormat]}
            </div>

            <Modal
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
            >
              {modals[currentModal]}
            </Modal>
          </div>
          :
          <div>

          </div>
        :
        forms[currentForm]
      }

      <Dialog value={text} />

    </Context.Provider>
  )
}

export default Calendar
