import { createContext, useState, useEffect } from "react";
import { api } from "../hooks/useApi";
import history from "./history";
import Login from "../a_pages/Login";

const Context = createContext();

function AuthProvider({ children }) {
    
    const [verif, setVerif] =useState(false)
    const [loading, setLoading] = useState(true)
    const [login, setLogin] = useState(localStorage.getItem('login'))
   
    useEffect(()=>{
        const token = localStorage.getItem('token')

        if(token && login){
            api.defaults.headers.Authorization = `Bearer ${(token)}`
         //   //console.log((token))
            setVerif(token)
        }

        setLoading(false)

    }, [])

    async function handleLogin(login){

        
        const { data: {token} } = await api.post(`verif`)
        api.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem('token', JSON.stringify(token))
        localStorage.setItem('login', login.login)
        localStorage.setItem('nome', login.nome)                
        localStorage.setItem('unidade', login.unidade)        
        localStorage.setItem('tema', login.tema)        
        localStorage.setItem('cargo', login.cargo)
        localStorage.setItem('grupo', login.grupo)
        setVerif(true)

        window.location.replace('/maestro/home')  
        

    }

    function handleLogout(){
        api.defaults.headers.Authorization = undefined;
        localStorage.removeItem('token')
        localStorage.removeItem('login')        
        localStorage.removeItem('unidade')        
        localStorage.removeItem('tema')        
        localStorage.removeItem('cargo')
        localStorage.removeItem('grupo')
        
        setVerif(false)

        window.location.replace('/maestro/login')       
        
    }

    if (loading){
        return <h1>Loading...</h1>
    }



    return(
        <Context.Provider value={{verif, handleLogin, handleLogout, login}}>
            {verif && login? children :
            <Login/>}
        </Context.Provider>
    )
}

export { Context, AuthProvider}