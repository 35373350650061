import  { Empty } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext } from 'react'
import Context from '../../Context';
import { MdAddCircleOutline } from 'react-icons/md'
 
export default function CardEmpty({ att, index, indexCards}){

    const ref = useRef()
  const { move } = useContext(Context)

  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id: 0, att  },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
      
      move(item, indexCards, att)
 
    }

  })

  dragRef(dropRef(ref))

    return(
        <Empty ref={ref} isDragging={isDragging}> 
        <MdAddCircleOutline/>
        </Empty>
    )
}