import React, { useContext } from "react";
import './Day.scss'
import Context from "../Context";

import moment from "moment";
import { get, update } from "../f_aux/fetch";

function Anestesic(props) {
    const { isEdit, setIsEdit, setRefresh } = useContext(Context)
   
    function alterarAgenda(cadastro) {
        if (isEdit.atendente == 13) {

            cadastro.hora_fim = moment(`2020.05.05 ${cadastro.hora}`).add(isEdit.duracao, 'minutes').format('HH:mm')
            update(`/alterarAgenda/${isEdit.id}`, cadastro, then)
            function then() {
                setRefresh(cadastro)
                setIsEdit(false)
                props.setInput(1, cadastro)
            }

        } else {
            window.alert('Apenas anestésico aqui...')
        }
    }


    return (

            props.card.map((item) =>
                <div className={`card ${item.nome_cliente ? 'fullBorder' : 'aberto'}`} key={props.card.indexOf(item)}
                    style={{
                        width: `calc( (100vw - 300px) / ${props.count + 1} / ${props.card.length} - 10*${props.salaLength}px )`,
                        backgroundColor: `${item.nome_cliente ? '#67767e' : '#fafaee'}`
                    }}
                    onClick={() => isEdit ?
                        alterarAgenda(item)
                        : props.setInput(1, item)}>
                 
                        <p style={{ color: !item.nome_cliente && '#af1caf' }}>{item ? item.nome_cliente || props.hora.substr(0, 5) : ''}</p>
                        <p>{item.nome_procedimento ? item.nome_procedimento : ''}</p>
                        <p>{item.user ? item.hora : ''}</p>
                  
                </div>

            )
        

    )
} export default Anestesic