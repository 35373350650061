import React, { useContext, useState } from "react";
import { Form } from "../../../a_model/Container";
import {InputWithLabel,  Button, InputMasks, InputText } from "../../../a_model/Input";
import { insert } from "../../../calendar/f_aux/fetch";
import Context from "../../Context";

export default function Cadastro() {
    const { setStep, user } = useContext(Context)
    const cadastro = {
        nome: '',
        telefone: 55,
        email: '',
        cidade: '',
        estado: '',
        rede: 1,
        origem: 'Manual',
        user
    }
    const [project, setProject] = useState(cadastro)
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    
      }
      function inserir(project){

        if(project.nome && project.telefone.length > 12 && project.cidade && project.rede && project.estado){
            project.telefone = sanitizeString(project.telefone)
            insert(`/inserir/leads_franquiados`, project, setStep(0))
        }else{
            window.alert('Preencha todos os campos')
        }
      }
      function sanitizeString(str) {
        return str.replace(/[\s()-]/g, '');
    }
    return (
        <Form>
            <div style={{width:'50%', border: '1px solid #ddd', borderRadius:'5px', padding: '20px'}}> 
            <span>Cadastro</span>
            <InputWithLabel
            name='nome'
            flex='Column'
            width='350px'
            value={project.nome || ''}
            title='Nome'
            handleOnChange={handleChange}
            />
            <InputMasks
            flex='Column'
            mask='2'
            name='telefone'
            value={project.telefone || ''}
            title='Telefone'
            handleOnChange={handleChange}
            />
            <InputWithLabel
            name='email'
            flex='Column'
            width='350px'
            value={project.email || ''}
            title='E-mail'
            handleOnChange={handleChange}
            />
            <InputWithLabel
            name='cidade'
            flex='Column'
            width='350px'
            value={project.cidade || ''}
            title='Cidade'
            handleOnChange={handleChange}
            />
            <InputWithLabel
            name='estado'
            flex='Column'
            width='350px'
            value={project.estado || ''}
            title='Estado'
            handleOnChange={handleChange}
            />
            <div style={{paddingTop:'10px'}}>
            <Button
            color='#555'
            value='Cadastrar'
            click={()=> inserir(project)}
            />
            </div>
            </div>
        </Form>
    )

}