import { useState, useContext, useEffect } from 'react'
import './InputDay.scss'
import { ContainerC, StepsForm } from '../../a_model/Container'
import { toDate } from '../../a_model/functions'
import { get } from '../f_aux/fetch'
import Context from '../Context'
import { MdUpdate } from "react-icons/md";
import { toDateTime } from "../../a_model/functions";

export default function HistoricoEvent({ setInput, dataCard }) {
    const [step, setStep] = useState(0)
    const steps = [
        { color:'#deeafa',nome: 'Cliente', content: <Id patients_idpatient={dataCard[0].patients_idpatient} /> },
        { color:'#deeafa',nome: 'Data & Hora', content: <Date data={dataCard[0].start_date.substr(0, 10)} hora={dataCard[0].start_time} doctor={dataCard[0].doctor_iddoctor} /> }
    ]
    return (

            <StepsForm
                height='80.7vh'
                step={step}
                setStep={setStep}
                steps={steps}

            />

    )
}

function Id({ patients_idpatient }) {
    const { categ } = useContext(Context)

    const [list, setList] = useState([])
    useEffect(() => {
        get(`/getHistorico/${patients_idpatient}`, setList)
    }, [])

    return (

        <ContainerC>
          
            {list.map((item, index) =>
                <div className='cardAtt'>

                    {index ? <p>{item.usuario_alteracao?`Alterado por ${item.usuario_alteracao}` : 'Agendado'} ({toDateTime(item.data_cadastro)})</p> : ''}
                    <LabelAtt
                        header='Código'
                        previous={item.idevent_history}
                        current={item.idevent_history}
                    />
                    <LabelAtt
                        header='Avaliadora'
                        previous={index ?
                            list[index - 1].nome_doctor
                            : item.nome_doctor}
                        current={item.nome_doctor}
                    />
                    <LabelAtt
                        header='Data'
                        previous={index ?
                            toDate(list[index - 1].date_event)
                            : toDate(item.date_event)}
                        current={toDate(item.date_event)}
                    />
                    <LabelAtt
                        header='Hora'
                        previous={index ?
                            list[index - 1].time_event
                            : item.time_event}
                        current={item.time_event}
                    />
                    <LabelAtt
                        header='Status'
                        previous={index ?
                            categ.find((element) => element.id == list[index - 1].categ).nome
                            : categ.find((element) => element.id == item.categ).nome}
                        current={categ.find((element) => element.id == item.categ).nome}
                    />
                    <LabelAtt
                        header='Nota'
                        previous={index ?
                            list[index - 1].descr
                            : item.descr}
                        current={item.descr}
                    />
                    <LabelAtt
                        header='Nota 2'
                        previous={index ?
                            list[index - 1].descr2
                            : item.descr2}
                        current={item.descr2}
                    />
                    <LabelAtt
                        header='Usuário'
                        previous={index ?
                            list[index - 1].login
                            : item.login}
                        current={item.login}
                    />
                </div>
            )}
        </ContainerC>

    )
}

function Date({ data, hora, doctor }) {
    const { categ } = useContext(Context)

    const [list, setList] = useState([])
    useEffect(() => {
        get(`/getHistorico/${data}/${hora}/${doctor}`, setList)
    }, [])

    return (

        <ContainerC>
          
            {list.map((item, index) =>
                <div className='cardAtt'>
                    {index ? <p>{item.usuario_alteracao?`Alterado por ${item.usuario_alteracao}` : 'Agendado'} ({toDateTime(item.data_cadastro)})</p> : ''}
                    <LabelAtt
                        header='Código'
                        previous={item.idevent_history}
                        current={item.idevent_history}
                    />

                    <LabelAtt
                        header='Cliente'
                        previous={index ?
                            list[index - 1].nome_cliente
                            : item.nome_cliente}
                        current={item.nome_cliente}
                    />
                    <LabelAtt
                        header='Telefone'
                        previous={index ?
                            list[index - 1].telefone
                            : item.telefone}
                        current={item.telefone}
                    />
                    <LabelAtt
                        header='Status'
                        previous={index ?
                            categ.find((element) => element.id == list[index - 1].categ).nome
                            : categ.find((element) => element.id == item.categ).nome}
                        current={categ.find((element) => element.id == item.categ).nome}
                    />
                    <LabelAtt
                        header='Nota'
                        previous={index ?
                            list[index - 1].descr
                            : item.descr}
                        current={item.descr}
                    />
                    <LabelAtt
                        header='Nota 2'
                        previous={index ?
                            list[index - 1].descr2
                            : item.descr2}
                        current={item.descr2}
                    />
                    <LabelAtt
                        header='Usuário'
                        previous={index ?
                            list[index - 1].login
                            : item.login}
                        current={item.login}
                    />
                </div>
            )}
        </ContainerC>

    )
}

function LabelAtt({ header, previous, current }) {

    return (
        <div className='labelAtt'>
            <label>
                {header}
            </label>
            {current == previous ?
                <span>{current}</span>
                : <span style={{ color: '#06a012' }}>{current} <MdUpdate /></span>
            }
        </div>
    )

}

