import { useEffect, useState, useCallback, memo } from "react";
import moment from "moment";
import "./Month.scss";
import 'moment/locale/pt-br'
import calendarBuild from "../calendar/f_aux/CalendarBuild";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { getTheme } from "./Theme";
import { produce } from 'immer'

export default function CalendarList({ includeWeek }) {

  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [currentDay, setCurrentDay] = useState(moment().locale("pt-br"));

  const weekEvent = useCallback((value) => {
    setSelectedWeeks(produce((draft) => {
      const index = selectedWeeks.findIndex((element) => element === value);
      if (index !== -1) {
        draft.splice(index, 1);
      } else {
        draft.push(value);
      }
    }));
  }, [selectedWeeks]);

  const MemoizedMonthCalendar = memo(({ currentDay }) => {
    return <MonthCalendar currentDay={currentDay} weekEvent={weekEvent} selectedWeeks={selectedWeeks} />;
  });

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        size="small"
        onClick={() => setCurrentDay(currentDay.clone().subtract(1, 'month'))}
      >
        <KeyboardArrowLeftIcon style={{ color: "#143296", fontSize: 40 }} />
      </IconButton>

      <MemoizedMonthCalendar currentDay={currentDay} />
      <MemoizedMonthCalendar currentDay={currentDay.clone().add(1, 'months')} />
      <MemoizedMonthCalendar currentDay={currentDay.clone().add(2, 'months')} />

      <IconButton
        size="small"
        onClick={() => setCurrentDay(currentDay.clone().add(1, 'month'))}
      >
        <KeyboardArrowRightIcon style={{ color: "#143296", fontSize: 40 }} />
      </IconButton>
    </div>
  );
}

function MonthCalendar({ currentDay, weekEvent, selectedWeeks }) {
  const [currentMoment, setCurrentMoment] = useState(currentDay);

  useEffect(() => {
    moment.updateLocale("pt", {
      months: [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ],
    });
  }, []);

  return (
    <div className="monthPage">
      <div className="headerPage" style={{ background: getTheme(localStorage.getItem('tema'), 0) }}>
        <IconButton
          size="small"
          onClick={() => setCurrentMoment(currentMoment.clone().subtract(1, 'month'))}
        >
          <KeyboardArrowLeftIcon style={{ color: "#143296", fontSize: 40 }} />
        </IconButton>
        {currentMoment.format('MMMM YYYY')}
        <IconButton
          size="small"
          onClick={() => setCurrentMoment(currentMoment.clone().add(1, 'month'))}
        >
          <KeyboardArrowRightIcon style={{ color: "#143296", fontSize: 40 }} />
        </IconButton>
      </div>

      <div className="contentMonth">
        <MonthCard
          weekEvent={weekEvent}
          selectedWeeks={selectedWeeks}
          currentMoment={currentMoment}
        />
      </div>
    </div>
  );
}

function MonthCard({ selectedWeeks, weekEvent, currentMoment }) {
  const [calendar, setCalendar] = useState([]);
  const weekDays = ["D", "S", "T", "Q", "Q", "S", "S"];

  useEffect(() => {
    setCalendar(calendarBuild(currentMoment));
  }, [currentMoment]);

  return (
    <div className="monthCard">
      <div className="weekDays">
        {weekDays.map((value, index) => (
          <div className="weekDay" key={index}>
            {value}
          </div>
        ))}
      </div>
      {calendar.map((week, index) => (
        <div
          className="week"
          key={index}
          style={{ backgroundColor: selectedWeeks.includes(week[0].format('YYYY-MM-DD')) ? '#f1c7f1' : '' }}
          onClick={() => weekEvent(week[0].format('YYYY-MM-DD'))}
        >
          {week.map((day, index) => (
            <div key={index} className="Days Days2">
              <div style={{ color: day.format('M') === currentMoment.format('M') ? getTheme(0, 0) : '#999' }}>
                {day.format("DD")}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function WeekCard({week}){

  function selectWeek(){

  }
  return(
<></>
  )
}
