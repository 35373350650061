import { useEffect, useState } from "react";
import 'moment/locale/pt-br'
import "./AtendForm.scss";
import moment from "moment";
import { Button } from "../../a_model/Input";
import { ContainerD } from "../../a_model/Container";
import { Escala } from "../c_layouts/Contents";
import Month from "../c_layouts/Month";
import { Dialog } from "../c_layouts/Dialog";
import { toDate } from "../../a_model/functions";
import { deletar, get, insert } from "../f_aux/fetch";

export default function SemanalForm(props) {
  const [params, setParams] = useState({ iddoctor: props.atend, data: false })
  const [project, setProject] = useState([])
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (props.atend) {
      setParams({ iddoctor: props.atend, data: false })
    }
  }, [props.atend])

  useEffect(() => {

    if (props.atend && params.data) {
      getSemana()
    }
  }, [refresh])

  function getSemana() {
    get(`/getSemanaDoctor/${props.atend}/${toDate(params.data)}`, then)
    function then(resp2) {
      //console.log(resp2)
      setProject(resp2.semana)
      setParams({...params, ['id']: resp2.id || false })
    }
  }

  function setData(data) {
    function then(resp2) {
      setProject(resp2.semana)
      setParams({ iddoctor: resp2.iddoctor, data: resp2.data, id: resp2.id ? resp2.id : false })
    }
    data ?

      get(`/getSemanaDoctor/${props.atend}/${data}`, then)
      : setProject({ ...project, ['data']: '' })

  }

  function insertSemana() {
    insert(`/insertSemanaDoctor`, { id: params.id || false, iddoctor: props.atend, data: params.data, semana: project }, then)
    function then() {
      setRefresh({ text: 'Atualizado', type: 0, att: Math.random() })
    }

  }

  function deleteSemana() {
    let resp = window.confirm("Confirma a exclusão?")
    if (resp) {
      deletar(`/deleteSemana/${params.id}`, then)
    }
    function then() {
      setRefresh({ text: 'Excluído', type: 0, att: Math.random() })
      setParams({ ...params, ['id']: false })
    }
  }

  return (
    <ContainerD>
      <Dialog
        value={refresh}
      />
      <Month
        currentMonth={parseInt(moment().format('M')) - 1}
        project={params}
        setData={setData}
      />
      <AtendCard
        insertSemana={insertSemana}
        deleteSemana={deleteSemana}
        setProject={setProject}
        project={project}
        params={params}
      />
    </ContainerD>
  )
}

function AtendCard(props) {
  return (
    props.params.data ?
      <div className="atend">
        <div>
          <Escala project={props.project} setProject={props.setProject} />
          <div className="headerAtend">
            <Button
              click={() => props.insertSemana()}
              value={props.params.id ? 'Salvar' : 'Cadastrar'}
              color='#174fb6'
            />
            {props.params.id &&
              <Button
                click={() => props.deleteSemana()}
                value='Excluir'
                color='#333'
              />
            }
          </div>
        </div>

      </div>
      :
      <div className="atend"> 
      <span>Selecione uma data...</span>
      </div>
  );
}
