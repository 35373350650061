import React, { useState, useEffect } from "react";
import { AtendForm } from '../b_forms/AtendForm'
import { NewAten } from "../c_layouts/NewAten";
import { Header } from "../../a_model/Header";
import { Select } from "../../a_model/Input";
import SemanalForm from "../b_forms/SemanalForm";
import { BlockAten } from "../b_forms/BlockAtend";
import Context from "../Context";
import { BiCalendar } from "react-icons/bi";
import { Page } from "../../a_model/Container";
import { get } from "../f_aux/fetch";
import { getTheme } from "../../a_model/Theme";
import { useContext } from "react";

function EditA() {

    const unidade = localStorage.getItem('unidade')
    const user = localStorage.getItem('login')
    const [atendentes, setAtendentes] = useState([])
    const [project, setProject] = useState([])
    const { setCurrentForm } = useContext(Context)
    useEffect(() => {
        get(`/atendentes/${unidade}`, setAtendentes)
    }, [])
    const [atend, setAtend] = useState('')

    function setAtendente(e) {
        get(`/atendente/${e.target.value}`, setProject)
        setAtend(e.target.value)
    }
    const [currentFicha, setCurrentFicha] = useState(0)

    const navList = [
        'Geral',
        'Semanal',
        'Cadastro',
        'Bloqueio',
    ]
    const steps = [
        <AtendForm
            unidade={unidade}
            atend={atend}
            user={user}
            project={project}
            setAtendentes={setAtendentes}
            setAtend={setAtend}
        />
        ,
        <SemanalForm
            unidade={unidade}
            atend={atend}
            user={user}
            project={project}
        />
        ,
        <>
            <NewAten
                unidade={unidade}
                user={user}
            />
        </>
        ,
        <BlockAten
            id_atendente={atend}
        />
    ]
    function setStep(e) {

        setCurrentFicha(e.target.value)

    }

    return (
        <Context.Provider value={{ unidade, user }}>
            <Page>
                <Header
                    setStep={setCurrentFicha}
                    navList={navList}
                    buttons={[
                        <button onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>
                    ]}
                >
                    <Select
                        classe='input2'
                        padrao='Atendentes'
                        handleOnChange={setAtendente}
                        options={atendentes}
                    />


                </Header>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                    {steps[currentFicha]}
                </div>
            </Page>
        </Context.Provider>

    )
}

export default EditA