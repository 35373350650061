import styled from 'styled-components'

export const Container = styled.div`
    padding: 10px;
    border-radius: 7px;
    background: #ecf1f866;
    display: flex;
    flex-direction: column;
    align-itens: center;
   
    >*{
        margin-bottom: 15px;
    }

`

export const List = styled.div`
    

`

export const ListItem = styled.li`



`