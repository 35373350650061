import { useEffect, useState } from "react";
import 'moment/locale/pt-br'
import "./AtendForm.scss";
import moment from "moment";
import { Button } from "../../a_model/Input";
import { ContainerD } from "../../a_model/Container";
import { Escala } from "../c_layouts/Contents";
import Month from "../c_layouts/Month";
import { Dialog } from "../c_layouts/Dialog";
import { deletar, get, insert } from "../f_aux/fetch";
import CalendarList from "../../a_model/CalendarList";

export default function SemanalForm(props) {
  const [params, setParams] = useState({ id_atendente: false, data: false })
  const [project, setProject] = useState([])
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (props.atend) {
      setParams({ id_atendente: props.atend, data: false })
    }
  }, [props.atend])

  useEffect(() => {

    if (props.atend) {
      getSemana()
    }
  }, [refresh])

  function getSemana() {
    get(`/getSemana/${props.atend}/${params.data ? params.data.substr(0, 10) : params.data}`, then)
    function then(resp2) {

      setProject(resp2.semana)
      setParams({ id_atendente: resp2.id_atendente, data: resp2.data, id: resp2.id ? resp2.id : false })
    }
  }

  function setData(data) {
    function then(resp2) {
      setProject(resp2.semana)
      setParams({ id_atendente: resp2.id_atendente, data: resp2.data, id: resp2.id ? resp2.id : false })
    }
    data ?

      get(`/getSemana/${props.atend}/${data}`, then)
      : setProject({ ...project, ['data']: '' })

  }



  function insertSemana() {
    insert(`/insertSemana`, { id: params.id ? params.id : false, id_atendente: params.id_atendente, data: params.data, semana: project }, then)
    function then() {
      setRefresh({ text: 'Atualizado', type: 0, att: Math.random() })
    }


  }

  function deleteSemana() {

    let resp = window.confirm("Confirma a restauração?")
    if (resp) {
      deletar(`/deleteSemana/${params.id}`, then)

    }

    function then() {
      setRefresh({ text: 'Restaurado', type: 0, att: Math.random() })
      setParams({ ...params, ['id']: false })
    }
  }




  return (
    <ContainerD>
      <Dialog
        value={refresh}
      />
      {/* <CalendarList
        includeWeek={console.log}
      />*/}
      <Month
        setData={setData}
        project={params}
      />
      <AtendCard
        insertSemana={insertSemana}
        deleteSemana={deleteSemana}
        setProject={setProject}
        project={project}
        params={params}
      />

    </ContainerD>


  )


}

function AtendCard(props) {



  return (

    props.params.data ?
      <div className="atend">
        <div className="headerAtend">
          <Button
            click={() => props.insertSemana()}
            value={props.params.id ? 'Salvar' : 'Cadastrar'}
            color='#174fb6'
          />
          {props.params.id &&
            <Button
              click={() => props.deleteSemana()}
              value='Restaurar padrão'
              color='#333'
            />
          }
        </div>
        <Escala project={props.project} setProject={props.setProject} />
      </div>
      :
      <div className="atend">
        <span>Selecione uma data...</span>
      </div>

  );
}
