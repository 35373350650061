import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import './Pages.css'
import moment from "moment";
import Context from "../../calendar/Context";
import { Page } from "../../a_model/Container";
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import { get } from "../../calendar/f_aux/fetch";
import { getTheme } from "../../a_model/Theme";
import CaixaVendaIecb from "../b_grids/CaixaVendaIecb";
import { InputPg } from "../b_grids/InputPg";

export default function CaixaIecb() {
    const { userp } = useParams()
    const user = userp || localStorage.getItem('login')
    const [vendas, setVendas] = useState({
        caixas: [],
        cursos: [],
        categorias: [],
    })
    const [data, setData] = useState(
        {
            data: moment().format('YYYY-MM-DD'),
            fim: moment().format('YYYY-MM-DD'),
            curso: 100,
            caixa: 100,
            categoria: 100
        })
    const [step, setStep] = useState(0)
    useEffect(() => {
        get(`/getSelectCaixaIecb/${data.data}/${data.fim}`, setVendas)

    }, [data])

    function handleChange(e) {

        if (e.target.name === 'data' && data.fim < e.target.value) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else if (e.target.name === 'fim' && e.target.value < data.data) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    const forms = [
        <CaixaVendaIecb />,
        <InputPg />
    ]
    const navList = ['Consulta', 'Registro']
    const cursoCateg = [
        <Select
            padrao='Cursos'
            width='120px'
            options={vendas.cursos}
            name='curso'
            value={data.curso}
            handleOnChange={handleChange}
        />,
        <Select
            padrao='Categorias'
            width='120px'
            options={vendas.categorias}
            name='categoria'
            value={data.categoria}
            handleOnChange={handleChange}
        />

    ]
    return (
        <Context.Provider value={{ user, data, step, setStep }}>
            <Page>

                <Header
                    step={step}
                    setStep={setStep}
                    navList={navList}

                >

                    <>
                        <InputDate
                            classe='input2'
                            name='data'
                            value={data.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='fim'
                            value={data.fim}
                            handleOnChange={handleChange}
                        />

                        {cursoCateg[1]}
                        <Select


                            padrao='Caixas'
                            options={vendas.caixas}
                            name='caixa'
                            value={data.caixa}
                            handleOnChange={handleChange}
                        />
                    </>
                </Header>

                {forms[step]}

            </Page>
        </Context.Provider>
    )
}