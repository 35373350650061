import  { Empty } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext } from 'react'
import Context from '../../Context';
import { MdAddCircleOutline } from 'react-icons/md'
 
export default function CardEmpty({ att, index, indexCards}){

    const ref = useRef()
  const { move } = useContext(Context)

  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id: 0, att  },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ isOver }, dropRef] = useDrop({
    accept: 'CARTEIRACARD', // Aceita itens do tipo CARTEIRACARD
    drop: (item, monitor) => {
       move(item, indexCards)
   //  window.alert('foi')
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  dragRef(dropRef(ref))

    return(
        <Empty ref={ref} isOver={isOver}> 
        <MdAddCircleOutline/>
        </Empty>
    )
}