import { useEffect, useState, useContext } from "react";
import 'moment/locale/pt-br'
import "./DayForm.css";
import Day from "../c_layouts/Day";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Context from "../Context";
import { get } from "../f_aux/fetch";
import InputDay from "../c_layouts/InputDay";
import { Modal } from "../../a_model/Modal";
import { TbCalendarEvent } from "react-icons/tb";
import { getTheme } from "../../a_model/Theme";
import { horariosProc } from "../../a_model/templates";

export default function DayAForm() {
  const { group, id_atendente, mudarDayForm, currentDay, refresh, setRefresh } = useContext(Context)
  const [agenda, setAgenda] = useState([])
  const [dataCard, setDataCard] = useState([{ atendente: '' }])
  useEffect(() => {
    if (id_atendente) {
      get(`/getAgendaA/${currentDay.format('YYYY-MM-DD')}/${id_atendente}`, setAgenda)
    }
  }, [currentDay, refresh])
  const [modalIsOpen, setIsOpen] = useState(false)

  function setInput(page, dataCard, hrs) {
    setDataCard([dataCard, hrs])
    page == 0 ? setIsOpen(false)
      : setIsOpen(true)
    setRefresh(Math.random())
  }


  return (
    <DndProvider backend={HTML5Backend}>

      <Modal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      >
        <InputDay
          setInput={setInput}
          dataCard={dataCard}
        />
      </Modal>

      <div className="content">

        <div id="inlineAtend">
        { group != 4 &&
          <div onClick={() => mudarDayForm()} id='iconHours'><TbCalendarEvent color={getTheme(localStorage.getItem('tema'), 0)} /></div>
}
          {agenda.map((dia, index) => (
            <div className='atendente' key={index} style={{ width: `calc( (100vw - 300px) / ${agenda.length} )` }} >
              <span style={{
                color: getTheme(localStorage.getItem('tema'), 0),
                borderLeft: '1px solid ' + getTheme(localStorage.getItem('tema'), 0)
              }}>{dia.dia}</span>
            </div>
          ))}

        </div>
        <div className="inlineHours">

          <div className="horas">
            {
              horariosProc.map((hora, index) => (
                <div className="hora" key={index}>
                  <span style={{ color: getTheme(localStorage.getItem('tema'), 0) }}>
                    {hora.hora}</span>
                </div>
              ))
            }
          </div>

          {agenda.map((item, index) => (
            <div key={index} className="atendColumn" style={{ width: `calc( (100vw - 300px) / ${agenda.length} )` }}>
              <DayCard
                setInput={setInput}
                horarios={horariosProc}
                cards={item}
                index={index}
                count={agenda.length}
              />
            </div>
          ))

          }

        </div>
      </div>

    </DndProvider>



  );
}


function DayCard(props) {

  return (


    props.cards.agenda.map((hora, index) => (
      <Day
        count={props.count}
        setInput={props.setInput}
        horario={props.horarios[index]}
        index={index}
        hora={hora.hora}
        card={hora}
        indexList={props.index}
      />
    ))


  );
}
