import React, { useState, useEffect, useContext } from "react";
import { Table, Th } from '../../a_model/Contents'
import { Card, Form } from '../../a_model/Container'
import { get } from "../f_aux/fetch";

export default function VendasForm({ cliente }) {

    const [vendas, setVendas] = useState({ vendas1: [], vendas0: [] })
    const [assinaturas, setAssinaturas] = useState([])
    const [sub, setSub] = useState({})
    const [modalIsOpen, setIsOpen] = useState(false)

    function getAss(vendaSub) {
        function then(resp2) {
            setAssinaturas(resp2)
            setSub(vendaSub)
            setIsOpen(true)
        }
        get(`/getAss/${vendaSub.id}`, then)
    }


    useEffect(() => {
        if (vendas.vendas1.length < 1 && cliente.id) {
            get(`/getVendas/${cliente.id}`, setVendas)
        }
    }, [cliente])
    const titles = [

        { width: '250px', title: 'Procedimento' },
        { width: '40px', title: 'Valor' },

    ]
    const titles2 = [

        { width: '250px', title: 'Procedimento' },
        { width: '40px', title: 'Valor' },
        { width: '', title: 'Data' },

    ]
    const status = [
        { nome: 'Pendente', cor: '#696969' },
        { nome: 'Ativo', cor: '#0f8f2f' },
        { nome: 'Encerrado', cor: '#b60000' },
        { nome: 'indicado', cor: '#143296' },
    ]

    const unidades = [
        0,
        'Matriz',
        'Bueno',
        'Goiânia Shopping',
        'Orion',
        'Campinas',
    ]

    return (
        vendas.vendas1.length > 0 ?
            <div style={{ overflow: 'auto', maxHeight: '100%' }}>
                <Form >
                    {vendas.vendas0.length > 0 &&
                        <Card header={<h2>Vendas sem orçamento: </h2>}>
                            <Table>
                                <Th array={titles2} />
                                {!Array.isArray(vendas) && vendas.vendas0.map((vendaSub, index) =>
                                    <tr
                                        key={index}>

                                        <td>
                                            {vendaSub.nome_procedimento}
                                        </td>
                                        <td>
                                            {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                        </td>
                                        <td>
                                            {vendaSub.data.substr(0, 10).split('-').reverse().join('/')}
                                        </td>
                                    </tr>
                                )}
                            </Table>
                        </Card>}




                    {vendas.vendas1.map((venda, index) =>
                        <Card index={index} header={
                            <>
                                <div>
                                    <label>Data:<span> {venda.data.substr(0, 10).split('-').reverse().join('/')}</span></label>
                                    <label>Status:<span style={{ backgroundColor: status[venda.status].cor, color: '#fff', fontWeight: '600', padding: '0 4px', borderRadius: '8px' }}> {status[venda.status].nome}</span></label>
                                </div>
                                <div>
                                    <label>Avaliadora:<span> {venda.avaliadora === venda.caixa ? '?' : venda.avaliadora}</span></label>
                                    <label>Caixa:<span> {venda.caixa}</span></label>
                                </div>
                                <div>
                                    <label>Unidade:<span> {unidades[venda.unidade]}</span></label>
                                    <label>Código:<span> {venda.id}</span></label>
                                </div>
                            </>
                        }>
                            <Table >
                                <Th array={titles} />
                                {venda.vendas_sub.map((vendaSub, index) =>
                                    <tr 
                                        key={index}>

                                        <td style={vendaSub.status == 0 ?{opacity: 0.8} : {color: '#146625'}}>
                                            {vendaSub.nome_procedimento}
                                        </td>
                                        <td>
                                            {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                        </td>

                                    </tr>
                                )}
                            </Table>
                        </Card>
                    )}
                     
                </Form>
            </div>
            : <li style={{ height: '25px' }}>Nenhum Orçamento</li>
    )

}

/*

*/