import React, { useContext, useEffect } from "react";
import './Pdf.css'
import { Card } from "../../a_model/Container";
import { Table, Th } from "../../a_model/Contents";
import Context from "../Context";
import { update } from "../f_aux/fetch";
import { HiOutlineTrash } from "react-icons/hi";
import copy from 'copy-to-clipboard'
export function Reagendar({ list }) {
    
    const { dialog, categ, setIsEdit, setIsOpen, setRefresh, user } = useContext(Context)

    function transferir(item) {
        setIsOpen(false)
        setIsEdit(item)
    }

    function excluir(item) {
        function then() {
            setRefresh(Math.random())
        }
        update(`/disableEvent/${item.idevent}/${user}`, {}, then)
    }

    const table = [
        { width: '', title: ' Data/Hora' },
        { width: '', title: 'Cliente' },
        { width: '', title: 'Telefone' },
        { width: '', title: 'Avaliadora' },
        { width: '', title: 'Usuário' },
        { width: '', title: 'Status' },
        { width: '', title: 'OK' },
    ]
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
    }
    function copiar(telefone) {
        copy(telefone)
        dialog('Copiado', 0)

    }
    function cancelar(project) {
        project.start_date = project.start_date.substr(0, 10)
        project.categ = 11
        project.user = user
        function then(resp) {
            if (resp.exists) {
                window.alert(`Erro, Avise o suporte.`)
            } else {
                setRefresh(Math.random())
            }
        }
        update(`/updateEvent`, project, then)
    }
    return (
        <Card header={
            <h2>Reagendar / Cancelado </h2>
        }>
            <div onClick={() => setIsOpen(false)} style={{ cursor: 'pointer', fontSize: '40px', position: 'absolute', top: '0', right: '20px', color: '#c02d2d', }}>
                x
            </div>
            <Table>
                <Th array={table} />
                {list.map((item, index) =>
                    <tr key={index}>
                        <td>
                            {item.start_date.substr(0, 10).split('-').reverse().join('/')} - {item.start_time.substr(0, 5)}
                        </td>
                        <td style={{ maxWidth: '270px' }}>
                            {item.title}
                        </td>
                        <td onClick={() => copiar(item.telefone)} style={{ maxWidth: '170px' }}>
                            {formatTel(item.telefone)}
                        </td>
                        <td style={{ maxWidth: '170px' }}>
                            {item.namedoctor}
                        </td>
                        <td style={{ fontWeight: user == item.usuario && 600, maxWidth: '170px' }}>
                            {item.usuario}
                        </td>
                        <td style={{ maxWidth: '130px', minWidth: '130px', color: '#FFF', fontWeight: 500, background: categ.find((element) => element.id == item.categ).color }}>
                            {item.categ == 7 || item.categ == 4 ?
                                <button style={{ fontSize: '16px' }} onClick={() => transferir({...item, user_update: user})}>
                                    Reagendar
                                </button>
                                :
                                <button style={{ fontSize: '16px' }} onClick={() => transferir({...item, user_update: user})}>
                                    Cancelado
                                </button>
                            }
                        </td>
                        <td >
                            {item.categ == 7 || item.categ == 4 ?
                                <button style={{ fontSize: '16px' }} onClick={() => cancelar(item)}>
                                    <HiOutlineTrash />
                                </button>
                                :
                                <button style={{ fontSize: '16px' }} onClick={() => excluir(item)}>
                                    OK
                                </button>
                            }
                        </td>
                    </tr>
                )}
            </Table>
        </Card>

    )

}