import styled from 'styled-components'

export const Input = styled.div`
display: inline-flex;
label{
    margin: 0 5px;
}
input, button{
       
    font-size: 1.1em;
    padding: 5px;
    border: none;
    border-radius: 3px;
    display: flex;
    align-itens: center;
    
    }
    button:hover, input::-webkit-calendar-picker-indicator:hover{
        cursor: pointer;
        opacity: 0.5;
        transition: all ease 0.2s;
        }
      
`

export const InfoContainer = styled.div`
margin: 4px 0;
label{
    
}
span{
    font-weight: 500;
    color: #555;
    margin: 0 4px;
    font-size: 1.1em;
}
button{
    margin-left: 4px;
    color: #fff;
   padding: 3px 5px;
    cursor: pointer;
    diplay: flex;
    align-items: flex-end;
  border: none;
  border-radius: 3px;
}

`

export const TextArea = styled.div`
display: flex;
align-items: flex-end;
textarea{
    box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
    width: 75%;
    padding: 10px;
    border: none;
    border-radius: 3px;
    display: flex;
    align-itens: center;
}
button{
    display: inline-flex;
    background:#1e5dd4;
    padding: 5px;
    border: none;
    border-radius: 2px;
    margin-left: 5px;
    color: #fff;
    font-weight: 500;
}

svg{
    margin: 0 0 -8px 5px;
    fill: #fff;
}

button:hover{
    cursor: pointer;
    opacity: 0.7;
    transition: all ease 0.2s;
}

`