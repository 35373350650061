import styled from 'styled-components'

export const Container = styled.div`
   
    padding: 0 5px;
    height: 100%;

    width: 9%;
    & + div{
        border-left: 1px solid #22222211;
    }

    header{
        display: flex;
        justify-content: space-between;
        align-itens:center;
        height: 22px;
        h2{
            font-weight: 400;
            font-size: 1em;
            padding: 0 10px;
        }
    }

    ul{
        padding-top: 3px;
        margin-top:30px;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        
    }

    ul::-webkit-scrollbar {
        width: 0;
          
                     /* width of the entire scrollbar */
      }
      
      ul::-webkit-scrollbar-track {
        
        background: transparent;        /* color of the tracking area */
      }
      
      ul::-webkit-scrollbar-thumb {
        
        background-color: #69696999;    /* color of the scroll thumb */
        border-radius: 30px;       /* roundness of the scroll thumb */
              }

    

`