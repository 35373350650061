import React, { useEffect, useContext, useState } from "react";
import { getColor } from '../../calendar/c_layouts/salaColor'
import { Context } from "../e_contexts/AuthContext";
import { get } from "../../calendar/f_aux/fetch";
import { toDate } from "../../a_model/functions";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

export default function Atendimentos() {
    const { params, setParams, toForm, login, step, statusList } = useContext(Context)
    const [cards, setCards] = useState(false)
    useEffect(() => {
        step == 4 &&
            get(`/getAtendimentos/${login}/${params.page}/${params.text || 1}`, setCards)

    }, [params, step])


    return (
        Array.isArray(cards) &&
        <div id="gridMobile">
            {
                cards.map((card, index) =>
                    <div key={index} className="agendaMobileCard" onClick={() => toForm(card, 1)}>
                        <p>
                            {card.nome_cliente}
                        </p>
                        <p style={{ color: '#14679e' }}> {card.nome_procedimento}</p>

                    </div>
                )}
            <footer>
               <ToPage length={cards.length}/>
            </footer>

        </div>
    )
}

function ToPage({length}) {
    const { params, setParams} = useContext(Context)
    return (
        <div id='pages'>
            <button 
            disabled={params.page === 0 ? true : false}
            onClick={()=> setParams({...params, ['page']: params.page-1})}
            >
<IoMdArrowDropleft/>
            </button>
 Página {params.page+1}
            <button 
            disabled={length < 50 ? true : false}
            onClick={()=> setParams({...params, ['page']: params.page+1})}
            >
            <IoMdArrowDropright/>
            </button >
        </div>
    )
}

