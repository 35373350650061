import { useEffect, useState } from 'react';
import { GiConfirmed } from 'react-icons/gi';
import './Dialog.css'
export function Dialog({ value }) {
  const types = [<GiConfirmed />];
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (value) {
      setOpacity(1);
      setTimeout(() => {
        setOpacity(0);
      }, 2000);
    }
  }, [value]);

  return (
    <div
      onClick={() => setOpacity(0)}
      style={{
        position: 'absolute',
        zIndex: opacity*7,
        backgroundColor: '#eceaea',
        right: '15vw',
        top: '2vh',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0',
        borderRadius: '5px',
        border: '1px solid #a1a1a1',
        padding: '10px',
        transition: 'opacity 0.5s',
        opacity: opacity,
      }}
      className="dialog"
    >
      {value.text}
      {types[value.type]}
    </div>
  );
}