import styled from 'styled-components'

export const Container = styled.div`
    padding: 10px;
    border-radius: 7px;
    background: #ecf1f866;
    display: flex;
    flex-direction: column;
    align-itens: center;
   
    >*{
        margin-bottom: 15px;
    }

`

export const List = styled.div`
    padding: 10px;
    border-radius: 7px;
    background: #ecf1f866;
    display: flex;
    flex-direction: column;
    align-itens: center;
    max-height: 300px;
    max-width: 300px;
overflow: auto;
    

    >*{
        margin-bottom: 15px;
    }

`

export const ListItem = styled.li`
padding: 5px;
label{
    color: #888;
}

p{
    padding:3px;
    background:#fff;
}


`