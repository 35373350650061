import React, { useState, useEffect, useContext } from "react";
import { InputDate, Button, LabelText } from "../../a_model/Input";
import moment from "moment";
import { createPDF } from "../f_aux/createPDF";
import Context from "../Context";
import './Clientes.css'
import { Assinaturas, NewSub } from "../c_layouts/ClientesLayout";
import { Modal } from '../../a_model/Modal'
import CadastroCliForm from "./CadastroCliForm";
import { Table, Th } from '../../a_model/Contents'
import { Card, Form } from '../../a_model/Container'
import { HiOutlineTrash } from "react-icons/hi";
import { deleteA } from "../f_aux/functions";
import { get } from "../f_aux/fetch";

export function ClientesForm({ confirm, setConfirm }) {

    const [vendas, setVendas] = useState([])
    const [historico, setHistorico] = useState([])
  
    const { unidade, user, cliente, setCliente, step } = useContext(Context)
    const [assinaturas, setAssinaturas] = useState([])
    const [sub, setSub] = useState({})
    const today = moment()
    const [modalIsOpen, setIsOpen] = useState(false)

    function getAss(vendaSub) {
        function then(resp2) {
            setAssinaturas(resp2)
            setSub(vendaSub)
            setIsOpen(true)
        }
        get(`/getAss/${vendaSub.id}`, then)
    }

    function Vendas() {

        useEffect(() => {
            if (vendas.length < 1) {
                get(`/getVendas/${cliente.id}`, setVendas)
            }
        }, [])
        const titles = [

            { width: '250px', title: 'Procedimento' },
            { width: '40px', title: 'Valor' },
            { width: '40px', title: 'disp' },
            { width: '40px', title: 'total' },

            { width: '', title: 'Código' },
        ]
        const titles2 = [

            { width: '250px', title: 'Procedimento' },
            { width: '40px', title: 'Valor' },
            { width: '40px', title: 'disp' },
            { width: '40px', title: 'total' },

            { width: '', title: 'Data' },
            { width: '', title: 'Código' },
        ]
        const status = [
            { nome: 'Pendente', cor: '#696969' },
            { nome: 'Ativo', cor: '#0f8f2f' },
            { nome: 'Encerrado', cor: '#b60000' }
        ]

        return (

            <Form >
                {vendas.vendas0 && vendas.vendas0.length > 0 &&
                    <Card header={<h2>Vendas sem orçamento: </h2>}>
                        <Table>
                            <Th array={titles2} />
                            {!Array.isArray(vendas) && vendas.vendas0.map((vendaSub, index) =>
                                <tr
                                    key={index}>

                                    <td>
                                        {vendaSub.nome_procedimento}
                                    </td>
                                    <td>
                                        {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                    </td>
                                    <td
                                        className='assButton'
                                        style={{ backgroundColor: status[vendaSub.status].cor }}
                                        onClick={() => getAss(vendaSub)}
                                    >
                                        {vendaSub.status == 0 ? '-' :
                                            vendaSub.qnt_sessao - vendaSub.realizadas}
                                    </td>
                                    <td>
                                        {vendaSub.qnt_sessao}

                                    </td>

                                    <td>
                                        {vendaSub.data.substr(0, 10).split('-').reverse().join('/')}
                                    </td>
                                    <td>
                                        {vendaSub.id}
                                    </td>
                                </tr>
                            )}
                        </Table>
                    </Card>}



                {!Array.isArray(vendas) ?
                    vendas.vendas1.map((venda, index) =>
                        <Card header={
                            <>
                                <div>
                                    <label>Data:<span> {venda.data.substr(0, 10).split('-').reverse().join('/')}</span></label>
                                    <label>Status:<span style={{ backgroundColor: status[venda.status].cor, color: '#fff', fontWeight: '600', padding: '0 4px', borderRadius: '8px' }}> {status[venda.status].nome}</span></label>
                                </div>
                                <div>
                                    <label>Avaliadora:<span> {venda.avaliadora === venda.caixa ? '?' : venda.avaliadora}</span></label>
                                    <label>Caixa:<span> {venda.caixa}</span></label>
                                </div>
                                <div>
                                    <label>Unidade:<span> {unidades.find((element)=> element.id == venda.unidade).nome}</span></label>
                                    <label>Código:<span> {venda.id}</span></label>
                                </div>
                            </>
                        }>
                            <Table >
                                <Th array={titles} />
                                {venda.vendas_sub.map((vendaSub, index) =>
                                    <tr
                                        key={index}>

                                        <td>
                                            {vendaSub.nome_procedimento}
                                        </td>
                                        <td>
                                            {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                        </td>

                                        <td
                                            className='assButton'
                                            style={{ backgroundColor: status[vendaSub.status].cor }}
                                            onClick={() => getAss(vendaSub)}
                                        >
                                            {vendaSub.status == 0 ? '-' :
                                                vendaSub.qnt_sessao - vendaSub.realizadas}
                                        </td>
                                        <td>
                                            {vendaSub.qnt_sessao}

                                        </td>

                                        <td>
                                            {vendaSub.id}
                                        </td>
                                    </tr>
                                )}
                            </Table>
                        </Card>
                    )
                    : ''

                }

            </Form>


        )
    }

    function Historico() {
        //console.log('ok')
        const [project, setProject] = useState({
            data: moment().add(1, 'day').format('YYYY-MM-DD'),
            inicio: moment('2023 07 15').format('YYYY-MM-DD'),
            fim: moment().add(1, 'year').format('YYYY-MM-DD')
        })

        function handleChange(e) {
            setProject({ ...project, [e.target.name]: e.target.value })
            //   //console.log(project.hora_fim)
        }
        function deletar(item) {
            function then() {
                setProject({ ...project, ['att']: Math.random() })
                window.alert('Registro Desativado')
            }
            deleteA(item, then, user)
        }
        function gerarPdf2(download) {
            function then(resp2) {
                setHistorico(resp2)
                createPDF(resp2, false, download)
            }
            get(`/buscarPDFcli/${cliente.id}/${project.inicio}/${project.fim}`, then)
        }
        const [agendamentos, setAgendamentos] = useState([]); // Novo estado para armazenar os agendamentos

        useEffect(() => {
            fetch(`${process.env.REACT_APP_CALENDAR}/buscarPDFcli/${cliente.id}/${project.inicio}/${project.fim}`, {
                method: "GET",
                heders: {
                    'Content-type': 'application/json',
                },
            })
            .then((resp) => resp.json())
            .then((resp2) => {
                if (resp2.length === 0) {
                    setAgendamentos([0]); // Define os agendamentos como vazio ou 0 se não houver dados
                } else {
                    setAgendamentos(resp2); // Define os agendamentos obtidos da API
                }
            })
            .catch(err => console.log(err))
        }, [cliente.id, project.inicio, project.fim]); // Dependências do useEffect
    
        // Atualiza o estado historico somente depois que os dados forem obtidos
        useEffect(() => {
            setHistorico(agendamentos);
        }, [agendamentos]);

        const titles = [

            { width: '90px', title: 'Data' },
            { width: '250px', title: 'Procedimento' },

            { width: '50px', title: 'Hora' },
            { width: '100px', title: 'Status' },
            { width: '60px', title: 'id' },
            { width: '', title: <HiOutlineTrash /> },
        ]

        return (
            historico.length > 0 ?
                <Form overflow='hidden'>

                    <header>
                        <div>
                            <h2>Histórico de agendamentos</h2>

                            <div className="dataCli">
                                <InputDate

                                    name='inicio'
                                    title='De'
                                    value={project.inicio}
                                    handleOnChange={handleChange}
                                />
                                <InputDate

                                    name='fim'
                                    title='Até'
                                    value={project.fim}
                                    handleOnChange={handleChange}
                                />
                            </div>

                        </div>
                    </header>
                    <body>
                        <Table height={'95%'}>
                            <Th array={titles} />


                            {historico.map((agendamento, index) =>
                                <tr
                                    key={index}>

                                    <td>
                                        {agendamento.data ? agendamento.data.substr(0, 10).split('-').reverse().join('/') : ''}
                                    </td>
                                    <td>
                                        {agendamento.nome_procedimento}

                                    </td>
                                    <td>
                                        {agendamento.hora}
                                    </td>
                                    <td >
                                        {agendamento.status == 0 ? 'Agendado' :
                                            parseInt(agendamento.assinado) > 0 ? parseInt(agendamento.assinado) > 1 ? 'Assinado' : 'Ass. Pendente' : 'Confirmado'}
                                    </td>

                                    <td>
                                        {agendamento.id}
                                    </td>

                                    {today.format("YYYY-MM-DD") <= agendamento.data ?

                                        <td onClick={() => deletar(agendamento)}><HiOutlineTrash /></td> : <td>-</td>}

                                </tr>
                            )}
                        </Table>
                    </body>
                    <footer>
                        <Button
                            color='#9e1b1b'
                            click={() => gerarPdf2(true)}
                            value='Gerar PDF'
                        />
                        <Button
                            color='#174fb6'
                            click={() => gerarPdf2(false)}
                            value='Imprimir'
                        />
                    </footer>

                </Form>
                : <div>Nenhum agendamento encontrado..</div>
        )
    }

    const fichas = [
        <CadastroCliForm
            confirm={confirm}
            setConfirm={setConfirm}
            cliente={cliente}
            setCliente={setCliente}
            unidade={unidade}
            user={user}
        />,
        <Vendas />,
        <Historico />,
    ]

    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' },
        { id: 4, nome: 'Orion' },
        { id: 5, nome: 'Campinas' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 13, nome: 'Brasília' },
    ]

    return (
        <>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}>
                <Assinaturas
                    sub={sub}
                >
                    {assinaturas.map((assinatura, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{assinatura.data && assinatura.data.substr(0, 10).split('-').reverse().join('/')}</td>
                            <td style={{ backgroundColor: assinatura.status.color, color: '#f1f1f1' }}>{assinatura.status.status}</td>
                            <td>{assinatura.atendente}</td>
                            <td>{unidades.find((element)=> element.id == assinatura.unidade).nome}</td>
                            <td>{assinatura.evolucao}</td>
                            <td>{assinatura.data_evolucao && assinatura.data_evolucao.substr(0, 10).split('-').reverse().join('/')}</td>
                        </tr>
                    )}
                </Assinaturas>
            </Modal>

            {cliente.nome ?

                fichas[step]

                : "Informe o cliente..."

            }



        </>
    )

}

/*

*/