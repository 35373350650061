import React, { useEffect } from "react";
import './Pdf.css'
import { Card } from "../../a_model/Container";
import { Table, Th } from "../../a_model/Contents";

export function Reagendar({ list }) {

    useEffect(() => {


    }, [])
    const status = ['', '', '', '', '', "Regendar", 'Cancelado']
    const table = [
        { width: '', title: ' Data/Hora' },
        { width: '', title: 'Cliente' },
        { width: '', title: 'Procedimento' },
        { width: '', title: 'Status' },
    ]
    return (
        <Card header={
            <h2>Reagendar / Cancelado </h2>
        }>
            <Table>
                <Th array={table}/>
                {list.map((item, index) =>
                    <tr key={index}>
                        <td>
                            {item.data.substr(0, 10).split('-').reverse().join('/')} - {item.hora}
                        </td>
                        <td>
                            {item.nome_cliente}
                        </td>

                        <td>
                            {item.nome_procedimento}
                        </td>

                        <td>
                            {status[item.status]}
                        </td>
                    </tr>
                )}

            </Table>


        </Card>

    )

}