import React, { useState, useEffect, useContext, useMemo } from "react";
import { Form, Card2, ContainerC, StepsForm } from "../../a_model/Container";
import { ComentariosForm, SearchList, Table, Table2, Th } from "../../a_model/Contents";
import { Modal } from '../../a_model/Modal'
import { get, insert, update } from "../../calendar/f_aux/fetch";
import { Button, InputSearch, InputText, TextArea2, LabelText3, LabelText1 } from "../../a_model/Input";
import Context from "../../calendar/Context";
import copy from "copy-to-clipboard";
import gif from '../../img/carregando1.gif';
import { MdSend } from "react-icons/md";
import { toDate } from '../../a_model/functions'
import './Caixa.css'

export default function Acompanhar() {
    const { user, data } = useContext(Context)
    const [isEdit, setIsEdit] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [statusStep, setStatusStep] = useState(0)
    const [currentCard, setCurrentCard] = useState({ card: [], valor: 0, clientes: 0 })
    const [loading, setLoading] = useState(gif);

    useEffect(() => {
        mudarStatus(0)

    }, [data]);

    function handleOpenModal(project) {
        setIsEdit(project)
        setIsOpen(true)

    }

    function mudarStatus(x) {
        setStatusStep(x)
        setLoading(gif)
        get(`/buscarRestantesAc/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${x}/${data.ver}`, thenE)
        function thenE(resp) {

            setCurrentCard(resp)
            setLoading(false)
        }

    }


    function Restantes() {

        const [search, setSearch] = useState('')
        const results = useMemo(() => {
            const lowerBusca = search.toLowerCase()
            return Array.isArray(currentCard) && currentCard.filter(user => user.nome_cliente ? (user.nome_cliente.toLowerCase().includes(lowerBusca) || user.nome_procedimento.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca)) : '');
        }, [search, currentCard])
        const titles = [
            { width: '320px', title: 'Cliente' },
            { width: '320px', title: 'Procedimento' },
            { width: '50px', title: 'Data' },
            { width: '60px', title: 'Restantes' },
            { width: '60px', title: 'Status' },
        ]

        const stepTitles = [
            "Todos",
            "Iniciados",
            "À iniciar",
            'Última Sessão',
            'Encerrados'
        ]
        return (

            <Form>
                <SearchList
                    stepTitles={stepTitles}
                    search={search}
                    setSearch={setSearch}
                    setStep={mudarStatus}
                    step={statusStep}
                >

                    <Table
                        height='70vh'
                    >
                        <Th array={titles} />
                        {Array.isArray(results) && results.map((dataCard, index) =>
                           parseInt(dataCard.restantes)-parseInt(dataCard.baixa) > 0 &&
                           <tr key={index} className='hover' onClick={() => handleOpenModal(dataCard)}>
                                <td>{dataCard.nome_cliente}</td>

                                <td style={{ maxWidth: '200px' }}>{dataCard.nome_procedimento}</td>
                                <td>{dataCard.ultimo ? dataCard.ultimo.substr(0, 10).split('-').reverse().join('/') : dataCard.data_venda.substr(0, 10).split('-').reverse().join('/')}</td>
                                <td >{dataCard.restantes-dataCard.baixa}</td>

                                <td style={{ color: dataCard.ver == 0 ? '#208a49' : '#aaa' }}>{dataCard.ver == 0 ? 'Resolvido' : 'Aberto'}</td>
                            </tr>
                        )}
                    </Table>
                </SearchList>
            </Form>
        )
    }



    return (
        loading ?
            <div className="carregandoRestantes">
                <img src={loading} alt="Carregando..." />
            </div> :
            <>
                <Restantes />
                <Modal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                >
                    <ResModal
                        setIsOpen={setIsOpen}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                    />
                </Modal>
            </>

    )
}

function ResModal({ setIsOpen, isEdit }) {

    const [dataCard, setDataCard] = useState([])
    const [step, setStep] = useState(0)
    useEffect(() => {
        get(`/getHistory/${isEdit.id_venda_sub}`, setDataCard)
    }, []);
    function resolver(isEdit) {

        update(`/resolverRestantes`, isEdit, setIsOpen)
    }

    function formatTel(telefone) {
        if (telefone) {
            let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))

            return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
        } else {
            return 0
        }
    }

    const steps = [
        {
            content:
                <Vendas
                    dataCard={dataCard}
                    isEdit={isEdit}
                />,
            nome: 'Lançamentos',
            color: '#ddd'
        },
        {
            content:
                <Comentarios
                    id_cliente={isEdit.id_cliente}
                />,
            nome: 'Comentários',
            color: '#ddd'
        },
    ]

    return (
        <div id='resModal' >

            <div id='headerModal'>


                <LabelText3
                    header="Cliente:"
                    svg='1'
                    onClick={() => copy(isEdit.nome_cliente)}

                >
                    {isEdit.nome_cliente}
                </LabelText3>

                <LabelText3
                    header="Telefone:"
                    svg='1'
                    onClick={() => copy(isEdit.telefone)}

                >
                    {formatTel(isEdit.telefone)}
                </LabelText3>
                <LabelText3
                    header="Código:"
                    svg='1'
                    onClick={() => copy(isEdit.id_cliente)}

                >
                    {isEdit.id_cliente}
                </LabelText3>


                <Button
                    value='Resolver'
                    color='#3db150'
                    click={() => resolver({ ...isEdit, ['ver']: 0 })}
                />



            </div>
            <StepsForm
                height='calc(90vh - 130px)'
                steps={steps}
                step={step}
                setStep={setStep}
            />
        </div>
    )
}

function Vendas({ dataCard, isEdit }) {

    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: '1', nome: 'Matriz' },
        { id: '3', nome: 'Goiânia' },
        { id: '4', nome: 'Orion' },
        { id: '5', nome: 'Campinas' },
        { id: '10', nome: 'Altamira' },
        { id: '11', nome: 'Paulinia' },

    ]

    const [stepForm, setStepForm] = useState(0)
    const [assinaturas, setAssinaturas] = useState([])
    const titles = [
        { width: '', title: 'Sessão' },
        { width: '', title: 'Data' },
        { width: '', title: 'Status' },
        { width: '', title: 'Atendente' },
        { width: '', title: 'Código' },
    ]
    const titles2 = [
        { width: '', title: '*' },
        { width: '', title: 'Data' },
        { width: '', title: 'Status' },
        { width: '', title: 'Atendente' },
        { width: '', title: 'Unidade' },
        { width: '', title: 'Evolução' },
    ]
    const status2 = [
        { nome: 'Pendente', cor: '#696969' },
        { nome: 'Ativo', cor: '#0f8f2f' },
        { nome: 'Encerrado', cor: '#b60000' },
        { nome: 'Indicação', cor: '#1A3BDB' }
    ]

    function getAss(id) {
        function then(resp2) {
            setAssinaturas(resp2)
            setStepForm(1)
        }
        get(`/getAss/${id}`, then)
    }
    const status = [
        { id: '0', nome: 'Agendado', cor: '#818181' },
        { id: 1, nome: 'Confirmado', cor: '#3948d3' },
        { id: 2, nome: 'Confirmado', cor: '#3948d3' },
        { id: 3, nome: 'Assinado', cor: '#d339d3' },
        { id: 5, nome: 'Reagendar', cor: '#c2bf34' },
        { id: 6, nome: 'Cancelado', cor: '#21a32c' },
        { id: 7, nome: 'Anulado', cor: '#a32121' },
        { id: 8, nome: 'Finalizado', cor: '#07871E' },
        { id: 4, nome: 'Baixa', cor: '#555' },
    ]
    const status3 = [
        { id: '0', nome: 'Indefinido', cor: '#818181' },
        { id: 1, nome: 'Assinado', cor: '#3948d3' },
        { id: 2, nome: 'Assinado', cor: '#3948d3' },
        { id: 3, nome: 'Assinado', cor: '#d339d3' },
        { id: 5, nome: 'Anulado', cor: '#c2bf34' },
        { id: 4, nome: 'Baixa', cor: '#555' },
    ]
    const forms = [
        <Card2
        height='100%'
            header={
                <>
                    <div>
                        <LabelText1 header='Procedimento'>
                            {isEdit.nome_procedimento}
                        </LabelText1>
                        <LabelText1 header='Venda:'>
                            {toDate(isEdit.data_venda)}
                        </LabelText1>
                    </div>
                    <div>
                        <LabelText1 header='Código'>
                            {isEdit.id_venda_sub}
                        </LabelText1>
                        <LabelText1 header='Avaliadora'>
                            {isEdit.avaliadora}
                        </LabelText1>
                    </div>
                </>
            }>
            <Table2
                height='calc(90vh - 300px)'
            >
                <Th array={titles} />
                {dataCard.map((item, index) =>

                    <tr key={index}>
                        <td>
                            {index + 1}

                        </td>
                        <td>
                            {toDate(item.data)}

                        </td>

                        <td style={{ color: 
                            item.cliente?
                            status3.find((element) => element.id == item.status).cor 
                            : status.find((element) => element.id == item.status).cor}}>
                            {item.cliente?
                            status3.find((element) => element.id == item.status).nome
                            : status.find((element) => element.id == item.status).nome}

                        </td>
                        <td>
                            {item.atendente}
                        </td>
                        <td>
                            {item.id}
                        </td>
                       
                    </tr>
                )}
            </Table2>
        </Card2>
        ,
        <ContainerC >


            <Table2 >
                <Th array={titles2} />
                {assinaturas.map((assinatura, index) =>
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{assinatura.data && assinatura.data.substr(0, 10).split('-').reverse().join('/')}</td>
                        <td style={{ backgroundColor: assinatura.status.color, color: '#f1f1f1' }}>{assinatura.status.status}</td>
                        <td>{assinatura.atendente}</td>
                        <td>{unidades.find((element) => element.id == assinatura.unidade).nome}</td>
                        <td>{assinatura.evolucao}</td>
                    </tr>
                )}
            </Table2>
            <Button
                value='Voltar'
                color='#b13d3d'
                click={() => setStepForm(0)}
            />
        </ContainerC>
    ]

    return (

        forms[stepForm]


    )
}

function Comentarios({ id_cliente }) {
    const { user } = useContext(Context)
    const [comentarios, setComentarios] = useState([])
    const [project, setProject] = useState({ id_leads: id_cliente, tabela: 'tratamento', user, nota: '', telefone: 0 })
    useEffect(() => {
        getComentarios()
    }, [])

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function getComentarios() {
        get(`/buscarComentarios/${id_cliente}/tratamento`, setComentarios)
    }

    function comentar() {

        if (project.nota.length > 0) {
            insert(`/insertTentativa`, project, getComentarios)
            setProject({ ...project, ['nota']: '' })
        } else {
            window.alert('Comentário vazio')
        }
    }

    return (
        <ComentariosForm height='calc( 80vh - 120px )'>
            <ul>

                {comentarios.length > 0 ?
                    comentarios.map((comentario, index) =>

                        <li key={index}>
                            <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)}:</label>
                            <p>{comentario.nota}</p>
                        </li>
                    ) :

                    <li style={{ height: '25px' }}>Nenhum Comentário</li>
                }
            </ul>
            <footer>
                <textarea
                    placeholder="Adicionar comentário..."
                    name='nota'
                    value={project.nota}
                    onChange={(e) => handleChange(e)}
                />
                <button
                    onClick={() => comentar()}
                >
                    Enviar <MdSend />
                </button>
            </footer>
        </ComentariosForm>
    )
}


