import React, { useState, useEffect, useContext } from 'react'
import { Header } from "../../a_model/Header";
import Body from './Body'
import { useParams } from "react-router";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { produce } from 'immer'
import Context from '../Context';
import moment from 'moment'
import { Modal } from '../../a_model/Modal';
import LeadsForm from './contents/LeadsForm';
import { Dialog } from './Dialog';
import { IoIosSearch, IoIosMenu } from "react-icons/io";
import { InputDate, InputSearch, Select } from "../../a_model/Input";
import { getTheme } from '../../a_model/Theme';
import { get, update } from '../../calendar/f_aux/fetch'
import './Leads.scss'
import { GiCheckMark } from 'react-icons/gi'
import { Custom } from './contents/Custom';
import Agendados from './Agendados';


export default function Leads() {

  const { userp } = useParams();
  const { empresap } = useParams();
  const user = userp || localStorage.getItem('login')
  const empresa = empresap || localStorage.getItem('empresa')
  const [tema, setTema] = useState(localStorage.getItem('tema'))
  const [users, setUsers] = useState([])
  const [leads, setLeads] = useState([
    { nome: 'Msg enviada', cards: [], status: 8, display: true, disabled: true },
    { nome: 'Outro momento', cards: [], status: 4, display: true, disabled: true },
    { nome: 'Indeciso', cards: [], status: 3, display: true, disabled: true },
    { nome: 'Retornar Ligação', cards: [], status: 10, display: true, disabled: false },
    { nome: 'Sem interesse', cards: [], status: 5, display: true, disabled: false },
    { nome: 'Sem Retorno', cards: [], status: 'nao', display: true, disabled: false },
    { nome: 'Agendado', cards: [], status: 2, display: true, disabled: false },
    { nome: 'Lixeira', cards: [], status: 9, display: true, disabled: false },
    { nome: 'Outros', cards: [], status: 11, display: true, disabled: false },

  ]);
  const [agendados, setAgendados] = useState([
    { nome: 'Não veio', cards: [], count: 0, status_naoveio: '1', display: true, disabled: true },
    { nome: 'Cancelado', cards: [], count: 0, status_naoveio: '20', display: true, disabled: true },
    { nome: 'Sem Retorno', cards: [], count: 0, status_naoveio: '2', display: true, disabled: false },
    { nome: 'Sem Interesse', cards: [], count: 0, status_naoveio: '3', display: true, disabled: false },
    { nome: 'Outro Momento', cards: [], count: 0, status_naoveio: '4', display: true, disabled: false },
    { nome: 'Agendado', cards: [], count: 0, status_naoveio: '5', display: true, disabled: false },
    { nome: 'Lixeira', cards: [], count: 0, status_naoveio: '6', display: true, disabled: false },
  ]);

  useEffect(() => {

    get(`/getUsers/${empresa}`, setUsers)
    if (user != localStorage.getItem('user')) { localStorage.setItem('user', JSON.stringify(user)) }

    get(`/buscarTema/${user}`, then)

    function then(resp2) {
      setTema(resp2.tema)
      localStorage.setItem('tema', resp2.tema)
    }

  }, [])

  function ok() { }
  useEffect(() => get(`/attCanceladosEvent`, ok), [])

  const [data, setData] = useState({
    data: moment().subtract(1, 'month').format('yyyy-MM-DD'),
    fim: moment().format('yyyy-MM-DD'),
    empresa,
    user,
    ativo: 100
  })
  const [form, setForm] = useState({ id: 0, telefone: 0, form: false });
  const [text, setText] = useState(false);
  const [step, setStep] = useState(0)
  const [search, setSearch] = useState('');
  const [stateData, setStateData] = useState(0)
  const navList = ['Geral', 'Não Vieram']

  function handleStateData(state) {

    if (state === 0) {
      setData({ ...data, data: '2020-01-01', fim: moment().format('yyyy-MM-DD') })
    } else {
      setData({ ...data, data: `${moment().format('yyyy-MM')}-01`, fim: moment().format('yyyy-MM-DD') })
    }
    setStateData(state)

  }

  function handleChange(e) {

    if (e.target.name === 'data' && data.fim < e.target.value) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else if (e.target.name === 'fim' && e.target.value < data.data) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }

  }
  function dialog(text, type) {
    setText({ text: text, type: type })

  }


  function move(item, indexCards, att) {
    // get(`/verificaLeadComercial/${item.id}/${item.status}/${user}`, then)

    // function then(resp) {
    function then2() {

      item.att()
      att()
    }
    //  if (resp) {
    //    item.att()
    //  setForm({})
    //   window.alert(`Esse Lead não está mais nesta categoria`)
    //} else {
    let project = { status: indexCards, id: item.id, user, coluna: 'status' }
    update(`/updateStatusAcompanhamento`, project, then2)
    // }

    //  }

  }
  function move2(item, indexCards, att) {

    function then2() {
      item.att()
      att()
    }
    let project = { status_naoveio: indexCards }
    console.log(project)
    update(`/editar/event/idevent/${item.idevent}`, project, then2)

  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 3
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '3px',
      borderRadius: '7px',
      transform: 'translate(-50%, -50%)',
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  function handleCloseModal(status) {
    setIsOpen(false)
    setForm({ id: 0, status, telefone: 0 })

  }

  function handleOpenModal(modal, card) {

    setForm(card)
    setCurrentModal(modal)
    setIsOpen(true)
  }
  const steps = [<Body />, <Agendados />]

  const buttons = [

    <input
      className='input'
      type='button'
      value='Data'
      onClick={() => handleStateData(1)}
    />,
    <input
      className='input'
      type='button'
      value='Voltar'
      onClick={() => handleStateData(0)}
    />
  ]
  const buttons2 = [
    <label> </label>
  ]

  const [currentModal, setCurrentModal] = useState(0)
  const modals = [
    <LeadsForm form={form} handleCloseModal={handleCloseModal} />,
    <Custom />
  ]

  function LeadsMenu() {

    return (
      <div id='leadsMenu'>
        <div id='buttonHover'></div>
        <div id='menuCategorias'>
          <h2>Categorias visíveis:</h2>
          {leads.map((item, index) =>
            <div key={index}>
              <span>{item.nome}</span>
              <button
                disabled={item.disabled}
                onClick={() =>
                  setLeads(produce(leads, draft => {
                    draft.find((element) => element.status == item.status).display =
                      draft.find((element) => element.status == item.status).display ? false : true
                  }))
                }
              > {item.display ? <GiCheckMark /> : ''} </button>
            </div>
          )}
        </div>
        <div id='menuCategorias' onClick={() => handleOpenModal(1, false)}>
          Tema
        </div>
      </div>
    )
  }

  const ativos = [
    { id: 1, nome: 'Ativos' },
    { id: '0', nome: 'Inativos' },
  ]

  function setStep2(page) {
    switch (page) {
      case 0:
        setData({
          ...data,
          ['data']: moment().subtract(1, 'month').format('yyyy-MM-DD'),
          ['fim']: moment().format('yyyy-MM-DD'),
        });
        break;
      case 1:
        setData({
          ...data,
          ['data']: moment().subtract(1, 'day').format('yyyy-MM-DD'),
          ['fim']: moment().format('yyyy-MM-DD'),
        });
        break;
    }
    setStep(page);
  }

  return (
    <Context.Provider value={{
      tema,
      empresa,
      search,
      setSearch,
      step,
      setStep,
      leads,
      setLeads,
      agendados,
      setAgendados,
      move,
      move2,
      user,
      handleOpenModal,
      form,
      data,
      setData,
      dialog
    }}>
      <Dialog value={text} />
      <DndProvider backend={HTML5Backend}>

        <Modal
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
          handleCloseModal={handleCloseModal}

        >
          {modals[currentModal]}
        </Modal>
        <LeadsMenu />
        <div id='leadsContainer'>
          <Header
            background={getTheme(localStorage.getItem('tema'), 0)}
            setStep={setStep2}
            navList={navList}
            buttons={buttons2}
          ><>
              <button
                className='input'
                type='button'
              >
                <IoIosMenu />
              </button>
              <InputSearch
                search={search}
                setSearch={setSearch}
              />


              <InputDate
                name='data'
                value={data.data}
                handleOnChange={handleChange}
              />
              <InputDate
                name='fim'
                value={data.fim}
                title='até'
                handleOnChange={handleChange}
              />
              <Select
                padrao='Todos'
                options={users}
                name='user'
                value={data.user}
                handleOnChange={handleChange}
              />
              {!step &&
                <Select
                  padrao='Status'
                  options={ativos}
                  name='ativo'
                  value={data.ativo}
                  handleOnChange={handleChange}
                />}
            </>
          </Header>
          {steps[step]}
        </div>
      </DndProvider>
    </Context.Provider>
  )
}

function Search() {
  const { setSearch, search } = useContext(Context)
  return (

    <div id='search'>
      <input
        type="text"
        placeholder="Pesquisar..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      >
      </input>
      <div>
        <IoIosSearch />
      </div>
    </div>
  )
}
