import React, { useState, useEffect, useContext } from "react";
import { InputDate, Button } from "../../a_model/Input";
import moment from "moment";
import { createPDF } from "../f_aux/createPDF";
import { GiCheckMark } from 'react-icons/gi'
import './Pdf.css'
import { Card } from "../../a_model/Container";
import Context from "../Context";
import { get } from "../f_aux/fetch";

export function Pdf() {
    const { unidade } = useContext(Context)
    const [list, setList] = useState([])
    const [project, setProject] = useState({
        data: moment().add(1, 'day').format('YYYY-MM-DD'),
        inicio: moment('2023 07 15').format('YYYY-MM-DD'),
        fim: moment().add(1, 'year').format('YYYY-MM-DD')
    })
    const [tudo, setTudo] = useState(false)
    const [atendentes, setAtendentes] = useState([])


    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //   //console.log(project.hora_fim)
    }
 
    useEffect(() => {
        get(`/get/atendentes/unidade/${unidade}/ativo=1`, then )

       

    }, [])

    function then(resp){
        resp.map((atendente, index) =>

            index > 0 ? setList((list) => [...list, { id: atendente.id, nome: atendente.nome, status: false }]) : setList([{ id: atendente.id, nome: atendente.nome, status: false }])
        )
        setList((list) => [...list, { id: 13, nome: 'Anestésico', status: false }]) 
    }

    function checkStatus(id) {
        if (id) {
            setList(
                list.map(item =>
                    item.id === id ? { ...item, status: item.status ? false : true } : { ...item }
                )
            )
        } else {
            const value = tudo ? false : true
            setTudo(tudo ? false : true)
            setList(
                list.map(item =>
                    item.id === id ? { ...item, status: value } : { ...item, status: value }

                )
            )
        }
    }
    function gerarPdf(download) {
        
        list.map((atendente) => {
            if (atendente.status) {
                get(`/buscarPDF/${project.data}/${atendente.id}/${unidade}`, then)

                function then(resp){
                    createPDF(resp, true, download)
                }
            }
        })

    }

    return (
        <Card header={
            <h2>Imprimir Agenda </h2>
        }>
            <InputDate
                name='data'
                value={project.data}
                handleOnChange={handleChange}
            />
            <div className="pdfOpt" >
                <button
                    onClick={() => checkStatus(false)}
                >
                    {tudo && <GiCheckMark />}
                </button>
                {tudo ? 'Desmarcar tudo' : 'Marcar tudo'}
            </div>
            {
                list.map((atendente) => (

                    ////console.log(hora.hora+props.day.format('L')+props.atendente.id),
                    <div
                        className="pdfOpt"
                        key={list.indexOf(atendente)}
                    >
                        <button
                            onClick={() => checkStatus(atendente.id)}
                        >
                            {atendente.status && <GiCheckMark />}
                        </button>
                        {atendente.nome}

                    </div>
                ))
            }
            <div className="inlineButton">
              
                <Button
                    color='#9e1b1b'
                    click={() => gerarPdf(true)}
                    value='Gerar PDF'
                />
                <Button
                    color='#174fb6'
                    click={() => gerarPdf(false)}
                    value='Imprimir'
                />
            </div>


        </Card>

    )

}