
const themes = [
    ['#4c95e2','#F4F4F8','#25486E','#B7E2FF'], //azul
    ['#E66360','#FFF1F0','#7F6162','#F4BABC'], //salmao
    ['#EF69D7','#EFF1F7','#A32F82','#F2D4EB'], //rosa
    ['#333','#fff','#444','#CCD7E0'], //marrom
    ['#33805B','#FFF7E6', '#255C41','#E9E0D1'], //verde
    ['#7159C1','#EBF2FF','#6A5E8F','#DCD8E8'], //roxo
    ['#2178DB','#FFFAF0','#748A99','#C8D4F4'], //marinho
    ['#841536','#FFF8F0','#943532','#FFD8DE'], //vermelho
    ['#4c95e2','#FFF1EC','#25486E','#CCD7E0'], //azul
]

const tema = ['#E7EBFA','#FAFAF4','#25486E','#B7E2FF']

export function getTheme(theme, index){

return themes[theme]? themes[theme][index]: themes[0][index]
   
}

export function getArrayTheme(){
    let resp = []
    for(let i in themes){
        resp.push({id: i, tema: themes[i]})
    }

    return resp
    }