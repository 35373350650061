import React from "react"
import ReactModal from "react-modal"
import css from './Input.module.scss'
export function Modal(props) {
    function handleCloseModal() {
        props.setIsOpen(false)

    }
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            zIndex: 5
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '3px',
            paddingTop: '25px',
            borderRadius: '7px',
            transform: 'translate(-50%, -50%)',
        }
    }
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={props.modalIsOpen}
            onRequestClose={ handleCloseModal }
            style={customStyles}
        >
            <button id={css.botaoFechar} onClick={()=> handleCloseModal()}>
                X
            </button>
            {props.children}
        </ReactModal>
    )
}

