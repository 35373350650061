import { Container, Label } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext, useEffect } from 'react'
import Context from '../../Context';
import './Card.scss'
import { getTheme } from '../../../a_model/Theme';
import moment from 'moment'
export function Card({ att, card, index, indexCards }) {

  const ref = useRef()
  const { move, addCarteira, tema, user, handleOpenModal } = useContext(Context)

  const [, dragRef] = useDrag({
    item: { indexCards, index, id_l: card.id_l, selecao: card.selecao, telefone_l: card.telefone_l, att },
    type: 'CARD',
    canDrag: false // Desabilitando arrastar o Card
  })

  const [{ isOver }, dropRef] = useDrop({
    accept: 'CARTEIRACARD', // Aceita itens do tipo CARTEIRACARD
    drop: (item, monitor) => {
       move(item, indexCards)
   //  window.alert('foi')
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  dragRef(dropRef(ref))

  const colors = [
    { id: 0, color: '#9929EA' },
    { id: 1, color: '#7962EA' },
    { id: 2, color: '#9982EB' },
    { id: 3, color: '#93A4EB' },
    { id: 4, color: '#B2BEEA' },
    { id: 5, color: '#B2BEEAaa' },
    { id: 6, color: '#B2BEEA44' },
    { id: 7, color: '#ddd' },
  ]



  return (
    <div className={`cardContainer ${isOver ? 'isOver' : ''}`} onClick={() => user == 'fellipe'? handleOpenModal(0, card) : addCarteira(card.telefone_l, card.selecao)} ref={ref} >
      <header style={{background:getTheme(tema, 3)+'99'}}>

        <h3>{card.data_cadastro_l && card.data_cadastro_l.substr(0, 10).split('-').reverse().join('/') }</h3>
        
        <Label color={card.tentativas < 7 ? colors.find((element) => element.id == card.tentativas).color : colors[7].color} />
      </header>
      <body>
      <p>{card.nome_l}</p>
      <p style={{fontWeight:500}}>{parseInt(card.count) > 1? card.count+ ` interesses` : card.interesse_l}</p>
       { card.selecao == 'nao' && !card.nota?
      <p style={{fontStyle: 'italic'}}>{card.tentativas+ ' tentativas'}</p> 
      : <p style={{color: '#2b59bb'}}>{card.nota}</p>
        }
      </body>
    </div>
  )
}

export function CarteiraCard({ att, card, index, indexCards }) {

  const ref = useRef()
  const { move, handleOpenModal, tema, data } = useContext(Context)
  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id_l: card.id_l, selecao: card.selecao, telefone_l: card.telefone_l, att },
    type: 'CARTEIRACARD', // Definindo o tipo como CARTEIRACARD
    canDrag: true // Permitindo arrastar o CarteiraCard
  })

  const [, dropRef] = useDrop({
    accept: 'CARD', // Permitindo soltar um Card
    canDrop: false, // Desabilitando soltar em CarteiraCard
    drop(item, monitor) {
     // move(item, indexCards, att)
    //  window.alert('foi')
    }
  })


  dragRef(dropRef(ref))

  const colors = [
    { id: 0, color: '#9929EA' },
    { id: 1, color: '#7962EA' },
    { id: 2, color: '#9982EB' },
    { id: 3, color: '#93A4EB' },
    { id: 4, color: '#B2BEEA' },
    { id: 5, color: '#B2BEEAaa' },
    { id: 6, color: '#B2BEEA44' },
    { id: 7, color: '#ddd' },
  ]
  return (
    <div className={`cardContainer ${isDragging ? 'isDragging' : ''}`} onClick={() => handleOpenModal(0, card)} ref={ref} isDragging={isDragging}>
      <header style={{background:getTheme(tema, 3)+'99'}}>

        <h3>{card.data_cadastro_l && card.data_cadastro_l.substr(0, 10).split('-').reverse().join('/') }</h3>
        
        <Label color={card.tentativas < 7 ? colors.find((element) => element.id == card.tentativas).color : colors[7].color} />
      </header>
      <body>
      <p>{card.nome_l}</p>
      <p style={{fontWeight:500}}>{parseInt(card.count) > 1? card.count+ ` interesses` : card.interesse_l}</p>
       { card.nota?
      <p>{moment(card.data_carteira).format('HH:mm')}</p> 
      : <p style={{color: '#2b59bb'}}>{card.nota}</p>
        }
      </body>
    </div>
  )
}