import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toCash } from '../../a_model/functions';

export function createUserPDF(data, result) {
    console.log(result)
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let rateioGeral = 0
    function getTotalGeral() {
        let retorno = 0;
        for (let aula of result) {
            retorno += parseFloat(aula.soma);
        }
        return retorno;
    }
    function getRateio(aula) {
        let retorno = 0;
        for (let aluno of aula.alunos) {

            if (aluno.tipo == 1 && aluno.status) {
                retorno += parseFloat(aluno.valor)
            }
        }

        retorno = retorno / 100 * (1.5)
        rateioGeral += retorno

        return retorno;
    }

    const title = [
        {
            table: {
                widths: ['100%'],
                body: [
                    [
                        {
                            text: `Relatório por período: ${data.data.substr(0, 10).split('-').reverse().join('/')} à ${data.data_fim.substr(0, 10).split('-').reverse().join('/')}
                            \nUnidade: IECB`,
                            fontSize: 15,
                            bold: true,
                            margin: [5, 5, 20, 5],
                            lineHeight: 0.7
                        }
                    ]
                ]
            }
        }
    ];

    const pagamentos = result.map((aula) => {
        const resultAula = aula.alunos.map((aluno) => {
            const valor = parseFloat(aluno.valor);
            const count = parseInt(aula.count);

            // Verifique se valor e count são válidos
            if (isNaN(valor) || isNaN(count)) {
                console.error('Valor inválido:', { valor, count, aula, aluno });
                return null;
            }

            const rateio = (valor / 100 * (1.5));


            return [
                {
                    text: `${aluno.tipo == 1 ? 'A' : 'M'}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2]
                },
                {
                    text: `${aluno.nome_aluno}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2]
                },
                {
                    text: `${valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
                {
                    text: `${aluno.tipo == 2 ? toCash(0) : rateio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
                {
                    text: `${aluno.usuario}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
                {
                    text: `${aluno.status ? 'Pago' : 'Pendente'}`,
                    italics: true,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
            ];
        }).filter(item => item !== null); // Filtra itens nulos

        const totalRow = [
            {},
            {
                text: 'Total',
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],

                noWrap: true
            },
            {
                text: `${parseFloat(aula.soma).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],
                noWrap: true
            },
            {
                text: `${getRateio(aula).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],
                noWrap: true
            },
            {},
            {}
        ];

        return [
            {
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: `${aula.nome_docente} - ${aula.nome_curso}`,
                                fontSize: 16,
                                margin: [2, 2, 2, 2]
                            }
                        ]
                    ]
                },
                layout: 'noBorders',
                margin: [2, 2, 2, 2]
            },
            {
                table: {
                    headerRows: 1,
                    widths: [10, 200, 70, 70, 50, 30],
                    body: [
                        [
                            { border: [true, true, true, true], text: '', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Nome', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Valor', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: `Rateio 1,5%`, style: 'tableHeader', fontSize: 13, bold: true }, 
                            { border: [true, true, true, true], text: 'Usuário', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: '*', style: 'tableHeader', fontSize: 13, bold: true },
                        
                        ],
                        ...resultAula,
                        totalRow
                    ]
                },
                layout: 'lightHorizontalLines'
            }
        ];
    });

    const totalGeral = {
        table: {
            widths: ['auto', 'auto'],
            body: [
                [
                    {
                        text: 'Total Rateio',
                        bold: true,
                        fontSize: 15,
                        alignment: 'left',
                        margin: [0, 5, 0, 5],
                        decoration: 'underline'
                    },
                    {
                        text: `${toCash(rateioGeral)}`,
                        bold: true,
                        fontSize: 15,
                        margin: [0, 5, 0, 5],
                        decoration: 'underline',
                        noWrap: true
                    }
                ]
            ]
        },
        layout: 'noBorders',
        margin: [0, 10, 0, 0]
    };

    const pdfContent = [
        ...pagamentos.flat(),
        totalGeral
    ];

    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 15, 15, 15],
        content: [
            title,
            ...pdfContent
        ],
        layout: 'lightHorizontalLines',
        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
        }
    };

    pdfMake.createPdf(docDefinitions).open();
}
