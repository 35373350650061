import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router';
import { ClientesForm } from "../b_forms/ClientesForm";
import { Header } from "../../a_model/Header";
import Context from "../Context";
import { InputSearch, LabelText } from "../../a_model/Input";
import { BiCalendar } from 'react-icons/bi'
import copy from "copy-to-clipboard";
import { Page } from "../../a_model/Container";
import { getTheme } from "../../a_model/Theme";
import { useContext } from "react";
import VendasForm from "../b_forms/VendasForm";
import Historico from "../b_forms/Historico";
import { get } from "../f_aux/fetch";
import CadastroCliForm from "../b_forms/CadastroCliForm";
//import { Search } from "../../styles/ContentStyle";
export default function Clientes() {

    const unidade = localStorage.getItem('unidade')
    const user = localStorage.getItem('login')
    const [clientes, setClientes] = useState([])
    const [cliente, setCliente] = useState({ telefone: '0000' })
    const [step, setStep] = useState(0)
    const { setCurrentForm } = useContext(Context)
    
    useEffect(() => {
        get(`/clients/${unidade}`, setClientes)
    }, [])

    const navList = ['Vendas', 'Cadastro', 'Histórico']
    const buttons = [<button onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>]
    const forms = [
        <VendasForm />,
        <CadastroCliForm />,
        <Historico />
    ]

    return (
        <Context.Provider value={{ unidade, user, setCliente, cliente, step }}>
            <Page>
                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={buttons}
                >
                    {cliente.nome ?
                        <div style={{ background: 'transparent' }}>
                            <LabelText

                                svg='1'
                                header='Cliente:'
                                onClick={() => setCliente({ telefone: 0 })}
                            >
                                {cliente.nome}
                            </LabelText>
                            <LabelText

                                svg='0'
                                header='Código:'
                                onClick={() => copy(cliente.id)}

                            >
                                {cliente.id}
                            </LabelText>

                        </div>

                        :

                        <ClientesBar
                            setCliente={setCliente}
                            clientes={clientes}
                        />
                    }

                </Header>
                <body style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>

                    {forms[step]}
                </body>

            </Page>
        </Context.Provider>
    )
}


function ClientesBar({ clientes, setCliente }) {
    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return clientes.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            width='350px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setCliente={setCliente}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setCliente, search }) {
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setCliente(result)}>
                        <span>{result.nome || '-'}</span>{result.telefone && <span>{formatTel(result.telefone)}</span>}
                    </li>

                ))}
            </ul>

        </>
    );
}