import React, { useState, useEffect, useContext } from "react";
import { Button, Checkbox, InputCash, InputNumber, InputText } from "../../a_model/Input";
import Context from "../Context";
import { Form, FormContainer, Page } from "../../a_model/Container";
import { get, insert, update } from "../f_aux/fetch";
import { Dialog } from "../c_layouts/Dialog";
import { SeletorDeCor, Table, Th } from "../../a_model/Contents";
import { Modal } from "../../a_model/Modal";

export default function DocentesIecb() {
    const [text, setText] = useState(false)
    const [dataCard, setDataCard] = useState(false)
    const [att, setAtt] = useState('')
    const [modalIsOpen, setIsOpen] = useState(false)
    function dialog(text, type) {
        setText({ text: text, type: type })
    }
    function openModal(docente) {
        setIsOpen(true)
        setDataCard(docente)
    }
    return (
        <Context.Provider value={{ att, setAtt, dialog, dataCard, openModal, setIsOpen }}>
            <Page>

                <Dialog value={text} />
                <div style={{ display: 'flex' }}>
                    <DocentesForm />
                    {/*<CreateDocente />*/}
                    {modalIsOpen &&
                        <EditDocente />
                    }
                </div>
            </Page>


        </Context.Provider>

    )
}

function DocentesForm() {
    const { openModal, att } = useContext(Context)
    const [docentes, setDocentes] = useState([])


    useEffect(() => {
        get(`/get/docentes/0/0`, setDocentes)
    }, [att]);

    const titles = [
        { width: '', title: 'Login' },
        { width: '100px', title: 'Nome' },

        { width: '', title: 'Rateio' },
    ]

    return (
        <Form>
            <header>
                <h2>Lista de Professoras</h2>
            </header>

            <Table >
                <Th array={titles} />
                {
                    docentes.map((item, index) =>
                        <tr className='hover' style={{ opacity: item.ativo ? 1 : 0.5 }} key={index} onClick={() => openModal(item)}>
                            <td>
                                {item.login}
                            </td>
                            <td style={{ maxWidth: '400px', fontWeight: 600, color: item.color }}>
                                {item.ativo ? item.nome : item.nome + ' (Inativo)'}
                            </td>

                            <td>
                                {item.rateio + '%'}
                            </td>
                        </tr>
                    )}
            </Table>
        </Form>
    )

}

function EditDocente() {

    const { dataCard, setAtt, setIsOpen } = useContext(Context)
    const [project, setProject] = useState(dataCard)

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //console.log(e.target.value)
    }
    function salvar() {
        update(`/editar/docentes/login/${project.login}`, project, then)
        function then() {
            setAtt(Math.random())
            setIsOpen(false)
        }
    }

    useEffect(() => {
        setProject(dataCard)
      }, [dataCard])
    return (
        <FormContainer >
            <header>
                <h2>Editar Professora</h2>
            </header>
            <body>
                <InputText
                    width='400px'
                    flex='column'
                    title='Nome'
                    name='nome'
                    value={project.nome}
                    handleOnChange={handleChange}
                />
                <div style={{ display: 'flex' }}>
                    <InputNumber
                        flex='column'
                        width='60px'
                        title='Rateio vip %'
                        name='rateio'
                        value={project.rateio}
                        handleOnChange={handleChange}
                    />
                    <InputNumber
                        flex='column'
                        width='60px'
                        title='Rateio regular %'
                        name='rateio_regular'
                        value={project.rateio_regular}
                        handleOnChange={handleChange}
                    />
                </div>
                <Checkbox
                    value={project.ativo}
                    label='Ativo'
                    name='ativo'
                    event={handleChange}

                />
                <SeletorDeCor project={project} name='color' setProject={setProject} />

            </body>
            <footer>
                <Button
                    value='Salvar'
                    click={() => salvar()}
                    color='#555'
                />
            </footer>
        </FormContainer>
    )

}

function CreateDocente() {
    const { dataCard, setAtt, setIsOpen } = useContext(Context)
    const docenteTemplate = {
        login: 'admin',
        nome: '',
        color: '#cccccc',
        rateio: 20,
        rateio_regular: 15,
        ativo: 1,

    }
    const [project, setProject] = useState(docenteTemplate)

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //console.log(e.target.value)
    }
    function salvar() {
        if (project?.nome.length > 2) {
            insert(`/inserir/docentes`, project, then)
        } else {
            window.alert('Nome precisa ter pelo menos 3 dígitos')
        }
        function then() {
            setAtt(Math.random())
            setIsOpen(false)
            setProject(docenteTemplate)
            window.alert('Profe')
        }
    }
    return (
        <FormContainer >
            <header>
                <h2>Cadastrar Professora</h2>
            </header>
            <body>
                <InputText
                    width='400px'
                    flex='column'
                    title='Nome'
                    name='nome'
                    value={project.nome}
                    handleOnChange={handleChange}
                />
                <div style={{ display: 'flex' }}>
                    <InputNumber
                        flex='column'
                        width='60px'
                        title='Rateio vip %'
                        name='rateio'
                        value={project.rateio}
                        handleOnChange={handleChange}
                    />
                    <InputNumber
                        flex='column'
                        width='60px'
                        title='Rateio regular %'
                        name='rateio_regular'
                        value={project.rateio_regular}
                        handleOnChange={handleChange}
                    />
                </div>
                <Checkbox
                    value={project.ativo}
                    label='Ativo'
                    name='ativo'
                    event={handleChange}

                />
                <SeletorDeCor project={project} name='color' setProject={setProject} />

            </body>
            <footer>
                <Button
                    value='Salvar'
                    click={() => salvar()}
                    color='#555'
                />
            </footer>
        </FormContainer>
    )

}