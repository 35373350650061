import React, { useState, useMemo, useEffect, useContext } from 'react';
import { InputSearchButton, InputSearch, ListItem } from '../../a_model/Input';
import { get } from '../f_aux/fetch';
export function PesquisarAulas({ setAula, cadastrar }) {
    const [search, setSearch] = useState('');
    const [aulas, setAulas] = useState([])

    useEffect(() => {
        get(`/getAulas/1`, setAulas)
    }, [])

    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return aulas.filter(user => (user.nome_curso.toLowerCase().includes(lowerBusca) || user.docente.includes(lowerBusca)));
    }, [search, aulas])

    return (

        <InputSearch
            width='400px'
            search={search}
            setSearch={setSearch}
            placeholder="Pesquisar aulas..."
        >
            <ListItem width='400px' height='370px'>
                {search.length < 3 ?
                    aulas.map((aula, index) => (
                        aula.count > 0 &&
                        <li
                            key={index} onClick={() => setAula(aula)}
                        >
                            <div >
                                {`${aula.data_agendado && aula.data_agendado.substr(0, 10).split('-').reverse().join('/')} - ${aula.nome_curso} - ${aula.docente}` || '-'}

                            </div>

                        </li>
                    ))
                    :
                    results.map((aula, index) => (
                        aula.count > 0 &&
                        <li
                            key={index} onClick={() => setAula(aula)}
                        >
                            <div >
                                {`${aula.data_agendado && aula.data_agendado.substr(0, 10).split('-').reverse().join('/')} - ${aula.nome_curso} - ${aula.docente}` || '-'}

                            </div>

                        </li>
                    ))}
            </ListItem>
        </InputSearch>
    );
}

export function PesquisarCursos({ setCurso }) {
    const [search, setSearch] = useState('');
    const [cursos, setCursos] = useState([])

    useEffect(() => {
        get(`/getCursos/1`, setCursos)
    }, [])

    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return cursos.filter(user => (user.nome.toLowerCase().includes(lowerBusca)));
    }, [search, cursos])

    return (

        <InputSearch
            width='380px'
            search={search}
            setSearch={setSearch}
            button='Cadastrar'
            placeholder="Pesquisar cursos..."
        >
            <ListItem width='380px' height='370px'>
                {search.length < 3 ?
                    cursos.map((curso, index) => (
                        <li
                            key={index} onClick={() => setCurso(curso)}
                        >
                            <div >
                                {`${curso.id} - ${curso.nome}  ` || '-'}

                            </div>

                        </li>
                    ))
                    :
                    results.map((curso, index) => (
                        <li
                            key={index} onClick={() => setCurso(curso)}
                        >
                            <div >
                                {`${curso.id} - ${curso.nome}  ` || '-'}

                            </div>

                        </li>
                    ))}
            </ListItem>
        </InputSearch>
    );
}

