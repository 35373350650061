import React, { useContext, useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import Context from './Context';
import { Form2 } from '../a_model/Container';
import { get, insert } from '../a_model/fetch';
import { Button, InputText, RadioList, TextArea2 } from '../a_model/Input';
import css from './checklist.module.scss';
import { sortState2 } from '../a_model/functions';

export default function Insert() {
    const { user, unidade, categorias, statusList, setStep, dialog } = useContext(Context);
    const [att, setAtt] = useState(false);
    const [list, setList] = useState([]);
    const [project, setProject] = useState({
        itens: [],
        usuario: user,
        unidade,
        nota_usuario: ''
    });

    const formRef = useRef(null); // Crie o ref para o formulário

    useEffect(() => {
        get(`/get/checklist_options/ativo/1`, then);
    }, []);

    function then(resp) {
        const sorted = sortState2(resp, 'categoria', 'asc');

        setList(sorted);
        setProject(produce(project, draft => {
            draft.itens = [];

            for (let i in sorted) {
                draft.itens.push({
                    id_options: sorted[i].id,
                    status: 0,
                    obs: ''
                });
            }
        }));
    }

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value });
    }

    function subChange(e, index) {
        const { name, value } = e.target;

        setProject(produce(project, draft => {
            draft.itens[index][name] = value;
        }));
    }

    function salvar() {
        // Verifique a validade do formulário
        if (formRef.current && formRef.current.reportValidity()) {
            insert(`/checklist`, project, then);
            function then() {
                dialog({ text: 'Formulário Enviado com Sucesso!', type: 0 });
                setStep(1)
            }
        } else {
            window.alert("Por favor, preencha todos os campos obrigatórios.");
        }
    }

    return (
        <Form2 height='100%' ref={formRef}> {/* Use um elemento HTML form */}

            <div id={css.grid}>
                <h1>Checklist</h1>
                {list.map((item, index) => (
                    <div key={index}>
                        {item.categoria > 0 &&
                            item.categoria > list[index - 1].categoria &&
                            <h2>{categorias.find((element) => element.id == item.categoria).nome}</h2>
                        }
                        <Question
                            key={index}
                            question={item}
                            index={index}
                            subChange={subChange}
                            statusList={statusList}
                            item={project.itens[index]}
                        />
                    </div>
                ))}
                <h2>Considerações Finais</h2>
                <div className={css.question}>
                    <TextArea2
                        name='nota_usuario'
                        value={project.nota_usuario}
                        handleOnChange={handleChange}
                    />
                </div>
                <Button
                    value='Enviar'
                    click={salvar} // Passa a função salvar corretamente
                />
            </div>
        </Form2>
    );
}

function Question({ question, statusList, index, subChange, item }) {
    function handleChange(e) {
        subChange(e, index);
    }

    return (
        <div className={css.question}>
            <h3>{question.nome}</h3>
            {question.descr && <p>{question.descr}</p>}
            {question.status > 0 && (
                <RadioList
                    title={question.nome}
                    name='status'
                    values={statusList}
                    handleOnChange={handleChange}
                    data={item.status}
                    required
                />
            )}
            <InputText
                title='Obs'
                name='obs'
                value={item.obs}
                handleOnChange={handleChange}
            />
        </div>
    );
}
