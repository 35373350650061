import { useEffect, useState } from 'react';
import { GiConfirmed } from 'react-icons/gi';
import './Dialog.css'
import Modal from 'react-modal'

export function Dialog({ value }) {
  const types = [<GiConfirmed />];
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (value) {
      setOpacity(1);
      setTimeout(() => {
        setOpacity(0);
      }, 2000);
    }
  }, [value]);

  return (
    <div
      onClick={() => setOpacity(0)}
      style={{
        position: 'absolute',
        zIndex: opacity * 5,
        backgroundColor: '#eceaea',
        right: '15vw',
        top: '15vh',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0',
        borderRadius: '5px',
        border: '1px solid #a1a1a1',
        padding: '10px',
        transition: 'opacity 0.5s',
        opacity: opacity,
      }}
      className="dialog"
    >
      {value.text}
      {types[value.type]}
    </div>
  );
}

export function useConfirm() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const showConfirm = () => {
    return new Promise((resolve) => {
      setModalIsOpen(true);
      setCallback(() => resolve); // Define a função de callback que será resolvida com o valor escolhido
    });
  };

  const handleConfirm = (result) => {
    setModalIsOpen(false);
    callback(result); // Chama a função de callback com o resultado (true ou false)
  };

  return { showConfirm, modalIsOpen, handleConfirm };
}



export function ConfirmDialog({ modalIsOpen, handleConfirm, message }) {

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 5
    },
    content: {
      top: '20%',
      left: '50%',
      width: '400px',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',

      borderRadius: '7px',
      transform: 'translate(-50%, -50%)',

    }
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleConfirm(false)}
      contentLabel="Exemplo de modal"
      style={customStyles}
    >
      <div id='modalConfirm'>
        <h2>{message}</h2>
        <div>

          <button onClick={() => handleConfirm(true)}>Sim</button>
          <button onClick={() => handleConfirm(false)}>Não</button>
        </div>
      </div>
    </Modal>
  );
}
