import React, {useContext} from "react";
import { Container } from '../styles/BodyStyle'
import Cards from "./contents/Cards";
import Context from '../Context';


export default function Body() {

    const { leads } = useContext(Context)

    return (
        <Container>
            {leads.map((cards, indexCards) =>
                <Cards 
                    cards={cards} 
                    indexCards={indexCards}
                    key={indexCards}
                />

            )}
        </Container>
    )
}