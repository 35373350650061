import { useContext, useEffect, useState } from 'react';
import React from 'react';
import Context from './Context';
import { get, update } from '../a_model/fetch';
import css from './checklist.module.scss'
import { getUnidade, sortState2, toDate } from '../a_model/functions';
import { Button, TextArea2 } from '../a_model/Input';
import { Table, Th } from '../a_model/Contents';

export default function Respostas() {
  const { unidades, params, categorias } = useContext(Context)
  const [respostas, setRespostas] = useState([]);
  const [checklist, setChecklist] = useState(false)
  const titles = [
    { title: 'Data' },
    { title: 'Unidade' },
    { title: 'Usuário' },
    { title: '*', },
    { title: 'CR' },
    { title: 'R' },
    { title: 'CH' },
    { title: 'AT' },
    { title: 'AV' },
    { title: 'VG' },
    { title: 'VA' },
    { title: 'AI' },

  ]

  useEffect(() => {
    /* // Função assíncrona para buscar os dados
     async function fetchData() {
       try {
         // Primeira requisição para obter os checklists
         get(`/get/checklist_franquias${params.empresa == 100 ?'/0/0' : `/empresa/${params.empresa}` }`, async (resp) => {
           const updatedRespostas = [];
 
           // Para cada checklist, faz uma segunda requisição para obter os itens
           for (let item of resp) {
             await new Promise((resolve) => {
               get(`/get/checklist_view/id_checklist/${item.id}`, (resp2) => {
                 updatedRespostas.push({ ...item, itens: resp2 });
                 resolve();
               });
             });
           }
 
           // Atualiza o estado de uma vez com todos os dados completos
 
           setRespostas(updatedRespostas);
         });
       } catch (error) {
         console.error("Erro ao buscar dados:", error);
       }
     }
 
     fetchData(); // Chama a função de busca de dados
 */

    get(`/respostas/${params.data}/${params.data_fim}/${params.unidade}`, then)


  }, [params]);
  function then(resp) {

    setRespostas(resp)

  }

  const notasColor = [
    { color: '#ec8173', min: 1, max: 2.4 },
    { color: '#ece073', min: 2.5, max: 2.9 },
    { color: '#d3f14b', min: 3, max: 3.9 },
    { color: '#4bcaf1', min: 4, max: 4.9 },
    { color: '#4bf19e', min: 5, max: 5 }
  ]

  return (
    
      !checklist ?
        respostas.length ?
        <Table>
          <Th array={titles} />
          {respostas.map((item, index) =>
            <tr key={index} className='hover' onClick={() => setChecklist(item)}>
              <td>{toDate(item.data)}</td>
              <td>{unidades.find((element) => element.id == item.unidade)?.nome}</td>
              <td>{item.usuario}</td>
              {item.notas.map((nota) =>
                <td style={{
                  background:
                    notasColor.find((element) => 
                      element.min <= parseFloat(nota.nota) &&
                    element.max >= parseFloat(nota.nota) 
                )?.color
                }}>
                  {parseInt(nota.nota) > 0 ? nota.nota : '-'}
                </td>
              )}
            </tr>
          )}
        </Table>
        : <span>Nenhum Formulário preenchido nesse período</span>
        :
        <div id={css.grid}>
          <button onClick={()=>setChecklist(false)}>
            Voltar
          </button>
          <div className={css.respostas}>
            <RespCard
              checklist={checklist}

            />
          </div>
        </div >
  

  )

}

function RespCard({ checklist, index }) {
  const { user, statusList, categorias, dialog } = useContext(Context)
  const [project, setProject] = useState({
    nota_usuario: checklist.nota_usuario,
    nota_adm: checklist.nota_adm,
    nota_gerencia: checklist.nota_gerencia,
  })
  const [resposta, setResposta] = useState({ ...checklist, itens: [] })
  useEffect(() => {

    get(`/get/checklist_view/id_checklist/${checklist.id}`, then)


    function then(resp) {
      const sorted = sortState2(resp, 'categoria', 'asc')
      setResposta({ ...resposta, ['itens']: sorted })

    }

  }, []);
  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })
  }
  function dialog2() {
    dialog({ text: 'Comentário Atualizado', type: 0 })
  }
  return (
    <div className={css.resposta} >
      <div className={css.header}>
        <span>
          <label>
            Data:
          </label>
          {toDate(resposta.data)}
        </span>

        <span>
          <label>
            Empresa:
          </label>
          {getUnidade(resposta.unidade)}
        </span>

        <span>
          <label>
            Usuário:
          </label>
          {resposta.usuario}
        </span>

      </div>
      {resposta.itens.map((item, index) =>
        <div className={css.item} key={index}>

          {item.categoria > 0 &&
            item.categoria !== resposta.itens[index - 1].categoria &&
            <h2>{categorias.find((element) => element.id == item.categoria).nome}</h2>
          }
          <div className={css.question}>
            <span>
              {item.nome}
              {item.status > 0 &&
                <div style={{ color: statusList.find((element) => element.id == item.status).color }}>
                  {statusList.find((element) => element.id == item.status).nome}
                </div>}
            </span>
            <span>
              Obs: {item.obs}
            </span>
          </div>
        </div>
      )}


      <div className={css.item}>

        <h2>Considerações Finais</h2>

        <div className={css.question}>
          <span>
            Usuário
          </span>
          {user == resposta.usuario ?


            <TextArea2
              name='nota_usuario' // O 'name' aqui é 'obs', o que permitirá que `subChange` saiba o que atualizar.
              value={project.nota_usuario} // O valor atual da observação.
              handleOnChange={handleChange}
            >
              <Button
                value='Salvar'
                click={() => update(
                  `/editar/checklist_franquias/id/${resposta.id}`,
                  { nota_usuario: project.nota_usuario },
                  dialog2
                )}
              />
            </TextArea2>
            :
            <span>
              {resposta.nota_usuario || '<vazio>'}
            </span>


          }
        </div>

        <div className={css.question}>
          <span>
            Gerência
          </span>
          {user == 'dradebora' ?


            <TextArea2
              name='nota_gerencia' // O 'name' aqui é 'obs', o que permitirá que `subChange` saiba o que atualizar.
              value={project.nota_gerencia} // O valor atual da observação.
              handleOnChange={handleChange}
            />

            :
            <span>
              {resposta.nota_gerencia || '<vazio>'}
            </span>


          }

        </div>

        <div className={css.question}>
          <span>
            Administração
          </span>
          {user == 'ligyane' ?


            <TextArea2
              name='adm' // O 'name' aqui é 'obs', o que permitirá que `subChange` saiba o que atualizar.
              value={project.adm} // O valor atual da observação.
              handleOnChange={handleChange}
            />

            :
            <span>
              {resposta.adm || '<vazio>'}
            </span>


          }

        </div>

      </div>


    </div>
  )
}
