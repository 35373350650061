import React from 'react'
import { getColor } from './salaColor'
import { Table, Th } from '../../a_model/Contents'

export function ProcList({ procs, setProc }) {

  const thead = procs[0] ?
    [
      {width:'',title:'Procedimento'},

  
      {width:'',title:'Região'},
      {width:'',title:'Res'},
      {width:'',title:'id'},
    ]
    : ''
//    {width:'',title:'Valor'},
 //<td >{(proc.valor || '-')} $</td>
  return (
    procs.length > 0 &&
    <Table height='50vh' >
      <Th array={thead}/>
      {procs.map((proc, index) => (

        <tr onClick={() => setProc(procs[index])} key={proc.id_vendas_sub} >
          <td style={{maxWidth:'300px'}}>{proc.nome || '-'}</td>
          <td style={{maxWidth:'250px'}}>{(proc.regiao || '-')}</td>
          <td style={{fontWeight:500, color:'#3c5e8f'}}>{(proc.restante || '-')}</td>
          <td >{(proc.id_vendas_sub || '-')}</td>
        </tr>
      )
      )}
    </Table >





  )

}

export function SalaList({ salas, editarSala }) {
  const thead = [    
      {width: '',title:'Índice'},
      {width: '',title:'Nome'},
      {width: '',title:'Quantidade'},
    ]

  return (
    salas.length > 0 &&
    <Table height='50vh'>
      <Th array={thead}/>
      {salas.map((sala, index) =>
        <tr
          onClick={() => editarSala(sala.id)}
          key={index}
        >
          <td style={{ color:'#fff', backgroundColor: sala.color }}>{sala.id}</td>
          <td >{(sala.nome || '-')}</td>
          <td >{(sala.qnt || '-')}</td>
        </tr>
      )}
    </Table >
  )
}