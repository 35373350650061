import { useState, useEffect, useContext } from 'react'
import { InputMasks, Button, InputText } from '../../a_model/Input'
import { get, update, updateParams } from '../f_aux/fetch'
import { Card } from '../../a_model/Container'
import { getTheme } from '../../a_model/Theme'
import Context from '../Context'

export default function CadastroCliForm({ id }) {
    const { cliente } = useContext(Context)
    //  const [cliente2, setCliente2] = useState({})
    //  const [vendas, setVendas] = useState([])
    const [isBR, setIsBR] = useState(cliente?.telefone?.substr?.(0, 2) === '55');

 
    const [project, setProject] = useState(cliente)
    // const [modalIsOpen, setIsOpen] = useState(false)
    function handleChange(e) {
        let value = e.target.value
        if (e.target.name === 'telefone') {
            
            value?.substr(0, 2) == '55' ?
                setIsBR(true)
                :
                setIsBR(false)
            value = value.replace(/[^0-9]/g, '')
        }
        setProject({ ...project, [e.target.name]: value })


    }
    function editarCadastro() {
        const cadastro = {['obs']: project.obs, ['telefone_l']: project.telefone.replace(/[^0-9]/g, ''), ['nome_l']: project.nome }
        function then() {
            window.alert('Cadastro Alterado!')
        }
        update(`/editar/acompanhamento/id/${project.id}`, cadastro, then)

    }
    /* function zerar() {
         updateParams(`/agruparCadastros/${cliente2.id}`, editPost)
     }
     function editPost() {
         let project2 = project
         project2.telefone = project.telefone.replace(/[^0-9]/g, '')
         update(`/alterarAcompanhamento`, project2, then)
         function then(resp2){
             if (resp2) {
                 setCliente2(resp2)
                 get(`/getVendas/${resp2.id}`, then2)
                 setCliente(project)
             } else {
                 window.alert('Cadastro alterado')
             }
         }
         function then2(resp2){
             if (resp2.vendas0.length > 0 || resp2.vendas1.length > 0) {
                 setVendas(resp2)
                 setIsOpen()
             } else {
                 let resp = window.confirm(`Telefone já cadastrado no sistema! nome: ${cliente2.nome}, id: ${cliente2.id} deseja cancela-lo?`)
                 if (resp) {
                     zerar()
                     update(`/alterarAcompanhamento`, project2, console.log)             
                 }
             }
         }
     }
 
     function agrupar() {
         function then() {
             setProject(cliente)
             window.alert('Agrupado!')
             setIsOpen(false)
         }
         updateParams(`/agruparCadastros/${cliente.id}/${cliente2.id}`, then)
 
     }
 */




    return (
        cliente.id ?
            <Card width='500px' height='500px' header='Informações do Cadastro'>

                <body style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>

                    <InputText
                        flex='column'
                        title="Nome"
                        name="nome"
                        value={project.nome}
                        placeholder="Insira o nome do paciente..."
                        handleOnChange={handleChange}
                    />

                    {isBR ?
                        <InputMasks
                            flex='column'
                            mask='2'
                            title="Telefone"
                            name="telefone"
                            value={project.telefone}
                            placeholder="Insira o telefone do paciente..."
                            handleOnChange={handleChange}
                        />
                        :

                        <InputText
                            flex='column'
                            title="Telefone"
                            name="telefone"
                            value={project.telefone}
                            placeholder="Insira o telefone do paciente..."
                            handleOnChange={handleChange}
                        />}

                    <InputText
                        flex='column'
                        title="Observação"
                        name="obs"
                        value={project.obs}
                        placeholder="Esta mensagem aparecerá quando o paciente for agendado..."
                        handleOnChange={handleChange}
                    />

                </body>
                <footer>
                    <Button
                        color='#5096e2'
                        value='Alterar'
                        click={() => editarCadastro()}

                    />

                </footer>
            </Card>
            : <span>Informe o cliente...</span>
    )
}
