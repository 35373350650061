import React, { useState, useEffect, useContext } from 'react'
import { Header } from "../../a_model/Header";
import Body from './Body'
import { useParams } from "react-router";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { produce } from 'immer'
import Context from '../Context';
import moment from 'moment'
import Modal from 'react-modal';
import LeadsForm from './contents/LeadsForm';
import { Dialog } from './Dialog';
import { IoIosSearch, IoIosMenu } from "react-icons/io";
import { InputDate, InputSearch, Select } from "../../a_model/Input";
import { getTheme } from '../../a_model/Theme';
import { get, update } from '../../calendar/f_aux/fetch'
import './Leads.scss'
import { GiCheckMark } from 'react-icons/gi'
import { Custom } from './contents/Custom';
import Relatorio from './Relatorio';


export default function Leads() {

  const { userp } = useParams();
  const { empresap } = useParams();
  const user = userp || localStorage.getItem('login')
  const empresa = empresap || localStorage.getItem('empresa')
  const [tema, setTema] = useState(localStorage.getItem('tema'))
  const [users, setUsers] = useState([])
  const [leadsList, setLeadsList] = useState([
    { nome: 'Novos', cards: [], selecao: 'nao', display: true, disabled: true },
    { nome: 'Atendendo', cards: [], selecao: 7, display: true, disabled: true },
    { nome: 'Msg enviada', cards: [], selecao: 'Mens', display: true, disabled: true },
    { nome: 'Agendado', cards: [], selecao: 1, display: true, disabled: false },
    { nome: 'Sem interesse', cards: [], selecao: 2, display: true, disabled: false },
    { nome: 'Outro momento', cards: [], selecao: 3, display: true, disabled: false },
    //{ nome: 'Indeciso', cards: [], selecao: 4, display: true, disabled: false },
    { nome: 'Sem Resposta', cards: [], selecao: 5, display: true, disabled: false },
    { nome: 'Não Revisados', cards: [], count: 0, selecao: 6, display: true, disabled: false },
    { nome: 'Revisados', cards: [], count: 0, selecao: 8, display: true, disabled: false },
    { nome: 'Lixeira', cards: [], selecao: 'lixeira', display: true, disabled: false },
  ]);
  const [data, setData] = useState({ interesse: 100, att: 0, data: moment().subtract(1, 'month').format('yyyy-MM-DD'), fim: moment().format('yyyy-MM-DD'), empresa, user })
  const [form, setForm] = useState({});
  const [text, setText] = useState(false);
  const [step, setStep] = useState(0)
  const [search, setSearch] = useState('');
  const [stateData, setStateData] = useState(1)
  const [interesses, setIntesses] = useState([])
  const navList = ['Geral', 'Relatório']

  useEffect(() => {

    get(`/getUsers/${empresa}`, setUsers)
    if (user != localStorage.getItem('user')) { localStorage.setItem('user', JSON.stringify(user)) }

    get(`/buscarTema/${user}`, then)

    function then(resp2) {
      setTema(resp2.tema)
      localStorage.setItem('tema', resp2.tema)
    }

  }, [])

  useEffect(() => {
    if (step == 1) {
      get(`/getInteressesRelatorioIecb/${data.data}/${data.fim}`, setIntesses)
    }
  }, [data])
  function handleStateData(state) {

    if (state === 0) {
      setData({ ...data, data: '2020-01-01', fim: moment().format('yyyy-MM-DD') })
    } else {
      setData({ ...data, data: `${moment().format('yyyy-MM')}-01`, fim: moment().format('yyyy-MM-DD') })
    }
    setStateData(state)

  }

  function handleChange(e) {

    if (e.target.name === 'data' && data.fim < e.target.value) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else if (e.target.name === 'fim' && e.target.value < data.data) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }

  }
  function dialog(text, type) {
    setText({ text: text, type: type })

  }


  function move(item, indexCards) {

    let project = { selecao: indexCards, telefone_l: item.telefone_l }
    update(`/updateSelecaoIecb`, project, setAtt)

  }

  function addCarteira(telefone_l, selecao) {
   
    get(`/verificaLeadIecb/${telefone_l}/${selecao}`, then)
    function then(resp) {
      if (resp) {
        let confirma = window.confirm('Deseja adicionar à sua carteira ?')
        confirma &&
          update(`/addCarteiraIecb`, { user, telefone_l }, setAtt)
      }else{
        let confirma = window.confirm('Deseja adicionar à sua carteira ?')
        confirma &&
          update(`/addCarteiraIecb`, { user, telefone_l }, setAtt)
      }
    }
  }
  function setAtt() {
    setData({ ...data, ['att']: Math.random() })
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 3
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '3px',
      borderRadius: '7px',
      transform: 'translate(-50%, -50%)',
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  function handleCloseModal() {
    setIsOpen(false)
    // setForm(selecao)
    setData({ ...data, ['att']: Math.random() })
  }

  function handleOpenModal(modal, card) {

    setForm(card)
    setCurrentModal(modal)
    setIsOpen(true)
  }
  const steps = [<Body />, <Relatorio/>]

  const buttons = [

    <input
      className='input'
      type='button'
      value='Data'
      onClick={() => handleStateData(1)}
    />,
    <input
      className='input'
      type='button'
      value='Voltar'
      onClick={() => handleStateData(0)}
    />
  ]
  const buttons2 = [
    <label> </label>
  ]

  const [currentModal, setCurrentModal] = useState(0)
  const modals = [
    <LeadsForm form={form} handleCloseModal={handleCloseModal} />,
    <Custom />
  ]

  function LeadsMenu() {

    return (
      <div id='leadsMenu'>
        <div id='buttonHover'></div>
        <div id='menuCategorias'>
          <h2>Categorias visíveis:</h2>
          {leadsList.map((item, index) =>
            <div key={index}>
              <span>{item.nome}</span>
              <button
                disabled={item.disabled}
                onClick={() =>
                  setLeadsList(produce(leadsList, draft => {
                    draft.find((element) => element.selecao == item.selecao).display =
                      draft.find((element) => element.selecao == item.selecao).display ? false : true
                  }))
                }
              > {item.display ? <GiCheckMark /> : ''} </button>
            </div>
          )}
        </div>
        <div id='menuCategorias' onClick={() => handleOpenModal(1, false)}>
          Tema
        </div>
      </div>
    )
  }



  return (
    <Context.Provider value={{ leadsList, addCarteira, tema, empresa, search, setSearch, step, setStep, setLeadsList, move, user, handleOpenModal, form, data, setData, dialog }}>
      <Dialog value={text} />
      <DndProvider backend={HTML5Backend}>

        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={customStyles}
        >
          {modals[currentModal]}
        </Modal>
        <LeadsMenu />
        <div id='leadsContainer'>
          <Header
            background={getTheme(localStorage.getItem('tema'), 0)}
            setStep={setStep}
            navList={navList}
            buttons={buttons2}
          ><>
              <button
                className='buttonMenu'
                type='button'
              >
                <IoIosMenu />
              </button>
              <InputSearch
                search={search}
                setSearch={setSearch}
              />

              {stateData === 1 &&
                <><InputDate
                  name='data'
                  value={data.data}
                  handleOnChange={handleChange}
                />
                  <InputDate
                    name='fim'
                    value={data.fim}
                    title='até'
                    handleOnChange={handleChange}
                  /></>}
              
              {step == 1  &&
                <Select
                width='200px'
                  padrao='Interesse'
                  options={interesses}
                  name='interesse'
                  value={data.interesse}
                  handleOnChange={handleChange}
                />

              }
            </>
          </Header>
          {steps[step]}
        </div>
      </DndProvider>
    </Context.Provider>
  )
}
