import { useState, useContext, useEffect, useRef } from 'react'
import { InputText, Button, InputTime, LabelText, LabelText3, InputDate, InputDateC, Select, InputFloat, Checkbox, RadioList } from '../../a_model/Input'
import './InputDay.scss'
import RBar from './RBar'
import moment from 'moment'
import { BsArrowReturnLeft, BsTrash } from 'react-icons/bs'
import { AiOutlineFileAdd, AiOutlineForm, AiOutlineCarryOut, AiOutlineLock, AiOutlineSwap } from 'react-icons/ai'
import copy from "copy-to-clipboard";
import { Form } from '../../a_model/Container'
import { get, insert, update, updateParams } from '../f_aux/fetch'
import Context from '../Context'
import { produce } from 'immer'
import { EditarCadastro } from './EditarCliente'
import { useConfirm, ConfirmDialog } from '../../a_model/Dialog';
import Modal from 'react-modal'
import { toDate } from '../../a_model/functions'


function InputDay({ setInput, dataCard }) {
    const { dialog, group, user, users, setIsEdit, categ, unidade } = useContext(Context)
    const [project, setProject] = useState({ ...dataCard[0], ['telefone']: '0', user_update: user })
    const [isCad, setIsCad] = useState(false)
    // const [isNiver, setIsNiver] = useState(0)
    // const [niverModal, setNiverModal] = useState(false)
    const { showConfirm, modalIsOpen, handleConfirm } = useConfirm();

    useEffect(() => {if (project.patients_idpatient) get(`/getCliente/${project.patients_idpatient}`, then1) }, [isCad])

    function then1(resp) {
        setProject({ ...project, ['title']: resp.nome_cliente, ['telefone']: resp.telefone })
    }
    function setName(e) {
        setProject({ ...project, ['patients_idpatient']: e.id, ['title']: e.nome_l, ['telefone']: e.telefone_l, ['end_time']: moment(`2020-01-01T${project.start_time}`).add(40, 'minutes').format('HH:mm') })
    }
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }
    async function insertEvent(project) {
        // const result = await showConfirm();
        // if (result) {
        //     project.servico = 1
        // }

        project.usuario = user
        project.categ = 1
        project.start_date = project.start_date.substr(0, 10)

        if (project.servico < 10) {
            insert(`/insertEvent`, project, then)
        } else {
            window.alert('Selecione o tipo de voucher')
        }
        function then(resp) {
            if (resp.agendado) {
                window.alert(`Cliente já agendandado às ${resp.agendado.start_time.substr(0, 5)} ${resp.agendado.start_date.substr(0, 10).split('-').reverse().join('/')}`)
                inputClose(false, 0)
            } else if (resp.exists) {
                window.alert(`Horário já reservado para ${resp.exists.title}`)
                inputClose(false, 0)
            } else {
                if (resp.interesse) {
                    window.alert('Nenhum interesse definido.')
                    setIsCad(true)

                } else {
                    if (resp.isCancel) {
                        let confirm = window.confirm(`Já existe um agendamento para esse cliente, deseja restaurá-lo aqui? id: ${resp.isCancel}`)

                        if (confirm) {
                            const att = {
                                categ: 1,
                                start_date: project.start_date,
                                end_date: project.start_date,
                                start_time: project.start_time,
                                end_time: project.end_time,
                                doctor_iddoctor: project.doctor_iddoctor,
                                empresa: project.empresa
                            }
                            update(`/editar/event/idevent/${resp.isCancel}`, att, inputClose('Restaurado', 0))
                        } else {
                            inputClose('Cancelado', 0)
                        }


                    } else {
                        inputClose('Cadastrado', 0)
                    }

                }

            }
        }

        ////console.log(project)
    }
    function bloquear(project) {
        project.usuario = user
        project.categ = 0
        project.start_date = project.start_date.substr(0, 10)
        project.patients_idpatient = 135
        function then(resp) {
            if (resp.agendado) {
                window.alert(`Cliente já agendandado às ${resp.agendado.start_time.substr(0, 5)} ${resp.agendado.start_date.substr(0, 10).split('-').reverse().join('/')}`)
                inputClose(false, 0)
            } else if (resp.exists) {
                window.alert(`Horário já reservado para ${resp.exists.title}`)
                inputClose(false, 0)
            } else {
                inputClose('Cadastrado', 0)
            }
        }
        insert(`/insertEvent`, project, then)
    }

    function updateEvent(project) {
        project.user = user
        project.start_date = project.start_date.substr(0, 10)
        function then(resp) {
            if (resp.exists) {
                window.alert(`Erro, Avise o suporte.`)
                inputClose(false, 0)
            } else {
                inputClose('Cadastrado', 0)
            }
        }
        update(`/updateEvent`, project, then)
    }

    function confirmar() {


        function then() {
            inputClose('Confirmado', 0)
        }
        update(`/confirmarEvent/${project.idevent}`, {}, then)
    }

    function cancelar(project) {
        project.start_date = project.start_date.substr(0, 10)
        project.categ = 11
        project.user = user
        function then(resp) {
            if (resp.exists) {
                window.alert(`Erro, Avise o suporte.`)
                inputClose(false, 0)
            } else {
                inputClose('Cadastrado', 0)
            }
        }
        update(`/updateEvent`, project, then)
    }

    function inputClose(text, type) {
        setInput(0, dataCard[0])
        text && dialog(text, type)
    }
    function encaixe() {

        setProject({
            ...project, ['idevent']: 0,
            ['patients_idpatient']: false,
            ['title']: '',
            ['telefone']: '',
            ['descr']: '',
            ['descr2']: '',
            ['servico']: 7,
            ['usuario']: user,
            ['start_time']: moment(`2020 01 01 ${dataCard[0].start_time}`).add(20, 'minutes').format('HH:mm'),
            ['end_time']: moment(`2020 01 01 ${dataCard[0].end_time}`).add(20, 'minutes').format('HH:mm')
        })

    }

    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
    }

    function transferir() {
        setInput(0, dataCard[0])
        setIsEdit(project)
    }

    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' },
        { id: 4, nome: 'Orion' },
        { id: 5, nome: 'Campinas' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 13, nome: 'Brasília' },
    ]

    function updateNiver(e) {
        update(`/editar/event/idevent/${project.idevent}`, { servico: e.target.value }, then)
        function then() {
        }

    }

    const statusList = [
        { id: '0', nome: 'Sem voucher', },
        { id: '1', nome: 'Aniversário', },
        { id: '2', nome: 'Voucher $200', },
        { id: '3', nome: 'Experiência Papel', },
        { id: '4', nome: 'Experiência Digital', },

    ]

    return (
        <Form>
            <ConfirmDialog modalIsOpen={modalIsOpen} handleConfirm={handleConfirm} message='Este Agendamento vem da campanha de aniversário?' />
            {isCad &&
                <EditarCadastro
                    setIsCad={setIsCad}
                    cadastro={project}
                />
            }
            

            <div className='inputForm'>
                <div className='inline2' style={{ width: '650px', alignItems: 'center' }}>
                    <LabelText
                        header='Data:'
                    >
                        { toDate(project.start_date) }
                    </LabelText>
                   
                    {project.idevent > 0 ?

                        (user.toLowerCase() == dataCard[0].usuario.toLowerCase() || group == 1 || group == 12) ?
                            <Select
                                options={users}
                                padrao={project.usuario}
                                name='usuario'
                                value={project.usuario}
                                handleOnChange={handleChange}
                            />
                            : <LabelText
                                header="Usuário:"

                            >
                                {project.usuario}
                            </LabelText> : ''}
                    {
                        project.categ == 1 || project.categ == 6 ?
                            <button style={{ fontSize: '16px' }} onClick={() => confirmar()}>
                                Confirmar
                            </button>
                            : <LabelText
                                header='Status'
                            >
                                {categ.find((element) => element.id == project.categ).nome}
                            </LabelText>

                    }

                </div>

                {project.patients_idpatient ?

                    <>
                        <LabelText3
                            header="Cliente:"
                            onClick={() => copy(project.title)}
                            onClick2={() => setIsCad(true)}
                        >
                            {project.title}
                        </LabelText3>
                        <LabelText3
                            header="Telefone:"
                            onClick={() => copy(project.telefone)}

                        >
                            {formatTel(project.telefone)}
                        </LabelText3>
                    </> :
                    <RBar setName={setName} />

                }



                <div className='inline1'>
                    <InputTime
                        disabled={true}
                        flex='column'
                        title='Início'
                        name='start_time'
                        value={project.start_time}
                        width='95px'
                        handleOnChange={(e) => handleChange(e)}
                    />
                    <InputTime
                        flex='column'
                        disabled={project.patients_idpatient ? true : false}
                        title='Término'
                        name='end_time'
                        value={project.end_time}
                        width='95px'
                        handleOnChange={(e) => handleChange(e)}
                    />

                </div>
                <InputText
                    disabled={false}
                    value={project.descr2}
                    handleOnChange={(e) => handleChange(e)}
                    name='descr2'

                    flex='column'
                    title='Anotação'
                />
                {project.idevent > 0 &&
                    <InputText
                        disabled={parseInt(group) < 3 || parseInt(project.categ) < 14 ? false : true}
                        value={project.descr}
                        handleOnChange={(e) => handleChange(e)}
                        name='descr'
                        flex='column'
                        title='Anotação Avaliadora'
                    />}



                {project.idevent > 0 ?
                    <div className='inline2'>
                        <LabelText
                            header={'Agendado'}
                        >
                            {toDate(project.data_agendado)}
                        </LabelText>

                        <LabelText
                            header={'ID'}
                        >
                            <div onClick={() => copy(project.idevent)}>
                                {project.idevent}
                            </div>
                        </LabelText>

                        <LabelText
                            header={'Cliente'}
                        >
                            <div onClick={() => copy(project.patients_idpatient)}>
                                {project.patients_idpatient}
                            </div>
                        </LabelText>
                        <button
                            onClick={() => copy(
                                `${project.title}, ficou agendada a sua avaliação na data ${project.start_date.substr(0, 10).split('-').reverse().join('/')} às ${project.start_time.substr(0, 5)} na agenda da ${project.namedoctor} na unidade ${unidades.find((element) => element.id == project.empresa).nome}.`

                            )}
                        >Texto</button>
                    </div>
                    : ''
                }
            </div>
            { project.patients_idpatient ?
                <RadioList
                    title="Voucher"
                    name='servico'
                    values={statusList}
                    handleOnChange={handleChange}
                    data={project.servico}
                    required={true}
                />
                : ''
            }
            <footer>
                {(
                    project.idevent > 0 ? (
                        <>
                            {project.next == 1 && project.empresa != 1 && 
                                (project.start_date == moment().format('YYYY-MM-DD') && project.start_time > moment().format('HH:mm') || project.start_date > moment().format('YYYY-MM-DD')) &&

                                <Button
                                    color="#2d4492"
                                    value='Encaixe'
                                    click={() => encaixe()}
                                >
                                    <AiOutlineFileAdd />
                                </Button>
                            }
                            <Button
                                color="#447461"
                                value='Salvar'
                                click={() => updateEvent(project)}
                            >
                                <AiOutlineForm />
                            </Button>
                            {(parseInt(project.categ) < 4) && <Button
                                color="#8f2828"
                                value='Cancelar'
                                click={() => cancelar(project)}
                            >
                                <BsTrash />
                            </Button>}
                            {project.idevent > 0 && parseInt(project.categ) < 4 &&
                                <Button
                                    color="#6c388f"
                                    value='Transferir'
                                    click={() => transferir()}
                                >
                                    <AiOutlineSwap />
                                </Button>}
                        </>
                    ) : (
                        project.patients_idpatient ? (
                            <Button
                                color="#447461"
                                value='Agendar'
                                click={() => insertEvent(project)}
                            >
                                <AiOutlineCarryOut />
                            </Button>
                        ) : (
                            <Button
                                color="#6c388f"
                                value='Bloquear'
                                click={() => bloquear(project)}
                            >
                                <AiOutlineLock />
                            </Button>
                        )
                    )
                )}
                <Button
                    color="#474747"
                    value='Voltar'
                    click={() => setInput(0, dataCard[0])}
                >
                    <BsArrowReturnLeft />
                </Button>



                {/* Adicione outras condições e botões de maneira semelhante... */}

            </footer>
        </Form>
    )
} export default InputDay

