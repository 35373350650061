import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import './DiarioForm.scss'
import { Card } from '../../a_model/Container'
import {  Table, Th } from '../../a_model/Contents'
import { getUnidade, toCash, toDate } from '../../a_model/functions'
import Context from "../Context";
import copy from "copy-to-clipboard"
import { get } from "../f_aux/fetch";

export default function RelatorioDiario() {
  const [paramsRel, setParamsRel] = useState({
    has_venda_s: 0,
    servico: 0
  })


  const [step, setStep] = useState(0)
  const steps = [
    <FirstStep setStep={setStep} setParamsRel={setParamsRel} />,
    <SecondStep setStep={setStep} paramsRel={paramsRel} />

  ]
  return (

    <div>
      {steps[step]}

    </div>
  )
}

function FirstStep({ setStep, setParamsRel }) {
  const { statusServico, params, empresa } = useContext(Context)
  const [relatorio, setRelatorio] = useState([])
  const [total, setTotal] = useState({ soma_bonus: 0, bonus: 0, soma_vendido: 0, vendido: 0, })
  useEffect(() => {
    get(`/getRelatorioVoucher/${empresa}/${params.data}/${params.data_fim}/${params.doctor}/${params.unidade}/${params.stateData}`, setRelatorio)
  }, [params])
  useMemo(() => {
    let soma_bonus = 0.0
    let soma_vendido = 0.0
    let bonus = 0
    let vendido = 0
    for (let i in relatorio) {
      soma_bonus += parseFloat(relatorio[i].soma_bonus) || 0
      soma_vendido += parseFloat(relatorio[i].soma_vendido) || 0
      bonus += parseInt(relatorio[i].bonus)
      vendido += parseInt(relatorio[i].vendido)

    }
    setTotal({ soma_bonus, bonus, soma_vendido, vendido, })
  }, [relatorio])
  const titles = [
    { title: 'status' },
    { title: 'Bônus' },
    { title: 'qnt' },
    { title: 'Venda' },
    { title: 'qnt' },
  ]
  function toSecond(value) {
    setStep(1)
    setParamsRel(value)
  }

  return (
    <Table>
      <Th array={titles} />
      {relatorio.map((item, index) =>

        <tr key={index}   >

          <td>
            {statusServico.find((element) => element.id == item.servico)?.nome}
          </td>
          <td>
            {toCash(item.soma_bonus)}
          </td>
          <td style={{ fontWeight: 600 }} className='hover' onClick={() => toSecond({ has_venda_s: 0, servico: item.servico, isVendido: false })}>
            {item.bonus}
          </td>

          <td>
            {toCash(item.soma_vendido)}
          </td>
          <td style={{ fontWeight: 600 }} className='hover' onClick={() => toSecond({ has_venda_s: 1, servico: item.servico, isVendido: true })}>
            {item.vendido}
          </td>

        </tr>


      )}
      <tr style={{ fontWeight: "500" }}>
        <td style={{ color: "#2956eb" }}>
          Total
        </td>
        <td style={{ color: "#2956eb" }}>
          {toCash(total.soma_bonus)}
        </td>
        <td style={{ color: "#2956eb" }}>
          {total.bonus}
        </td>

        <td style={{ color: "#2956eb" }}>
          {toCash(total.soma_vendido)}
        </td>
        <td style={{ color: "#2956eb" }}>
          {total.vendido}
        </td>
      </tr>
    </Table>
  )
}

function SecondStep({ setStep, paramsRel }) {
  const { statusServico, params, empresa, categ } = useContext(Context)
  const [relatorio, setRelatorio] = useState([])
  const [cliente, setCliente] = useState(false)

  useEffect(() => {
   paramsRel.isVendido?
    get(`/getRelatorioVoucher3/${empresa}/${params.data}/${params.data_fim}/${params.doctor}/${params.unidade}/${params.stateData}/${paramsRel.servico}/${paramsRel.has_venda_s}}`, setRelatorio)
   : get(`/getRelatorioVoucher2/${empresa}/${params.data}/${params.data_fim}/${params.doctor}/${params.unidade}/${params.stateData}/${paramsRel.servico}/${paramsRel.has_venda_s}}`, setRelatorio)
 
  }, [params])
  const titles = [
    { title: 'Data' },
    { title: 'Cliente' },
    { title: 'Valor' },


  ]

  useMemo(() => {
    let soma_bonus = 0.0
    let soma_vendido = 0.0
    for (let i in relatorio) {
      soma_bonus += parseFloat(relatorio[i].soma_bonus)
      soma_vendido += parseFloat(relatorio[i].soma_vendido)
    }
    //total = {}
  }, [])


  return (
    <div style={{ display: 'flex', width: '100vw', justifyContent:'space-around' }}>
      <div>
        <button onClick={() => setStep(0)}>
          Voltar
        </button>
        <Table
          footer={`Total: ${parseFloat(relatorio.length)}`}
        >
          <Th array={titles} />
          {relatorio.map((item, index) =>
            <tr key={index} className="hover" >
              <td>
                {toDate(item.start_date)}
              </td>
              <td onClick={() => setCliente(item)}>
                {item.title}
              </td>
              <td onClick={() => copy(item.patients_idpatient)}>
                {toCash(item.soma)}
              </td>
              
            </tr>

          )}
        </Table>
      </div>
      {cliente ?
        <VendaForm cliente={cliente} />
        :
        <div style={{ width: '700px' }}></div>

      }
    </div>
  )
}

function VendaForm({ cliente }) {
  const [vendaSub, setVendaSub] = useState({})

  useEffect(() => {
    get(`/buscarVendasSubData/${cliente.patients_idpatient}/${cliente.start_date.substr(0,10)}`, then)
    function then(resp2) {
      // setVendaSub(resp)
console.log(resp2)
      get(`/get/vendas_servico/id/${cliente.id_vendas}`, then2)
      function then2(resp) {
        setVendaSub({ ...resp2, ['avaliadora']: resp[0].avaliadora, ['caixa']: resp[0].caixa, ['unidade']: resp[0].unidade, })
      }
    }

  }, [cliente])

  const vendido = [
    { color: '#777' },
    { fontWeight: '500', color: '#169c3e' }
  ]
  const titles2 = [
    { width: '', title: 'Pagamento' },
    { width: '', title: 'Valor' },
  ]
  const titles5 = [
    { width: '', title: 'Procedimento' },
    { width: '', title: 'Valor' },
    { width: '', title: 'Bônus' },
    { width: '', title: 'os' },
  ]
  function qnt(value) {
    return value > 20 || value < 2 ? 'À Vista' : value + 'X'
  }
  return (
    <Card
    width='700px'
    >
      <header>
        <div>
          <div style={{ display: 'flex' }}>
            <label>Cliente:<span> {cliente.title}</span></label>
            <label>Código:<span> {cliente.id_vendas}</span></label>
          </div>
          <div style={{ display: 'flex' }}>
            <label>Unidade:<span> {getUnidade(vendaSub.unidade)}</span></label>
            <label>Caixa:<span> {vendaSub.caixa}</span></label>
            <label>Avaliadora:<span> {vendaSub.avaliadora}</span></label>
          </div>
        </div>
      </header>
      <div>
        <Table
          height='300px'

        >
          <Th array={titles5} />
          {vendaSub.vendasSub?.map((vendaSub, index) =>
            <tr key={index} >
              <td style={vendido[vendaSub.venda.substr(1, 1) == "i" ? 1 : 0]}>
                {vendaSub.nome_procedimento}
              </td>

              <td style={vendido[vendaSub.venda.substr(1, 1) == "i" ? 1 : 0]}>
                {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </td>
              <td>
              {vendaSub.bonificado}
              </td>
              <td>
              {vendaSub.id_vendas}
              </td>


            </tr>)}
        </Table>
        <Table>
          <Th array={titles2} />
          {vendaSub.pg && vendaSub.pg.map((pg, index) =>
            <tr>
              <td>
                {pg.nome_forma} ({qnt(pg.itens_qnt)})
              </td>
              <td>
                {parseFloat(pg.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </td>
            </tr>
          )}
        </Table>
      </div>
    </Card>
  )
}