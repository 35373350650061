import { useEffect, useState } from "react";
import 'moment/locale/pt-br'
import "./AtendForm.scss";
import { Button, } from "../../a_model/Input";
import { get, update, updateParams } from "../f_aux/fetch";

import { Escala } from "../c_layouts/Contents";

export function AtendForm(props) {

  const [project, setProject] = useState([])

  useEffect(() => {
    if (props.atend) {
      get(`/getModelDoctor/${props.atend}`, setProject)
    }
  }, [props.atend, props.project])


  function alterarAtendente() {
    update(`/updateModelDoctor/${props.atend}`, project, then)
    function then() {
      window.alert("Agenda alterada!")
    }
  }

  return (
    props.atend ?
      <div className="atend">
        <Escala project={project} setProject={setProject} />

        <div className="headerAtend">

          <Button
            click={() => alterarAtendente()}
            value='Salvar'
            color='#174fb6'
          />
      
        </div>
        
      </div>
      : 
      <div className="atend">
      <span>Selecione uma Avaliadora...</span>
      </div>
  );
}
