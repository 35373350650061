import React, { useMemo, useContext, useEffect, useState } from "react";
import { Card, CardAgendado } from "./Card";
import { CardEmpty, CardEmptyAgendado } from "./CardEmpty";
import Context from "../../Context";
import '../components.css'
import { get } from "../../../calendar/f_aux/fetch";
import { SelectHeader } from "../../../a_model/Input";
import './Cards.scss'
import { getTheme } from "../../../a_model/Theme";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";
import gif from '../../../img/carregando1.gif';

export function Cards({ cardsRef }) {
    const { search, data, empresa, leads, form } = useContext(Context)
    const [cards, setCards] = useState(cardsRef);
    const [params, setParams] = useState({ status: cardsRef.status, page: 0, att: 0 })
    const [loading, setLoading] = useState(gif);
    const [count, setCount] = useState(0)
    const statusList = [
        { nome: 'Retornar Ligação', cards: [], status: 10, display: true, disabled: false },
        { nome: 'Sem interesse', cards: [], status: 5, display: true, disabled: false },
        { nome: 'Sem Retorno', cards: [], status: 'nao', display: true, disabled: false },
        { nome: 'Agendado', cards: [], status: 2, display: true, disabled: false },
        { nome: 'Lixeira', cards: [], status: 9, display: true, disabled: false },
        { nome: 'Outros', cards: [], status: 11, display: true, disabled: false },

    ]
    useEffect(() => {

        get(`/getLeadCount/${data.data}/${data.fim}/${data.user}/${params.status}/${empresa}/${data.ativo}`, setCount)

    }, [data])
    useEffect(() => {

        setLoading(gif)
        get(`/getLead/${data.data}/${data.fim}/${data.user}/${params.status}/${0}/${empresa}/${data.ativo}`, then)
        setParams({ ...params, ['page']: 0 })
    }, [data, params.status])

    useEffect(() => {
        if (form.status == params.status) {
            setLoading(gif)
            get(`/getLead/${data.data}/${data.fim}/${data.user}/${params.status}/${params.page}/${empresa}/${data.ativo}`, then)
        }
    }, [form])


    function setPage(x) {
        setLoading(gif)
        setParams({ ...params, ['page']: params.page + x })
        get(`/getLead/${data.data}/${data.fim}/${data.user}/${params.status}/${params.page + x}/${empresa}/${data.ativo}`, then)
    }

    function then(resp) {
        setCards(resp)
        setLoading(false)
        get(`/getLeadCount/${data.data}/${data.fim}/${data.user}/${params.status}/${empresa}/${data.ativo}`, setCount)


    }
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return cards.cards.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca)) : '');
    }, [search, cards])
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value })

    }
    function att() {
        setLoading(gif)
        get(`/getLead/${data.data}/${data.fim}/${data.user}/${params.status}/${params.page}/${empresa}/${data.ativo}`, then)

    }
    return (
        <div className='cardsContainer' style={{ width: 99 / (leads.filter(item => item.display === true).length) + 'vw' }} key={cardsRef.nome}>
            <header>
                {cardsRef.disabled ?
                    <h2>{cards.nome}</h2> :
                    <SelectHeader
                        disabled={cardsRef.disabled}
                        color={getTheme(localStorage.getItem('tema'), 2)}
                        value={cards.status}
                        name='status'
                        options={statusList}
                        handleOnChange={handleChange}
                    />}
            </header>
            <ul>
                {loading ? <img src={loading} alt="Carregando..." /> :
                    Array.isArray(cards.cards) && results.length > 0 ?
                        results.map((card, index) =>
                            <Card att={att} status={cards.status} card={card} index={index} indexCards={cards.status} key={index} />
                        )
                        :
                        <CardEmpty att={att} status={cards.status} index={0} indexCards={cards.status} />
                }
            </ul>
            <footer>
                <button disabled={params.page > 0 ? false : true} onClick={() => setPage(-1)}>
                    <MdOutlineArrowLeft color={params.page > 0 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
                <span>{params.page * 30 + 1} - {params.page * 30 + cards.cards.length}</span>
                <button disabled={cards.cards.length > 29 ? false : true} onClick={() => setPage(1)}>
                    <MdOutlineArrowRight color={cards.cards.length > 29 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
                <div style={{ color: '#777' }}> {` [${count.toLocaleString('pt-BR')}]`} </div>
            </footer>

        </div>
    )
}

export function CardsAgendados({ cardsRef }) {
    const { search, data, empresa, agendados, form } = useContext(Context)
    const [cards, setCards] = useState(cardsRef);
    const [params, setParams] = useState({ status_naoveio: cardsRef.status_naoveio, page: 0, att: 0 })
    const [loading, setLoading] = useState(gif);

    useEffect(() => {

        //  get(`/getLeadAgendadoCount/${data.data}/${data.fim}/${data.user}/${params.status_naoveio}/${empresa}`, setCount)

    }, [data])
    useEffect(() => {

        setLoading(gif)
        get(`/getNaoVieram/${data.data}/${data.fim}/${data.user}/${params.status_naoveio}/${empresa}`, then)
        setParams({ ...params, ['page']: 0 })
    }, [data, params.status_naoveio])

    useEffect(() => {
        if (form.status == params.status_naoveio) {
            setLoading(gif)
            get(`/getNaoVieram/${data.data}/${data.fim}/${data.user}/${params.status_naoveio}/${empresa}`, then)
        }
    }, [form])


    function setPage(x) {
        setLoading(gif)
        setParams({ ...params, ['page']: params.page + x })
        get(`/getAgendados/${data.data}/${data.fim}/${data.user}/${params.status_naoveio}/${params.page + x}/${empresa}`, then)
    }

    function then(resp) {
        setCards(resp)
        setLoading(false)
        
        // get(`/getLeadAgendadoCount/${data.data}/${data.fim}/${data.user}/${params.status_naoveio}/${empresa}`, setCount)


    }
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return cards.cards ? cards.cards.filter(user => user.nome_cliente ? (user.nome_cliente.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca)) : '') : [];
    }, [search, cards])
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value })

    }
    function att() {
        setLoading(gif)
        get(`/getNaoVieram/${data.data}/${data.fim}/${data.user}/${params.status_naoveio}/${empresa}`, then)

    }
    return (
        cards.cards &&
        <div className='cardsContainer' style={{ width: 99 / (agendados.filter(item => item.display === true).length) + 'vw' }} key={cardsRef.nome}>
             <header>

                <h2>{cards.nome}</h2>
            </header>
            <ul>
                {loading ? <img src={loading} alt="Carregando..." /> :
                    Array.isArray(cards.cards) && results.length > 0 ?
                        results.map((card, index) =>
                            <CardAgendado att={att}
                                status_naoveio={cards.status_naoveio}
                                disabled={cards.disabled}
                                card={card}
                                index={index}
                                indexCards={cards.status_naoveio}
                                key={index}
                            />
                        )
                        :
                        <CardEmptyAgendado att={att} status_naoveio={cards.status_naoveio} index={0} indexCards={cards.status_naoveio} />
                }
            </ul>
            
                <footer>
                
                <div style={{ color: '#777' }}> {` [${cards.cards.length}]`} </div>
            </footer>
            
        </div>
    )
}
/**
 *  <button disabled={params.page > 0 ? false : true} onClick={() => setPage(-1)}>
                    <MdOutlineArrowLeft color={params.page > 0 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
                    <span>{params.page * 30 + 1} - {params.page * 30 + cards.cards.length}</span>
             
                <button disabled={cards.cards.length > 29 ? false : true} onClick={() => setPage(1)}>
                    <MdOutlineArrowRight color={cards.cards.length > 29 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
              
 */