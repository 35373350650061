import React from "react";
import { useState, useEffect } from "react";
import { GiConfirmed } from 'react-icons/gi'
import styled from 'styled-components'

export function Dialog({ value }) {
    const types = [
        <GiConfirmed />
    ]
    const [display, setDisplay] = useState('none')
    useEffect(() => {

        if(value){
            setDisplay('block')
            setTimeout(function() {
                setDisplay('none')
            }, 2000);
            
        }

         
    }, [value])
    return (
        <Container onClick={()=>setDisplay('none')}style={{ display: display }} className="dialog">
            {value.text}{types[value.type]}
        </Container>
    )
}

const Container = styled.div`
  
    position: absolute;
    background-color: #eceaea;
    right: 15vw;
    top: 2vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0;
    border-radius: 5px;
    border: 1px solid #a1a1a1;
    padding: 10px;
    transition: all ease 1.5s;
    z-index: 5;
    
    svg{
        transition: all ease 1.5s;
        fill:#1d9748;
        margin: 3px 0 -5px 3px;
        font-size: 1.5em;
      }
  
    
`

