
export const horariosProc = [
    { hora: '08:00', hora_fim: '08:40' },
    { hora: '08:40', hora_fim: '09:20' },
    { hora: '09:20', hora_fim: '10:00' },
    { hora: '10:00', hora_fim: '10:40' },
    { hora: '10:40', hora_fim: '11:20' },
    { hora: '11:20', hora_fim: '12:00' },
    { hora: '12:00', hora_fim: '12:40' },
    { hora: '12:40', hora_fim: '13:20' },
    { hora: '13:20', hora_fim: '14:00' },
    { hora: '14:00', hora_fim: '14:40' },
    { hora: '14:40', hora_fim: '15:20' },
    { hora: '15:20', hora_fim: '16:00' },
    { hora: '16:00', hora_fim: '16:40' },
    { hora: '16:40', hora_fim: '17:20' },
    { hora: '17:20', hora_fim: '18:00' },
    { hora: '18:00', hora_fim: '18:40' },
    { hora: '18:40', hora_fim: '19:20' },
    { hora: '19:20', hora_fim: '20:00' },
    { hora: '20:00', hora_fim: '20:40' },
    { hora: '20:40', hora_fim: '21:20' },
    { hora: '21:20', hora_fim: '22:00' },
  ]