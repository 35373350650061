import React, { useMemo, useContext, useEffect, useState } from "react";
import {CarteiraCard} from "./Card";
import CardEmpty from "./CardEmpty";
import Context from "../../Context";
import '../components.css'
import { get } from "../../../calendar/f_aux/fetch";
import { SelectHeader } from "../../../a_model/Input";
import './Cards.scss'
import { getTheme } from "../../../a_model/Theme";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";
import gif from '../../../img/carregando1.gif';
import moment from "moment";

export default function Carteira() {
    const { data, leadsList, form, user } = useContext(Context)
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(gif);
   
    useEffect(() => {  

        setLoading(gif)
        get(`/getCarteiraIecb/${user}`, then)
    }, [data])

    function then(resp) {
        setCards(resp)
        setLoading(false)

    }

    function att() {
        setLoading(gif)
        get(`/getCarteiraIecb/${user}`, then)
      
    }
    return (
        <div className='cardsContainer' style={{ width: 99 / (leadsList.filter(item => item.display === true).length + 1) + 'vw' }} >
            <header>
                    <h2>Carteira</h2> 
            </header>
            <ul>
                {loading ? <img src={loading} alt="Carregando..." /> :
                    cards.length > 0 ?
                        cards.map((card, index) =>
                            <CarteiraCard att={att} selecao={0} card={card} index={index} indexCards={0} key={index} />
                        )
                        :
                        <CardEmpty att={att} selecao={0} index={0} indexCards={0} />
                }
            </ul>
            <footer>
                <div style={{color:'#777'}}> {` [${cards.length}]`} </div>
            </footer>
        
        </div>
    )
}