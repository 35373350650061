import React, { useMemo, useContext, useEffect, useState } from "react";
import { Card } from "./Card";
import CardEmpty from "./CardEmpty";
import Context from "../../Context";
import '../components.css'
import { get } from "../../../calendar/f_aux/fetch";
import { SelectHeader2 } from "../../../a_model/Input";
import './Cards.scss'
import { getTheme } from "../../../a_model/Theme";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";
import gif from '../../../img/carregando1.gif';

export default function Cards({ cardsRef }) {
    const { search, data, empresa, leadsList, form } = useContext(Context)
    const [cards, setCards] = useState(cardsRef);
    const [params, setParams] = useState({ selecao: cardsRef.selecao, page: 0, att: 0 })
    const [loading, setLoading] = useState(gif);
    const [count, setCount] = useState(0)
    const [statusList, setStatusList] = useState([])
    useEffect(() => {
        let tempList = []
        for (let i in leadsList) {
            if (leadsList[i].display) {
                tempList.push(leadsList[i])
            }
        }
        setStatusList(tempList)
    }, [])
    useEffect(() => {

        get(`/getLeadCount/${data.data}/${data.fim}/${data.user}/${params.selecao}/${empresa}`, setCount)

    }, [data])
    useEffect(() => {

        setLoading(gif)
        get(`/getLeadsIecb/${data.data}/${data.fim}/${params.selecao}/data_cadastro_l/desc/0`, then)
        setParams({ ...params, ['page']: 0 })

    }, [data, params.selecao])

    function setPage(x) {
        setLoading(gif)
        setParams({ ...params, ['page']: params.page + x })
        get(`/getLeadsIecb/${data.data}/${data.fim}/${params.selecao}/data_cadastro_l/desc/${params.page + x}`, then)


    }

    function then(resp) {
        setCards(resp)
        setLoading(false)


    }
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return cards.cards.filter(user => user.nome_l ? (user.nome_l.toLowerCase().includes(lowerBusca) || user.telefone_l.includes(lowerBusca)) : '');
    }, [search, cards])
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value })

    }

    return (
        <div className='cardsContainer' style={{ width: 99 / (leadsList.filter(item => item.display === true).length + 1) + 'vw' }} key={cardsRef.nome}>
            <header>
                {cardsRef.disabled ?
                    <h2>{cards.nome}</h2> :
                    <SelectHeader2
                        disabled={cardsRef.disabled}
                        color={getTheme(localStorage.getItem('tema'), 2)}
                        value={cards.selecao}
                        name='selecao'
                        options={statusList}
                        handleOnChange={handleChange}
                    />}
            </header>
            <ul>
                {loading ? <img src={loading} alt="Carregando..." /> :
                    Array.isArray(cards.cards) && results.length > 0 ?
                        results.map((card, index) =>
                            <Card selecao={cards.selecao} card={card} index={index} indexCards={cards.selecao} key={index} />
                        )
                        :
                        <CardEmpty selecao={cards.selecao} index={0} indexCards={cards.selecao} />
                }
            </ul>
            <footer>
                <button disabled={params.page > 0 ? false : true} onClick={() => setPage(-1)}>
                    <MdOutlineArrowLeft color={params.page > 0 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
                <span>{params.page * 30 + 1} - {params.page * 30 + cards.cards.length}</span>
                <button disabled={cards.cards.length > 29 ? false : true} onClick={() => setPage(1)}>
                    <MdOutlineArrowRight color={cards.cards.length > 29 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
                <div style={{ color: '#777' }}> {` [${cards.count && cards.count.toLocaleString('pt-BR')}]`} </div>
            </footer>

        </div>
    )
}