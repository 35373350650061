import { useEffect, useState, useContext } from "react";
import 'moment/locale/pt-br'
import "./DayForm.css";
import Day from "../c_layouts/Day";
import Context from "../Context";
import { deletar, get } from "../f_aux/fetch";
import InputDay from "../c_layouts/InputDay";
import Modal from 'react-modal'
import { getTheme } from '../../a_model/Theme'
import { RiRefreshLine } from 'react-icons/ri'
import { FaCalendarAlt } from 'react-icons/fa'

import moment from 'moment'
import DayModal from "./DayModal";

export default function DayForm() {
  const { currentFormat, setCurrentFormat, group, empresa, mudarDayForm,count, currentDay, refresh, setRefresh, agenda, setBalao, user, unidade } = useContext(Context)
  const [dataCard, setDataCard] = useState([{ atendente: '' }])
  const [modalIsOpen, setIsOpen] = useState(false)
  const hoje = moment()

  let horarios = [
    { hora: '08:00', hora_fim: '08:40' },
    { hora: '08:40', hora_fim: '09:20' },
    { hora: '09:20', hora_fim: '10:00' },
    { hora: '10:00', hora_fim: '10:40' },
    { hora: '10:40', hora_fim: '11:20' },
    { hora: '11:20', hora_fim: '12:00' },
    { hora: '12:00', hora_fim: '12:40' },
    { hora: '12:40', hora_fim: '13:20' },
    { hora: '13:20', hora_fim: '14:00' },
    { hora: '14:00', hora_fim: '14:40' },
    { hora: '14:40', hora_fim: '15:20' },
    { hora: '15:20', hora_fim: '16:00' },
    { hora: '16:00', hora_fim: '16:40' },
    { hora: '16:40', hora_fim: '17:20' },
    { hora: '17:20', hora_fim: '18:00' },
    { hora: '18:00', hora_fim: '18:40' },
    { hora: '18:40', hora_fim: '19:20' },
    { hora: '19:20', hora_fim: '20:00' },
    { hora: '20:00', hora_fim: '20:40' },
    { hora: '20:40', hora_fim: '21:20' },
    { hora: '21:20', hora_fim: '22:00' },
  ]

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 5
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '1px',
      transform: 'translate(-50%, -50%)',
      background: '#deeafa',
      padding: '5px',
      border: '10px solid #deeafa'
    }
  }


  function setInput(page, dataCard, next) {

    setDataCard([dataCard, next])
    page == 0 ? setIsOpen(false)
      : setIsOpen(true)
    setRefresh(Math.random())
  }

  return (
    agenda.length > 0 &&

    <>
      <Modal
        onRequestClose={() => setIsOpen(false)}
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={customStyles}
      >
        <DayModal
          setInput={setInput}
          dataCard={dataCard}
        />
      </Modal>

      <div className="content" style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>

        <div id="inlineAtend" >
          {currentFormat == 0 || group == 2 ?
          <div id='iconHours' onClick={() => window.location.replace(`/event/${empresa}/${user}/${group}/${unidade}`)}>
            <RiRefreshLine color={getTheme(localStorage.getItem('tema'), 0)} />
          </div>
          :
          <div id='iconHours' onClick={() => setCurrentFormat(false)}>
          <FaCalendarAlt color={getTheme(localStorage.getItem('tema'), 0)} />
        </div>
          
          }

          {
            agenda[0].unidade?
          
          agenda.map((doctor, index) => (
            <div onClick={() => setCurrentFormat(doctor.iddoctor)} className='doctor' key={index}
              style={{ background: doctor.unidade == 3? getTheme(localStorage.getItem('tema'), 0) : getTheme(localStorage.getItem('tema'), 1), width: `calc( ( 100vw - 300px ) / ${count} )` }}
            >
              <span style={{
                color: doctor.unidade == 3? getTheme(localStorage.getItem('tema'), 1) : getTheme(localStorage.getItem('tema'), 0),
                borderLeft: '1px solid ' + (doctor.unidade == 3? getTheme(localStorage.getItem('tema'), 1) : getTheme(localStorage.getItem('tema'), 0))
              }}>{doctor.namedoctor && doctor.namedoctor}</span>
            </div>
          )) 
          :
          agenda.map((doctor, index) => (
            <div onClick={() => mudarDayForm(doctor.iddoctor)} className='doctor' key={index}
              style={{ background: getTheme(localStorage.getItem('tema'), 1), width: `calc( ( 100vw - 300px ) / ${count} )`, opacity: hoje > moment(doctor.data+ ' 23:59:59') ? 0.5 : 1 }}
            >
              <span style={{
                fontWeight: doctor.week == currentDay.format('ddd')? 600:500,
                fontSize: doctor.week == currentDay.format('ddd')? '17px':'15px',
                textDecoration: hoje.format('YYYY-MM-DD') == doctor.data? 'underline' : '',
                color: getTheme(localStorage.getItem('tema'), 0),
                borderLeft: '1px solid ' + (getTheme(localStorage.getItem('tema'), 0))
              }}>{doctor.week && doctor.title}</span>
            </div>
          ))
          
          }
          

        </div>

        <div className="inlineHours" >

          <div className="horas">
            {
              horarios.map((hora, index) => (
                <div className="horaE" key={index}>
                  <span style={{ color: getTheme(localStorage.getItem('tema'), 0) }}>
                    {hora.hora}</span>
                </div>
              ))
            }
          </div>

          {agenda && agenda.map((doctor, index) => (
            <div className="doctorColumn" style={{ width: `calc( (100vw - 300px) / ${count} )` }}>
              <DayCard
                setBalao={setBalao}
                setInput={setInput}
                horarios={horarios}
                cards={doctor.agenda}
                indexList={index}
              />
            </div>
          ))}
        </div>
      </div>
    </>

  );
}

function DayCard(props) {

  return (

    props.cards && props.cards.map((hora, index) => (
      <Day
        setInput={props.setInput}
        horario={props.horarios[index]}
        index={index}
        hora={hora.hora}
        card={hora}
        indexList={props.indexList}
      />
    ))


  );
}
