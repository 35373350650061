import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export function createPDFiecb(data, result) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;



    const title = [
        {
            table: {
                widths: ['100%'],
                body: [
                    [
                        {
                            text: `Fechamento por período: ${data.data.substr(0, 10).split('-').reverse().join('/')} à ${data.fim.substr(0, 10).split('-').reverse().join('/')}
                             \n${data.caixa == 100 ? '-' : `Caixa: ${data.caixa}`}`,
                             fontSize: 15,
                            bold: true,
                            margin: [5, 5, 20, 5],
                            lineHeight: 0.5
                        }
                    ]
                ]
            }
        }
    ];

    const pagamentos = result.vendas.map((pagamento) => {
        const itens = pagamento.itens.map((item) => {
            return [
                {
                    text: `${item.count}`,
                    italics: true,
                    fontSize: 11,
                    margin: [0, 2, 0, 2]
                },
                {
                    text: `${item.nome_forma.substr(0, 14)}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2]
                },
                {
                    text: `${parseFloat(item.sumValor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
                {
                    text: `${parseInt(item.qnt) > 1 && parseInt(item.qnt) < 20 ? item.qnt + 'x' : 'À Vista'}`,
                    italics: true,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                }
            ];
        });

        const totalRow = [
            {
                text: 'Total',
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],
                colSpan: 2,
                alignment: 'right',
                noWrap: true
            },
            {},
            {
                text: `${parseFloat(pagamento.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],
                noWrap: true
            },
            {
                text: '',
                border: [false, false, false, false],
                noWrap: true
            }
        ];

        return [
            {
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: `${pagamento.nome}`,
                                fontSize: 16,
                                margin: [2, 2, 2, 2]
                            }
                        ]
                    ]
                },
                layout: 'noBorders',
                margin: [2, 2, 2, 2]
            },
            {
                table: {
                    headerRows: 1,
                    widths: [25, 100, 70, 60],
                    body: [
                        [
                            { border: [true, true, true, true], text: 'Qnt', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Método', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Valor', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Parcelas', style: 'tableHeader', fontSize: 13, bold: true }
                        ],
                        ...itens,
                        totalRow
                    ]
                },
                layout: 'lightHorizontalLines'
            }
        ];
    });

    const totalGeral = {
        table: {
            widths: ['auto', 'auto'],
            body: [
                [
                    {
                        text: 'Total Geral',
                        bold: true,
                        fontSize: 15,
                        alignment: 'left',
                        margin: [0, 5, 0, 5],
                        decoration: 'underline',
                    },
                    {
                        text: `${parseFloat(result.totalVendas).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
                        bold: true,
                        fontSize: 15,
                        margin: [0, 5, 0, 5],
                        decoration: 'underline',
                        noWrap: true
                    }
                ]
            ]
        },
        layout: 'noBorders',
        margin: [0, 10, 0, 0]
    };

    const pdfContent = [
        ...pagamentos.flat(),
        totalGeral,
        // Remover o texto vazio e a quebra de página aqui
    ];



    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 15, 15, 15],
        content: [
            title,
            ...pdfContent
        ],
        layout: 'lightHorizontalLines',
    };

    pdfMake.createPdf(docDefinitions).open();
}
