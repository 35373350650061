import React, {useContext} from "react";
import Cards from "./contents/Cards";
import Context from '../Context';
import { getTheme } from "../../a_model/Theme";
import Carteira from "./contents/Carteira";


export default function Body() {

    const { leadsList } = useContext(Context)

    return (
        <body style={{background: getTheme(localStorage.getItem('tema'), 1)}}>
         <Carteira/>
            {Array.isArray(leadsList) &&  
            leadsList.map((cards, indexCards) =>
                cards.display &&
                <Cards 
                    cardsRef={cards} 
                    indexCards={indexCards}
                    key={indexCards}
                />

            )}
        </body>
    )
}