import { useContext, useEffect, useMemo, useState } from "react"
import { SearchList, Table, Th } from "../../a_model/Contents"
import { toCash, toDate } from "../../a_model/functions"
import { get } from "../../calendar/f_aux/fetch"
import Context from "../../a_model/context"
import { Caixa, Card } from "../../a_model/Container"
import { Modal } from "../../a_model/Modal"

export default function IndicacaoGrid() {
    const { params } = useContext(Context)
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
    const [step, setStep] = useState(0)
    const [card, setCard] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    useEffect(() => {
        get(`/getIndicacoes/${params.data}/${params.data_fim}/${params.atendente}/${params.fechamento}/${params.unidade}`, setList)
    }, [params])

    const titles = [
        { title: 'Data', width: '' },
        { title: 'Status', width: '' },
        { title: 'Cliente', width: '180px' },
        { title: 'Atendente', width: '' },
        { title: 'qnt', width: '' },
        { title: 'Total', width: '' },
        { title: 'Código', width: '' },
    ]

    const stepTitles = []
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return list.filter(item => item.nome_cliente ? (item.nome_cliente.toLowerCase().includes(lowerBusca)) : '');
    }, [search, list])

    return (
        <>
            <SearchList
                stepTitles={stepTitles}
                search={search}
                setSearch={setSearch}
                setStep={setStep}
                step={step}
            >

                <Table
                    height='calc(100vh - 180px)'
                    footer={`Total: ${list.length}`}
                >
                    <Th array={titles} />
                    {Array.isArray(results) && results.length > 0 ?
                        results.map((item, index) =>
                            <tr key={index} className='hover' onClick={() => setIsOpen(item)}>
                                <td >{toDate(item.data)}</td>
                                <td >{item.fechamento}</td>
                                <td style={{ maxWidth: '180px' }}>{item.nome_cliente}</td>
                                <td >{item.avaliadora}</td>
                                <td >{item.count}</td>
                                <td >{toCash(item.sum)}</td>
                                <td >{item.id_vendas}</td>

                            </tr>
                        )
                        : 'Nenhum Resultado'
                    }


                </Table>
            </SearchList>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >
                <VsCard card={modalIsOpen} setCard={setIsOpen} />
            </Modal>
      </>
    )
}

function VsCard({ card, setCard }) {
    const [vendasSub, setList] = useState(false)
    const { setIdVendas, setStep } = useContext(Context)
    
    function setVendasSub(resp) {
        setList({ ...card, ['sumVendasSub']: resp.sumVendasSub, ['vendasSub']: resp.vendasSub, ['pg']: resp.pg })

    }
    useEffect(() => {
        if (card) {
            get(`/buscarVendasSub/${card.id_vendas}`, setVendasSub)
        }
    }, [card])
    const titles2 = [
        { width: '', title: 'Pagamento' },
        { width: '', title: 'Valor' },
    ]
    const titles5 = [
        { width: '', title: 'Procedimento' },
        { width: '', title: 'Região' },
        { width: '', title: 'Valor' },
    ]
    const vendido = [
        { color: '#999' },
        { fontWeight: '500', color: '#169c3e' }
    ]
    function qnt(value) {
        return value > 20 || value < 2 ? 'À Vista' : value + 'X'
    }
    function fechar(){
        setStep(1)
        setIdVendas(card.id_vendas)
    }
    return (
        vendasSub &&
        <Card
        >
            <header>
                <div>
                    <div style={{ display: 'flex' }}>
                        <label>Cliente:<span> {vendasSub.nome_cliente}</span></label>
                        <label>Código:<span> {vendasSub.id_vendas || vendasSub.id_os}</span></label>
                    </div>
                    <div style={{ display: 'flex' }}>
                       {vendasSub.fechamento === 2 && <label>Caixa:<span> {vendasSub.caixa}</span></label>}
                        <label>Avaliadora:<span> {vendasSub.avaliadora}</span></label>
                        {vendasSub.fechamento === 2 &&<label>Venda:<span> {toDate(vendasSub.data_venda)}</span></label>}

                    </div>
                </div>
            </header>
            <div>
                <Table
                    height='300px'
                    footer={
                        vendasSub.fechamento === 1 ?
                        '' : `Total: ${parseFloat(vendasSub.sumVendasSub).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                    }
                >
                    <Th array={titles5} />
                    {vendasSub.vendasSub && vendasSub.vendasSub.map((vendaSub, index) =>
                        <tr key={index} >
                            <td style={vendido[vendaSub.venda.substr(0, 1) == "S" ? 1 : 0]}>
                                {vendaSub.nome_procedimento}
                            </td>
                            <td style={{ maxWidth: '150px' }}>
                                {vendaSub.regiao || '-'}
                            </td>
                            <td style={vendido[vendaSub.venda.substr(0, 1) == "S" ? 1 : 0]}>
                                {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </td>



                        </tr>)}
                </Table>
                {vendasSub.fechamento === 1 ?
                    <button
                    onClick={()=> fechar()}
                    style={{fontSize:'22px', padding:'2px', margin:'5px'}}
                    >
                        Fechar venda
                        </button>
                    :
                    <Table>
                        <Th array={titles2} />
                        {vendasSub.pg && vendasSub.pg.map((pg, index) =>
                            <tr key={index}>
                                <td>
                                    {pg.nome_forma} ({qnt(pg.itens_qnt)})
                                </td>
                                <td>
                                    {parseFloat(pg.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </td>
                            </tr>
                        )}
                    </Table>
                }
            </div>
        </Card>
    )
}