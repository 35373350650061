import React, { useState } from "react";
import { StepsForm } from "../../a_model/Container";
import InputDay from '../c_layouts/InputDay'
import HistoricoEvent from '../c_layouts/HistoricoEvent'

export default function DayModal({ setInput, dataCard }) {
    const [step, setStep] = useState(0)
    const steps = [
        {content:
                <InputDay
                    setInput={setInput}
                    dataCard={dataCard}
                />,
            nome: 'Agendamento',
            color: '#fafcff'},
            {content:
                <HistoricoEvent
                    setInput={setInput}
                    dataCard={dataCard}
                />,
            nome: 'Histórico',
            color: '#FFFAF0'},
    ]
    return (
        <StepsForm
            step={step}
            setStep={setStep}
            steps={steps}
        />
    )
}