import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './calendar/e_contexts/AuthContext'
import Login from './calendar/a_pages/Login'
import Home from './calendar/a_pages/Home'

function App2() {
  return (


      <AuthProvider>
        <Routes>
          <Route exact path='/login' element={<Login />}></Route>
          <Route exact path='/home' element={<Home />}></Route>
        </Routes>
      </AuthProvider>




  );
}

export default App2;