import React, { useState, useEffect, useContext } from 'react'
import { Container } from '../styles/LeadsStyle'
import { Header } from "../../a_model/Header";
import Body from './Body'
import { useParams } from "react-router";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { produce } from 'immer'
import Context from '../Context';
import moment from 'moment'
import Modal from 'react-modal';
import LeadsForm from './contents/LeadsForm';
import { Dialog } from './Dialog';
import Relatorio from './Relatorio';
import { IoIosSearch, IoIosMenu } from "react-icons/io";
import { Button, InputDate } from "../../a_model/Input";
import { getTheme } from '../../a_model/Theme';
import { get, update } from '../../calendar/f_aux/fetch'
import { GiCheckMark } from 'react-icons/gi'


export default function Leads() {


  const { userp } = useParams();
  const { empresap } = useParams();
  const user = userp || localStorage.getItem('login')
  const empresa = empresap || localStorage.getItem('empresa')
  const [leads, setLeads] = useState([
    { nome: 'Msg enviada', cards: [], status: 0, display: true },
    { nome: 'Agendado', cards: [], status: 1, display: true },
    { nome: 'Indeciso', cards: [], status: 4, display: true },
    { nome: 'Não atendido', cards: [], status: 7, display: true },
    { nome: 'Sem interesse', cards: [], status: 2, display: false },
    { nome: 'Outro momento', cards: [], status: 3, display: false },
    { nome: 'Lixeira', cards: [], status: 5, display: false },
    { nome: 'Sem contato', cards: [], status: 6, display: false },
    { nome: 'Outros', cards: [], status: 8, display: false },
  ]);
  useEffect(() => {
    get(`/buscarTema/${user}`, then)
    function then(resp2) {
      localStorage.setItem('tema', JSON.stringify(resp2.tema))
    }
  }, [])
  const [data, setData] = useState({ data: '0000-00-00', fim: moment().format('yyyy-MM-DD'), unidade: 1 })
  const [form, setForm] = useState({});
  const [text, setText] = useState(false);
  const [step, setStep] = useState(0)
  const [search, setSearch] = useState('');
  const [stateData, setStateData] = useState(0)
  const navList = ['Leads', 'Relatório']
  const [statusOff, setStatusOff] = useState([
    { nome: 'Sem interesse', cards: [], status: 2, restrict: false },
    { nome: 'Outro momento', cards: [], status: 3, restrict: false },
    { nome: 'Lixeira', cards: [], status: 5, restrict: false },
    { nome: 'Sem contato', cards: [], status: 6, restrict: false },
    { nome: 'Outros', cards: [], status: 8, restrict: false },
  ])

  function handleStateData(state) {

    if (state === 0) {
      setData({ data: '0000-00-00', fim: moment().format('yyyy-MM-DD'), unidade: 1 })
    } else {
      setData({ data: `${moment().format('yyyy-MM')}-01`, fim: moment().format('yyyy-MM-DD'), unidade: 1 })
    }
    setStateData(state)

  }

  function handleChange(e) {

    if (e.target.name === 'data' && data.fim < e.target.value) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else if (e.target.name === 'fim' && e.target.value < data.data) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }

  }
  function dialog(text, type) {
    setText({ text: text, type: type })
  }


  function move(item, indexCards, att) {
    ////console.log(`/verificaLeadComercial/${item.id}/${item.status}${indexCards == 100 ? `/${user}` : ''}`)
    if(parseInt(item.indexCards) < 100 && parseInt(indexCards)  < 100 ){
      window.alert(`Só é possível alterar status da sua carteira.`)
    }else{

    get(`/verificaLeadComercial/${item.id}/${item.status}/${user}`, then)

    function then(resp) {
      function then2() {

        item.att()
        att()
      }
      if (resp) {
        item.att()
        setForm({})
        window.alert(`Esse Lead não está mais nesta categoria`)
      } else {
        if (indexCards == 100) {
          let project = { id: item.id, telefone: item.telefone, user, tabela: 'acompanhamento', nota: 0, id_leads: item.id, }
          update(`/updateCarteira`, project, then2)


        } else {
          let project = { status: indexCards, id: item.id, user }
          update(`/updateLead`, project, then2)


        }
      }


    }
  }

  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 3
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '3px',
      borderRadius: '7px',
      transform: 'translate(-50%, -50%)',
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  const [noRegister, setNoRegister] = useState(false)
  function handleCloseModal() {
    setIsOpen(false)
    setForm(false)

  }

  function handleOpenModal(card) {
  
    setForm(card)
    setIsOpen(true)
  }
  const steps = [<Body />, <Relatorio />]

  const buttons = [

    <input
      className='input'
      type='button'
      value='Todos'
      onClick={() => handleStateData(1)}
    />,
    <input
      className='input'
      type='button'
      value='Data'
      onClick={() => handleStateData(0)}
    />
  ]
  const buttons2 = [
    <label> </label>
  ]

  function LeadsMenu() {

    return (
      <div id='leadsMenu'>
        <div id='buttonHover'></div>
        <div id='menuCategorias'>
          <h2>Categorias visíveis:</h2>
          {leads.map((item, index) =>
            <div key={index}>
              <span>{item.nome}</span>
              <button
                onClick={() =>
                  setLeads(produce(leads, draft => {
                    draft.find((element) => element.status == item.status).display =
                      draft.find((element) => element.status == item.status).display ? false : true
                  }))
                }
              > {item.display ? <GiCheckMark /> : ''} </button>
            </div>
          )}
        </div>
      </div>
    )
  }


  return (
    <Context.Provider value={{ statusOff, empresa, search, setSearch, step, setStep, leads, setLeads, move, user, handleOpenModal, form, data, setData, dialog }}>
      <Dialog value={text} />
      <DndProvider backend={HTML5Backend}>

        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={customStyles}
        >
          <LeadsForm form={form} handleCloseModal={handleCloseModal} noRegister={noRegister} setNoRegister={setNoRegister} />
        </Modal>
        <LeadsMenu />
        <div id='leadsContainer'>
          <Header
            background={getTheme(localStorage.getItem('tema'), 0)}
            setStep={setStep}
            navList={navList}
            buttons={buttons2}
          ><>
              <button
                className='input'
                type='button'
              >
                <IoIosMenu />
              </button>
              <Search />

              {buttons[stateData]}

              {stateData === 1 &&
                <><InputDate
                  name='data'
                  value={data.data}
                  handleOnChange={handleChange}
                />
                  <InputDate
                    name='fim'
                    value={data.fim}
                    title='até'
                    handleOnChange={handleChange}
                  /></>}
            </>
          </Header>
          {steps[step]}
        </div>
      </DndProvider>
    </Context.Provider>
  )
}

function Search() {
  const { setSearch, search } = useContext(Context)
  return (

    <div id='search'>
      <input
        type="text"
        placeholder="Pesquisar..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      >
      </input>
      <div>
        <IoIosSearch />
      </div>
    </div>
  )
}
