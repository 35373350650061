import React, {useContext} from "react";
import { CardsAgendados } from "./contents/Cards";
import Context from '../Context';
import { getTheme } from "../../a_model/Theme";


export default function Agendados() {

    const { agendados } = useContext(Context)

    return (
        <body style={{background: getTheme(localStorage.getItem('tema'), 1)}}>
         
            {Array.isArray(agendados) &&  
            agendados.map((cards, indexCards) =>
               
                <CardsAgendados 
                    cardsRef={cards} 
                    indexCards={indexCards}
                    key={indexCards}
                />

            )}
        </body>
    )
}