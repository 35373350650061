import React, { useContext, useState, useEffect } from "react";
import Context from '../Context';
import { Table, Th } from "../../a_model/Contents";
import { Form } from "../../a_model/Container";

export default function Relatorio() {

    const { data } = useContext(Context)
    const [leads, setLeads] = useState([])
    useEffect(() => {

        fetch(`${process.env.REACT_APP_CALENDAR}/buscarLeads/${data.data}/${data.fim}`,
            { method: "GET", heders: { 'Content-type': 'application/json' }, }).then((resp) => resp.json())
            .then((resp2) => {
                setLeads(resp2)
            }).catch(err => console.log(err))

    }, [data])
    const thead = [
        {width:'',title:'Data'},
        {width:'250px',title:'Nome'},
        {width:'',title:'Telefone'},
        {width:'',title:'Cidade'},
    ]


    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
    }

    return (
        <Form>
            <Table footer={`Total: ${leads.length} registros`}>
                <Th array={thead} />
                {
                    leads.map((lead, index) =>
                        <tr key={index}>
                            <td>
                                {lead.data_cadastro.substr(0, 10).split('-').reverse().join('/')}
                            </td>
                            <td>
                                {lead.nome}
                            </td>
                            <td>
                                {formatTel(lead.telefone)}
                            </td>
                            <td>
                                {lead.cidade}- {lead.estado}
                            </td>
                        </tr>
                    )
                }

            </Table>
        </Form>
    )
}