import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Button, InputText } from '../../a_model/Input';
import styled from 'styled-components'
import { get } from '../f_aux/fetch';
import Context from "../Context";


export default function RBar(props) {
    const [search, setSearch] = useState('');
    const [last, setLast] = useState({})
    const {clients, user} = useContext(Context)

    useEffect(() => {
        get(`/buscarUltimo/${user}`,setLast)
      
    }, []);

    function lastName() {
        if (last) {
       

                if(last.obs){
                    window.alert(last.obs)
                }   
            props.setName2(last)
            getProcs(last.id_cliente)
        } else {
            window.alert('Nada encontrado')
        }
    }

    function setSearch2(e) {
        setSearch(e)
    }

    function getProcs(id) {
        get(`/procs/${id}`, props.setOptions)

    }


    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return clients.filter(user => (user.nome.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca)));


    }, [search, clients])
    return (
        <Search>

            <div>
                <input
                    type='text'
                    placeholder="Pesquisar cliente..."
                    onChange={(e) => setSearch2(e.target.value)}
                />
                <Button
                    color='#585858'
                    value="Último"
                    click={() => lastName()}
                />


            </div>

            <ListItem
                getProcs={getProcs}
                setName={props.setName}
                setCurrentName={props.setCurrentName}
                handleChange={props.handleChange}
                users={results}
                search={search}
                setOptions={props.setOptions}
            />

        </Search>
    );
}

function ListItem({ users, search, getProcs, setName }) {

    //const [id, setId] = useState('');



    function clickName(index) {
        setName(users[index])
        getProcs(users[index].id)
        if(users[index].obs){
            window.alert(users[index].obs)
        }
    }


    return (
        search.length > 1 ?
            <ul >
                {users.map(user => (
                    <li
                        key={user.id} onClick={() => clickName(users.indexOf(user))}
                    >
                        <div >
                            {user.nome || '-'}

                        </div>

                        <div >

                            {" (" + user.telefone.substr(2, 2) + ") " + user.telefone.substr(4, (user.telefone.length - 8)) + "-" + user.telefone.substr(-4, user.telefone.length) || '-'}

                        </div>
                    </li>
                ))}

            </ul>
            : ''
    );
}

const Search = styled.div`
      
    div{
        display:flex;

        input{
            width: 450px;
            border: 1px solid #ddd;
            padding: 0 10px;
            border-radius: 5px 0 0 5px;
            border-right: 0;
            height: 38px;
        }
        button{
            border-left: none;
            margin:0;
            height: 38px;
            border-radius: 0 5px 5px 0;
            border: 1px solid #ddd;

        }

    }
    
    
    ul{ 
        padding: 0 5px;  
        position: absolute;
        margin-left: 3.5px; 
        overflow-x: hidden;
        overflow-y: auto;
        height: 50vh;
        width: 450px;
        z-index: 5;
        li{
            display:flex;
            background:#fff;
            padding:5px;
            border-bottom: 1px solid #eee;
            div:first-child{
                width:300px;
    
           }
           
        } 
                
        li:hover{
            cursor: pointer;
            background:#f1f1f1;
        } 
    }

`