import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import './Pages.css'
import moment from "moment";
import CaixaVenda from "../b_grids/CaixaVenda";
import Context from "../../calendar/Context";
import { Page } from "../../a_model/Container";
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import { get } from "../../calendar/f_aux/fetch";
import { getTheme } from "../../a_model/Theme";
import { GiCheckMark } from 'react-icons/gi'

export default function Caixa() {
    const { unidadep } = useParams()
    const { userp } = useParams()
    const user = userp || localStorage.getItem('login')
    const unidade = unidadep || localStorage.getItem('unidade')
    const [vendas, setVendas] = useState({
        unidades: [],
        avaliadoras: [],
        caixas: [],
        vendas: [],
        sumVendas: 0,
        sumVendasTotal: 0,
        pagamentos: [],
        sumPagamentos: 0,
        crediario: [],
        sumCrediario: 0,
        bonus: [],
        sumBonus: 0,
        recebimentos: [],
        sumRecebimentos: 0,
    })
    const [data, setData] = useState({ data: moment().format('YYYY-MM-DD'), fim: moment().format('YYYY-MM-DD'), unidade, avaliadora: 100, caixa: 100 })
    const [refresh, setRefresh] = useState({})
    const [step, setStep] = useState(0)
    useEffect(() => {
        get(`/buscarVenda/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${data.caixa}`, setVendas)
      
    }, [data, refresh])

    function handleChange(e) {
        //console.log(e.target.value)
        if (e.target.name === 'data' && data.fim < e.target.value) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else if (e.target.name === 'fim' && e.target.value < data.data) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    const forms = [
        <CaixaVenda />
    ]
    const navList = ['Caixa']

    return (
        <Context.Provider value={{ setRefresh, unidade, user, data, vendas }}>
            <Page>

                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[
                        <button>Usuário: {user}</button>
                    ]}
                >

                    <>


                        <InputDate
                            classe='input2'
                            name='data'
                            value={data.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='fim'
                            value={data.fim}
                            handleOnChange={handleChange}
                        />
                        {unidade == 1 &&
                            <Select
                                padrao='Unidades'

                                options={vendas.unidades}
                                name='unidade'
                                value={data.unidade}
                                handleOnChange={handleChange}
                            />}
                        <Select
                            padrao='Avaliadoras'

                            options={vendas.avaliadoras}
                            name='avaliadora'
                            value={data.avaliadora}
                            handleOnChange={handleChange}
                        />
                        <Select
                            padrao='Caixas'
                            options={vendas.caixas}
                            name='caixa'
                            value={data.caixa}
                            handleOnChange={handleChange}
                        />
                    </>
                </Header>

                {forms[step]}

            </Page>
        </Context.Provider>
    )
}