import React, { useContext, useState, useEffect } from "react";
import Context from '../../Context';
import { LabelInfo } from "../../Input";
import { MdChatBubbleOutline, MdOutlinePhone, MdSend } from "react-icons/md";
import copy from "copy-to-clipboard";
import { get, insert, update } from "../../../calendar/f_aux/fetch";
import './LeadsForm.scss'
import VendasForm from "./VendasForm";
import { toTel } from "../../../a_model/functions";

export default function LeadsForm({ form, handleCloseModal }) {

    const { user, dialog, setData } = useContext(Context)
    const [project, setProject] = useState({
        telefone: form.telefone,
        id_leads: form.id,
        tabela: 'acompanhamento',
        user,
        nota: ''
    })
    const [step, setStep] = useState(0)
    const [info, setInfo] = useState({ nome_l: false, leads: 0, comentarios: 0, agendamentos: 0, orcamentos: 0 })

    useEffect(() => {
        console.log(form)
        get(`/getForm/${form.telefone}`, setInfo)
    }, [])
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function comentar() {
        function then() {
            if (form?.idevent) {
                update(`/editar/event/idevent/${form.idevent}`, { descr2: project.nota }, then2)
            } else {
                then2()
            }
        }
        function then2() {
            handleCloseModal(form?.idevent ? form.status_naoveio : form.status)
            dialog('Comentário inserido', 0)
        }

        if (project.nota.length > 0) {
            insert(`/insertTentativa`, project, then)

        } else {
            window.alert('Comentário vazio')
        }
    }


    const steps = [
        { content: <Informações status={form.status} dialog={dialog} info={info} />, nome: 'Informações', color: '#c7e3fa' },
        { content: <ComentariosLeads telefone={form.telefone} />, nome: `Leads (${info.leads})`, color: '#E1D9F8' },
        { content: <Comentarios id_cliente={form.id} />, nome: `Comentários (${info.comentarios})`, color: '#FADCF5' },
        { content: <Event id_cliente={form.id} />, nome: `Agendamentos (${info.agendamentos})`, color: '#FAD8D2' },
        { content: <VendasForm cliente={form} />, nome: `Orçamentos (${info.orcamentos})`, color: '#F7F3D6' },

    ]
    return (
        form &&
        <div id='leadsFormContainer'>

            
            <div id='stepsForm' style={{ background: steps[step].color }}>
                <header>
                    {steps.map((item, index) =>
                        <button
                            style={{ background: index == step ? item.color : 'transparent' }}
                            onClick={() => setStep(index)}
                        >
                            {item.nome}
                        </button>

                    )}

                </header>

                {steps[step].content}

            </div>
            <footer>
                <textarea
                    placeholder="Adicionar comentário..."
                    name='nota'
                    value={project.nota}
                    onChange={(e) => handleChange(e)}
                />
                <button
                    onClick={() => comentar()}
                >
                    <MdSend />
                </button>
            </footer>
        </div>


    )
}

function Informações({ info, dialog, status }) {

    const titles = [
        { nome: 'Msg enviada', status: 8 },
        { nome: 'Outro momento', status: 4 },
        { nome: 'Indeciso', status: 3 },
        { nome: 'Retornar Ligação', status: 10 },
        { nome: 'Sem interesse', status: 5 },
        { nome: 'Sem Retorno', status: 'nao' },
        { nome: 'Agendado', status: 2 },
        { nome: 'Lixeira', status: 9 },
        { nome: 'Outros', status: 11 }
    ]
    function copiar(value) {
        copy(value)
        dialog('Copiado', 0)
    }

    return (
        info.nome &&
        <div id='infoLeads'>


            <LabelInfo header='Cliente:'>
                <span onClick={() => copiar(info.id)}>{info.nome}</span>
            </LabelInfo>
            <LabelInfo header='Telefone:'>
                {info.telefone ? toTel(info.telefone) : '-'}
                <button onClick={() => copiar('3' + info.telefone)} style={{ background: '#038129' }}>
                    <MdOutlinePhone /> Ligação
                </button >
                <button onClick={() => copiar(info.telefone.substr(5, info.telefone.length - 2))} style={{ background: '#1e5dd4' }}>
                    <MdOutlinePhone /> Chat
                </button >

            </LabelInfo>
            {status && <LabelInfo header='Status:'>
                <span> {titles.find((element) => element.status == status)
                    ? titles.find((element) => element.status == status).nome
                    : status}</span>
            </LabelInfo>}
            {info.interesse_l && <LabelInfo header='Interesse:'>
                <span onClick={() => copiar(info.id)}>{info.interesse_l}</span>
            </LabelInfo>}
            {info.campanha && <LabelInfo header='Campanha:'>
                <span onClick={() => copiar(info.rede_l)}>{info.campanha}</span>
            </LabelInfo>}
            <LabelInfo header='Data de cadastro:'>
                <span >{info.data_cadastro.substr(0, 10).split('-').reverse().join('/')}</span>
            </LabelInfo>

        </div>

    )
}
function Comentarios({ id_cliente }) {

    const [comentarios, setComentarios] = useState([])

    useEffect(() => {

        get(`/buscarComentarios/${id_cliente}/acompanhamento`, setComentarios)

    }, [])

    return (
        <ul id='leadsFormList'>

            {comentarios.length > 0 ?
                comentarios.map((comentario, index) =>

                    <li key={index}>
                        <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)}:</label>
                        <p>{comentario.nota}</p>
                    </li>
                ) :

                <li style={{ height: '25px' }}>Nenhum Comentário</li>
            }
        </ul>

    )
}

function ComentariosLeads({ telefone }) {

    const [comentarios, setComentarios] = useState([])

    useEffect(() => {

        get(`/buscarComentariosLeads/${telefone}`, setComentarios)

    }, [])

    return (
        <ul id='leadsFormList'>

            {comentarios.length > 0 ?
                comentarios.map((comentario, index) =>

                    <li key={index}>
                        <label>{comentario.usuario} {comentario.data.substr(2, 8).split('-').reverse().join('/')} - {comentario.data.substr(11, 5)} ({comentario.leads}):</label>
                        <p>{comentario.texto}</p>
                    </li>
                ) :

                <li style={{ height: '25px' }}>Nenhum Comentário</li>
            }
        </ul>

    )
}

function Event({ id_cliente }) {

    const [event, setEvent] = useState([])

    useEffect(() => {
        get(`/getLeadEvent/${id_cliente}`, setEvent)

    }, [])

    return (
        <ul id='leadsFormList'>

            {event.length > 0 ?
                event.map((item, index) =>

                    <li className='liEvent' key={index}>

                        <span>Data: {item.data.substr(2, 8).split('-').reverse().join('/')} - {item.hora.substr(0, 5)}</span>
                        <span>Avaliadora: {item.avaliadora}</span>
                        <span>Usuário: {item.usuario}</span>
                        <div style={{ background: item.color }} > {item.categ}</div>
                    </li>
                ) :

                <li style={{ height: '25px' }}>Nenhuma Avaliação</li>
            }
        </ul>

    )
}