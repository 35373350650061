import React, { useContext, useState, useEffect } from "react";
import { Container, List, ListItem } from '../../styles/ModalStyle'
import Context from '../../Context';
import { LabelInfo } from "../../Input";
import { TextArea } from "../../styles/ContentStyle";
import { MdChatBubbleOutline, MdOutlinePhone, MdSend } from "react-icons/md";
import copy from "copy-to-clipboard";
import { insert } from "../../../calendar/f_aux/fetch";


export default function LeadsForm({ form, handleCloseModal }) {

    const { user, dialog } = useContext(Context)
    const [project, setProject] = useState({ telefone: form.telefone_l, id_leads: form.id, tabela: 'acompanhamento', user, nota: '' })
    const [comentarios, setComentarios] = useState([])

    useEffect(() => {

        fetch(`${process.env.REACT_APP_CALENDAR}/buscarComentarios/${form.id}/acompanhamento`,
            { method: "GET", heders: { 'Content-type': 'application/json' }, }).then((resp) => resp.json())
            .then((resp2) => {

                setComentarios(resp2)
            }).catch(err => console.log(err))

    }, [])

    const titles = [
        { nome: 'Msg enviada', status: 0 },
        { nome: 'Agendado', status: 1 },
        { nome: 'Indeciso', status: 4 },
        { nome: 'Não atendido', status: 7 },
        { nome: 'Sem interesse', status: 2 },
        { nome: 'Outro momento', status: 3 },
        { nome: 'Lixeira', status: 5 },
        { nome: 'Sem contato', status: 6 },
        { nome: 'Outros', status: 8 },
    ]
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function comentar() {
        if (project.nota.length > 0) {
            
            insert(`/insertTentativa${comentarios[0].nota == 0 && user == comentarios[0].user? `/${comentarios[0].id}` : ''}`, project, handleCloseModal)
        } else {
            window.alert('Comentário vazio')
        }
    }

    function copiar(value) {
        copy(value)
        dialog('Copiado', 0)
    }

    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        form &&
        <Container>

            <div>
                <LabelInfo header='Cliente:'>
                   <span onClick={() => copiar(form.id)}> {form.nome_l}</span>
                </LabelInfo>
                <LabelInfo header='Telefone:'>
                    {form.telefone_l ? formatTel(form.telefone_l) : '-'}
                    <button onClick={() => copiar('3' + form.telefone_l)} style={{ background: '#038129' }}>
                        <MdOutlinePhone /> Ligação
                    </button >
                    <button onClick={() => copiar(form.telefone_l.substr(2, form.telefone_l.length - 2))} style={{ background: '#1e5dd4' }}>
                        <MdOutlinePhone /> Chat
                    </button >

                </LabelInfo>
                <LabelInfo header='status:'>
                    <span>{titles.find((element) => element.status == form.status).nome || '-'}</span>
                </LabelInfo>
            </div>
            <div>
                <List>
                    <h2>Comentários</h2>
                    <ul>
                        {comentarios.map((comentario, index) =>
                        comentario.nota != 0 &&
                            <ListItem key={index}>
                                <label>{comentario.user} {comentario.data.substr(2, 8).split('-').reverse().join('/')}:</label>
                                <p>{comentario.nota}</p>
                            </ListItem>
                        )}
                    </ul>
                </List>
                <TextArea>
                    <textarea
                        placeholder="Adicionar comentário..."
                        name='nota'
                        value={project.nota}
                        onChange={(e) => handleChange(e)}
                    />
                    <button
                        onClick={() => comentar()}
                    >
                        Enviar <MdSend />
                    </button>
                </TextArea>
            </div>

        </Container>
    )
}