import React, { useState, useEffect, useContext } from "react";
import './Day.scss'
import moment from "moment";
import Context from "../Context";
import { deletar, get, insert, update, updateParams } from "../f_aux/fetch";

import { getTheme } from "../../a_model/Theme";

export function Day(props) {
    const { dialog, setIsEdit, setRefresh } = useContext(Context)


    function alterarAgenda(isEdit) {

        isEdit.start_date = props.card.start_date.substr(0, 10)
        isEdit.hora = props.card.hora
        isEdit.hora_fim = props.card.hora_fim
        isEdit.doctor_iddoctor = props.card.doctor_iddoctor
        isEdit.categ = 1
        function then(resp) {
            if (resp.exists) {
                window.alert(`Horário já reservado para ${resp.exists.title}`)
                inputClose(false, 0)
                setIsEdit(false)
            } else {
                inputClose('Cadastrado', 0)
                setIsEdit(false)
            }
        }
        update(`/updateEvent`, isEdit, then)

    }

    function inputClose(x) {
        setRefresh(props.card)
        dialog(x, 0)


    }

    return (
        <div className="cards" >

            <div
                className={`hourAgenda2 hourAgendado`}

            >
                <div style={{ background: props.card.color || '#aaa', borderRadius: '7px' }} className='hourHover'
                    onClick={() =>
                        props.setInput(2, props.card, 1)}>

                    <span style={{ color: '' }}>
                        {props.card.nome_curso || ''}
                    </span>


                    <span >
                        {props.card.nome_docente}
                    </span>
                    <span style={{}} >
                        {`${props.card.hora} ~ ${props.card.hora_fim}`}
                    </span>
                    {parseInt(props.card.duracao) > 89 && props.card.nota ?
                        <span style={{ fontStyle: 'italic' }}>
                            * {props.card.nota}
                        </span>
                        : ''}
                </div>

            </div>

        </div>
    )
}

export function Aberto(props) {
    const { isEdit, setIsEdit, dialog, setRefresh } = useContext(Context)
    function replicar(isEdit) {
        const project = isEdit
        project.data = props.data

        function then() {
            setIsEdit(false)
            dialog('Inserido', 0)
            setRefresh(Math.random())
        }
        insert('/insertAgendaIecb', project, then)
    }

    return (
        isEdit ?
            <div
                className={`hourAgenda2 hourAgendaDisp2`}
                onClick={() => replicar(isEdit)}
            >
                <span  >
                    +  Replicar

                </span>

            </div>
            :
            <div
                className={`hourAgenda2 hourAgendaDisp2`}
                onClick={() => props.setInput(2, { data: props.data }, 0)}
            >

                <span  >
                    +  Adicionar

                </span>


            </div>

    )
}

