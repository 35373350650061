export function getColor(i){const color = [     
      '#8d8d8d',
      '#c90c0c',
      '#cf5915',
      '#9370DB',
      '#c5b204',
      '#8db400',
      '#48b400',
      '#00b47e',
      '#00b4b4',
      '#00afdb',
      '#32CD32',
      '#0071db',
      '#304fff',
      '#662eff',
      '#8f2eff',
      '#c42eff',
      '#ff2eee',
      '#ff2eaf',
      '#7c4c39',
      '#286299',
      '#5b9236',
      '#a14a44',
      '#4682B4',
      '#604da3',  
      '#20B2AA',
      '#66CDAA',
      '#5F9EA0',
      '#3CB371',  
      '#DAA520',
      '#008bdb',
      '#B8860B',
      '#FF6347',
      '#FFA500', 
      '#CD853F',
      '#8371a3',
      '#F4A460',
      '#7B68EE',
      '#8b2b28', 
      '#BA55D3',
      '#ac6f53',
      '#e95a5a',
      '#ff7e27',
      '#FFD700',
      '#6A67D5',
      '#288b41',
      '#cf7e15',
      '#e23a34',
      '#e61cdb',
      '#641c61'
      ]
      let result = color[i]? color[i]: ''
    
      return result
}

export function getColor2(i){
      const color = [     
      '#4c95e2',
      '#4c5bdf',
      '#914cdf',
      '#da4cdf',
      '#4c95e2',
      '#4c5bdf',
      '#914cdf',
      '#da4cdf',
      '#4c95e2',
      '#4c5bdf',
      '#914cdf',
      '#da4cdf',
      //'#df4c91',
     // '#df4c4c',
     // '#df7b4c',
      //'#dfb14c',
     // '#a9d63e',
     // '#61d63e',  
     // '#3ed69c',
     // '#3ed6d6',
      //'#4c7fdf',
      ]
      let result = color[i]? color[i]: color[i-color.length]
    
      return result
}
