import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import './Pages.css'
import moment from "moment";
import Context from "../../calendar/Context";
import { Page } from "../../a_model/Container";
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import { get } from "../../calendar/f_aux/fetch";
import { GiCheckMark } from 'react-icons/gi'
import Restantes from "../b_grids/Restantes";

import Acompanhar from "../b_grids/Acompanhar";

export default function Relatorios() {
    const { unidadep } = useParams()
    const { userp } = useParams()
    const user = userp || localStorage.getItem('login')
    const unidade = unidadep || localStorage.getItem('unidade')
    const [vendas, setVendas] = useState({
        unidades: [],
        avaliadoras: [],
        vendas: [],
        sumVendas: 0,
        pagamentos: [],
        sumPagamentos: 0,
        crediario: [],
        sumCrediario: 0,
    })
    const [data, setData] = useState({ data: moment().format('YYYY-MM-DD'), fim: moment().format('YYYY-MM-DD'), unidade, avaliadora: 100, ver: 100 })
    const [refresh, setRefresh] = useState({})
    const [step, setStep] = useState(0)
    const [current, setCurrent] = useState(true)
    useEffect(() => {
        get(`/buscarVenda/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/100`, setVendas)
    }, [data, refresh])

    function handleChange(e) {

        if (e.target.name === 'data' && data.fim < e.target.value) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else if (e.target.name === 'fim' && e.target.value < data.data) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }
    const optionsData = [
        { id: '0', nome: 'Ativos', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().format('YYYY-MM-DD') },
        { id: 1, nome: 'Ociosos há 1 mês', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().subtract(1, 'months').format('YYYY-MM-DD') },
        { id: 2, nome: 'Ociosos há 2 mêses', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().subtract(2, 'months').format('YYYY-MM-DD') },
        { id: 3, nome: 'Ociosos há 2 meses e 20 dias', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().subtract(80, 'days').format('YYYY-MM-DD') },
        { id: 4, nome: 'Vencidos', data: '2010-01-01', fim: moment().subtract(3, 'months').format('YYYY-MM-DD') },
    ]
    function handleChangeData(e) {
        const item = optionsData.find((element) => element.id == e.target.value)
        setData({ ...data, ['data']: item.data, ['fim']: item.fim })
    }

    const forms = [
        <Acompanhar/>,
        <Restantes />,
    ]
    const navList = ['Acompanhar', 'Crédito']

    function Check() {
        function setThisData() {
            if (current) {
                setCurrent(false)
                setData({ ...data, ['data']: '2010-01-01' })

            } else {
                setCurrent(true)
                setData({ ...data, ['data']: data.fim })

            }
        }
        return (
            <div
                className="cardAtend"
                onClick={setThisData}
            >
                {<GiCheckMark style={{ fill: current ? 'rgb(43, 112, 30)' : 'transparent' }} />}
            </div>
        )
    }

    const verList = [
        { id: '1', nome: 'Abertos' },
        { id: '0', nome: 'Resolvidos' }
    ]
    return (
        <Context.Provider value={{ setRefresh, unidade, user, data, vendas }}>
            <Page>

                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[
                        <button>Usuário: {user}</button>
                    ]}
                >
                    <>
                        {step === 1 ? <Check /> : ''}
                        <InputDate
                            classe='input2'
                            name='data'
                            value={data.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='fim'
                            value={data.fim}
                            handleOnChange={handleChange}
                        />
                        <Select
                            padrao='Situação'
                            options={optionsData}
                            name='filter'
                            handleOnChange={handleChangeData}
                        />
                        {unidade == 1 &&
                            <Select
                                padrao='Unidades'
                                options={vendas.unidades}
                                name='unidade'
                                value={data.unidade}
                                handleOnChange={handleChange}
                            />
                        }
                        <Select
                            padrao='Avaliadoras'
                            options={vendas.avaliadoras}
                            name='avaliadora'
                            value={data.avaliadora}
                            handleOnChange={handleChange}
                        />
                        <Select
                            name='ver'
                            padrao='Status'
                            options={verList}
                            handleOnChange={handleChange}
                            value={data.ver}
                        />
                    </>
                </Header>
                {forms[step]}
            </Page>
        </Context.Provider>
    )
}