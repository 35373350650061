
export function get(url, then) {
      
    fetch(process.env.REACT_APP_CALENDAR+url, {
        method: "GET",
        headers: {
            'Content-type': 'application/json',
        },
    })
        .then((resp) => resp.json()).then((json) => {
            then(json)
        })
        .catch(err => console.log(err))
}

export function insert(url, body, then) {

  
    fetch(process.env.REACT_APP_CALENDAR+url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((resp) => resp.json()).then((json) => {
            then(json)
        })
        .catch(err => console.log(err))
}

export function update(url, body, then) {

  
    fetch(process.env.REACT_APP_CALENDAR+url, {
        method: "PUT",
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((resp) => resp.json()).then((json) => {
            then(json)
        })
        .catch(err => console.log(err))
}

export function updateParams(url, then) {

  
    fetch(process.env.REACT_APP_CALENDAR+url, {
        method: "PUT",
        headers: {
            'Content-type': 'application/json',
        },
    })
        .then((resp) => resp.json()).then((json) => {
            then(json)
        })
        .catch(err => console.log(err))
}

export function deletar(url, then) {

  
    fetch(process.env.REACT_APP_CALENDAR+url, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
        },
    })
        .then((resp) => resp.json()).then((json) => {
            then(json)
        })
        .catch(err => console.log(err))
}