import styled, {css} from 'styled-components'

export const Container = styled.div`
    position: relative;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
    border-top: 20px solid rgba(230, 236, 245, 0.4);
    cursor: pointer;
    padding: 8px 10px;
    margin: 0 9px 10px 9px;
    max-width: 100px;
    header{
        position: absolute;
        top: -17px;
        left: 6px;
        display:flex;
        justify-ccontent:space-between;
        width: 90%;
        
        h3{
            font-weight: 500;
            font-size: 0.9em;
            color: #696969;
          
        }
    
    }
   

    p {
        font-size: 0.9em;
        color: #696969;
        margin-top: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

   ${props => props.isDragging && css`  
    border: 2px dashed rgba(0, 0, 0, 0.2);
    padding-top: 31px;
    border-radius: 0;
    background: transparent;
    cursor: grabbing;
    
    header, p{
        opacity: 0;
    }
`}
   
    

`

export const Label = styled.span`
    margin: 3px;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    display: inline-block;
    background: ${props => props.color};

`

export const Empty = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    margin: 0 7px 10px 9px; 
    box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
    height: 50vh;   
   
    border: 2px dashed rgba(0, 0, 0, 0.2);
    
    svg{
        font-size: 3em;
        opacity: 0.2;
    }
    

`

