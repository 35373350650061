import  { Container, Label } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext } from 'react'
import Context from '../../Context';
 
export default function Card({ card, index, indexCards, status}){
 
    const ref = useRef()
  const { move, handleOpenModal } = useContext(Context)
  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id: card.id, status },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
      move(item, indexCards, status)
  
    }
   
  })

  dragRef(dropRef(ref))


    return(
        <Container onClick={()=> handleOpenModal(card)} ref={ref} isDragging={isDragging}>
           <header>

           <h3>{card.data_cadastro && card.data_cadastro.substr(2,8).split('-').reverse().join('/')}</h3>
           <Label color='#7159c1' />
            </header> 
            <p>{card.nome}</p>
            <p>{card.tentativas} tentativas</p>
            
        </Container>
    )
}