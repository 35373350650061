import { useEffect, useState } from "react";
import moment from "moment";
import "./Month.scss";
import 'moment/locale/pt-br'
import calendarBuild from "../f_aux/CalendarBuild";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { getTheme } from "../../a_model/Theme";
export default function MonthAtend(props) {
  const [currentMoment, setCurrentMoment] = useState(moment().locale("pt-br"));

  const [dateSelected, setDateSelected] = useState([]);


  moment.updateLocale("pt", {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  });


  return (

    <div className="monthPage">

      <div>

        <div className="headerPage" style={{background:getTheme(localStorage.getItem('tema'), 0)}}>

          <IconButton
            size="small"
            onClick={() => setCurrentMoment(currentMoment.clone().subtract(1, 'month'))}
          >
            <KeyboardArrowLeftIcon style={{ color: "#143296", fontSize: 40 }} />
          </IconButton>
          {currentMoment.format('MMMM YYYY')}
          <IconButton
            size="small"
            onClick={() => setCurrentMoment(currentMoment.clone().add(1, 'month'))}
          >
            <KeyboardArrowRightIcon style={{ color: "#143296", fontSize: 40 }} />
          </IconButton>

        </div>

        <div className="contentMonth">

          <MonthCard

            mudar={props.mudar}
            setCurrentFormat={props.setCurrentFormat}
            setCurrentDay={props.setCurrentDay}
            currentDay={props.currentDay}
            currentMoment={currentMoment}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
          />

        </div>
      </div>

    </div>

  );
}

function MonthCard(props) {

  const [calendar, setCalendar] = useState([]);
  const weekDays = ["D", "S", "T", "Q", "Q", "S", "S"];
  const [currentSel, setCurrentSel] = useState(false)
  
  function mudar(day, index){
    props.mudar(day)
    setCurrentSel(index)
  }

  useEffect(() => {
    setCalendar(calendarBuild(props.currentMoment));
  }, [props.currentMoment]);

  return (
    <div className="monthCard">
      <div className="weekDays">
        {weekDays.map((value, index) => (
          <div className="weekDay" key={index}>
            {value}
          </div>
        ))}
      </div>
      {calendar.map((week, index) => (
        <div className={`week ${index === currentSel ? 'sel' : ''}`} onClick={()=> mudar(week[0], index)} key={index}
        >
          {week.map((day, index2) => (

            <div key={index2} className='Days Days2'>
              <div
                style={{ color: day.format('M') == props.currentMoment.format('M') ? '#4c95e2' : '#999' }}
              >
                {day.format("DD").toString()}
              </div>

            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
