import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import './DiarioForm.scss'
import { Table, Th } from '../../a_model/Contents'
import { toCash } from '../../a_model/functions'
import Context from "../Context";
import { get } from "../f_aux/fetch";

const unidades = [

  { id: 1, nome: 'Matriz' },
  { id: 3, nome: 'SPA' },
  { id: 10, nome: 'Altamira' },
  { id: 11, nome: 'Paulinia' },
  { id: 13, nome: 'Brasília' },
]

export default function RelatorioGerenciaDiario() {

  return (

    <div id='relatorioUnidadesListEvent'>

      {
        unidades.map((unidade, index) =>

          <FirstStepList unidade={unidade} indexList={index} />
        )}

    </div>
  )
}

function FirstStepList({ unidade, indexList }) {
  const [doctors, setDoctors] = useState([])
  useEffect(() => {
    get(`/getDoctorsUnidade/${unidade.id}`, setDoctors)

  }, [])
  return (
    <div key={indexList} className='relatorioDoctorsListEvent'>
      <h1> {unidade.nome}</h1>
      <div >
        {
          doctors.map((doctor, index) =>
            <FirstStep
              index={indexList + '-' + index}
              doctor={doctor}
              unidade={unidade.id}
            />
          )}
      </div>
    </div>
  )
}

function FirstStep({ index, doctor, unidade }) {
  const { statusServico, params } = useContext(Context)
  const [relatorio, setRelatorio] = useState([])
  const [total, setTotal] = useState({ soma_bonus: 0, bonus: 0, soma_vendido: 0, vendido: 0, })
  useEffect(() => {
    get(`/getRelatorioVoucher/${100}/${params.data}/${params.data_fim}/${doctor.id}/${unidade}/${params.stateData}`, setRelatorio)
  }, [params])

  useMemo(() => {
    let soma_bonus = 0.0
    let soma_vendido = 0.0
    let bonus = 0
    let vendido = 0
    for (let i in relatorio) {
      soma_bonus += parseFloat(relatorio[i].soma_bonus) || 0
      soma_vendido += parseFloat(relatorio[i].soma_vendido) || 0
      bonus += parseInt(relatorio[i].bonus)
      vendido += parseInt(relatorio[i].vendido)

    }
    setTotal({ soma_bonus, bonus, soma_vendido, vendido, })
  }, [relatorio])
  const titles = [
    { title: 'status' },
    { title: 'Bônus' },
    { title: 'qnt' },
    { title: 'Venda' },
    { title: 'qnt' },
  ]

  return (
    relatorio.length > 0 &&
    <div className='relatorioDoctorEvent'>

     <label> 
      {doctor.nome}
     </label>
      <Table>
        <Th array={titles} />
        {relatorio.map((item, index) =>

          <tr key={index}   >

            <td>
              {statusServico.find((element) => element.id == item.servico)?.nome}
            </td>
            <td>
              {toCash(item.soma_bonus)}
            </td>
            <td style={{ fontWeight: 600 }} >
              {item.bonus}
            </td>

            <td>
              {toCash(item.soma_vendido)}
            </td>
            <td style={{ fontWeight: 600 }}>
              {item.vendido}
            </td>

          </tr>


        )}
        <tr style={{ fontWeight: "500" }}>
          <td style={{ color: "#2956eb" }}>
            Total
          </td>
          <td style={{ color: "#2956eb" }}>
            {toCash(total.soma_bonus)}
          </td>
          <td style={{ color: "#2956eb" }}>
            {total.bonus}
          </td>

          <td style={{ color: "#2956eb" }}>
            {toCash(total.soma_vendido)}
          </td>
          <td style={{ color: "#2956eb" }}>
            {total.vendido}
          </td>
        </tr>
      </Table>
    </div>
  )
}