import { useState, useContext } from "react";
import 'moment/locale/pt-br'
import "./DayForm.scss";
import { Day, Aberto } from "../c_layouts/Day";
import Context from "../Context";
import InputDay from "../c_layouts/InputDay";
import Modal from 'react-modal'
import { getTheme } from "../../a_model/Theme";
import { RiRefreshLine } from 'react-icons/ri'
import { FaCalendarAlt } from 'react-icons/fa'
import moment from 'moment'
import InputAgendado from "../c_layouts/InputAgendado";

export default function DayForm() {
  const { currentFormat, setCurrentFormat, group, dialog, isEdit, setIsEdit, mudarDayForm, count, setRefresh, agenda, setBalao, user } = useContext(Context)
  const [dataCard, setDataCard] = useState([{ atendente: '' }])
  const [modalIsOpen, setIsOpen] = useState(false)
  const hoje = moment()

  let horarios = [
    { hora: '07:00', hora_fim: '07:30' },
    { hora: '07:30', hora_fim: '08:00' },
    { hora: '08:00', hora_fim: '08:30' },
    { hora: '08:30', hora_fim: '09:00' },
    { hora: '09:00', hora_fim: '10:00' },
    { hora: '09:30', hora_fim: '10:00' },
    { hora: '10:00', hora_fim: '10:30' },
    { hora: '10:30', hora_fim: '11:00' },
    { hora: '11:00', hora_fim: '11:30' },
    { hora: '11:30', hora_fim: '12:00' },
    { hora: '12:00', hora_fim: '12:30' },
    { hora: '12:30', hora_fim: '13:00' },
    { hora: '13:00', hora_fim: '13:30' },
    { hora: '13:30', hora_fim: '14:00' },
    { hora: '14:00', hora_fim: '14:30' },
    { hora: '14:30', hora_fim: '15:00' },
    { hora: '15:00', hora_fim: '15:30' },
    { hora: '15:30', hora_fim: '16:00' },
    { hora: '16:00', hora_fim: '16:30' },
    { hora: '16:30', hora_fim: '17:00' },
    { hora: '17:00', hora_fim: '17:30' },

  ]
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 5
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '1px',
      transform: 'translate(-50%, -50%)',
      background: '#fafcff',
      padding: '5px',
      border: '10px solid #deeafa'
    }
  }


  function setInput(page, dataCard, modal) {
    setModal(modal)
    setDataCard(dataCard)
    setRefresh(Math.random())
    page == 0 ? setIsOpen(false)
      : setIsOpen(true)

  }

  function inputClose(text, type) {
    setInput(0, dataCard, 0)
    text && dialog(text, type)
  }

  const [modal, setModal] = useState(0)
  const modals = [
    <InputDay
      setInput={setInput}
      dataCard={dataCard}
    />,
    <InputAgendado
      setInput={setInput}
      aula={dataCard}
    />
  ]
  return (
    agenda.length > 0 &&

    <Context.Provider value={{
      user,
      dialog,
      isEdit,
      setIsEdit,
      setIsOpen,
      inputClose,
      dataCard,
      setModal,
      setRefresh
    }}>
      <Modal
        onRequestClose={() => setIsOpen(false)}
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={customStyles}
      >
        {modals[modal]}
      </Modal>

      <div className="content" style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>

        <div id="inlineAtend2" >
          

          {

            agenda.map((doctor, index) => (
              <div onClick={() => mudarDayForm(doctor.iddoctor)} className='doctor' key={index}
                style={{ background: getTheme(localStorage.getItem('tema'), 1), width: `calc( ( 100% ) / ${count} )`, opacity: hoje > moment(doctor.data + ' 23:59:59') ? 0.5 : 1 }}
              >
                <span style={{
                  fontWeight: hoje.format('YYYY-MM-DD') == doctor.data ? 600 : 500,
                  fontSize: hoje.format('YYYY-MM-DD') == doctor.data ? '17px' : '15px',
                  color: getTheme(localStorage.getItem('tema'), 0),
                  borderLeft: '1px solid ' + (getTheme(localStorage.getItem('tema'), 0))
                }}>{doctor.title}</span>
              </div>
            ))

          }


        </div>

        <div className="inlineHours2" >

          {agenda && agenda.map((doctor, index) => (
            <div 
            className="doctorColumn2" 
            style={{ minWidth: `calc( 100% / ${count} + 0.1px )` }}
            key={index}
            >
              <DayCard
                setInput={setInput}
                cards={doctor.agenda}
              />
              <Aberto
              setInput={setInput}
              data={doctor.data}
              />
            </div>
          ))}
        </div>
      </div>
    </Context.Provider>

  );
}

function DayCard(props) {

  return (

    props.cards && props.cards.map((hora, index) => (
    
      <Day
        setInput={props.setInput}
        
        index={index}
        hora={hora.hora}
        card={hora}
        indexList={props.indexList}
      />
    ))
    


  );
}
