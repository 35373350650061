/* eslint-disable eqeqeq */

import css from './Input.module.scss'
import { GiCheckMark } from 'react-icons/gi'
import InputMask from 'react-input-mask'
import { useState, useEffect } from 'react'
import { BiEdit, BiCopy } from 'react-icons/bi'
import { MdOutlineClose } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import moment from 'moment'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import './inputTeste.css'
const masks = ["99h99", "999.999.999-99", "99 (99) 99999-9999"]

export function InputText({ disabled, width, title, name, handleOnChange, value }) {

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => {
        if (value === '') setIsFocused(false);
    };

    function change(e) {
        handleOnChange(e)

    }

    return (
        <div style={{ width: width }} className={`input-container ${isFocused || value ? 'focused' : ''}`}>
            <input

                disabled={disabled}
                name={name}
                type="text"
                value={value}
                onChange={(e) => change(e)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="input-field"
            />
            <label className="input-label">{title}</label>
        </div>
    );
};

export function InputWithLabel({ disabled, width, flex, title, name, placeholder, handleOnChange, value, background }) {
    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                disabled={disabled}
                style={{ width: width, background: background ? value : '' }}
                type='text'
                className={css.input}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
            />
        </div>
    )
}

export function InputText2({ disabled, width, flex, title, name, placeholder, handleOnChange, value, background }) {
    return (


        <input
            disabled={disabled}
            style={{ width: width, background: background ? value : '' }}
            type='text'
            className={css.inputText2}
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={handleOnChange}
            value={value}
        />

    )
}

export function InputDate({ name, handleOnChange, value }) {
    return (
        <input
            className={css.input}
            style={{ width: '10em' }}
            type='date'
            name={name}
            key={name}
            onChange={handleOnChange}
            value={value} />
    )
}

export function InputCash({ width, flex, title, name, placeholder, handleOnChange, value }) {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (e) => {
        // Remover caracteres não numéricos (exceto ponto e vírgula para permitir decimais)
        let formattedValue = e.target.value.replace(/[^0-9.,]/g, '');

        // Substituir vírgulas por pontos
        formattedValue = formattedValue.replace(/,/g, '.');

        // Atualizar o valor do estado local
        setInputValue(formattedValue);

        // Chamar a função de manipulação de estado com o valor formatado
        handleOnChange({ target: { name: e.target.name, value: formattedValue } });
    };

    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <div className={css.inputContainerCash} >
                <span>R$</span>
                <input
                    style={{ paddingLeft: '28px', width: width }}
                    type='text'
                    className={css.input}
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    value={inputValue}
                />
            </div>
        </div>
    );
}





export function Select({ value, padrao, width, name, options, handleOnChange, disabled }) {

    return (

        <select
            disabled={disabled}
            className={css.input}
            style={{ width: width, padding: '10px' }}
            name={name}
            id={name}
            onChange={handleOnChange}
        >
            {padrao && <option key={'key'} value={100} >{padrao}</option>}
            {options.map((option, index) => (
                <option selected={value == option.id ? true : false} value={option.id} name={option.nome} key={option.id + index}>{(option.nome ? option.nome : option.id)}</option>
            ))}
        </select>
    )
}


export function InputMasks({ flex, placeholder, title, name, handleOnChange, value, mask }) {
    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <InputMask
                className={css.input}
                style={{ width: mask === '0' ? '6em' : '15em', flexDirection: flex }}
                placeholder={placeholder}
                mask={masks[mask]}
                name={name}
                id={name}
                onChange={handleOnChange}
                value={value}

            />
        </div>
    )
}

export function InputTel({ flex, placeholder, title, name, handleOnChange, value, mask }) {
    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <InputMask
                className={css.input}
                style={{ width: mask === '0' ? '6em' : '15em', flexDirection: flex }}
                placeholder={placeholder}
                mask={masks[mask]}
                name={name}
                id={name}
                onChange={handleOnChange}
                value={value}

            />
        </div>
    )
}

export function InputNumber({ max, min, width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width }}
                type='number'
                className={css.input}
                min={min || '0'}
                max={max}
                step='1'
                name={name}
                id={name}
                placeholder={placeholder}
                onInput={(e) => {
                    // Remover caracteres não numéricos (exceto ponto para permitir decimais)
                    e.target.value = e.target.value.replace(/[^0-9.,]/g, '');

                    // Adicionar sua lógica de manipulação de estado aqui, se necessário
                    handleOnChange(e);
                }}
                value={value}
            />
        </div>
    )
}

export function InputFloat({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width }}
                type='text' // Use 'text' para permitir pontos e vírgulas
                className={css.input}
                name={name}
                id={name}
                placeholder={placeholder}
                onInput={(e) => {
                    // Remover caracteres não numéricos (exceto ponto e vírgula para permitir decimais)
                    e.target.value = e.target.value.replace(/[^0-9.,]/g, '');

                    // Substituir vírgula por ponto para interpretação correta do número
                    e.target.value = e.target.value.replace(/,/g, '.');

                    // Adicionar sua lógica de manipulação de estado aqui, se necessário
                    handleOnChange(e);
                }}
                value={value}
            />
        </div>
    );
}
export function InputTime({ disabled, width, title, name, handleOnChange, value }) {

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => {
        if (value === '') setIsFocused(false);
    };

    function change(e) {
        handleOnChange(e)

    }

    return (
        <div style={{ width: width }} className={`input-container ${isFocused || value ? 'focused' : ''}`}>
            <input
                style={{ width: width }}
                type='time'
                className="input-field" name={name}
                id={name}
                onChange={handleOnChange}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
            />
            <label className="input-label">{title}</label>
        </div>
    );
};

export function InputPass({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width }}
                type='password'
                className={css.input}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
            />
        </div>
    )
}

export function InputDate2({ name, handleOnChange, value, classe, buttons }) {

    return (
        <div className={css.inputDate}>

            {buttons && <span name={name} value={moment(value).subtract(1, "days").format('YYYY-MM-DD')}><MdOutlineKeyboardArrowLeft /> Anterior</span>}
            <div id={classe} >
                <input

                    style={{ width: '10em' }}
                    type='date'
                    name={name}
                    id={name}
                    onChange={handleOnChange}
                    value={value} />
                <span style={{ color: '#fff' }}>{moment(value).locale("pt-br").format('ddd, DD/MM/YY')}</span>
                <div><FcCalendar /></div>
            </div>
            {buttons && <span>Próximo <MdOutlineKeyboardArrowRight /></span>}
        </div>

    )
}

export function Checkbox({ name, value, label, event }) {
    const [check, setCheck] = useState(value || 0)

    function mudar(){
       // event(check === 0 ? 1 : 0)
        setCheck(check == 0 ? 1 : 0)
        const f ={
            target :{
                name, value: check == 0 ? 1 : 0
            }
        }
        event(f)
    }



    return (
        <div className={css.checkBox}>
            <label>{label}</label>
            <div onClick={() => mudar()} >
                {check == 1 ? <GiCheckMark /> : ''}
            </div>
        </div>
    )
}

export const Radio = ({ handleOnChange, title, name, data, values }) => {
    return (
        <div className={css.radios}>
            <label htmlFor={name}>{title}</label>
            {values.map(i => {
                return (
                    <label className={css.radioContainer}>
                        <input
                            key={i}
                            type="radio"
                            values={i}
                            value={i}
                            name={name}
                            required
                            checked={data === i}
                            onChange={handleOnChange}
                        />
                        <GiCheckMark />
                        <p>{i}</p>
                    </label>
                )
            })}
        </div>

    )
}

export const RadioList = ({ handleOnChange, title, name, data, values }) => {
    function change(e) {
        handleOnChange({ target: { name, value: e.target.value } })
    }
    return (
        <div key={title} className={css.radios}>
            { /*title && <label htmlFor={name}>{title}</label>*/}
            <div>
                {values.map((i, index) => {

                    return (
                        <label key={title + index} className={css.radioContainer}>
                            <input
                                type="radio"
                                //values={values}
                                value={i.id}
                                name={name + title}
                                required
                                checked={data == i.id}
                                onChange={change}
                            />
                            <GiCheckMark />
                            <p>{i.nome}</p>

                        </label>
                    )
                })}
            </div>
        </div>

    )
}

export function Button(props) {
    const [disable, setDisable] = useState(false)
    function click() {
        props.click()
        //   setDisable(disable? false:true)
    }
    return (
        <button
            disabled={props.disabled || disable}
            style={{ backgroundColor: `${props.color}`, width: props.width ? props.width : '' }}
            type='button'
            className={css.botao2}
            onClick={click}
        >{props.children}{props.value}
        </button>

    )
}

export function Button2(props) {
    return (
        <div className={css.button2}>
            <button
                type='button'
                onClick={props.onClick}
            >{props.children}{props.value}
            </button>
        </div>
    )
}

export function Button3(props) {
    return (
        <button
            className={css.button3}
            type='button'
            onClick={props.onClick}
        >{props.children}{props.value}
        </button>
    )
}

export function InputDateC({ flex, title, name, placeholder, handleOnChange, value, classe }) {
    return (
        <div id={classe} className={css.inputContainer} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: '10em' }}
                type='date'
                name={name}
                className={css.input}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value} />
        </div>
    )
}

export function SelectC({ value, padrao, width, flex, text, name, options, handleOnChange, classe, disabled }) {

    return (
        <div id={classe} className={css.inputContainer} style={{ flexDirection: 'column' }}>
            <label htmlFor={name}>{text}</label>
            <select
                disabled={disabled}
                className={css.input}
                style={{ width: width, padding: '10px' }}
                name={name}
                id={name}
                onChange={handleOnChange}
            >
                {padrao && <option key={'key'} value={100} >{padrao}</option>}
                {options.map((option, index) => (
                    <option selected={value == option.id ? true : false} value={option.id} name={option.nome} key={option.id + index}>{(option.nome ? option.nome : option.id)}</option>
                ))}
            </select>
        </div>
    )
}



export function Select2({ value, padrao, width, name, options, handleOnChange, disabled }) {

    return (
        <div id={css.inputSelect}>
            <select
                disabled={disabled}
                style={{ width: width }}
                name={name}
                id={name}
                onChange={handleOnChange}
            >
                {padrao && <option value={100} >{padrao}</option>}
                {options.map((option) => (
                    option.id && <option selected={value == option.id ? true : false} value={option.id} name={option.nome} key={option.id}>{(option.nome ? option.nome : option.id)}</option>
                ))}
            </select>
        </div>
    )
}

export function TextArea({ width, height, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className={css.taContainer} style={flex ? { flexDirection: flex } : { flexDirection: 'column' }}>
            <label>{title}</label><br />
            <textarea
                style={{ width: width, minHeight: height }}
                placeholder={placeholder}
                name={name}
                id={name}
                value={value}
                onChange={handleOnChange}
            />
        </div>
    )
}

export function TextArea2(props) {
    return (
        <div className={css.taContainer2}>
            <textarea
                style={{ width: props.width, minHeight: props.height }}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.handleOnChange}
            />
            {props.children}
        </div>
    )
}

export function LabelText(props) {

    const svg = [<BiCopy color={props.color} />, <BiEdit color={props.color} />]
    return (
        <div className={css.labelContainer} style={props.flex ? { flexDirection: props.flex } : { alignItems: 'center' }}>
            <label style={{ color: `${props.color}` }}>{props.header}</label>

            <span style={{ color: `${props.color}` }}>{props.children}</span>
            {props.onClick &&
                <div onClick={props.onClick} > {svg[props.svg]} </div>}
        </div>


    )

}
export function LabelText1(props) {

    return (
        <div className={css.labelContainer1} >
            <label >{props.header}</label>

            <span >{props.children}</span>

        </div>


    )

}

export function LabelText2(props) {
    return (
        <div className={css.labelContainer} id={css.labelContainer2} style={props.flex ? { flexDirection: props.flex } : { alignItems: 'end' }}>
            <label >{props.header}</label>

            <span>{props.children}</span>
            {props.onClick &&
                <div onClick={props.onClick} > <BiEdit /> </div>}
        </div>


    )

}
export function LabelText3(props) {
    return (
        <div style={props.row ? { alignItems: 'center' } : { flexDirection: 'column' }} id={css.labelText3}>
            <label >{props.header}</label>

            <div style={{ width: props.width }}>
                <span >{props.children}</span>
                <div>
                    {props.onClick && <button onClick={props.onClick}>Copiar</button>}
                    {props.onClick2 && <button onClick={props.onClick2}>Alterar</button>}
                </div>
            </div>
        </div>


    )

}

export function LabelText4(props) {
    return (
        <div id={css.labelText4} style={props.style}>
            <label >{props.header}</label>
            <span >{props.children}</span>
        </div>


    )

}
export function ConfirmButton(props) {
    return (

        <button
            style={{ border: `1px solid ${props.color}`, color: props.color }}
            className={css.confirmButton}
            type='button'
            onClick={props.onClick}
        >
            {props.value}
        </button>

    )

}
export function CloseButton({ onClick }) {
    return (

        <button
            id={css.closeButton}
            type='button'
            onClick={onClick}
        >
            <MdOutlineClose />
        </button>

    )

}

export function InputSearch(props) {
    return (
        <div id={css.searchContainer} style={{ width: props.width }}>
            <div id={css.search} >
                <input
                    style={{ width: props.width }}
                    type="text"
                    placeholder={props.placeholder || "Pesquisar..."}
                    value={props.search}
                    onChange={(e) => props.setSearch(e.target.value)}
                >
                </input>
                <div>
                    <IoIosSearch />
                </div>
            </div>
            {props.children}
        </div>
    )
}

export function InputSearchButton(props) {
    return (
        <div id={css.searchContainer}>
            <div id={css.search}>
                <input
                    style={{ width: props.width }}
                    type="text"
                    placeholder={props.placeholder || "Pesquisar..."}
                    value={props.search}
                    onChange={(e) => props.setSearch(e.target.value)}
                >
                </input>
                <button onClick={() => props.cadastrar()}>
                    {props.button}
                </button>
            </div>
            {props.children}
        </div>
    )
}

export function ListItem(props) {
    return (
        <ul id={css.listItem} style={{ width: props.width, maxHeight: props.height }}>
            {props.children}
        </ul>
    )
}

export function SelectHeader({ color, value, padrao, width, name, options, handleOnChange, disabled }) {

    return (

        <select
            disabled={disabled}
            style={{ color: color }}
            name={name}
            id={name}
            onChange={handleOnChange}
        >
            {padrao && <option key={'key'} value={100} >{padrao}</option>}
            {options.map((option) => (
                <option selected={value == option.status ? true : false} value={option.status} name={option.nome} key={option.status}>{option.nome}</option>
            ))}
        </select>
    )
}
export function SelectHeader2({ color, value, padrao, width, name, options, handleOnChange, disabled }) {

    return (

        <select
            disabled={disabled}
            style={{ color: color }}
            name={name}
            id={name}
            onChange={handleOnChange}
        >
            {padrao && <option key={'key'} value={100} >{padrao}</option>}
            {options.map((option) => (
                <option selected={value == option.selecao ? true : false} value={option.selecao} name={option.nome} key={option.selecao}>{option.nome}</option>
            ))}
        </select>
    )
}