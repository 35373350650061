import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import Context from "../Context";
import { BiCalendar } from "react-icons/bi";
import { Page } from "../../a_model/Container";
import { get } from "../f_aux/fetch";
import DiarioForm from "../b_forms/DiarioForm";
import moment from "moment";
import { RankingBar } from "../../a_model/Contents";
import { getTheme } from '../../a_model/Theme'
import { Dialog } from "../c_layouts/Dialog";
import RelatorioDiario from "../b_forms/RelatorioDiario";
import RelatorioGerenciaDiario from "../b_forms/RelatorioGerenciaDiario";

export default function Diario() {
    const { setCurrentForm, user, unidade, empresa, categ, statusServico } = useContext(Context)

    const [doctors, setDoctors] = useState([])
    const [users, setUsers] = useState({ users: [], count: [] })
    const [params, setParams] = useState({ stateData: 'start_date', coluna: 'start_date', sentido: 'asc', user: 100, doctor: 100, status: 100, unidade: 100, data: moment().format('YYYY-MM-DD'), data_fim: moment().format('YYYY-MM-DD'), period: 0 })
    const [card, setCard] = useState({ itens: [] })
    const [att, setAtt] = useState('')
    const [text, setText] = useState(false)
    const [stateData, setStateData] = useState(1)
    const [currentStep, setCurrentStep] = useState(0)
    const buttons = [

        <input
            className='input'
            type='button'
            value='Agendado'
            onClick={() => handleStateData(1)}
        />,
        <input
            className='input'
            type='button'
            value='Data'
            onClick={() => handleStateData(0)}
        />
    ]
    useEffect(() => {
        get(`/getDoctors/${empresa}`, setDoctors)
    }, [params.unidade]);
    useEffect(() => {
        get(`/getUsersEvent/${empresa}/${params.data}/${params.data_fim}/${params.doctor}/${params.unidade}/${params.status}/${params.stateData}`, setUsers)
        get(`/getAgendaDiario/${empresa}/${params.data}/${params.data_fim}/${params.user}/${params.doctor}/${params.unidade}/${params.status}/${params.coluna}/${params.sentido}/${params.stateData}`, setCard)

    }, [params, att]);
    function dialog(text, type) {
        setText({ text: text, type: type })
    }

    const status = [
        { id: '1,6', nome: 'Agendado', color: '#A18AF4' },
        { id: '2,3', nome: 'Confirmado', color: '#348EE3' },
        { id: '4,7', nome: 'Reagendar', color: '#DECA64' },
        { id: '11,13', nome: 'Cancelado', color: '#8B0000' },
        { id: '12', nome: 'Não veio', color: '#E08039' },
        { id: '14,15,16,18,19,20,21,22,5', nome: 'Atendido', color: '#F593AF' },
        { id: '17', nome: 'Retorno', color: '#B8C4DB' },
    ]

    function handleChange(e) {
        if (e.target.name === 'data' && params.data_fim < e.target.value) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else if (e.target.name === 'data_fim' && e.target.value < params.data) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else {
            setParams({ ...params, [e.target.name]: e.target.value })
        }
    }

    const navList = ['Diário', 'Voucher', 'Geral']
    const steps = [
        <>

            <DiarioForm />
            <div>
                <RankingBar>
                    {users.count.map((user, index) =>
                        user &&
                        <li key={index}>
                            <span>
                                {user.usuario}
                            </span>
                            <div
                                style={{
                                    width: (user.count / users.count[0].count) * 100 + "%",
                                    background: getTheme(user.tema, 0)
                                }}>
                                {user.count}
                            </div>

                        </li>
                    )}

                </RankingBar>

            </div>
        </>
        ,
        <RelatorioDiario/>,
        <RelatorioGerenciaDiario/>
    ]
    const unidades = [
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' }
    ]
    function setStep() {
        setParams({ ...params, ['period']: params.period === 0 ? 1 : 0 })
    }
    function handleStateData(state) {

        if (state === 0) {
            setParams({ ...params, stateData: 'data_agendado' })
        } else {
            setParams({ ...params, stateData: 'start_date' })
        }
        setStateData(state)

    }

    return (
        <Context.Provider value={{ categ, empresa, dialog, categ, status, card, setAtt, params, setParams, user, statusServico }}>
            <Page>
                <Header

                    setStep={setCurrentStep}
                    navList={navList}
                    buttons={[
                        <button onClick={() => setCurrentForm(0)}><BiCalendar />Agenda</button>
                    ]}
                ><>
                        {buttons[stateData]}
                        <InputDate
                            classe='input2'
                            name='data'
                            value={params.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='data_fim'
                            value={params.data_fim}
                            handleOnChange={handleChange}
                        />

                        <Select
                            classe='input2'
                            padrao='Avaliadoras'
                            options={doctors}
                            name='doctor'
                            value={params.atendente}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            padrao='Usuários'
                            options={users.users}
                            name='user'
                            value={params.user}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            padrao='Status'
                            options={status}
                            name='status'
                            value={params.status}
                            handleOnChange={handleChange}
                        />
                        {unidade == 1 &&
                            <Select
                                classe='input2'
                                padrao='Unidades'
                                options={unidades}
                                name='unidade'
                                value={params.unidade}
                                handleOnChange={handleChange}
                            />}
                    </>
                </Header>
                <Dialog value={text} />
                <body style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>
                    {steps[currentStep]}

                </body>
            </Page>
        </Context.Provider>

    )
}
