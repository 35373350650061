import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import { Header } from "../../a_model/Header";
import { Button3, InputDate, Select } from "../../a_model/Input";
import Context from "../Context";
import { BiCalendar } from "react-icons/bi";
import { Page } from "../../a_model/Container";
import { get } from "../f_aux/fetch";
import DiarioIecbForm from "../b_forms/DiarioIecbForm";
import moment from "moment";

import { getTheme } from "../../a_model/Theme";
import { Dialog } from "../c_layouts/Dialog";
import { createPDF } from "../f_aux/createPDF";
import { createUserPDF } from "../f_aux/createUserPDF";

export default function DiarioIecb() {
    const { setCurrentForm, user, unidade, empresa, categ } = useContext(Context)

    const [docentes, setDocentes] = useState([])
    const [users, setUsers] = useState([])
    const [params, setParams] = useState({
        user: 100,
        docente: 100,
        status: 1,
        unidade: 100,
        data: moment().format('YYYY-MM-DD'),
        data_fim: moment().format('YYYY-MM-DD'),
        tipo: 100
    })
    const [card, setCard] = useState([])
    const [att, setAtt] = useState('')
    const [text, setText] = useState(false)

    useEffect(() => {
        //    get(`/getDocentesIecb/1`, setDocentes)
    }, [params.unidade]);
    useEffect(() => {
        // get(`/get/${empresa}/${params.data}/${params.data_fim}/${params.doctor}/${params.unidade}/${params.status}`, setUsers)
        get(`/getDiarioIecb/${params.data}/${params.data_fim}/${params.docente}/${params.user}/${params.status}/${params.tipo}`, then)
    }, [params, att]);
    function then(resp) {
        setDocentes(resp.docentes)
        setUsers(resp.users)
        setCard(resp.itens)

    }
    function dialog(text, type) {
        setText({ text: text, type: type })
    }

    const status = [
        { id: '1', nome: 'Ativo', color: '#A18AF4' },
        { id: '0', nome: 'Inativo', color: '#348EE3' },

    ]
    const categorias = [
        { id: '1', nome: 'Aluno', color: '#A18AF4' },
        { id: '2', nome: 'Modelo', color: '#348EE3' },

    ]

    function handleChange(e) {
        if (e.target.name === 'data' && params.data_fim < e.target.value) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else if (e.target.name === 'data_fim' && e.target.value < params.data) {
            setParams({ ...params, ['data']: e.target.value, ['data_fim']: e.target.value })
        } else {
            setParams({ ...params, [e.target.name]: e.target.value })
        }
    }

    const navList = ['']
    const steps = [
        <DiarioIecbForm />,
    ]
    const [step, setStep] = useState(0)

    return (
        <Context.Provider value={{ dialog, categ, status, card, setAtt, params, user }}>
            <Page>
                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[]}
                ><>
                        <InputDate
                            classe='input2'
                            name='data'
                            value={params.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='data_fim'
                            value={params.data_fim}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            padrao='Professoras'
                            options={docentes}
                            name='docente'
                            value={params.docente}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            padrao='Usuários'
                            options={users}
                            name='user'
                            value={params.user}
                            handleOnChange={handleChange}
                        />
                        <Select
                            classe='input2'
                            options={status}
                            name='status'
                            value={params.status}
                            handleOnChange={handleChange}
                        />
                        {/* <Select
                            classe='input2'
                            options={categorias}
                            name='tipo'
                            padrao='Categoria'
                            value={params.tipo}
                            handleOnChange={handleChange}
                        /> */}
                        <Button3
                            value={params.user == 100 ? 'Gerar Relatório' : 'Gerar Relatório de Venda'}
                            onClick={() => params.user == 100 ? createPDF(params, card) : createUserPDF(params, card)}
                        />

                    </>
                </Header>
                <Dialog value={text} />
                <body>
                    {steps[step]}
                </body>
            </Page>
        </Context.Provider>

    )
}
