import React, { useState, useEffect, useContext } from "react";
import './Day.scss'
import { getColor } from "./salaColor";
import Context from "../Context";

function SalaDay(props) {
    const { setBalao } = useContext(Context)

    function alert() {
        let resp = window.confirm(`Deseja excluir o bloqueio? ${props.card.id}`)
        if (resp) {
            fetch(`${process.env.REACT_APP_CALENDAR}/delete5/${props.card.id}`, {
                method: "DELETE",
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then((resp) => resp.json()).then((data) => {
                    props.setRefresh(Math.random())
                })
                .catch(err => console.log(err))
        }
    }

    function setClass() {
        if (props.card.data_fim) {
            if (props.card.hora >= props.horario) {
                if (props.card.hora_fim <= props.horario_fim) {
                    return ('fullBorder')
                } else {
                    return ('topBorder')
                }
            } else if (props.card.hora_fim <= props.horario_fim) {
                return ('bottomBorder')
            }
            if (props.card.hora < props.horario && props.card.hora_fim > props.horario_fim) {
                return ('mediumBorder')
            }
        } else {
            return ('empCard')
        }
    }


    return (
        <div className="salaCards" >


            {
                !props.card.data ?
                    <div className='salaCard'></div>
                    :

                    <div className={`salaCard salaAgendado ${setClass()}`}
                        style={{ backgroundColor: getColor(props.card.sala) }}
                        onClick={() => alert()}
                    >
                        {props.card.data &&
                            <header className={`balaoDay topLeft`}>
                                <p>Aparelho Bloqueado</p>
                                <p>{props.card.nome_procedimento}</p>
                                <p>{props.card.hora.substr(0, 5)} ~ {props.card.hora_fim.substr(0, 5)}</p>
                                {props.card.nota ? <p style={{ fontWeight: '600' }}>* {props.card.nota}</p> : ''}
                            </header>
                        }
                    </div>
            }

        </div>

    )
} export default SalaDay