import { useState, useContext, useEffect } from 'react'
import { InputText, InputCash, Button, InputTime, LabelText3, InputDate, InputDateC, Select, InputNumber, SelectC } from '../../a_model/Input'
import './InputDay.scss'
import { PesquisarAulas, PesquisarCursos } from './RBar'
import { BsArrowReturnLeft, BsTrash } from 'react-icons/bs'
import { AiOutlineForm, AiOutlineCarryOut } from 'react-icons/ai'
import { get, insert } from '../f_aux/fetch'
import Context from '../Context'
import moment from 'moment'
import { getTheme } from '../../a_model/Theme'
import { StepsForm } from '../../a_model/Container'

function InputDay() {
    const steps = [
        { content: <NovaAula />, nome: 'Cadastrar Nova Aula', color: '#f1f1f1' },
        { content: <NovoAgendamento />, nome: 'Agendar Aula', color: getTheme(localStorage.getItem('tema'), 1) }
    ]
    const [step, setStep] = useState(0)


    return (
        <div id='inputDayIecb'>

            <StepsForm
                step={step}
                setStep={setStep}
                steps={steps}
                height='100%'
            />
        </div>
    )
} export default InputDay

function NovaAula() {
    const { inputClose, dataCard, user } = useContext(Context)
    const [docentes, setDocentes] = useState([])

    useEffect(() => {
        get(`/getDocentesIecb/1`, setDocentes)
    }, [])

    const [project, setProject] = useState({ ...dataCard, ['hora']: '08:00', ['hora_fim']: '17:00', ['usuario']: user })


    function setCurso(e) {
        setProject({ ...project, ['id_curso']: e.id, ['nome']: e.nome, ['qnt']: e.qnt, ['valor']: e.valor })
    }

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function insertCadastro() {
        function then(resp) {
            if (resp) {
                // window.alert('Aula Cadastrada')
                inputClose('Aula Cadastrada!', 0)
                inputClose()
            } else {
                window.alert(`Erro`)
            }

        }
        if (project.docente) {
          
            insert('/insertAula', project, then)
        } else {
            window.alert('Selecione uma professora')
        }
    }

    return (
        <div id='cadastroAula' >
            <div >
                {
                    !project.nome ?
                        <PesquisarCursos setCurso={setCurso} />
                        :
                        <>
                            <LabelText3
                                style={{ maxWidth: '400px', marginBottom: '10px' }}
                                header="Curso"
                                onClick2={() => project.idevent == 0 && ''}
                            >
                                {project.nome}
                            </LabelText3>
                            <SelectC
                                width='200px'
                                name='docente'
                                flex='column'
                                padrao='Selecione...'
                                options={docentes}
                                value={project.docente}
                                text='Professora'
                                handleOnChange={(e) => handleChange(e)}
                            />
                            <div className='inline1'>

                                <InputTime
                                    disabled={false}
                                    flex='column'
                                    title='Início'
                                    name='hora'
                                    value={project.hora}
                                    width='95px'
                                    handleOnChange={(e) => handleChange(e)}
                                />
                                <InputTime
                                    flex='column'
                                    disabled={false}
                                    title='Término'
                                    name='hora_fim'
                                    value={project.hora_fim}
                                    width='95px'
                                    handleOnChange={(e) => handleChange(e)}
                                />

                            </div>
                            <InputCash
                                width='100px'
                                flex='column'
                                title='Valor'
                                name='valor'
                                value={project.valor}
                                handleOnChange={handleChange}
                            />

                            <InputText
                                disabled={false}
                                value={project.nota}
                                handleOnChange={(e) => handleChange(e)}
                                name='nota'
                                width='600px'
                                flex='column'
                                title='Anotação'
                            />
                        </>
                }
            </div>
            <footer>
                <Button
                    color="#447461"
                    value='Cadastrar'
                    click={() => insertCadastro()}
                >
                    <AiOutlineForm />
                </Button>
                <Button
                    color="#474747"
                    value='Voltar'
                    click={() => inputClose()}
                >
                    <BsArrowReturnLeft />
                </Button>
            </footer>
        </div>
    )
}

function NovoAgendamento() {
    const { inputClose, dataCard, user } = useContext(Context)
   
    const [project, setProject] = useState({ ...dataCard, ['hora_fim']: '17:00', ['usuario']: user })


    function setCurso(e) {
        setProject({ ...project, ['id_curso']: e.id, ['nome']: e.nome, ['qnt']: e.qnt, ['valor']: e.valor })
    }

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function insertCadastro() {
        function then(resp) {
            if (resp) {
                // window.alert('Aula Cadastrada')
                inputClose('Aula Cadastrada!', 0)
                inputClose()
            } else {
                window.alert(`Erro`)
            }

        }
        if (project.docente) {
            //console.log(project)
            insert('/insertAula', project, then)
        } else {
            window.alert('Selecione uma professora')
        }
    }

    return (
        <div id='cadastroAula' >
            <div >
                {
                    !project.nome ?
                        <PesquisarAulas setCurso={setCurso} />
                        :
                        <>
                            <LabelText3
                                style={{ maxWidth: '400px', marginBottom: '10px' }}
                                header="Curso"
                                onClick2={() => project.idevent == 0 && ''}
                            >
                                {project.nome}
                            </LabelText3>
                            <LabelText3
                                header="Professora"
                            >
                                {project.docente}
                            </LabelText3>
                            <div className='inline1'>

                                <InputTime
                                    disabled={false}
                                    flex='column'
                                    title='Início'
                                    name='hora'
                                    value={project.hora}
                                    width='95px'
                                    handleOnChange={(e) => handleChange(e)}
                                />
                                <InputTime
                                    flex='column'
                                    disabled={false}
                                    title='Término'
                                    name='hora_fim'
                                    value={project.hora_fim}
                                    width='95px'
                                    handleOnChange={(e) => handleChange(e)}
                                />

                            </div>
                            <InputCash
                                width='100px'
                                flex='column'
                                title='Valor'
                                name='valor'
                                value={project.valor}
                                handleOnChange={handleChange}
                            />

                            <InputText
                                disabled={false}
                                value={project.nota}
                                handleOnChange={(e) => handleChange(e)}
                                name='nota'
                                width='600px'
                                flex='column'
                                title='Anotação'
                            />
                        </>
                }
            </div>
            <footer>
                <Button
                    color="#447461"
                    value='Cadastrar'
                    click={() => insertCadastro()}
                >
                    <AiOutlineForm />
                </Button>
                <Button
                    color="#474747"
                    value='Voltar'
                    click={() => inputClose()}
                >
                    <BsArrowReturnLeft />
                </Button>
            </footer>
        </div>
    )
}

