import  { Container, Label } from '../../styles/CardStyle'
import { useDrag, useDrop } from "react-dnd";
import React, { useRef, useContext, useEffect } from 'react'
import Context from '../../Context';

export default function Card({ att, card, index, indexCards}){

    const ref = useRef()
  const { move, handleOpenModal } = useContext(Context)

  const [{ isDragging }, dragRef] = useDrag({
    item: { indexCards, index, id: card.id, status: card.status, telefone: card.telefone_l, att },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
      move(item, indexCards, att)
     // att()
    }
   
  })

  dragRef(dropRef(ref))


    return(
        <div className={`cardContainer ${isDragging? 'isDragging' : ''}`} onClick={()=> ''} ref={ref} isDragging={isDragging}>
           <header>

           <h3>{card.data_cadastro && card.data_cadastro.substr(0,10).split('-').reverse().join('/')}</h3>
           <Label color='#7159c1' />
            </header> 
            <p>{card.nome_l}</p>
            <p>{card.tentativas} tentativas</p>
            
        </div>
    )
}