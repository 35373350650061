import React, { useMemo, useContext, useEffect, useState } from "react";
import { Container } from '../../styles/CardsStyle'
import Card from "./Card";
import CardEmpty from "./CardEmpty";
import Context from "../../Context";
import '../components.css'
import { get } from "../../../calendar/f_aux/fetch";
import { SelectHeader } from "../../../a_model/Input";
import './Cards.scss'
import { getTheme } from "../../../a_model/Theme";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";

export default function Cards({ cardsRef }) {
    const { search, data, empresa, user, leads } = useContext(Context)
    const [cards, setCards] = useState(cardsRef);
    const [params, setParams] = useState({ status: cardsRef.status, page: 0, att: 0 })
    const statusList = [
        { nome: 'Msg enviada', cards: [], status: 0, restrict: false },
        { nome: 'Agendado', cards: [], status: 1, restrict: false },
        { nome: 'Sem interesse', cards: [], status: 2, restrict: false },
        { nome: 'Outro momento', cards: [], status: 3, restrict: false },
        { nome: 'Indeciso', cards: [], status: 4, restrict: false },
        { nome: 'Lixeira', cards: [], status: 5, restrict: false },
        { nome: 'Sem contato', cards: [], status: 6, restrict: false },
        { nome: 'Não atendido', cards: [], status: 7, restrict: false },
        { nome: 'Outros', cards: [], status: 8, restrict: false },
    ]
    useEffect(() => {

        get(`/getLead/${data.data}/${data.fim}/${user}/${empresa}/${params.status}/${params.page}`, setCards)

    }, [data, params])
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return cards.cards.filter(user => user.nome_l ? (user.nome_l.toLowerCase().includes(lowerBusca) || user.telefone_l.includes(lowerBusca)) : '');
    }, [search, cards])
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value })

    }
    function att() {
        setParams({ ...params, ['att']: Math.random() })

    }
    return (
        <div className='cardsContainer' style={{ width: 100 / (leads.filter(item => item.display === true).length + 1) + 'vw' }} key={cardsRef.nome}>
            <header>
                <SelectHeader
                    color={getTheme(localStorage.getItem('tema'), 2)}
                    value={cards.status}
                    name='status'
                    options={statusList}
                    handleOnChange={handleChange}
                />
            </header>
            <ul>
                {Array.isArray(cards.cards) && results.length > 0 ?
                    results.map((card, index) =>
                        <Card att={att} status={cards.status} card={card} index={index} indexCards={cards.status} key={index} />
                    )
                    :
                    <CardEmpty att={att} status={cards.status} index={0} indexCards={cards.status} />
                }
            </ul>
            <footer>
                <button  disabled={params.page > 0 ? false: true} onClick={() => setParams({ ...params, ['page']: params.page - 1 })}>
                    <MdOutlineArrowLeft color={params.page > 0 ? getTheme(localStorage.getItem('tema'), 0) : ''} />
                </button>
                <span>{params.page*15 + 1} - {(params.page + 1) *15}</span>
                <button onClick={() => setParams({ ...params, ['page']: params.page + 1 })}>
                <MdOutlineArrowRight color={getTheme(localStorage.getItem('tema'), 0)}/>
                </button>
            </footer>
        </div>
    )
}