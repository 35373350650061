import React, { useContext, useEffect, useMemo, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { get, insert, update } from "../../calendar/f_aux/fetch";
import { InputCash, InputNumber, Button, Select, LabelText3, InputText, InputSearchButton, InputSearch } from '../../a_model/Input'
import { LeftList2, SearchList, Table, Th } from "../../a_model/Contents";
import { toCash, toDate, toTel } from "../../a_model/functions";
import { Modal } from "../../a_model/Modal";
import CadastrarAcompanhamento from '../../agendaIecb/c_layouts/CadastrarAcompanhamento'
import { produce } from 'immer'
import Context from "../../calendar/Context";


export function InsertProduto() {

    const { user, setStep } = useContext(Context)
     const [modalIsOpen, setIsOpen] = useState(false)
    const [project, setProject] = useState(
        {
            id_cliente: 0,
            user,
            pagamentos: [],
            restante: 0,
            valor: 0
        })

    const formas = [
        { id: 1, nome: 'Débito', parcelas: false },
        { id: 2, nome: 'Dinheiro', parcelas: false },
        { id: 4, nome: 'Crédito', parcelas: true },
        { id: 11, nome: 'Pago pelo link', parcelas: true },
        { id: 17, nome: 'Pix', parcelas: false },
        { id: 3, nome: 'Cheque', parcelas: false },

    ]

    const titles = [
        { title: 'Forma' },
        { title: 'Valor' },
        { title: 'Parcelas' },
        { title: <HiOutlineTrash /> }
    ]

    const [pagamento, setPagamento] = useState({
        valor: 0,
        id_pagamento: 1,
        qnt: 1
    })

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function pgChange(e) {
        setPagamento({ ...pagamento, [e.target.name]: e.target.value })
    }

    function adicionar() {
        if (project.restante < parseFloat(pagamento.valor)) {
            window.alert(`Valor restante: R$${project.restante}`)
        } else {
            setProject(produce(project, draft => {
                draft.pagamentos.push(pagamento)
                draft.restante -= parseFloat(pagamento.valor)

            }))
            setPagamento({ qnt: 1, id_pagamento: 1, valor:  parseFloat(project.restante) - parseFloat(pagamento.valor) })
            
        }
    }
    function remover(index) {
        setProject(produce(project, draft => {
            draft.restante += parseFloat(draft.pagamentos[index].valor)
            draft.pagamentos.splice(index, 1)

        }))
    }



    function insertPagamento() {
        insert(`/insertProdutoIecb`, project, then)
        function then() {

setStep(0)
        }

    }

    function setName(cli, close) {
        setProject({ ...project, ['id_cliente']: cli.id, ['nome_cliente']: cli.nome })
        close && setIsOpen(false)
    }
    function limparCliente() {
          setProject({
            id_cliente: 0,
            user,
            pagamentos: [],
            restante: 0,
            valor: 0
        })

    }

    function setProdutoName(item) {
        setProject({ ...project, ['produto']: item.id, ['nome_produto']: item.nome, ['valor']: item.preco_venda })
        
    }

    function definir(){
        setProject({...project, ['restante']: project.valor})
        setPagamento({...pagamento, ['valor']: project.valor})
    }

    return (

        <div id='pgCard' style={{ minHeight: '70vh' }}>
            <header>
                {project.nome_cliente ?
                    <LabelText3
                        header='Cliente'
                    >
                        {project.nome_cliente}
                    </LabelText3>
                    :
                    <RBar
                        setIsOpen={setIsOpen}
                        setName={setName}
                    />
                }
                {

                    project.produto ?
                        <>
                            <LabelText3
                                header='Produto'
                            >
                                {project.nome_produto}
                            </LabelText3>
                            <div style={{ display: 'flex', alignItems: 'flex-end', width: '200px' }}>

                                {project.restante || project.pagamentos.length ?
                                    <>
                                        <LabelText3
                                            header='Valor'>
                                            {toCash(project.valor)}
                                        </LabelText3>
                                        <Button
                                        color='#555'
                                            value='Limpar'
                                            click={() => limparCliente()}
                                        />
                                    </>
                                    :
                                    <>
                                        <InputCash
                                            width='120px'
                                            flex='Column'
                                            name='valor'
                                            value={project.valor}
                                            title='Valor'
                                            handleOnChange={handleChange}
                                        />
                                         <Button
                                            value='Definir'
                                            color='#2d4492'
                                            click={() => definir()}
                                        />
                                    </>
                                }
                            </div>
                        </>
                        :
                        project.id_cliente ?
                            <ProcsBar
                                setProject={setProdutoName}
                            />
                            : ''
                }

            </header>
            <body>{
                (project.restante || project.pagamentos.length) ?
                <LabelText3
                    header='Restante'
                >
                    {toCash(project.restante)}
                </LabelText3> : ''
            }
                {project.pagamentos.length > 0 &&
                    <div style={{ margin: '10px' }}>
                        Inserir:

                        <Table height='152px'
                        >
                            <Th array={titles} />
                            {project.pagamentos.map((pg, index) =>
                                <tr key={index}>
                                    <td>
                                        {formas.find((element) => element.id == pg.id_pagamento).nome}
                                    </td>
                                    <td>
                                        {parseFloat(pg.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td>
                                        {parseInt(pg.qnt) > 1 ? pg.qnt + 'x' : 'À Vista'}
                                    </td>
                                    <td onClick={() => remover(index)}>
                                        <HiOutlineTrash />
                                    </td>
                                </tr>
                            )}
                        </Table>



                    </div>
                }
                <div >
                    { project.restante  ?
                        
                            <div id='inputPg'>
                                <Select
                                    options={formas}
                                    name='id_pagamento'
                                    value={pagamento.id_pagamento}
                                    handleOnChange={pgChange}
                                />
                                {formas.find((element) => element.id == pagamento.id_pagamento).parcelas &&
                                    <InputNumber
                                        width='60px'
                                        min='1'
                                        max='12'
                                        name='qnt'
                                        value={pagamento.qnt}
                                        handleOnChange={pgChange}
                                    />}
                                <InputCash
                                    width='125px'
                                    name='valor'
                                    value={pagamento.valor}
                                    handleOnChange={pgChange}
                                />

                                <Button
                                    color='#447461'
                                    value='Inserir'
                                    disabled={pagamento.valor > 0 ? false : true}
                                    click={() => adicionar()}
                                />

                            </div> :''

                    }
                </div>

            </body>
            <footer>

                <div className='inline1'>
                    {(project.restante === 0 && project.pagamentos.length > 0) &&
                        <Button
                            color='#2d4492'
                            value='Salvar'
                            click={() => insertPagamento()}
                        />}

                </div>
            </footer>
            <Modal
                setIsOpen={setIsOpen} modalIsOpen={modalIsOpen}
            >
                <CadastrarAcompanhamento
                    setRefresh={setName}
                    id_leads={0}
                    user2={user}
                    id_aula={0}
                />

            </Modal>

        </div>
    )
}

function RBar({ setName, setIsOpen }) {
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([])
    useEffect(() => {
        search.length > 1 &&
            get(`/getClientesAgendarIecb/${search}/${1}`, setResult)

    }, [search])

    function cadastrar() {
        setIsOpen(true)
    }



    return (
        <div style={{ marginTop: '20px' }}>
            <InputSearchButton
                width='100%'
                cadastrar={cadastrar}
                search={search}
                setSearch={setSearch}
                button='Cadastrar'
                placeholder="Pesquisar aluno..."

            >
                {search &&
                    <ListItem

                        incluir={setName}
                        alunos={result}
                        search={search}
                    />}
            </InputSearchButton>
        </div>
    );
}

function ListItem({ alunos, incluir }) {

    return (
        <ul >
            {alunos.map((aluno, index) => (
                !aluno.leads &&
                <li key={index} onClick={() => incluir(aluno, false)}>

                    <div style={{ color: aluno.leads ? '#4864e2' : '' }}>
                        {aluno.nome || '-'}

                    </div>

                    <div style={{ color: aluno.leads ? '#4864e2' : '' }}>

                        {toTel(aluno.telefone)}

                    </div>
                </li>

            ))}
        </ul>
    );
}

function ProcsBar({ setProject }) {
    const [search, setSearch] = useState('');
    const [procs, setProcs] = useState([{ nome: '', id: '' }])
    useEffect(() => get(`/get/pacotes_servico/SP/P`, setProcs), []);

    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return procs.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <div style={{ marginTop: '20px' }}>
            <InputSearch
                placeholder='Pesquisar produto...'
                width='350px'
                search={search}
                setSearch={setSearch}>

                <ListProdutos
                    search={search}
                    setProject={setProject}
                    results={results}
                />
            </InputSearch>
        </div>
    );
}

const ListProdutos = React.memo(({ results, setProject, search }) => {
    return (
        search.length > 2 &&
        <>
            <ul>
                {results.map((result, index) => (
                    <li
                        key={index}
                        onClick={() => setProject(result)}
                        onFocus={() => setProject(result)} // Adicione um evento de foco
                        tabIndex={0} // Torna o elemento focável
                    >     <span>{result.nome || '-'} - {toCash(result.preco_venda)} </span>
                    </li>
                ))}
            </ul>
        </>
    );
});


