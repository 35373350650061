import React, { useEffect, useContext, useState, useRef } from "react";
import { Context } from "../e_contexts/AuthContext";
import { get, insert } from "../../calendar/f_aux/fetch";
import "./Form.scss"
import { getColor } from '../../calendar/c_layouts/salaColor'
import { InputCash, InputSearch, InputText, LabelText4 } from "../../a_model/Input";
import { Modal } from "../../a_model/Modal";
import { useMemo } from "react";
import { produce } from "immer";
import { toCash, toDate } from "../../a_model/functions";

export default function Indicacao() {
    const { cardForm } = useContext(Context)
  
    const [step, setStep] = useState(0)

    const steps = [
        <Indicacoes setStep={setStep}/>,
        <Indicar setStep={setStep}/>

    ]

    useEffect(() => {

    }, [cardForm])


    return (
        steps[step]
    )
}

function Indicacoes({setStep}) {
    const { cardForm } = useContext(Context)
    const [vendas, setVendas] = useState([])
    function then(resp) {
        setVendas(resp.vendas1)
    }
    useEffect(() => {
        get(`/getVendas/${cardForm.id_cliente}`, then)

    }, [])

    return (
        <><header>
            <button style={{ fontSize: '20px', margin:'15px' }} onClick={()=> setStep(1)}>Indicar</button>
        </header>

            <body>
                <div id='gridMobile'>
                    {vendas.map((card, index) =>

                        <div className="indicacaoCard" key={index}>
                            <header>{toDate(card.data)} - {card.avaliadora}</header>
                            {card.vendas_sub.map((item, index) =>
                                <div key={index}>
                                    <span style={{ fontWeight: 600, fontSize: '17px', color: item.venda == 'Sim' ? '#088b13' : '' }}>
                                        {item.nome_procedimento}
                                    </span>
                                    <span>
                                        {toCash(item.total)}
                                    </span>
                                </div>
                            )}
                        </div>


                    )}
                </div>
            </body>
        </>

    )

}

function Indicar() {
    const { cardForm, toForm, } = useContext(Context)

    const [project, setProject] = useState
        ({
            id_cliente: cardForm.id_cliente,
            login: localStorage.getItem('login'),
            empresa: cardForm.empresa,
            unidade: cardForm.unidade,
            itens: [],
            obs_pagamento: ''
        })
    const [modalIsOpen, setIsOpen] = useState(true)


    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
        //   //console.log(project.hora_fim)
    }
    function remover(index) {
        setProject(produce(project, draft => {
            draft.itens.splice(index, 1)
            draft.valor = 0
            for (let item of draft.itens) {
                draft.valor += parseFloat(item.valor) * item.qnt
            }
        }))
    }

    function inserir() {
        function then() {
            window.alert('Indicação Inserida.')
            toForm({}, 0)
        }
        insert(`/insertIndicacao`, project, then)
    }

    return (
        <div id="mobileForm">
            <header style={{ height: 'auto', padding: '10px' }}>
                <div>
                    <p>
                        Cliente: <span>{cardForm.nome_cliente}</span>
                    </p>

                </div>

            </header>
            <body>

                <div className="indicacaoCard">
                    <header>Registrar indicação</header>
                    {project.itens.map((item, index) =>
                        <div key={index}>
                            <span style={{ color: getColor(item.sala) + "99", WebkitTextStrokeColor: getColor(item.sala), fontWeight: 600, fontSize: '17px' }}>
                                {item.nome_procedimento}
                            </span>
                            <span>
                                Região: {item.regiao}
                            </span>
                            <span>
                                {toCash(item.total)}
                            </span>
                        </div>
                    )}
                </div>
                <button
                    className="buttonMobile"
                    onClick={() => setIsOpen(true)}
                >Adicionar</button>
            </body>
            <footer>
                <div className="inputTextMobile" style={{ marginTop: '25px' }}>
                    <span>Anotação</span>
                    <input
                        name='obs_pagamento'
                        value={project.obs_pagamento}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <button
                    style={{ marginTop: '25px' }}
                    className="buttonMobile"
                    disabled={project.itens.length > 0 ? false : true}
                    onClick={() => inserir()}
                >
                    Salvar
                </button>
            </footer>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >
                <InsertItem
                    project={project}
                    setProject={setProject}
                    setIsOpen={setIsOpen}
                />
            </Modal>

        </div>
    )
}


function InsertItem({ project, setProject, setIsOpen }) {
    const [procs, setProcs] = useState([])
    const [proc, setProc] = useState({ idproduto: false })
    function handleChange(e) {
        setProc({ ...proc, [e.target.name]: e.target.value })
        //console.log(proc)
    }
    function setProc2(item) {
        setProc({ total: item.preco_venda, valor: item.preco_venda, nome_procedimento: item.nome, idproduto: item.id, qnt: item.qnt_sessao, sala: item.chave, SP: item.SP })
    }

    useEffect(() => {
        setProc({})
        get(`/buscarProcsProds`, setProcs)
    }, [])

    function adicionar() {
        setProject(produce(project, draft => {
            draft.itens.push(proc)
        }))
        setIsOpen(false)
    }



    return (
        <div id='procModal'>
            <h3>Selecione o procedimento</h3>
            {proc.idproduto ?
                <>
                    <LabelText4
                        header='Procedimento'
                    >
                        {proc.nome_procedimento}
                    </LabelText4>
                    <InputCash
                        title='Valor'
                        flex='column'
                        name='total'
                        width='90px'
                        value={proc.total || ''}
                        handleOnChange={handleChange}
                    />
                    <InputText
                        title='Região'
                        flex='column'
                        name='regiao'
                        width='250px'
                        value={proc.regiao}
                        handleOnChange={handleChange}

                    />

                    <button
                        className="buttonMobile"
                        onClick={() => adicionar()}
                    >
                        Inserir
                    </button>
                </>
                :
                <ProcsBar
                    procs={procs}
                    setProc2={setProc2}
                />
            }
        </div>
    )
}

function ProcsBar({ procs, setProc2 }) {
    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return procs.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            width='350px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setProc2={setProc2}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setProc2, search }) {
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setProc2(result)}>
                        <span>{result.nome || '-'}</span>{result.telefone && <span>{formatTel(result.telefone)}</span>}
                    </li>

                ))}
            </ul>

        </>
    );

}

