import React, { useState, useEffect, useContext } from 'react'
import { Container } from '../styles/LeadsStyle'
import { Header } from "../../a_model/Header";
import Body from './Body'
import { useParams } from "react-router";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { produce } from 'immer'
import Context from '../Context';
import moment from 'moment'
import Modal from 'react-modal';
import LeadsForm from './contents/LeadsForm';
import { Dialog } from './Dialog';
import Relatorio from './Relatorio';
import { Button, InputDate, InputSearch } from "../../a_model/Input";
import { getTheme } from '../../a_model/Theme';
import {get} from '../../calendar/f_aux/fetch'
import Cadastro from './contents/Cadastro';

export default function Leads() {
  const { userp } = useParams();
  const user = userp || localStorage.getItem('login')
  const [leads, setLeads] = useState([]);
  const [data, setData] = useState({ data: '0000-00-00', fim: moment().format('YYYY-MM-DD'), unidade: 1 })
  const [form, setForm] = useState({});
  const [text, setText] = useState(false);
  const [step, setStep] = useState(0)
  const [search, setSearch] = useState('');
  const [stateData, setStateData] = useState(0)
  const navList = ['Leads', 'Relatório', 'Cadastrar']

  function handleStateData(state) {

    if (state === 0) {
      setData({ data: '0000-00-00', fim: moment().format('YYYY-MM-DD'), unidade: 1 })
    } else {
      setData({ data: `${moment().format('YYYY-MM')}-01`, fim: moment().format('YYYY-MM-DD'), unidade: 1 })
    }
    setStateData(state)

  }

  function handleChange(e) {

    if (e.target.name === 'data' && data.fim < e.target.value) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else if (e.target.name === 'fim' && e.target.value < data.data) {
      setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }

  }
  function dialog(text, type) {
    setText({ text: text, type: type })
  }
  useEffect(() => {
    if(step === 0)get(`/buscarLeads/${data.data}/${data.fim}/${user}`, setLeads)
  }, [data, form, step])

  function move(item, indexCards, status) {
    get(`/verificaLead/${item.id}/${item.status}`, then)

    function then(resp){
      if (resp) {
        setForm({})
        window.alert(`Esse Lead não está mais nesta categoria`)
      } else {
        let project = { status, id: item.id, user }
        fetch(`${process.env.REACT_APP_CALENDAR}/updateStatus`, {
          method: "PUT",
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(project),
        })
          .then((resp) => resp.json()).then((resp2) => {

              setNoRegister(false)
              setLeads(produce(leads, draft => {
                const dragged = draft[item.indexCards].cards[item.index]
                draft[item.indexCards].cards.splice(item.index, 1)
                draft[indexCards].cards.splice(0, 0, dragged)
                setForm({})
              }))
          })
          .catch(err => console.log(err))
      }

    }


  }


  const customStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '3px',
        borderRadius: '7px',
        transform: 'translate(-50%, -50%)',
    }
}

  const [modalIsOpen, setIsOpen] = useState(false)
  const [noRegister, setNoRegister] = useState(false)
  function handleCloseModal() {
    setIsOpen(false)
    setForm({})

  }

  function handleOpenModal(card) {
  
    setForm(card)
    setIsOpen(true)
  }
  const steps = [<Body />, <Relatorio />, <Cadastro/>]

  const buttons = [

    <input
    className='input'
      type='button'
      value='Todos'
      onClick={() => handleStateData(1)}
    />,
    <input
    className='input'
      type='button'
      value='Data'
      onClick={() => handleStateData(0)}
    />
  ]
  const buttons2 = [
    <label> </label>
  ]
  return (
    <Context.Provider value={{ search, setSearch, step, setStep, leads, setLeads, move, user, handleOpenModal, form, data, setData, dialog }}>
      <Dialog value={text} />
      <DndProvider backend={HTML5Backend}>

        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={customStyles}
        >
          <LeadsForm form={form} handleCloseModal={handleCloseModal} noRegister={noRegister} setNoRegister={setNoRegister} />
        </Modal>
        <Container>
          <Header
            background={getTheme(localStorage.getItem('tema'),0)}
            setStep={setStep}
            navList={navList}
            buttons={buttons2}
          ><>
              <InputSearch search={search} setSearch={setSearch} />

              {buttons[stateData]}

              {stateData === 1 &&
                <><InputDate
                  name='data'
                  value={data.data}
                  handleOnChange={handleChange}
                />
                  <InputDate
                    name='fim'
                    value={data.fim}
                    title='até'
                    handleOnChange={handleChange}
                  /></>}
            </>
          </Header>
          {steps[step]}
        </Container>
      </DndProvider>
    </Context.Provider>
  )
}
