import React, { useContext, useState, useEffect } from "react";
import Context from '../Context';
import { Table, Th } from "../../a_model/Contents";
import { Form } from "../../a_model/Container";
import { get } from "../../calendar/f_aux/fetch";

export default function Relatorio() {

    const { data } = useContext(Context)
    const [leads, setLeads] = useState([])
    useEffect(() => {

      get(`/getRelatorioLeadsIecb/${data.data}/${data.fim}/${data.interesse}`, setLeads)
    }, [data])
    const thead = [
        {width:'',title:'Data'},
        {width:'',title:'Nome'},
        {width:'',title:'Telefone'},
        {width:'',title:'Interesse'},
    ]


    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)} ${retorno.substr(-4, 4)}`
    }

    return (
        <Form>
            <Table footer={`Total: ${leads.length} registros`}>
                <Th array={thead} />
                {
                    leads.map((lead, index) =>
                        <tr key={index}>
                            <td>
                                {lead.data_cadastro_l.substr(0, 10).split('-').reverse().join('/')}
                            </td>
                            <td style={{maxWidth:'250px'}}>
                                {lead.nome_l}
                            </td>
                            <td style={{maxWidth:'180px'}}>
                                {formatTel(lead.telefone_l)}
                            </td>
                            <td style={{maxWidth:'250px'}}>
                                {parseInt(lead.count) > 1? lead.count+ ` interesses` : lead.interesse_l}
                            </td>
                        </tr>
                    )
                }

            </Table>
        </Form>
    )
}